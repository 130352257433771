import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import {IUploadableResourceState, UploadStatusType} from "../../store/common/UploadableResource";
import SuccessfulUpload from "./uploaddialog/SuccessfulUpload";
import FailedUpload from "./uploaddialog/FailedUpload";
import ProgressingUpload from "./uploaddialog/ProgressingUpload";
import CloseIcon from "@mui/icons-material/Close";
import UploadFilePanel from "../UploadFilePanel";
import {CancelButton} from "../button/CancelButton";
import {_transl} from "../../store/localization/TranslMessasge";
import {AttachmentTranslationKey} from "./AttachmentTranslationKey";


const margin = 3;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
        },
        dialogTitle: {
            color: "gray",
            padding: 0,
            textAlign: "center",
            "& div": {
                padding: theme.spacing(margin)
            },
            "& hr": {
                marginLeft: theme.spacing(margin),
                marginRight: theme.spacing(margin),
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
        dialogContent: {
            padding: theme.spacing(margin),
            paddingTop: theme.spacing(margin + 1),

        },
        uploadArea: {
            color: "gray",
            display: "flex",
            flexDirection: "column",
            maxHeight: "400px",
        },
        uploadAreaTitle: {
            fontSize: "1.2em"
        },
        uploadAreaFiles: {
            flexGrow: 1,
            overflow: "scroll"
        },
        uploadAreaFilesNoFilesArea: {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        uploadAreaFilesFilesArea: {
            width: "100%",
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
        },
        uploadAreaClearButton: {
            marginLeft: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        cancelButton: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.grey[100],
        }
    })
);

interface Props {
    opened: boolean,
    uploadedAttachments: IUploadableResourceState[],
    uploadAttachment: (file: File) => void,
    removeFromUploaded: (item: IUploadableResourceState) => void,
    onClosed?: () => void,
}

export default function UploadDialog(props: Props) {

    const {opened, uploadedAttachments, onClosed, uploadAttachment, removeFromUploaded} = props;
    const classes = useStyles();

    function renderUploadedItems() {
        const items = uploadedAttachments.map(uploaded => {
            const status = uploaded.uploadStatus.status;

            if (status === UploadStatusType.SUCCESSFUL) {
                return <SuccessfulUpload key={uploaded.itemId} item={uploaded} />

            } else if (status === UploadStatusType.FAILED) {
                return <FailedUpload key={uploaded.itemId} item={uploaded} />

            } else {
                return <ProgressingUpload key={uploaded.itemId} item={uploaded} />
            }
        });

        return <div className={classes.uploadAreaFilesFilesArea}>
            {items}
        </div>
    }

    function getFinishedItems() {
        return uploadedAttachments.filter(item => item.uploadStatus.status === UploadStatusType.SUCCESSFUL || item.uploadStatus.status === UploadStatusType.FAILED);
    }

    function clearFinishedItems() {
        getFinishedItems().forEach(item => removeFromUploaded(item));
    }

    return (
        <Dialog
            open={opened}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            onClose={onClosed}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle id="scroll-dialog-title" className={classes.dialogTitle}>
                <div>{_transl(AttachmentTranslationKey.UPLOAD_TITLE)}</div>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClosed}
                    size="large">
                    <CloseIcon />
                </IconButton>
                <Divider />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={5}>
                        <UploadFilePanel uploadAttachment={uploadAttachment}/>
                    </Grid>
                    <Grid item xs={12} sm={7} className={classes.uploadArea}>
                        <div className={classes.uploadAreaTitle}>
                            {_transl(AttachmentTranslationKey.UPLOAD_AREA)}{getFinishedItems().length > 0
                            && <Button
                            className={classes.uploadAreaClearButton}
                            variant={"contained"}
                            size={"small"}
                            onClick={() => clearFinishedItems()}>{_transl(AttachmentTranslationKey.UPLOAD_CLEAR)}</Button>}
                        </div>
                        <div className={classes.uploadAreaFiles}>

                            {uploadedAttachments != null && uploadedAttachments.length > 0 && renderUploadedItems()}

                            {(uploadedAttachments === null || uploadedAttachments.length === 0) && <div className={classes.uploadAreaFilesNoFilesArea}>
                                {_transl(AttachmentTranslationKey.UPLOAD_AREA_NO_FILES)}
                            </div>}
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={() => onClosed && onClosed()} variant={"outlined"}/>
            </DialogActions>
        </Dialog>
    );

}
