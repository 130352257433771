import React, {useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import Wizard from "../../../../../components/wizard/Wizard";
import {IconButton, Paper} from "@mui/material";
import {AjaxResponse} from "rxjs/ajax";
import {WizardStep} from "../../../../../components/wizard/WizardStep";
import {BlobUtils} from "../../../../../common/BlobUtils";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DiagramsPickDialog from "../../diagrams/DiagramsPickDialog";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {ValidateModelTranslationKey} from "./ValidateModelTranslationKey";
import RouteDefinitionUtils, {validateModelPage} from "../../../../../common/routedefinition/RouteDefinitionUtils";
import {useHistory} from "react-router-dom";
import validateModelService, {MetamodelValidationDto} from "../../../../../common/apis/MetamodelService";
import {DiagramInfoDto} from "../../../../../common/apis/diagram/DiagramInfoDto";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import clsx from "clsx";
import CommonCssStyles from "../../../../../css/CommonCssStyles";
import {createValidateModelGridColDef} from "./ValidateModelGrid";
import {PersistentStateId} from "../../../../../store/common/Grid";
import {ActionButtonType, EnabledPolicy, GridAction} from "../../../../../components/grid/GridAction";
import SearchIcon from "@mui/icons-material/Search";
import ExtGridWrapper from "../../../../../components/grid/ExtGridWrapper";


const elementsGridDivBorderColor = "lightgray";
const elementsGridDivBackgroundColor = "white";

enum ButtonId {
    PICK_DIAGRAM = "PICK_DIAGRAM",
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: CommonCssStyles.getRootPageStyles(theme),
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme, {paddingTop: theme.spacing(7)}),
        itemsGridDiv: {
            color: "gray",
            backgroundColor: elementsGridDivBackgroundColor,
            padding: theme.spacing(1),
        },
        settingsDiv: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
            border: "1px solid " + elementsGridDivBorderColor,
            backgroundColor: elementsGridDivBackgroundColor,
            width: "100%",
            height: "100%",
        },
        wizardElementSelectionDescription: {
            display: "flex",
            alignItems: "center",
            gap: "1em",
        },
        explicitElementSelectionDiv: {
            marginTop: "1em",
        }
    }));


export function ValidateModelPage() {

    const [testedDiagram, setTestedDiagram] = useState<DiagramInfoDto>();
    const [metamodelDiagram, setMetamodelDiagram] = useState<DiagramInfoDto>();
    const [diagramDialogOpened, setDiagramDialogOpened] = useState<boolean>(false);
    const history = useHistory();
    const classes = useStyles();
    const validation = "validation.xlsx"


    return <Paper className={classes.page}>
        <div className={classes.headerPageSegment}>
            <Typography variant="h6">
                {_transl(ValidateModelTranslationKey.VALIDATE_AGAINST_METAMODEL)}
            </Typography>
        </div>
        <Divider />
        <div className={clsx(classes.controlPageSegment)}>
            <Wizard steps={getSteps()}
                    lastStepLabel={_transl(ValidateModelTranslationKey.VALIDATION_RESULT)}
                    lastStepButtonLabel={_transl(ValidateModelTranslationKey.VALIDATE_MODEL_BUTTON)}
                    lastStepAction={() => doExport()}
                    lastStepActionSuccessProcessor={(result) => {
                        const response = result as AjaxResponse;
                        const blob = new Blob([response.response]);

                        BlobUtils.saveBlob(blob, validation);
                    }}
                    lastStepActionInProgressText={_transl(ValidateModelTranslationKey.MODEL_VALIDATING_IN_PROGRESS)}
                    lastStepActionSuccessfulText={_transl(ValidateModelTranslationKey.VALIDATION_IS_DONE)}
                    lastStepActionSuccessfulTextAdditionalInfo={_transl(ValidateModelTranslationKey.VALIDATION_IS_DONE_ADDITIONAL_INFO)}
                    lastStepActionFailedText={_transl(ValidateModelTranslationKey.MODEL_VALIDATING_FAILED)}
                    cancelWizard={() => resetExport()}
                    wizardGridMinHeight={"25em"}
            />
        </div>
    </Paper>

    function resetExport() {
        const path = RouteDefinitionUtils.resolvePath(validateModelPage, {});
        history.push(path);
    }

    function getSteps(): WizardStep[] {

        return [
            new WizardStep(
                _transl(ValidateModelTranslationKey.SELECT_MODEL),
                <AccountTreeIcon/>,
                <div className={classes.wizardElementSelectionDescription}>
                    <div>{_transl(ValidateModelTranslationKey.MODEL_DIAGRAM_SELECTION_LABEL)}</div>
                    <div>
                        <IconButton
                            aria-label="close"
                            onClick={() => setDiagramDialogOpened(true)}
                            size="large">
                            <SearchIcon/>
                        </IconButton>
                    </div>
                </div>,
                false,
                () => renderSelectDiagramsStep(testedDiagram, setTestedDiagram),
                () => canProceedFromSelectTestedDiagramStep()),
            new WizardStep(
                _transl(ValidateModelTranslationKey.SELECT_METAMODEL),
                <AccountTreeIcon/>,
                <div className={classes.wizardElementSelectionDescription}>
                    <div>{_transl(ValidateModelTranslationKey.MODEL_DIAGRAM_SELECTION_LABEL)}</div>
                    <div>
                        <IconButton
                            aria-label="close"
                            onClick={() => setDiagramDialogOpened(true)}
                            size="large">
                            <SearchIcon/>
                        </IconButton>
                    </div>
                </div>,
                false,
                () => renderSelectDiagramsStep(metamodelDiagram, setMetamodelDiagram),
                () => canProceedFromSelectMetamodelDiagramStep()),
        ];
    }

    function renderSelectDiagramsStep(diagram: DiagramInfoDto | undefined, setDiagram:  React.Dispatch<React.SetStateAction<DiagramInfoDto | undefined>>): JSX.Element {

        return <div className={classes.itemsGridDiv}>

            {diagramDialogOpened &&
                <DiagramsPickDialog isOpened={true}
                                    isMultiSelection={false}
                                    onDiagramsPicked={(pickedDiagrams) => {
                                        setDiagram(pickedDiagrams[0]);
                                        setDiagramDialogOpened(false);
                                    }}
                                    onDialogClosed={() => setDiagramDialogOpened(false)}/>
            }
            <ExtGridWrapper
                columns={createValidateModelGridColDef(_transl)}
                rows={diagram !== undefined ? [diagram] : []}
                rowCount={diagram !== undefined ? 1 : 0}
                getRowId={row => row.identifier}
                actions={[
                    GridAction.buttonBuilder(ButtonId.PICK_DIAGRAM, ActionButtonType.IMMEDIATE,
                        _transl(ValidateModelTranslationKey.MODEL_DIAGRAM_SELECTION_LABEL),
                        <SearchIcon/>)
                        .enabledPolicy(EnabledPolicy.ALWAYS)
                        .onClick(() => setDiagramDialogOpened(true)).build(),
                ]}
                peristentStateId={PersistentStateId.VALIDATE_MODEL_PAGE_GRID}
                resourceId={"diagrams"}
            />
        </div>
    }

    function canProceedFromSelectTestedDiagramStep(): boolean {
        return testedDiagram !== undefined;
    }

    function canProceedFromSelectMetamodelDiagramStep(): boolean {
        return metamodelDiagram !== undefined;
    }

    function doExport(): Promise<any> {

        const dto: MetamodelValidationDto = {
           testedDiagramIdentifier: testedDiagram ? testedDiagram.identifier : "",
           metamodelDiagramIdentifier: metamodelDiagram ? metamodelDiagram.identifier: "",
           extractMetamodel: true,
        }
        return validateModelService.validateModel(dto);
    }
}
