import {StyleSettings, UpdateMode} from "./StyleSettings";
import {StyleDto} from "../../../common/apis/diagram/StyleDto";

export class StyleSettingsApplicator {

    applyStyleSettings(styleSettings: StyleSettings, style?: StyleDto): StyleDto | undefined {
        const resultStyle: StyleDto = {
            fillColor: null,
            lineColor: null,
            font: {
                color: null,
                styles: null,
                size: null,
                name: null
            }
        };

        if (styleSettings.fillColorUpdateMode === UpdateMode.USE_CUSTOM) {
            resultStyle.fillColor = styleSettings.fillColor;
        } else if (styleSettings.fillColorUpdateMode === UpdateMode.KEEP_UNCHANGED) {
            resultStyle.fillColor = style?.fillColor || null;
        }

        if (styleSettings.lineColorUpdateMode === UpdateMode.USE_CUSTOM) {
            resultStyle.lineColor = styleSettings.lineColor;
        } else if (styleSettings.lineColorUpdateMode === UpdateMode.KEEP_UNCHANGED) {
            resultStyle.lineColor = style?.lineColor || null;
        }

        if (styleSettings.textColorUpdateMode === UpdateMode.USE_CUSTOM) {
            resultStyle.font!.color = styleSettings.textColor;
        } else if (styleSettings.textColorUpdateMode === UpdateMode.KEEP_UNCHANGED) {
            resultStyle.font!.color = style?.font?.color || null;
        }

        if (styleSettings.fontStylesUpdateMode === UpdateMode.USE_CUSTOM) {
            resultStyle.font!.styles = styleSettings.fontStyles;
        } else if (styleSettings.fontStylesUpdateMode === UpdateMode.KEEP_UNCHANGED) {
            resultStyle.font!.styles = style?.font?.styles || null;
        }

        if (styleSettings.fontSizeUpdateMode === UpdateMode.USE_CUSTOM) {
            resultStyle.font!.size = styleSettings.fontSize;
        } else if (styleSettings.fontSizeUpdateMode === UpdateMode.KEEP_UNCHANGED) {
            resultStyle.font!.size = style?.font?.size || null;
        }

        return resultStyle;
    }
}
