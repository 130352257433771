import React, {useEffect, useRef, useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Wizard from "../../../../components/wizard/Wizard";
import UploadFilePanel from "../../../../components/UploadFilePanel";
import PickList from "../../../../components/picklist/PickList";
import {CollectionDto} from "../../../../common/apis/collection/CollectionDto";
import {IApplicationState} from "../../../../store/Store";
import {useDispatch, useSelector} from "react-redux";
import {MenuItem, Select} from "@mui/material";
import Api from "../../../../common/Api";
import {IElementsImportMetadataDto} from "../../../../common/apis/Imports";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {WizardStep} from "../../../../components/wizard/WizardStep";
import {FetchableResourceType, getResourceFetchAction} from "../../../../store/common/FetchableResource";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ImportTranslationKey} from "./ImportTranslationKey";
import {FileFormat, FileType} from "./FileType";
import {AjaxError} from "rxjs/ajax";
import {ImportErrorCode} from "./ImportErrorCode";
import {ValidationError} from "../../../../common/ValidationError";


const pickListBackgroundColor = "whitesmoke";
const pickListSubheaderBackgroundColor = "rgb(235, 235, 235)";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pickListDiv: {
            color: "gray",
            border: "1px solid lightgray",
            backgroundColor: pickListBackgroundColor,
            padding: theme.spacing(1),
        },
        uploadContentFileName: {
            fontSize: "1.4em",
            marginBottom: theme.spacing(5),
        },
        uploadContentFileTypeLabel: {
            marginRight: theme.spacing(2),
            fontWeight: "bolder",
        },
    })
);

interface Props {
    // router props
    resetImport: () => void,
}

export default function ElementImportWizard(props: Props) {

    const classes = useStyles();

    const [selectedFile, setSelectedFile] = useState<File>();
    const [selectedFileType, setSelectedFileType] = useState<FileType>();
    const [selectedFileTypeRecognitionDone, setSelectedFileTypeRecognitionDone] = useState<boolean>(false);
    const [selectedOptions, setSelectedOptions] = useState<Array<CollectionDto>>();

    const dispatch = useDispatch();
    const collections = useSelector((state: IApplicationState) => state.pages.common.options.collections.resource);
    const resourcesRefetched = useRef<boolean>(false);

    useEffect(() => {
        if (!resourcesRefetched.current) {
            dispatch(getResourceFetchAction(FetchableResourceType.COMMON_COLLECTION_OPTIONS));
            resourcesRefetched.current = true;
        }
    }, [resourcesRefetched, dispatch]);

    useEffect(() => {
        if (selectedFile && !selectedFileType && !selectedFileTypeRecognitionDone) {
            const fileTypeByFileName = FileType.findFileTypeByFileName(selectedFile?.name);
            setSelectedFileTypeRecognitionDone(true);
            setSelectedFileType(fileTypeByFileName || undefined);
        }
    }, [props, selectedFile, selectedFileType, selectedFileTypeRecognitionDone]);

    function getSteps(): WizardStep[] {
        return [
            new WizardStep(_transl(ImportTranslationKey.CHOOSE_FILE_TITLE), <CloudUploadIcon/>, _transl(ImportTranslationKey.CHOOSE_FILE_AND_TYPE_ICON), false,
            () => renderSelectFileStep(), () => canProceedFromSelectFileStep()),
            new WizardStep(_transl(ImportTranslationKey.ADD_COLLECTIONS), <GroupAddIcon />, _transl(ImportTranslationKey.ADD_COLLECTIONS_ICON), true,
                () => renderAssignGroupsStep(), () => canProceedFromAssignGroupStep())
        ];
    }

    function renderSelectFileStep(): JSX.Element {
        const uploadPanelContentGetter = selectedFile != null ? () => createFileTypeSelect() : undefined;

        return <UploadFilePanel uploadAttachment={file => setSelectedFile(file)}
                                uploadPanelContent={uploadPanelContentGetter}/>
    }

    function createFileTypeSelect(): JSX.Element {
        const nonSelectedFileType = " ";

        return <React.Fragment>
            <div className={classes.uploadContentFileName}>{selectedFile?.name}</div>
            <div>
                <span className={classes.uploadContentFileTypeLabel}>{_transl(ImportTranslationKey.CHOOSE_FILE_TYPE)}</span>
                <span>
                    <Select
                        label={_transl(ImportTranslationKey.TYPE)}
                        id="file-type"
                        value={selectedFileType?.format || nonSelectedFileType}
                        style={{minWidth: "12em"}}
                        onChange={event => setSelectedFileType(FileType.findFileTypeByFormat(event.target.value as FileFormat))}
                    >
                        <MenuItem value={nonSelectedFileType}></MenuItem>
                        {FileType.values().map((fileType) =>
                            <MenuItem value={fileType.format}>{_transl(fileType.label)}</MenuItem>)}
                    </Select>
                </span>
            </div>
        </React.Fragment>
    }

    function canProceedFromSelectFileStep(): boolean {
        return selectedFileType != null && selectedFile != null;
    }

    function renderAssignGroupsStep(): JSX.Element {
        return <div className={classes.pickListDiv}>
            <PickList initialPickedOptions={selectedOptions || []}
                      initialAvailableOptions={collections}
                      availableOptionsTitle={_transl(ImportTranslationKey.PICK_LIST_AVAILABLE_OPTIONS_COLLECTIONS)}
                      pickedOptionsTitle={_transl(ImportTranslationKey.PICK_LIST_SELECTED_OPTIONS_COLLECTIONS)}
                      getOptionText={(option) => (option as CollectionDto).name}
                      showError={false}
                      onPickedOptionsUpdated={(options) => setSelectedOptions(options as CollectionDto[])}
                      backgroundColor={pickListBackgroundColor}
                      listSubheaderBackgroundColor={pickListSubheaderBackgroundColor}
            />
        </div>
    }

    function canProceedFromAssignGroupStep(): boolean {
        return true;
    }

    function doImport(): Promise<any> {
        const metadata: IElementsImportMetadataDto = {
            format: selectedFileType?.format as FileFormat,
            collectionCodes: selectedOptions != null ? selectedOptions.map(option => option.code) : [],
        }
        return Api.imports.importElements(selectedFile as File, metadata)
            .toPromise()
    }

    function translateErrorToMessage(error: AjaxError): string | undefined {
        if (error instanceof ValidationError) {
            const validationError = error as ValidationError;
            if (validationError.error.code === ImportErrorCode.IMPORT_ELEMENTS_DUPLICATE_HEADER_ERROR && validationError.error.arguments) {
                return _transl(ImportTranslationKey.IMPORT_ELEMENT_DUPLICATE_HEADER_ERROR, validationError.error.arguments);
            }
            return undefined;
        }
    }

    return (
        <Wizard steps={getSteps()}
                lastStepLabel={_transl(ImportTranslationKey.LAST_STEP_LABEL)}
                lastStepButtonLabel={_transl(ImportTranslationKey.LAST_STEP_BUTTON_LABEL)}
                lastStepAction={() => doImport()}
                lastStepActionInProgressText={_transl(ImportTranslationKey.LAST_STEP_ACTION_IN_PROGRESS)}
                lastStepActionSuccessfulText={_transl(ImportTranslationKey.LAST_STEP_ACTION_SUCCESSFUL)}
                lastStepActionFailedText={_transl(ImportTranslationKey.LAST_STEP_FAILED)}
                translateErrorToMessage={translateErrorToMessage}
                cancelWizard={props.resetImport}
                wizardGridMinHeight={"25em"}
        />
    );
}
