import {IFilter} from "../../store/elements/Elements";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {ElementDto} from "../../common/apis/element/ElementDto";
import DataProvider from "./dataprovider/DataProvider";
import ExtGrid, {ExtGridProps} from "./ExtGrid";
import {PagingFilterDto} from "../../common/apis/PagingFilterDto";
import CopyUtils from "../../common/CopyUtils";
import {GridSortModel} from "@mui/x-data-grid-pro";
import {
    convertToSortingCriterionDto,
    SortingCriterionDto
} from "../../common/apis/SortingCriterionDto";
import {GridPresetsProcessor} from "./presets/GridPresetsProcessor";
import {GridPresets} from "./presets/GridPresets";
import ExtGridWrapper from "./ExtGridWrapper";

interface ExtGridWithDataProviderProps extends Omit<ExtGridProps, "rows" | "rowCount" | "autoPageSize" | "autoHeight" | "presets" | "onPresetsChanged"> {
    filter: PagingFilterDto,
    dataProvider: DataProvider<any, any>,
    isAtLeastOneIdentifierMandatory? : boolean,
    presets?: GridPresets,
    onPresetsChanged?: (presets: GridPresets) => void,
}

export const ExtGridWithDataProvider = (props: ExtGridWithDataProviderProps) => {

    const {dataProvider, filter, isAtLeastOneIdentifierMandatory, presets, onPresetsChanged, ...rest} = props;
    const presetsExtractor = useRef<GridPresetsProcessor>(new GridPresetsProcessor());

    const [rows, setRows] = useState<ElementDto[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageToken, setPageToken] = useState<string>();
    const [sortingCriteria, setSortingCriteria] = useState<SortingCriterionDto[] | undefined>(
        createSortingCriteria(presetsExtractor.current.getSortModel(presets)));

    const createNormalizedPresets = (presets?: GridPresets) => {
        const normalizationResult = presetsExtractor.current.normalizePresets(props.columns, presets);
        return normalizationResult.normalizedPresets;
    };

    const gridPresets = createNormalizedPresets(presets);

    const fetchData = useCallback((filter: IFilter, pageToken: string | undefined, sortingCriteria: SortingCriterionDto[] | undefined) => {
        let isMounted = true;

        (async () => {
            try {
                if (isAtLeastOneIdentifierMandatory && filter.identifiers?.length === 0) {
                    setRows([]);
                } else {
                    const response = await dataProvider.fetch(createFilterCopy(filter, pageToken, sortingCriteria));
                    if (isMounted) {
                        if (pageToken) {
                            setRows((prevRows) => [...prevRows, ...response.items as Array<ElementDto>]);
                        } else {
                            setRows(response.items as Array<ElementDto>);
                        }

                        setTotalCount(response.totalCount);
                        setPageToken(response.nextPageToken);
                    }
                }
            } catch (error) {
            }
        })();

        return () => {
            isMounted = false;
        }
    }, [dataProvider, isAtLeastOneIdentifierMandatory]);

    useEffect(() => {
        fetchData(filter, undefined, sortingCriteria);
    }, [fetchData, filter, sortingCriteria]);

    function handleOnRowScrollEnd() {
        if (pageToken) {
            fetchData(filter, pageToken, sortingCriteria);
        }
    }

    useEffect(() => {
        setSortingCriteria(createSortingCriteria(presetsExtractor.current.getSortModel(presets)));
    }, [presets]);

    function handleOnSortModelChange(gridSortModel: GridSortModel) {
        const newSortingCriteria = createSortingCriteria(gridSortModel);

        fetchData(filter, undefined, newSortingCriteria);
        setSortingCriteria(newSortingCriteria);
    }

    function createSortingCriteria(gridSortModel: GridSortModel) {
        return gridSortModel.map((gridSortItem) => convertToSortingCriterionDto(gridSortItem));
    }

    const createFilterCopy = (filter: IFilter, pageToken: string | undefined, sortingCriteria: SortingCriterionDto[] | undefined) => {
        const filterCopy = CopyUtils.deepCopy(filter);
        filterCopy.pageToken = pageToken;
        filterCopy.sortingCriteria = sortingCriteria;

        return filterCopy;
    };

    return (
        <>
            {onPresetsChanged &&
                <ExtGrid
                    {...rest}
                    presets={gridPresets}
                    onPresetsChanged={onPresetsChanged}
                    rows={rows}
                    rowCount={totalCount}
                    onRowScrollEnd={handleOnRowScrollEnd}
                    onSortModelChange={handleOnSortModelChange}
                    sortingMode={"server"}
                />
            }
            {!onPresetsChanged &&
                <ExtGridWrapper
                    {...rest}
                    rows={rows}
                    rowCount={totalCount}
                    onRowScrollEnd={handleOnRowScrollEnd}
                    onSortModelChange={handleOnSortModelChange}
                    sortingMode={"server"}
                />
            }
        </>
    );
};
