import React, {useRef, useState} from "react";
import {SaveButton} from "../../../../../../components/button/SaveButton";
import {CancelButton} from "../../../../../../components/button/CancelButton";
import {_transl} from "../../../../../../store/localization/TranslMessasge";
import {ElementDetailTranslationKey} from "../../ElementDetailTranslationKey";
import {CommonTranslation} from "../../../CommonTranslation";
import {getLabelForPropertyType, propertyTypes} from "../../../property/PropertyType";
import TextField from "../../../../../../components/fields/textfield/TextField";
import Dialog from "../../../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../../../components/dialogs/DialogActions";
import Grid from "../../../../../../components/dialogs/Grid";
import {MenuItem} from "@mui/material";
import constructElementDetailController, {ElementDetailController} from "../../controller/ElementDetailController";
import elementService from "../../service/ElementService";
import diagramService from "../../../diagrams/service/DiagramService";
import Snackbar from "../../../snackbar/Snackbar";
import {LabelsTranslationKey} from "../../../labels/LabelsTranslationKey";
import {CreatePropertyDialogValidator} from "./CreatePropertyDialogValidator";

export interface CreatePropertyDialogProps {
    opened: boolean,
    elementId: string,
    onSave: () => void,
    onClosed: () => void,
}

export default function CreatePropertyDialog(props: CreatePropertyDialogProps) {
    const elementDetailController = useRef<ElementDetailController>(constructElementDetailController(elementService, diagramService));
    const validator = useRef<CreatePropertyDialogValidator>(new CreatePropertyDialogValidator());
    const {opened, elementId, onSave, onClosed} = props

    const [name, setName] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [value, setValue] = useState<string>("");

    const [isValidated, setValidated] = useState<boolean>(false);
    const [nameValidationMsg, setNameValidationMsg] = useState<string | undefined>(undefined);
    const [typeValidationMsg, setTypeValidationMsg] = useState<string | undefined>(undefined);
    const [valueValidationMsg, setValueValidationMsg] = useState<string | undefined>(undefined);

    function validateName(text: string): boolean {
        const isValid = validator.current.validateName(text);
        if (isValid) {
            setNameValidationMsg(undefined);
        } else {
            setNameValidationMsg(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
        }
        return isValid;
    }

    function validateValue(text: string): boolean {
        const isValid = validator.current.validateValue(text);
        if (isValid) {
            setValueValidationMsg(undefined);
        } else {
            setValueValidationMsg(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
        }
        return isValid;
    }

    function validateType(text: string): boolean {
        const isValid = validator.current.validateType(text);
        if (isValid) {
            setTypeValidationMsg(undefined);
        } else {
            setTypeValidationMsg(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
        }
        return isValid;
    }

    function isFormValid() {
        const isNameValid = validateName(name);
        const isTypeValid = validateType(type);
        const isValueValid = validateValue(value);

        return isNameValid && isTypeValid && isValueValid;
    }

    const onDialogSave = () => {
        setValidated(true)

        if (isFormValid()) {
            (async () => {
                try {
                    await elementDetailController.current.createProperty(elementId, name, type, value);
                    onSave()
                    onClosed()
                    Snackbar.success(_transl(LabelsTranslationKey.CREATE_PROPERTY_SUCCEEDED));
                } catch (e) {
                    Snackbar.error(_transl(LabelsTranslationKey.CREATE_PROPERTY_FAILED));
                }
            })();
        }
    }


    return (
            <Dialog open={opened}
                    onClose={onClosed}
                    fullWidth={true}>

                <DialogTitle id="scroll-dialog-title"
                             title={_transl(ElementDetailTranslationKey.ATTRIBUTES_ADD_TITLE)}
                             onDialogClosed={onClosed}/>

                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField id="name-field"
                                       label={_transl(CommonTranslation.TITLE)}
                                       errorMessage={nameValidationMsg}
                                       value={name}
                                       onChange={(title) => {
                                           if (isValidated) {
                                               validateName(title);
                                           }
                                           setName(title)
                                       }}
                                       required={true}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="type-field"
                                       select
                                       label={_transl(ElementDetailTranslationKey.TYPE)}
                                       errorMessage={typeValidationMsg}
                                       value={type}
                                       onChange={(type) => {
                                           if (isValidated) {
                                               validateType(type);
                                           }
                                           setType(type)
                                       }}
                                       required={true}>

                                {propertyTypes.map(({value}) => (
                                        <MenuItem key={value} value={value}>
                                            {getLabelForPropertyType(value)}
                                        </MenuItem>
                                ))}

                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="value-field"
                                       label={_transl(ElementDetailTranslationKey.ATTRIBUTES_VALUE)}
                                       value={value}
                                       required={true}
                                       errorMessage={valueValidationMsg}
                                       onChange={(value) => {
                                           if (isValidated) {
                                               validateValue(value);
                                           }
                                           setValue(value)
                                       }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SaveButton onClick={onDialogSave}/>
                    <CancelButton onClick={onClosed} variant={"outlined"}/>
                </DialogActions>
            </Dialog>
    );
}

