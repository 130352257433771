import {UserDto} from "../common/apis/user/UserDto";

export interface MainPageAccessResolver {
    hasPageAccess(user?: UserDto): boolean,
}

class MainPageAccessResolverImpl implements MainPageAccessResolver {

    hasPageAccess(user?: UserDto): boolean {
        return user?.login != null;
    }

}

export default new MainPageAccessResolverImpl();
