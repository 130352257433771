import ElementImportWizard from "./ElementImportWizard";
import RelationshipImportWizard from "./RelationshipImportWizard";
import RelationshipMatrixImportWizard from "./RelationshipMatrixImportWizard";
import ModelImportWizard from "./ModelImportWizard";
import {ImportConfigurationNullable} from "../../../../common/Types";
import UserPermissionsImportWizard from "./UserPermissionsImportWizard";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ImportTranslationKey} from "./ImportTranslationKey";
import {UserDto} from "../../../../common/apis/user/UserDto";
import {UserRoleType} from "../../../../common/access/UserRoleType";
import {UserRole} from "../../../../common/access/UserRole";

export enum ConfigurationId {
    ELEMENTS = "elements",
    RELATIONSHIP = "relationship",
    RELATIONSHIP_MATRIX = "relationship_matrix",
    ARCHIMATE = "archimate",
    PERMISSIONS = "permissions",
}

export class Configuration {

    constructor(public id: ConfigurationId,
                public pageLabel: string,
                public label: string,
                public description: string,
                public getWizard: (resetWizard: () => void) => JSX.Element,
                public isVisible: (user: UserDto) => boolean = () => true) {
    }

    public static findById(configurations: Array<Configuration>, id: ConfigurationId): ImportConfigurationNullable {
        const filtered = configurations.filter(configuration => configuration.id === id);
        return filtered.length === 1 ? filtered[0] : null;
    }

   public static getConfigurations(){
        const configurations: Array<Configuration> = [  new Configuration(ConfigurationId.ELEMENTS, _transl(ImportTranslationKey.IMPORT_ELEMENTS_LABEL), _transl(ImportTranslationKey.IMPORT_ELEMENTS_LABEL),
           _transl(ImportTranslationKey.IMPORT_ELEMENTS_DESCRIPTION), (resetWizard) => <ElementImportWizard
               resetImport={resetWizard}/>),
           new Configuration(ConfigurationId.RELATIONSHIP, _transl(ImportTranslationKey.IMPORT_RELATIONSHIPS_LABEL), _transl(ImportTranslationKey.IMPORT_RELATIONSHIPS_LABEL),
               _transl(ImportTranslationKey.IMPORT_RELATIONSHIPS_DESCRIPTION), (resetWizard) => <RelationshipImportWizard
                   resetImport={resetWizard}/>, (user) => UserRole.isAtLeast(user, UserRoleType.ROLE_SPRAVCE)),
           new Configuration(ConfigurationId.RELATIONSHIP_MATRIX, _transl(ImportTranslationKey.IMPORT_RELATIONSHIP_MATRIX_LABEL), _transl(ImportTranslationKey.IMPORT_RELATIONSHIP_MATRIX_LABEL),
               _transl(ImportTranslationKey.IMPORT_RELATIONSHIP_MATRIX_DESCRIPTION), (resetWizard) =>
                   <RelationshipMatrixImportWizard resetImport={resetWizard}/>, (user) => UserRole.isAtLeast(user, UserRoleType.ROLE_SPRAVCE)),
           new Configuration(ConfigurationId.ARCHIMATE, _transl(ImportTranslationKey.IMPORT_FROM_ARCHIMATE_LABEL), _transl(ImportTranslationKey.IMPORT_FROM_ARCHIMATE_LABEL),
               _transl(ImportTranslationKey.IMPORT_FROM_ARCHIMATE_DESCRIPTION), (resetWizard) => <ModelImportWizard
                   resetImport={resetWizard}/>),
           new Configuration(ConfigurationId.PERMISSIONS, _transl(ImportTranslationKey.IMPORT_PERMISSIONS_LABEL), _transl(ImportTranslationKey.IMPORT_PERMISSIONS_LABEL),
               _transl(ImportTranslationKey.IMPORT_PERMISSIONS_DESCRIPTION), (resetWizard) => <UserPermissionsImportWizard
                   resetImport={resetWizard}/>, (user) => UserRole.isAtLeast(user, UserRoleType.ROLE_SPRAVCE))
       ]
       return configurations;
   }
}
