import IndicatorPanel from "./IndicatorPanel";
import AttachmentListPanel from "./ListPanel";
import AttachmentUploadDialog from "./UploadDialog";
import React, {Dispatch} from "react";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import {FetchableResourceType, IFetchableResourceState} from "../../store/common/FetchableResource";
import {
    AttachmentDeleteResourceIdType,
    AttachmentDownloadResourceIdType,
    AttachmentResourceType,
    AttachmentType,
    getAttachmentFetchAction,
    getDeleteAttachmentAction,
    getDeleteAttachmentRemoveItemAction,
    getDownloadAttachmentAction,
    getDownloadAttachmentRemoveItemAction,
    getUploadAttachmentAction,
    getUploadAttachmentRemoveItemAction
} from "../../store/common/Attachments";
import {DownloadableResourceType, IDownloadableResourceState} from "../../store/common/DownloadableResource";
import {DeletableResourceType, IDeletableResourceState} from "../../store/common/DeletableResource";
import {IUploadableResourceState, UploadableResourceType} from "../../store/common/UploadableResource";
import {IApplicationState} from "../../store/Store";
import {connect} from "react-redux";

const styles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
    }
});

interface IProps extends WithStyles<typeof styles> {
    resourceType: AttachmentType,
    resourceId: string,
    onAttachmentsUpdated: () => void,

    // # store props & actions

    // element attachments
    elementAttachments: IFetchableResourceState<AttachmentResourceType>,
    elementDownloadedAttachments: IDownloadableResourceState<AttachmentDownloadResourceIdType>[],
    elementDeletedAttachments: IDeletableResourceState<AttachmentDeleteResourceIdType>[],
    elementUploadedAttachments: IUploadableResourceState[],

    // diagram attachments
    diagramAttachments: IFetchableResourceState<AttachmentResourceType>,
    diagramDownloadedAttachments: IDownloadableResourceState<AttachmentDownloadResourceIdType>[],
    diagramDeletedAttachments: IDeletableResourceState<AttachmentDeleteResourceIdType>[],
    diagramUploadedAttachments: IUploadableResourceState[],

    // attachment action callbacks
    fetchAttachments: (resourceType: FetchableResourceType, resourceId: string) => void,
    downloadAttachment: (resourceType: DownloadableResourceType, resourceId: string, attachmentId: string, fileName: string) => void,
    deleteAttachment: (resourceType: DeletableResourceType, resourceId: string, attachmentId: string) => void,
    uploadAttachment: (resourceType: UploadableResourceType, resourceId: string, file: File) => void,

    // clean action callbacks
    removeAttachmentFromDownloaded: (resourceType: DownloadableResourceType, resourceId: string, attachmentId: string) => void,
    removeAttachmentFromUploaded: (resourceType: UploadableResourceType, resourceId: string, file: File) => void,
    removeAttachmentFromDeleted: (resourceType: DeletableResourceType, fetchResourceType: FetchableResourceType, resourceId: string, attachmentId: string) => void,

    canUploadAttachment?: boolean,
}

interface IState {
    uploadDialogOpened: boolean,
}

class AttachmentsPanel extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            uploadDialogOpened: false,
        };
    }

    componentDidMount() {
        this.fetchAttachments();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.resourceId !== prevProps.resourceId) {
            this.fetchAttachments();
        }
    }

    fetchAttachments() {
        const { resourceId } = this.props;
        const { fetchableResourceType } = this.resolveProperties();
        this.props.fetchAttachments(fetchableResourceType, resourceId);
    }

    render(): JSX.Element {
        const {classes} = this.props;
        const {resourceId, canUploadAttachment} = this.props;
        const {attachments, downloadedAttachments, deletedAttachments, uploadedAttachments,
        fetchableResourceType, deletableResourceType, downloadableResourceType, uploadableResourceType} = this.resolveProperties();
        const {downloadAttachment, removeAttachmentFromDownloaded, removeAttachmentFromUploaded, removeAttachmentFromDeleted} = this.props;
        const {uploadDialogOpened} = this.state;

        return(
            <div className={classes.root}>
                <IndicatorPanel onUploadNewAttachment={() => this.updateUploadDialogOpened(true)} disabled={!canUploadAttachment}/>
                <AttachmentListPanel attachments={attachments}
                                     downloadedAttachments={downloadedAttachments}
                                     deletedAttachments={deletedAttachments}
                                     deleteAttachment={(attachmentId) => this.deleteAttachment(deletableResourceType, resourceId, attachmentId)}
                                     downloadAttachment={(attachmentId, fileName) => downloadAttachment(downloadableResourceType, resourceId, attachmentId, fileName)}
                                     onDownloadedAttachmentSaved={(attachmentId) => removeAttachmentFromDownloaded(downloadableResourceType, resourceId, attachmentId)}
                                     onDownloadedAttachmentFailViewed={(attachmentId) => removeAttachmentFromDownloaded(downloadableResourceType, resourceId, attachmentId)}
                                     onDeletedAttachmentViewed={(attachmentId) => removeAttachmentFromDeleted(deletableResourceType, fetchableResourceType, resourceId, attachmentId)}
                                     key={"existingListPanel"} />

                <AttachmentUploadDialog opened={uploadDialogOpened}
                                        uploadedAttachments={uploadedAttachments}
                                        uploadAttachment={(file) => this.uploadAttachment(uploadableResourceType, resourceId, file)}
                                        removeFromUploaded={(item) => removeAttachmentFromUploaded(uploadableResourceType, resourceId, item.resource as File)}
                                        onClosed={() => this.updateUploadDialogOpened(false)} />
            </div>
        )
    }

    resolveProperties() {
        const { resourceType } = this.props;

        if ( resourceType === AttachmentType.ELEMENTS) {
            return this.resolveItemProperties();
        } else if (resourceType === AttachmentType.DIAGRAMS) {
            return this.resolveDiagramProperties();
        } else {
            throw new Error("Unsupported attachment type");
        }
    }

    resolveItemProperties() {
        const { resourceId } = this.props;
        const { elementAttachments, elementDownloadedAttachments, elementUploadedAttachments, elementDeletedAttachments } = this.props;
        return {
            attachments: elementAttachments,
            downloadedAttachments: this.filterByResourceId(resourceId, elementDownloadedAttachments,
                (state, id) => state?.resourceId?.resourceId === id),
            deletedAttachments: this.filterByResourceId(resourceId, elementDeletedAttachments,
                (state, id) => state?.resourceId?.resourceId === id),
            uploadedAttachments: this.filterByResourceId(resourceId, elementUploadedAttachments,
                (state, id) => state?.itemId === id),
            fetchableResourceType: FetchableResourceType.ELEMENTS_DETAIL_PAGE_ATTACHMENTS,
            downloadableResourceType: DownloadableResourceType.ELEMENTS_DETAIL_PAGE_ATTACHMENT,
            deletableResourceType: DeletableResourceType.ELEMENTS_DETAIL_PAGE_ATTACHMENT,
            uploadableResourceType: UploadableResourceType.ELEMENTS_DETAIL_PAGE_ATTACHMENT,
        };
    }

    resolveDiagramProperties() {
        const { resourceId } = this.props;
        const { diagramAttachments, diagramDownloadedAttachments, diagramUploadedAttachments, diagramDeletedAttachments } = this.props;
        return {
            attachments: diagramAttachments,
            downloadedAttachments: this.filterByResourceId(resourceId, diagramDownloadedAttachments,
                (state, id) => state?.resourceId?.resourceId === id),
            deletedAttachments: this.filterByResourceId(resourceId, diagramDeletedAttachments,
                (state, id) => state?.resourceId?.resourceId === id),
            uploadedAttachments: this.filterByResourceId(resourceId, diagramUploadedAttachments,
                (state, id) => state?.itemId === id),
            fetchableResourceType: FetchableResourceType.DIAGRAM_DETAIL_PAGE_ATTACHMENTS,
            downloadableResourceType: DownloadableResourceType.DIAGRAM_DETAIL_PAGE_ATTACHMENT,
            deletableResourceType: DeletableResourceType.DIAGRAM_DETAIL_PAGE_ATTACHMENT,
            uploadableResourceType: UploadableResourceType.DIAGRAM_DETAIL_PAGE_ATTACHMENT,
        };
    }

    filterByResourceId<T>(resourceId: string, array: Array<T>, resourceIdEquals: (resource: T, id: string) => boolean) {
        return array.filter(item => resourceIdEquals(item, resourceId));
    }

    updateUploadDialogOpened(opened: boolean) {
        this.setState(state => {
            return {
                ...state,
                uploadDialogOpened: opened,
            }
        })
    }

    private deleteAttachment(deletableResourceType: DeletableResourceType, resourceId: string, attachmentId: string) {
        const { deleteAttachment, onAttachmentsUpdated } = this.props;
        deleteAttachment(deletableResourceType, resourceId, attachmentId);
        if (onAttachmentsUpdated) {
            onAttachmentsUpdated();
        }
    }

    private uploadAttachment(uploadableResourceType: UploadableResourceType, resourceId: string, file: File) {
        const { uploadAttachment, onAttachmentsUpdated } = this.props;
        uploadAttachment(uploadableResourceType, resourceId, file);
        if (onAttachmentsUpdated) {
            onAttachmentsUpdated();
        }
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    // items
    elementAttachments: state.pages.attachments.attachments.items,
    elementDownloadedAttachments: state.pages.attachments.downloadedAttachments.items,
    elementDeletedAttachments: state.pages.attachments.deletedAttachments.items,
    elementUploadedAttachments: state.pages.attachments.uploadedAttachments.items,
    // diagrams
    diagramAttachments: state.pages.attachments.attachments.diagrams,
    diagramDownloadedAttachments: state.pages.attachments.downloadedAttachments.diagrams,
    diagramDeletedAttachments: state.pages.attachments.deletedAttachments.diagrams,
    diagramUploadedAttachments: state.pages.attachments.uploadedAttachments.diagrams,
})


const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
    fetchAttachments: (resourceType: FetchableResourceType, resourceId: string) => {
        dispatch(getAttachmentFetchAction(resourceType, resourceId));
    },
    downloadAttachment: (resourceType: DownloadableResourceType, resourceId: string, attachmentId: string, fileName: string) => {
        dispatch(getDownloadAttachmentAction(resourceType, {resourceId, attachmentId}, fileName));
    },
    deleteAttachment: (resourceType: DeletableResourceType, resourceId: string, attachmentId: string) => {
        dispatch(getDeleteAttachmentAction(resourceType, {resourceId, attachmentId}));
    },
    uploadAttachment: (resourceType: UploadableResourceType, resourceId: string, file: File) => {
        dispatch(getUploadAttachmentAction(resourceType, resourceId, file));
    },
    removeAttachmentFromDeleted: (resourceType: DeletableResourceType, fetchResourceType: FetchableResourceType, resourceId: string, attachmentId: string) => {
        dispatch(getDeleteAttachmentRemoveItemAction(resourceType, {resourceId, attachmentId}));
        dispatch(getAttachmentFetchAction(fetchResourceType, resourceId));
    },
    removeAttachmentFromDownloaded: (resourceType: DownloadableResourceType, resourceId: string, attachmentId: string) => {
        dispatch(getDownloadAttachmentRemoveItemAction(resourceType, {resourceId, attachmentId}));
    },
    removeAttachmentFromUploaded: (resourceType: UploadableResourceType, resourceId: string, file: File) => {
        dispatch(getUploadAttachmentRemoveItemAction(resourceType, resourceId, file));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(AttachmentsPanel));
