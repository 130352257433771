import {Grid, Typography} from "@mui/material";
import {ButtonLayout, NegativeButtons, PositiveButtons} from "../../../../components/button/ButtonLayout";
import {SaveButton} from "../../../../components/button/SaveButton";
import {CancelButton} from "../../../../components/button/CancelButton";
import {useState} from "react";
import {FontSizeSlider} from "./FontSizeSlider";
import {UpdateModeToggle} from "./UpdateModeToggle";
import {FontStyle, StyleSettings, UpdateMode} from "../StyleSettings";
import {FontStyleToggle} from "./FontStyleToggle";
import {ColorPickerButton} from "../../../../components/color/ColorPickerButton";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {StyleSettingsTranslationKey} from "./StyleSettingsTranslationKey";
import {ColorDto, Colors} from "../../../../common/apis/Color";
import {DEFAULT_FONT_SIZE} from "../../../../common/diagrameditor/common/UIConstants";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../components/dialogs/DialogActions";

const DEFAULT_STYLE_SETTINGS: StyleSettings = {
    fillColorUpdateMode: UpdateMode.KEEP_UNCHANGED,
    fillColor: Colors.WHITE,
    lineColorUpdateMode: UpdateMode.KEEP_UNCHANGED,
    lineColor: Colors.BLACK,
    textColorUpdateMode: UpdateMode.KEEP_UNCHANGED,
    textColor: Colors.BLACK,
    fontStylesUpdateMode: UpdateMode.KEEP_UNCHANGED,
    fontStyles: [],
    fontSizeUpdateMode: UpdateMode.KEEP_UNCHANGED,
    fontSize: DEFAULT_FONT_SIZE,
};

enum Variant {
    NODES = "nodes",
    CONNECTIONS = "connections"
}

export type StyleSettingsDialogVariant = `${Variant}`;

enum Mode {
    SINGLE = "single",
    BULK = "bulk"
}

export type StyleSettingsDialogMode = `${Mode}`;

export interface StyleSettingsDialogProps {
    open: boolean,
    settings?: StyleSettings,
    variant?: StyleSettingsDialogVariant,
    mode?: StyleSettingsDialogMode,
    onSave?: (settings: StyleSettings) => void,
    onCancel?: () => void,
}

export function StyleSettingsDialog(props: StyleSettingsDialogProps) {
    const onSave = props.onSave || (() => {});
    const onCancel = props.onCancel || (() => {});
    const mode = props.mode || Mode.BULK;
    const hideKeepUnchanged = (mode === Mode.SINGLE);

    const [settings, setSettings] = useState(props.settings || DEFAULT_STYLE_SETTINGS);

    function updateSettings(updateProps: Partial<StyleSettings>) {
        setSettings({
            ...settings,
            ...updateProps
        });
    }

    function isFillColorUpdateDisabled() {
        return settings.fillColorUpdateMode !== UpdateMode.USE_CUSTOM;
    }

    function changeFillColorMode(mode: UpdateMode) {
        updateSettings({fillColorUpdateMode: mode});
    }

    function changeFillColor(color: ColorDto) {
        updateSettings({fillColor: color});
    }

    function isLineColorUpdateDisabled() {
        return settings.lineColorUpdateMode !== UpdateMode.USE_CUSTOM;
    }

    function changeLineColorMode(mode: UpdateMode) {
        updateSettings({lineColorUpdateMode: mode});
    }

    function changeLineColor(color: ColorDto) {
        updateSettings({lineColor: color});
    }

    function isTextColorUpdateDisabled() {
        return settings.textColorUpdateMode !== UpdateMode.USE_CUSTOM;
    }

    function changeTextColorMode(mode: UpdateMode) {
        updateSettings({textColorUpdateMode: mode});
    }

    function changeTextColor(color: ColorDto) {
        updateSettings({textColor: color});
    }

    function isFontStylesUpdateDisabled() {
        return settings.fontStylesUpdateMode !== UpdateMode.USE_CUSTOM;
    }

    function changeFontStylesMode(mode: UpdateMode) {
        updateSettings({fontStylesUpdateMode: mode});
    }

    function changeFontStyles(fontStyles: FontStyle[]) {
        updateSettings({fontStyles: fontStyles});
    }

    function isFontSizeUpdateDisabled() {
        return settings.fontSizeUpdateMode !== UpdateMode.USE_CUSTOM;
    }

    function changeFontSizeMode(mode: UpdateMode) {
        updateSettings({fontSizeUpdateMode: mode});
    }

    function changeFontSize(fontSize: number) {
        updateSettings({fontSize: fontSize});
    }

    function onSaveClicked() {
        onSave(settings);
    }

    return (
        <Dialog open={props.open} maxWidth={"sm"}>
            <DialogTitle title={_transl(StyleSettingsTranslationKey.STYLE_SETTINGS)} onDialogClosed={onCancel} />
            <DialogContent>
                <Grid container spacing={2} marginTop={1}>
                    {props.variant === Variant.NODES &&
                        <>
                            <Grid item container xs={2} alignItems={"center"}>
                                <Typography>{_transl(StyleSettingsTranslationKey.FILL_COLOR)}</Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <ColorPickerButton title={_transl(StyleSettingsTranslationKey.CHANGE_COLOR)}
                                                   color={settings.fillColor}
                                                   onChange={changeFillColor}
                                                   disabled={isFillColorUpdateDisabled()}/>
                            </Grid>
                            <Grid item container xs={3} justifyContent={"flex-end"}>
                                <UpdateModeToggle value={settings.fillColorUpdateMode}
                                                  hideKeepUnchangedMode={hideKeepUnchanged}
                                                  onChange={changeFillColorMode} />
                            </Grid>
                        </>
                    }

                    <Grid item container xs={2} alignItems={"center"}>
                        <Typography>{_transl(StyleSettingsTranslationKey.LINE_COLOR)}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <ColorPickerButton title={_transl(StyleSettingsTranslationKey.CHANGE_COLOR)}
                                           color={settings.lineColor}
                                           onChange={changeLineColor}
                                           disabled={isLineColorUpdateDisabled()} />
                    </Grid>
                    <Grid item container xs={3} justifyContent={"flex-end"}>
                        <UpdateModeToggle value={settings.lineColorUpdateMode}
                                          hideKeepUnchangedMode={hideKeepUnchanged}
                                          onChange={changeLineColorMode} />
                    </Grid>

                    <Grid item container xs={2} alignItems={"center"}>
                        <Typography>{_transl(StyleSettingsTranslationKey.TEXT_COLOR)}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <ColorPickerButton title={_transl(StyleSettingsTranslationKey.CHANGE_COLOR)}
                                           color={settings.textColor}
                                           onChange={changeTextColor}
                                           disabled={isTextColorUpdateDisabled()} />
                    </Grid>
                    <Grid item container xs={3} justifyContent={"flex-end"}>
                        <UpdateModeToggle value={settings.textColorUpdateMode}
                                          hideKeepUnchangedMode={hideKeepUnchanged}
                                          onChange={changeTextColorMode} />
                    </Grid>

                    <Grid item container xs={2} alignItems={"center"}>
                        <Typography>{_transl(StyleSettingsTranslationKey.FONT_STYLE)}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <FontStyleToggle fontStyles={settings.fontStyles}
                                         onChange={changeFontStyles}
                                         disabled={isFontStylesUpdateDisabled()} />
                    </Grid>
                    <Grid item container xs={3} justifyContent={"flex-end"}>
                        <UpdateModeToggle value={settings.fontStylesUpdateMode}
                                          hideKeepUnchangedMode={hideKeepUnchanged}
                                          onChange={changeFontStylesMode} />
                    </Grid>

                    <Grid item container xs={2} alignItems={"center"}>
                        <Typography>{_transl(StyleSettingsTranslationKey.FONT_SIZE)}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <FontSizeSlider value={settings.fontSize}
                                        onChange={changeFontSize}
                                        disabled={isFontSizeUpdateDisabled()}/>
                    </Grid>
                    <Grid item container xs={3} alignItems={"center"} justifyContent={"flex-end"}>
                        <UpdateModeToggle value={settings.fontSizeUpdateMode}
                                          hideKeepUnchangedMode={hideKeepUnchanged}
                                          onChange={changeFontSizeMode} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonLayout>
                    <PositiveButtons>
                        <SaveButton onClick={onSaveClicked}/>
                    </PositiveButtons>
                    <NegativeButtons>
                        <CancelButton onClick={onCancel}/>
                    </NegativeButtons>
                </ButtonLayout>
            </DialogActions>
        </Dialog>
    );
}
