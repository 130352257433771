import React, {Dispatch} from "react";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import {connect} from "react-redux";
import {IApplicationState} from "../../store/Store";
import {CollectionDto} from "../../common/apis/collection/CollectionDto";
import EditablePickListComponent from "./EditablePickListComponent";
import ArrayUtils from "../../common/ArrayUtils";
import {_transl} from "../../store/localization/TranslMessasge";
import {ElementDetailTranslationKey} from "../../pages/main/content/elementdetail/ElementDetailTranslationKey";

const styles = (theme: Theme) => createStyles({
});

interface IProps extends WithStyles<typeof styles> {
    // textfield props
    textFieldLabel?: string,
    textFieldId?: string

    // picklist props
    assignedCollections?: Array<CollectionDto>,
    assignedCollectionCodes?: Array<String>,

    // edit callback
    doUpdate: (items: Array<CollectionDto>) => Promise<any>,
    onSuccessfulUpdate?: (items: Array<CollectionDto>) => void,

    // state props
    collections: Array<CollectionDto>,
    readonly?: boolean,
}

interface IState {
    assignedCollections: Array<CollectionDto>,
}

class EditableCollectionsComponent extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            assignedCollections: this.getAssignedCollections(props),
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const prevCollections = this.getAssignedCollections(prevProps);
        const actualCollections = this.getAssignedCollections(this.props);
        if (!ArrayUtils.arraysEqualIgnoreOrder(prevCollections, actualCollections, (label) => label.code)) {
            this.updateAssignedCollections(actualCollections);
        }
    }

    render() {
        const { textFieldLabel, textFieldId, readonly } = this.props;
        const { collections, } = this.props;
        const { doUpdate, onSuccessfulUpdate } = this.props;

        const { assignedCollections } = this.state;

        const getOptionText: (item: unknown) => string = (item) => (item as CollectionDto).name;
        const update = doUpdate as (items: unknown[]) => Promise<any>;

        return (
            <EditablePickListComponent textFieldLabel={textFieldLabel || _transl(ElementDetailTranslationKey.COLLECTIONS_TITLE)}
                                       textFieldId={textFieldId}
                                       pickListAvailableOptions={collections.filter(collection => collection.acl.canAssignObjectsToCollection)}
                                       pickListPickedOptions={assignedCollections}
                                       pickListIsPickedOptionDisabled={(collection) => !(collection as CollectionDto).acl.canAssignObjectsToCollection}
                                       pickListGetOptionText={getOptionText}
                                       getId={(item) => item ? (item as CollectionDto).id : ""}
                                       pickListDialogTitle={"Přiřazení kolekcí"}
                                       pickListAvailableOptionsTitle={"Dostupné kolekce"}
                                       pickListPickedOptionsTitle={"Přiřazené kolekce"}
                                       doUpdate={update}
                                       onSuccessfulUpdate={(options) => {
                                           if (onSuccessfulUpdate != null) {
                                               onSuccessfulUpdate(options as Array<CollectionDto>);
                                           }
                                           this.updateAssignedCollections(options as Array<CollectionDto>);
                                       }}
                                       readonly={readonly}
            />
        );
    }

    private getAssignedCollections(props: IProps): Array<CollectionDto> {
        const { collections } = props;
        const assignedCollections = props.assignedCollections || [];
        const assignedCollectionCodes = props.assignedCollectionCodes || [];

        return [...assignedCollections, ...this.filterOutCodes(collections, assignedCollectionCodes)]
    }

    private updateAssignedCollections(options: Array<CollectionDto>) {
        this.setState(state => {
            return {
                ...state,
                assignedCollections: options,
            }
        })
    }

    private filterOutCodes(collections: Array<CollectionDto>, filteredOutCodes: Array<String>) {
        return [...collections.filter(option => filteredOutCodes.indexOf(option.code) > -1 )]
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    collections: state.pages.common.options.collections.resource || [],
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EditableCollectionsComponent));
