import {DiagramNode} from "../../model/Model";
import * as d3 from "d3";
import {HORIZONTAL_LINE_HEIGHT, ShapeType} from "./AbstractNodeRenderer";
import {ModelManager} from "../../manager/ModelManager";
import {ElementDto} from "../../../apis/element/ElementDto";
import NodeRendererFactory from "./NodeRendererFactory";
import {IDiagramNodeDto} from "../../../apis/diagram/IDiagramNodeDto";
import {DiagramsGridAction, DiagramsGridActionType} from "../../../../pages/main/content/diagrams/DiagramsGridAction";
import RenderMode from "../../context/RenderMode";
import RouteDefinitionUtils, {diagramEditorPage} from "../../../routedefinition/RouteDefinitionUtils";

export default class ExternalLinkRenderer {

    private static readonly EXTERNAL_LINK_ATTRIBUTE_NAMES = ["hyperlink", "link", "url"]
    private static readonly EXTERNAL_LINK_ICON_SIZE = 13;

    public render(node: DiagramNode,
                  shapeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                  shapeType: ShapeType,
                  modelManager: ModelManager) {
        const element = node.elementIdentifier ? modelManager.getElementById(node.elementIdentifier) : null;
        const externalLink = element ? this.findExternalLink(element) : this.findDiagramRefLink(node);
        if (externalLink) {
            const {offsetX, offsetY} = this.findExternalLinkOffset(shapeType);
            this.appendExternalLink(externalLink, node, shapeGroup, offsetX, offsetY);
        }
    }

    private findExternalLink(element: ElementDto) {
        if (element.properties) {
            const urls = element.properties
                .filter(property => ExternalLinkRenderer.EXTERNAL_LINK_ATTRIBUTE_NAMES.indexOf(property.definition.name.toLowerCase()) !== -1)
                .map(property => property.value)
                .filter(value => value && value.trim().length > 0);
            if (urls.length > 0) {
                return urls[0];
            }
        }
        return null;
    }

    private findDiagramRefLink(node: IDiagramNodeDto) {
        if (node.diagramReferences && node.diagramReferences.length > 0) {
            const identifier = node.diagramReferences[0];
            const queryParams = [
                {name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.SHOW_EDITOR), value: identifier},
                {name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.EDITOR_MODE), value: RenderMode.EDIT}
            ];
            return RouteDefinitionUtils.resolvePath(diagramEditorPage, {}, queryParams);
        }
        return null;
    }

    private findExternalLinkOffset(shapeType: ShapeType) {
        let offset = {
            offsetX: 5,
            offsetY: 5
        }
        if (shapeType === ShapeType.BLOCK) {
            offset.offsetX += HORIZONTAL_LINE_HEIGHT;
        } else if (shapeType === ShapeType.BEVELLED_RECTANGLE) {
            offset.offsetX += 2;
            offset.offsetY += 2;
        } else if (shapeType === ShapeType.DEVICE) {
            offset.offsetY += HORIZONTAL_LINE_HEIGHT;
        } else if (shapeType === ShapeType.CLOUD) {
            offset.offsetX += 3;
            offset.offsetY += 3;
        }
        return offset;
    }

    private appendExternalLink(externalLink: string,
                               node: DiagramNode,
                               shapeGroup: d3.Selection<SVGGElement, DiagramNode, null, undefined>,
                               offsetX: number,
                               offsetY: number) {
        shapeGroup.append("use")
            .attr("xlink:href", `#${NodeRendererFactory.EXTERNAL_LINK_ICON_ID}`)
            .attr("x", node.x + node.w - ExternalLinkRenderer.EXTERNAL_LINK_ICON_SIZE - offsetX)
            .attr("y", node.y + node.h - ExternalLinkRenderer.EXTERNAL_LINK_ICON_SIZE - offsetY)
            .attr("width", `${ExternalLinkRenderer.EXTERNAL_LINK_ICON_SIZE}`)
            .attr("height", `${ExternalLinkRenderer.EXTERNAL_LINK_ICON_SIZE}`)
            .attr("cursor", "pointer")
            .on("click", () => window.open(externalLink as string, "_blank")?.focus())
            .append("title")
            .text(externalLink);
    }
}
