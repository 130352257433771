import {Divider} from "@mui/material";
import IDiagramApi from "../../../../../common/diagrameditor/api/IDiagramApi";
import React from "react";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import IDiagramEditorApi from "../../../../../common/diagrameditor/api/IDiagramEditorApi";
import {AlignmentType} from "../../../../../common/diagrameditor/common/AlignmentType";
import {IDiagramConnectionDto} from "../../../../../common/apis/diagram/IDiagramConnectionDto";
import AlignNodesMenuItems from "./AlignNodesMenuItems";
import DeleteIcon from "@mui/icons-material/Delete";
import {CommonTranslation} from "../../CommonTranslation";
import {ContextMenu} from "../../../../../diagram/editor/components/menu/ContextMenu";
import {DiagramEditorTranslationKey} from "./DiagramEditorTranslationKey";
import {Brush} from "@mui/icons-material";
import {MenuItem} from "../../../../../components/menu/MenuItem";

interface Props {
    opened: boolean,
    connection: IDiagramConnectionDto,
    clientCoordinates: [number, number],
    onClose: () => void,
    onStylesClick: () => void,
    diagramApi: IDiagramApi,
    diagramEditorApi?: IDiagramEditorApi,
}

export default function ConnectionContextMenu(props: Props) {

    const {opened, onClose, clientCoordinates, diagramEditorApi} = props;
    const isAlignDisabled = !diagramEditorApi?.areSelectedNodesAlignable();
    const anchorPosition = {
        top: clientCoordinates[1],
        left: clientCoordinates[0]
    };

    function alignNodesClicked(alignmentType: AlignmentType) {
        diagramEditorApi?.onAlignSelectedNodesClicked(alignmentType);
    }

    function deleteSelectedItems() {
        diagramEditorApi?.onDeleteSelectedItemsMenuActivated();
        onClose();
    }

    function onStylesClick() {
        props.onStylesClick();
        onClose();
    }

    return (
        <ContextMenu open={opened}
                     onClose={onClose}
                     anchorPosition={anchorPosition}>
            <MenuItem label={_transl(CommonTranslation.REMOVE)}
                      icon={<DeleteIcon/>}
                      shortcut={"⌫"}
                      onClick={deleteSelectedItems} />
            <Divider />
            <AlignNodesMenuItems isAlignDisabled={isAlignDisabled}
                                 alignNodesClicked={(type) => alignNodesClicked(type)}
                                 onClose={() => onClose}/>
            <Divider />
            <MenuItem label={_transl(DiagramEditorTranslationKey.CONTEXT_MENU_STYLES)} icon={<Brush/>}
                      onClick={onStylesClick}/>
        </ContextMenu>
    );
}
