export enum ExtGridTranslationKey {
    BULK_ACTIONS = "extGrid.bulkActions",
    ERROR_OVERLAY_DAFAULT_LABEL = "extGrid.errorOverlayDefaultLabel",
    NO_RESULTS_OVERLAY_LABEL = "extGrid.noResultsOverlayLabel",
    NO_ROWS = "extGrid.noRows",
    ROWS_SELECTED = "extGrid.rowsSelected",
    ROWS_TOTAL = "extGrid.rowsTotal",
    SELECTED_SLASH_TOTAL = "extGrid.record.selectedSlashTotal",
    SHOW_DETAIL = "extGrid.showDetail",
    OPEN_DIAGRAM_VIEW = "extGrid.openDiagramView",
    OPEN_DIAGRAM_EDITOR = "extGrid.openDiagramEditor",
    TOTAL = "extGrid.record.total",

    TOOLBAR_DENSITY = "extGrid.toolbar.density",
    TOOLBAR_DENSITY_LABEL = "extGrid.toolbar.density.label",
    TOOLBAR_DENSITY_COMPACT = "extGrid.toolbar.density.compact",
    TOOLBAR_DENSITY_STANDARD = "extGrid.toolbar.density.standard",
    TOOLBAR_DENSITY_COMFORTABLE = "extGrid.toolbar.density.comfortable",

    TOOLBAR_DENSITY_COLUMNS = "extGrid.toolbar.density.columns",
    TOOLBAR_DENSITY_COLUMNS_LABEL = "extGrid.toolbar.density.columns.label",

    TOOLBAR_FILTERS = "extGrid.toolbar.filters",
    TOOLBAR_FILTERS_LABEL = "extGrid.toolbar.filters.label",
    TOOLBAR_FILTERS_TOOLTIP_HIDE = "extGrid.toolbar.filters.tooltip.hide",
    TOOLBAR_FILTERS_TOOLTIP_SHOW = "extGrid.toolbar.filters.tooltip.show",
    TOOLBAR_FILTERS_TOOLTIP_ACTIVE = "extGrid.toolbar.filters.tooltip.active",

    TOOLBAR_QUICK_FILTER_PLACEHOLDER = "extGrid.toolbar.quick.filter.placeholder",
    TOOLBAR_QUICK_FILTER_LABEL = "extGrid.toolbar.quick.filter.label",
    TOOLBAR_QUICK_FILTER_DELETE_ICON_LABEL = "extGrid.toolbar.quick.filter.delete.icon.label",

    TOOLBAR_EXPORT = "extGrid.toolbar.export",
    TOOLBAR_EXPORT_LABEL = "extGrid.toolbar.export.label",
    TOOLBAR_EXPORT_CSV = "extGrid.toolbar.export.csv",
    TOOLBAR_EXPORT_PRINT = "extGrid.toolbar.export.print",
    TOOLBAR_EXPORT_EXCEL = "extGrid.toolbar.export.excel",

    COLUMNS_PANEL_TEXTFIELD_LABEL = "extGrid.columns.panel.textfield.label",
    COLUMNS_PANEL_TEXTFIELD_PLACEHOLDER = "extGrid.columns.panel.textfield.placeholder",
    COLUMNS_PANEL_DRAG_ICON_LABEL = "extGrid.columns.panel.drag.icon.label",
    COLUMNS_PANEL_SHOW_ALL_BUTTON = "extGrid.columns.panel.show.all.button",
    COLUMNS_PANEL_HIDE_ALL_BUTTON = "extGrid.columns.panel.hide.all.button",

    FILTER_PANEL_ADD_FILTER = "extGrid.filter.panel.add.filter",
    FILTER_PANEL_DELETE_ICON_LABEL = "extGrid.filter.panel.delete.icon.label",
    FILTER_PANEL_LINK_OPERATOR = "extGrid.filter.panel.link.operator",
    FILTER_PANEL_OPERATORS = "extGrid.filter.panel.operators",
    FILTER_PANEL_OPERATOR_AND = "extGrid.filter.panel.operator.and",
    FILTER_PANEL_OPERATOR_OR = "extGrid.filter.panel.operator.or",
    FILTER_PANEL_COLUMNS = "extGrid.filter.panel.columns",
    FILTER_PANEL_INPUT_LABEL = "extGrid.filter.panel.input.label",
    FILTER_PANEL_INPUT_PLACEHOLDER = "extGrid.filter.panel.input.placeholder",

    FILTER_OPERATOR_CONTAINS = "extGrid.filter.operator.contains",
    FILTER_OPERATOR_EQUALS = "extGrid.filter.operator.equals",
    FILTER_OPERATOR_STARTS_WITH = "extGrid.filter.operator.starts.with",
    FILTER_OPERATOR_ENDS_WITH = "extGrid.filter.operator.ends.with",
    FILTER_OPERATOR_IS = "extGrid.filter.operator.is",
    FILTER_OPERATOR_NOT = "extGrid.filter.operator.not",
    FILTER_OPERATOR_AFTER = "extGrid.filter.operator.after",
    FILTER_OPERATOR_ON_OR_AFTER = "extGrid.filter.operator.on.or.after",
    FILTER_OPERATOR_BEFORE = "extGrid.filter.operator.before",
    FILTER_OPERATOR_ON_OR_BEFORE = "extGrid.filter.operator.on.or.before",
    FILTER_OPERATOR_IS_EMPTY = "extGrid.filter.operator.is.empty",
    FILTER_OPERATOR_IS_NOT_EMPTY = "extGrid.filter.operator.is.not.empty",
    FILTER_OPERATOR_IS_ANY_OF = "extGrid.filter.operator.is.any.of",

    FILTER_VALUE_ANY = "extGrid.filter.value.any",
    FILTER_VALUE_TRUE = "extGrid.filter.value.true",
    FILTER_VALUE_FALSE = "extGrid.filter.value.false",

    COLUMN_MENU_LABEL = "extGrid.column.menu.label",
    COLUMN_MENU_SHOW_COLUMNS = "extGrid.column.menu.show.columns",
    COLUMN_MENU_FILTER = "extGrid.column.menu.filter",
    COLUMN_MENU_HIDE_COLUMN = "extGrid.column.menu.hide.column",
    COLUMN_MENU_UNSORT = "extGrid.column.menu.unsort",
    COLUMN_MENU_SORT_ASC = "extGrid.column.menu.sort.asc",
    COLUMN_MENU_SORT_DESC = "extGrid.column.menu.sort.desc",
    COLUMN_MENU_ADD_CUSTOM_COLUMN = "extGrid.column.menu.addCustomColumn",
    COLUMN_MENU_REMOVE_COLUMN = "extGrid.column.menu.removeColumn",

    COLUMN_HEADER_FILTERS_TOOLTIP_ACTIVE = "extGrid.column.header.filters.tooltip.active",
    COLUMN_HEADER_FILTERS_LABEL = "extGrid.column.header.filters.label",
    COLUMN_HEADER_SORT_ICON_LABEL = "extGrid.column.header.sort.icon.label",

    FOOTER_VISIBLE_TOTAL_ROWS_FROM = "extGrid.footer.visible.total.rows.from",

    CHECKBOX_SELECTION_HEADER_NAME = "extGrid.checkbox.selection.header.name",
    CHECKBOX_SELECTION_SELECT_ALL_ROWS = "extGrid.checkbox.selection.select.all.rows",
    CHECKBOX_SELECTION_UNSELECT_ALL_ROWS = "extGrid.checkbox.selection.unselect.all.rows",
    CHECKBOX_SELECTION_SELECT_ROW = "extGrid.checkbox.selection.select.row",
    CHECKBOX_SELECTION_UNSELECT_ROW = "extGrid.checkbox.selection.unselect.row",

    BOOLEAN_CELL_TRUE_LABEL = "extGrid.boolean.cell.true.label",
    BOOLEAN_CELL_FALSE_LABEL = "extGrid.boolean.cell.false.label",

    ACTIONS_CELL_MORE = "extGrid.actions.cell.more",

    PIN_TO_LEFT = "extGrid.pin.to.left",
    PIN_TO_RIGHT = "extGrid.pin.to.right",
    UNPIN = "extGrid.unpin",

    TREE_DATA_GROUPING_HEADER_NAME = "extGrid.tree.data.grouping.header.name",
    TREE_DATA_EXPAND = "extGrid.tree.data.expand",
    TREE_DATA_COLLAPSE = "extGrid.tree.data.collapse",

    GROUPING_COLUMN_HEADER_NAME = "extGrid.grouping.column.header.name",
    GROUP_COLUMN = "extGrid.group.column",
    UNGROUP_COLUMN = "extGrid.ungroup.column",

    DETAIL_PANEL_TOGGLE = "extGrid.detail.panel.toggle",
    EXPAND_DETAIL_PANEL = "extGrid.expand.detail.panel",
    COLLAPSE_DETAIL_PANEL = "extGrid.collapse.detail.panel",

    ROW_REORDERING_HEADER_NAME = "extGrid.row.reordering.header.name",

    AGGREGATION_MENU_ITEM_HEADER = "extGrid.aggregation.menu.item.header",
    AGGREGATION_FUNCTION_LABEL_SUM = "extGrid.aggregation.function.label.sum",
    AGGREGATION_FUNCTION_LABEL_AVG = "extGrid.aggregation.function.label.avg",
    AGGREGATION_FUNCTION_LABEL_MIN = "extGrid.aggregation.function.label.min",
    AGGREGATION_FUNCTION_LABEL_MAX = "extGrid.aggregation.function.label.max",
    AGGREGATION_FUNCTION_LABEL_SIZE = "extGrid.aggregation.function.label.size",

}
