export enum DiagramsGridActionType {
    SHOW_DETAIL = "SHOW_DETAIL",
    SHOW_EDITOR = "SHOW_EDITOR",
    EDITOR_MODE = "EDITOR_MODE",
    EDIT_BACKUP = "EDIT_BACKUP",
}

export class DiagramsGridAction {
    public static readonly [DiagramsGridActionType.SHOW_DETAIL] = new DiagramsGridAction(DiagramsGridActionType.SHOW_DETAIL, "showDetail");
    public static readonly [DiagramsGridActionType.SHOW_EDITOR] = new DiagramsGridAction(DiagramsGridActionType.SHOW_EDITOR, "showEditor");
    public static readonly [DiagramsGridActionType.EDITOR_MODE] = new DiagramsGridAction(DiagramsGridActionType.EDITOR_MODE, "editorMode");
    public static readonly [DiagramsGridActionType.EDIT_BACKUP] = new DiagramsGridAction(DiagramsGridActionType.EDIT_BACKUP, "editBackup");

    public static values(): Array<DiagramsGridAction> {
        return Object.keys(DiagramsGridActionType)
            .map(key => DiagramsGridAction[key as DiagramsGridActionType])
            .sort((a, b) => a.actionType.toString().localeCompare(b.actionType.toString()));
    }

    public static valueOf(actionTypeName: string) {
        const existingValues = Object.keys(DiagramsGridActionType)
            .filter((name) => name === actionTypeName);
        return existingValues.length === 1 ? DiagramsGridAction[existingValues[0] as DiagramsGridActionType] : null;
    }

    public static getQueryDataKey(actionType: DiagramsGridActionType) {
        return DiagramsGridAction[actionType].queryDataKey;
    }

    constructor(public readonly actionType: DiagramsGridActionType,
                public readonly queryDataKey: string) {
    }
}
