import RouteDefinitionID from "./RouteDefinitionID";
import RouteDefinition from "./RouteDefinition";
import LoginPage from "../../pages/login/LoginPage";
import Constants from "../Constants";
import MainPage from "../../pages/MainPage";
import RouteDefinitionRenderer from "./RouteDefinitionRenderer";
import ElementsPage, {
    ELEMENTS_PAGE_PRESETS_ID_QUERY_PARAM, ELEMENTS_PAGE_SHOW_DETAIL_QUERY_PARAM
} from "../../pages/main/content/elements/ElementsPage";
import {ExtractRouteParams, RouteComponentProps} from "react-router";
import {ExportConfigurationIdNullable, ImportConfigurationIdNullable, JSXElementNullable} from "../Types";
import ParamsExtractor from "./ParamsExtractor";
import ProfilePage from "../../pages/main/content/users/ProfilePage";
import DiagramsPage from "../../pages/main/content/diagrams/DiagramsPage";
import RouteDefinitionType from "./RouteDefinitionType";
import ImportPage from "../../pages/main/content/import/ImportPage";
import LabelsPage from "../../pages/main/content/labels/LabelsPage";
import CollectionsPage from "../../pages/main/content/collections/CollectionsPage";
import AppInfoPage from "../../pages/main/content/AppInfoPage";
import UsersPage from "../../pages/main/content/users/UsersPage";
import ExportPage from "../../pages/main/content/export/ExportPage";
import LoginFailedPage from "../../pages/login/LoginFailedPage";
import StereotypesPage from "../../pages/main/content/stereotypes/StereotypesPage";
import DashboardPage from "../../pages/main/content/dashboard/DashboardPage";
import DashboardAdministrationPage from "../../pages/main/content/dashboard/DashboardAdministrationPage";
import HierarchyTreePage from "../../pages/main/content/tree/HierarchyTreePage";
import mainPageAccessResolver from "../../pages/MainPageAccessResolver";
import loginPageService from "../../pages/login/LoginPageService";
import stereotypesPageAccessResolver from "../../pages/main/content/stereotypes/StereotypesPageAccessResolver";
import importPageAccessResolver from "../../pages/main/content/import/ImportPageAccessResolver";
import {UserDto} from "../apis/user/UserDto";
import collectionsPageAccessResolver from "../../pages/main/content/collections/CollectionsPageAccessResolver";
import GenerateMetamodelPage from "../../pages/main/content/metamodel/extraction/GenerateMetamodelPage";
import ModelsComparisonPage from "../../pages/main/content/modelscomparison/ModelsComparisonPage";
import {DiagramsGridAction, DiagramsGridActionType} from "../../pages/main/content/diagrams/DiagramsGridAction";
import {CommonTranslation} from "../../pages/main/content/CommonTranslation";
import {_transl} from "../../store/localization/TranslMessasge";
import {MenuDefinitionTranslationKey} from "../menudefinition/MenuDefinitionTranslationKey";
import DiagramExportViewPage from "../../pages/DiagramExportViewPage";
import DiagramEditorPage from "../../pages/main/content/diagrams/DiagramEditorPage";
import GraphQueryPage from "../../pages/main/content/graphquery/GraphQueryPage";
import {ValidateModelPage} from "../../pages/main/content/metamodel/validation/ValidateModelPage";
import {ModelDocumentationPage} from "../../pages/main/content/documentation/ModelDocumentationPage";


const MAIN_PATH = Constants.FE_APP_MAIN_ROUTE;

// params

export const ID_PARAM = "id";
export const LOGIN_PARAM = "login";
export const INITIAL_CONFIGURATION_ID_PARAM = "initialConfigurationId";

// application route definitions

export const loginPage = new RouteDefinition(RouteDefinitionID.LOGIN_PAGE, RouteDefinitionType.APPLICATION, Constants.FE_APP_LOGIN_ROUTE, {}, params => <LoginPage service={loginPageService} {...params} />, false);
export const loginFailedPage = new RouteDefinition(RouteDefinitionID.LOGIN_FAILED_PAGE, RouteDefinitionType.APPLICATION, Constants.FE_APP_LOGIN_FAILED_ROUTE, {}, params => <LoginFailedPage {...params} />, false);
export const diagramViewPage = new RouteDefinition(RouteDefinitionID.DIAGRAM_EXPORT_VIEW_PAGE, RouteDefinitionType.APPLICATION, `${Constants.FE_APP_DIAGRAM_EXPORT_VIEW_ROUTE}`, {}, params => <DiagramExportViewPage {...params} />, true);
export const mainPage = new RouteDefinition(RouteDefinitionID.MAIN_PAGE, RouteDefinitionType.APPLICATION, Constants.FE_APP_MAIN_ROUTE, {}, params => <MainPage {...params} />, false, false, undefined, (user) => mainPageAccessResolver.hasPageAccess(user));
export const mainPageRedirect = new RouteDefinition(RouteDefinitionID.MAIN_PAGE_REDIRECT, RouteDefinitionType.APPLICATION, "*", undefined,undefined, false, true, Constants.FE_APP_MAIN_ROUTE);

// main page route definitions

export const dashboardPage = new RouteDefinition(RouteDefinitionID.DASHBOARD_PAGE, RouteDefinitionType.MAIN_PAGE, `${Constants.FE_APP_DASHBOARD_ROUTE}`, {}, params => <DashboardPage />);
export const structuresPage = new RouteDefinition(RouteDefinitionID.STRUCTURES_PAGE, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/structures`, {}, params => <HierarchyTreePage/>);
export const elementsPage = new RouteDefinition(RouteDefinitionID.ELEMENTS_PAGE, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/elements`, {}, params => <ElementsPage {...params} />);
export const diagramsPage = new RouteDefinition(RouteDefinitionID.DIAGRAMS_PAGE, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/diagrams`, {}, params => <DiagramsPage {...params} />);
export const diagramEditorPage = new RouteDefinition(RouteDefinitionID.DIAGRAM_EDITOR_PAGE, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/diagramEditor`, {}, params => <DiagramEditorPage {...params} />);
export const toolsPage = new RouteDefinition(RouteDefinitionID.TOOLS_PAGE, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/tools`, {}, params => <div>{_transl(MenuDefinitionTranslationKey.TOOLS)}</div>);
export const administrationPage = new RouteDefinition(RouteDefinitionID.ADMINISTRATION_PAGE, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/administration`, {}, params => <div>{_transl(MenuDefinitionTranslationKey.ADMINISTRATION)}</div>);
export const profilePage = new RouteDefinition(RouteDefinitionID.PROFILE_PAGE, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/profile`, {}, params => <ProfilePage {...params} />);

export const queriesPage = new RouteDefinition(RouteDefinitionID.QUERIES_PAGE, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/queries`, {}, params => <GraphQueryPage {...params} />);
export const exportPage = new RouteDefinition(RouteDefinitionID.EXPORT_PAGE, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/export`, {}, params => <ExportPage />);
export const exportPageWithPreselectedConfiguration = new RouteDefinition(RouteDefinitionID.EXPORT_PAGE_WITH_PRESELECTED_CONFIGURATION, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/export/:${INITIAL_CONFIGURATION_ID_PARAM}`, {[INITIAL_CONFIGURATION_ID_PARAM]: undefined as ExportConfigurationIdNullable}, params => <ExportPage {...params}/>);
export const importPage = new RouteDefinition(RouteDefinitionID.IMPORT_PAGE, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/import`, {}, params => <ImportPage />, undefined, undefined, undefined, (user) => importPageAccessResolver.hasPageAccess(user));
export const importPageWithPreselectedConfiguration = new RouteDefinition(RouteDefinitionID.IMPORT_PAGE_WITH_PRESELECTED_CONFIGURATION, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/import/:${INITIAL_CONFIGURATION_ID_PARAM}`, {[INITIAL_CONFIGURATION_ID_PARAM]: undefined as ImportConfigurationIdNullable}, params => <ImportPage {...params}/>);
export const generateMetamodelPage = new RouteDefinition(RouteDefinitionID.GENERATE_METAMODEL, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/generateMetamodel`, {}, params => <GenerateMetamodelPage />);
export const modelsComparisonPage = new RouteDefinition(RouteDefinitionID.MODELS_COMPARISON, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/modelsComparison`, {}, params => <ModelsComparisonPage />);
export const validateModelPage = new RouteDefinition(RouteDefinitionID.VALIDATE_MODEL, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/validateModel`, {}, params => <ValidateModelPage />);
export const modelDocumentationPage = new RouteDefinition(RouteDefinitionID.MODEL_DOCUMENTATION, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/modelDocumentation`, {}, params => <ModelDocumentationPage />);

export const usersPage = new RouteDefinition(RouteDefinitionID.USERS_PAGE, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/users`, {}, params => <UsersPage {...params} />, );
export const labelsPage = new RouteDefinition(RouteDefinitionID.LABELS_PAGE, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/labels`, {}, params => <LabelsPage {...params} />, );
export const collectionsPage = new RouteDefinition(RouteDefinitionID.COLLECTIONS_PAGE, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/collection`, {}, params => <CollectionsPage {...params} />, undefined, undefined, undefined, (user) => collectionsPageAccessResolver.hasPageAccess(user));
export const stereotypesPage = new RouteDefinition(RouteDefinitionID.STEREOTYPES_PAGE, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/stereotypes`, {}, params => <StereotypesPage {...params} />, undefined, undefined, undefined, (user) => stereotypesPageAccessResolver.hasPageAccess(user));
export const dashboardAdministrationPage = new RouteDefinition(RouteDefinitionID.DASHBOARD_ADMINISTRATION_PAGE, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/dashboard-administration`, {}, params => <DashboardAdministrationPage />);

export const appInfoPage = new RouteDefinition(RouteDefinitionID.APP_INFO_PAGE, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/about`, {}, params => <AppInfoPage {...params} />);
export const logoutPage = new RouteDefinition(RouteDefinitionID.LOGOUT_PAGE, RouteDefinitionType.MAIN_PAGE, `${MAIN_PATH}/dashboard`, {});

export const mainPageNotFoundPage = new RouteDefinition(RouteDefinitionID.MAIN_PAGE_NOT_FOUND_PAGE, RouteDefinitionType.MAIN_PAGE, "*", {}, params => <div>{_transl(CommonTranslation.PAGE_NOT_FOUND)}</div>);


const definitions = [
    // app routes (order matters)
    loginPage, loginFailedPage, diagramViewPage, mainPage, mainPageRedirect,

    // main page routes (order matters !!)
    dashboardPage,
    structuresPage, elementsPage, diagramsPage, diagramEditorPage, toolsPage, administrationPage, profilePage,
    queriesPage, exportPage, exportPageWithPreselectedConfiguration, importPage, importPageWithPreselectedConfiguration,
    generateMetamodelPage, modelsComparisonPage, validateModelPage, modelDocumentationPage,
    usersPage, labelsPage,  collectionsPage, stereotypesPage,
    dashboardAdministrationPage, appInfoPage,

    // "Not Found" page catches all (i.e. do NOT put any page after "Not Found" page)
    mainPageNotFoundPage,
]


export default class RouteDefinitionUtils {

    public static getById(id: RouteDefinitionID) {
        return definitions.filter(definition => definition.id === id)[0];
    }

    public static getByType(type: RouteDefinitionType) {
        return definitions.filter(definition => definition.type === type);
    }

    public static getRoutesByType(type: RouteDefinitionType, user?: UserDto) {
        return this.getByType(type).map(definition => RouteDefinitionUtils.getRoute<unknown>(definition as RouteDefinition<unknown>, user))
    }

    public static getRoute<T>(definition: RouteDefinition<T>, user?: UserDto) {
        return RouteDefinitionRenderer({definition, user});
    }

    public static doRender<T>(definition: RouteDefinition<T>, params: RouteComponentProps<ExtractRouteParams<string, string>>): JSXElementNullable {
        const extractedParams = ParamsExtractor.extract(params, definition);
        return definition.doRender != null ? definition.doRender(extractedParams) : null;
    }

    public static resolvePath<T>(definition: RouteDefinition<T>, params: T, queryParams?: Array<{name: string, value: string}>): string {
        const keys = Object.keys(params);
        let replaced = definition.path;
        // @ts-ignore
        keys.forEach(key => replaced = replaced.replace(":"+key, params[key]))
        if (queryParams) {
            replaced += "?";
            queryParams.forEach(param => {
                replaced += `${param.name}=${param.value}&`;
            })

        }
        return replaced;
    }

    public static resolveDiagramDetailPath(identifier: string) {
        const queryParam = {
            name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.SHOW_DETAIL),
            value: identifier
        };
        return RouteDefinitionUtils.resolvePath(diagramsPage, {}, [queryParam]);
    }

    public static resolveElementDetailPath(identifier: string) {
        const queryParam = {
            name: ELEMENTS_PAGE_SHOW_DETAIL_QUERY_PARAM,
            value: identifier
        };
        return RouteDefinitionUtils.resolvePath(elementsPage, {}, [queryParam]);
    }

    public static resolveElementPagePath(pagePresetsId: string) {
        const queryParam = {
            name: ELEMENTS_PAGE_PRESETS_ID_QUERY_PARAM,
            value: pagePresetsId,
        };
        return RouteDefinitionUtils.resolvePath(elementsPage, {}, [queryParam]);
    }

}
