import React from "react";
import {
    CircularProgress,
    IconButton,
    InputBase,
    Paper,
    Slide
} from "@mui/material";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import SearchIcon from '@mui/icons-material/Search';
import Divider from "@mui/material/Divider";
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {of, Subject, Subscription} from 'rxjs';
import {IApplicationState} from "../../../../store/Store";
import {connect} from "react-redux";
import {catchError, debounceTime, filter, startWith, switchMap} from "rxjs/operators";
import {Action, Dispatch} from "redux";
import UIUtils from "../../../../common/UIUtils";
import ContextAreaSelect from "./ContextAreaSelect";
import {withRouter, RouteComponentProps} from "react-router";
import {SearchResultItem, SearchProvider} from "./SearchProvider";
import SearchResultArea, {SEARCH_RESULT_AREA_ACTION_ROLE} from "./SearchResultArea";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {AppBarTranslationKey} from "../AppBarTranslationKey";

// styles

const appBarSearchStyles = (theme: Theme) => createStyles({
    root: {
        color: "white",
        backgroundColor: "transparent",
        padding: 0,
        display: 'inline-flex',
        alignItems: 'center',
        width: 400,
    },
    appBarSearchMenus: {
        position: "relative",
    },
    appBarSearch: {
        display: 'inline-flex',
        alignItems: 'center',
        overflow: "hidden",
        borderRadius: 50,
    },
    searchBarIconsPadding: {
        padding: theme.spacing(0.8),
    },
    searchBarInProgressIcon: {
        color: "white",
    },
    closedBgColor: {
        backgroundColor: theme.palette.primary.main,
    },
    openedBgColor: {
        backgroundColor: "rgba(255, 255, 255, 0.15)",
    },
    contextArea: {
        height: "100%",
        paddingLeft: theme.spacing(2),
        backgroundColor: "rgba(255, 255, 255, 0.17)",
        cursor: "pointer",
        userSelect: "none",
    },
    visible: {
        visibility: "visible",
    },
    hidden: {
        visibility: "hidden",
    },
    input: {
        color: "white",
        marginLeft: theme.spacing(1),
        flex: 1,
    },
});


const INPUT_BASE_ID = "app-bar-search-id";

export enum SearchStatus {
    NOT_STARTED = "NOT_STARTED",
    IN_PROGRESS = "IN_PROGRESS",
    SUCCEEDED = "SUCCEEDED",
    FAILED = "FAILED",
}

export interface ISearchState {
    status: SearchStatus,
    items: Array<unknown>,
}

// props & state

interface IProps extends WithStyles<typeof appBarSearchStyles>, RouteComponentProps {
    searchProviders: SearchProvider[],
    doSearch: (action: Action<unknown>) => void,
}

interface IState {
    searchTerm: string,
    searchBarOpened: boolean,
    contextAreaSelectOpened: boolean,
    searchProviderId: string,
    searchResultAreaOpened: boolean,
    searchState: ISearchState,
}

// component class

class Search extends React.Component<IProps, IState> {

    private searchInputSubscription: Subscription | null;
    private searchSubject: Subject<string>;

    constructor(props: IProps) {
        super(props);
        this.state = this.createInitialState(props.searchProviders[0].id);
        this.searchInputSubscription = null;
        this.searchSubject = new Subject<string>();
    }

    createInitialState(defaultProviderId: string): IState {
        return {
            searchTerm: "",
            searchBarOpened: false,
            contextAreaSelectOpened: false,
            searchProviderId: defaultProviderId,
            searchResultAreaOpened: false,
            searchState: this.createSearchStatus(SearchStatus.NOT_STARTED, []),
        }
    }

    createSearchStatus(status: SearchStatus, items: Array<unknown>): ISearchState {
        return {
            status: status,
            items: items,
        }
    }

    componentWillUnmount() {
        if (this.searchInputSubscription != null) {
            this.searchInputSubscription.unsubscribe();
        }
    }

    componentDidMount() {
        this.searchInputSubscription = this.searchSubject
            .pipe(
                filter(str => str != null && str.length >= 3),
                debounceTime(700))
            .subscribe((term) => {
                const searchTerm = term || "";

                const { searchProviderId } = this.state;
                const { resultAreaConfig } = this.findProviderById(searchProviderId);

                resultAreaConfig.search(searchTerm).pipe(
                    switchMap((resp) => of(resultAreaConfig.extractItemsFromResponse(resp.response))),
                    switchMap((items) => of(this.createSearchStatus(SearchStatus.SUCCEEDED, items))),
                    catchError(err => of(this.createSearchStatus(SearchStatus.FAILED, []))),
                    startWith(this.createSearchStatus(SearchStatus.IN_PROGRESS, []))
                ).subscribe(result => {
                    this.updateSearchState(result.status, result.items);
                })
            });
    }

    findProviderById(providerId: string): SearchProvider {
        return this.props.searchProviders
            .find((provider) => provider.id === providerId) as SearchProvider;
    }

    render() {
        const { classes, searchProviders } = this.props;
        const { searchBarOpened, searchTerm, searchProviderId, searchState, contextAreaSelectOpened, searchResultAreaOpened } = this.state;

        const bgColor = searchBarOpened ? classes.openedBgColor : classes.closedBgColor;
        const inProgressIconClass = searchState.status === SearchStatus.IN_PROGRESS ? classes.visible : classes.hidden;
        const searchProvider = this.findProviderById(searchProviderId);

        return (
            <div className={classes.appBarSearchMenus}>
                <div className={`${bgColor} ${classes.appBarSearch}`}>
                    <Slide in={searchBarOpened} direction={"left"} onEntered={() => UIUtils.requestFocus(INPUT_BASE_ID)}>
                        <Paper component="form" className={classes.root} elevation={0}>
                            <div className={classes.contextArea} onClick={e => this.updateContextAreaSelectOpened(!contextAreaSelectOpened)} >
                                { _transl(searchProvider.contextAreaConfig.primaryTextKey) }
                                <IconButton
                                    className={`${classes.searchBarIconsPadding}`}
                                    aria-label="menu"
                                    color="inherit"
                                    size="large">
                                    <ArrowDropDownIcon />
                                </IconButton>
                            </div>
                            <InputBase
                                id={INPUT_BASE_ID}
                                autoComplete='off'
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter') {
                                        ev.preventDefault();
                                        this.search();
                                    }
                                }}
                                className={classes.input}
                                value={searchTerm}
                                onChange={e => this.updateSearchTerm(e.target.value)}
                                placeholder={ _transl(AppBarTranslationKey.SEARCH_BAR_ENTER_AT_LEAST_3_CHARS) }
                                onBlur={e => {
                                    const relatedTarget: HTMLElement = e.relatedTarget as HTMLElement;
                                    const relatedTargetRole = relatedTarget != null ? relatedTarget.getAttribute('role') : "";
                                    if (relatedTargetRole !== SEARCH_RESULT_AREA_ACTION_ROLE) {
                                        this.updateSearchStateAreaOpened(false);
                                    }
                                }}
                            />

                            <CircularProgress size={20} className={`${classes.searchBarInProgressIcon} ${inProgressIconClass}`} />
                            <Divider />
                        </Paper>
                    </Slide>
                    <IconButton
                        className={classes.searchBarIconsPadding}
                        aria-label="search bar"
                        color="inherit"
                        onClick={e => this.updateSearchBarOpened()}
                        size="large">
                        { searchBarOpened ? <CloseIcon />  : <SearchIcon />}
                    </IconButton>
                </div>

                <ContextAreaSelect searchProviders={searchProviders} contextAreaSelectOpened={contextAreaSelectOpened} onContextAreaUpdated={(id: string) => this.updateSelectedSearchProviderId(id)} />

                <SearchResultArea searchProvider={searchProvider} searchResultAreaOpened={searchResultAreaOpened} searchState={searchState}
                                  onItemSelected={(item) => this.onSearchResultItemSelected(item)} onShowAllItemsSelected={() => this.onShowAllItemsSelected()} />
            </div>
        );
    }

    updateSearchState(status: SearchStatus, items: Array<unknown>) {
        const searchStateAreaOpened = status === SearchStatus.SUCCEEDED || status === SearchStatus.FAILED;
        this.setState((state) => {
            return {
                ...state,
                searchResultAreaOpened: searchStateAreaOpened,
                searchState : this.createSearchStatus(status, items),
            }
        });
    }

    updateSearchBarOpened() {
        const newSearchBarOpened = !this.state.searchBarOpened;
        let newState: IState;
        if (newSearchBarOpened) {
            newState = {
                ...this.state,
                searchBarOpened: newSearchBarOpened,
            }
        } else {
            newState = this.createInitialState(this.props.searchProviders[0].id);
        }
        this.setState((state) => newState);
    }

    updateSearchTerm(newTerm: string) {
        this.setState((state) => {
            return {
                ...state,
                contextAreaSelectOpened: false,
                searchResultAreaOpened: false,
                searchTerm: newTerm,
            }
        }, () => {
            this.search();
        });
    }

    search() {
        this.searchSubject.next(this.state.searchTerm);
    }

    updateSearchStateAreaOpened(opened: boolean) {
        this.setState((state) => {
            return {
                ...state,
                searchResultAreaOpened: opened,
            }
        });
    }

    updateContextAreaSelectOpened(opened: boolean) {
        this.setState((state) => {
            return {
                ...state,
                contextAreaSelectOpened: opened,
            }
        });
    }

    updateSelectedSearchProviderId(id: string) {
        this.setState((state) => {
            return {
                ...state,
                contextAreaSelectOpened: false,
                searchProviderId: id,
            }
        }, () => {
            UIUtils.requestFocus(INPUT_BASE_ID);
        });
    }

    onShowAllItemsSelected() {
        const { doSearch, history } = this.props;
        const { searchTerm, searchProviderId } = this.state;
        const { resultAreaConfig } = this.findProviderById(searchProviderId);

        const action: Action<unknown> = resultAreaConfig.getSearchAction(searchTerm);
        doSearch(action);

        const newHistory = resultAreaConfig.getRedirectUrl();
        history.push(newHistory);

        this.updateSearchBarOpened();
    }

    onSearchResultItemSelected(item: SearchResultItem) {
        const { history } = this.props;
        const { searchProviderId } = this.state;
        const { resultAreaConfig } = this.findProviderById(searchProviderId);

        const newHistory = resultAreaConfig.getDetailRedirectUrl(item);
        history.push(newHistory);

        this.updateSearchBarOpened();
    }

}

const mapStateToProps = (state: IApplicationState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    doSearch: (action: Action<unknown>) => dispatch(action),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(appBarSearchStyles, { withTheme: true })(withRouter(Search)));
