import Keycloak, {KeycloakTokenParsed} from "keycloak-js";
import {KeycloakHolder} from "../keycloak/KeycloakHolder";

interface TimeoutSetter {
    setTimeout(handler: () => void, timeoutMs: number): number,
    clearTimeout(id: number): void,
}

class WindowTimeoutSetter implements TimeoutSetter {

    setTimeout(handler: () => void, timeoutMs: number): number {
        return window.setTimeout(handler, timeoutMs);
    }

    clearTimeout(id: number) {
        window.clearTimeout(id);
    }
}

export interface TokenRefreshService {
    planTokenRefresh(keycloakInstance: Keycloak.KeycloakInstance,
                     tokenParsed: KeycloakTokenParsed,
                     onRefreshFailed?: (reason: any) => void): void,
}

class IntervalTokenRefreshService implements TokenRefreshService {

    private static readonly MIN_VALIDITY_SEC = 10;

    private timeoutId?: number;

    constructor(private timeoutSetter: TimeoutSetter) {
    }

    planTokenRefresh(keycloakInstance: Keycloak.KeycloakInstance,
                     tokenParsed: KeycloakTokenParsed,
                     onRefreshFailed?: (reason: any) => void) {
        const nextRefreshTimeoutMs = this.getNextRefreshTimeoutMs(tokenParsed);
        if (nextRefreshTimeoutMs >= 0) {
            this.timeoutId = this.timeoutSetter.setTimeout(() => {
                    keycloakInstance.updateToken(IntervalTokenRefreshService.MIN_VALIDITY_SEC)
                        .then(
                            (refreshed) => {
                                this.planTokenRefresh(keycloakInstance, KeycloakHolder.getParsedToken()!, onRefreshFailed)
                            },
                            (reason: any) => onRefreshFailed && onRefreshFailed(reason))
                        .catch((reason) => onRefreshFailed && onRefreshFailed(reason));
                },
                nextRefreshTimeoutMs);
        } else {
            keycloakInstance.logout();
        }
    }

    private getNextRefreshTimeoutMs(tokenParsed: KeycloakTokenParsed): number {
        return ((tokenParsed.exp! - tokenParsed.iat!) * 1000) - (IntervalTokenRefreshService.MIN_VALIDITY_SEC / 2 * 1000);
    }
}

export default new IntervalTokenRefreshService(new WindowTimeoutSetter()) as TokenRefreshService;
