import Constants from "../Constants";
import {Observable, Subscriber} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";
import Api from "../Api";
import {IFilter} from "../../store/elements/Elements";
import {ElementCreateDto} from "./element/ElementCreateDto";
import {map} from "rxjs/operators";
import {ElementDto} from "./element/ElementDto";

export interface IElementStateDto {
    code: string,
    name: string,
}

export interface IElementTypeDto {
    code: string,
    name: string,
}

export default class Elements {

    public static ELEMENT_ID_PARAM = ":elementId";
    public static ELEMENT_PROPERTY_DEFINITION_ID_PARAM = ":propertyDefinitionId";
    public static ATTACHMENT_ID_PARAM = ":attachmentId";
    public static ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/repository/elements";
    public static ENDPOINT_BULK_URL: string = Elements.ENDPOINT_URL + "/bulk";
    public static SEARCH_URL: string = Elements.ENDPOINT_URL + ":search";
    public static GET_BY_IDENTIFIER_URL: string = `${Elements.ENDPOINT_URL}/${Elements.ELEMENT_ID_PARAM}`;
    public static GET_ATTACHMENTS_BY_IDENTIFIER_URL: string = `${Elements.ENDPOINT_URL}/${Elements.ELEMENT_ID_PARAM}/attachments`;
    public static DOWNLOAD_ATTACHMENT_BY_IDENTIFIER_URL: string = `${Elements.ENDPOINT_URL}/${Elements.ELEMENT_ID_PARAM}/attachments/${Elements.ATTACHMENT_ID_PARAM}`;
    public static UPLOAD_ATTACHMENT_BY_IDENTIFIER_URL: string = `${Elements.ENDPOINT_URL}/${Elements.ELEMENT_ID_PARAM}/attachments`;
    public static DELETE_ATTACHMENT_BY_IDENTIFIER_URL: string = `${Elements.ENDPOINT_URL}/${Elements.ELEMENT_ID_PARAM}/attachments/${Elements.ATTACHMENT_ID_PARAM}`;
    public static STATES_URL: string = Elements.ENDPOINT_URL + "/states";
    public static ELEMENT_TYPES_URL: string = Elements.ENDPOINT_URL + "/supported-element-types";
    public static POST_ITEM_CREATE_PROPERTY_URL: string = `${Elements.ENDPOINT_URL}/${Elements.ELEMENT_ID_PARAM}/properties/`;
    public static PUT_ITEM_UPDATE_PROPERTY_URL: string = `${Elements.ENDPOINT_URL}/${Elements.ELEMENT_ID_PARAM}/properties/${Elements.ELEMENT_PROPERTY_DEFINITION_ID_PARAM}`;
    public static DELETE_ITEM_PROPERTY_URL: string = `${Elements.ENDPOINT_URL}/${Elements.ELEMENT_ID_PARAM}/properties/${Elements.ELEMENT_PROPERTY_DEFINITION_ID_PARAM}`;
    public static CREATE_ELEMENT: string = `${Elements.ENDPOINT_URL}/`;
    public static UPDATE_MERGE_ELEMENTS_URL: string = `${Elements.ENDPOINT_URL}/${Elements.ELEMENT_ID_PARAM}:merge`;
    public static PUT_ADD_BULK_LABELS_TO_ELEMENTS: string = `${Elements.ENDPOINT_BULK_URL}/labels:add`;
    public static DELETE_BULK_LABELS_TO_ELEMENTS: string = `${Elements.ENDPOINT_BULK_URL}/labels:delete`;
    public static PUT_ADD_BULK_COLLECTIONS_TO_ELEMENTS: string = `${Elements.ENDPOINT_BULK_URL}/collections:add`;
    public static DELETE_BULK_COLLECTIONS_TO_ELEMENTS: string = `${Elements.ENDPOINT_BULK_URL}/collections:delete`;
    public static PUT_UPDATE_ELEMENTS_TYPE: string = `${Elements.ENDPOINT_BULK_URL}/type:update`;
    public static POST_ADD_BINDING_ELEMENTS_URL: string = `${Elements.ENDPOINT_URL}/${Elements.ELEMENT_ID_PARAM}:add-relationship`;

    doSearch(filter: IFilter): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Elements.SEARCH_URL,
            method: "POST",
            body: Elements.createSearchFilter(filter),
        });
    }

    public static createSearchFilter(filter: IFilter) {
        return {
            "fullTextQuery": filter.searchText,
            "nameLike": filter.nameLike,
            "identifierLike": filter.identifierLike,
            "identifiers": filter.identifiers,
            "states": filter.selectedStates != null ? filter.selectedStates.map(state => state.code) : [],
            "types": filter.selectedTypes != null ? filter.selectedTypes.map(type => type.name) : [],
            "collections": filter.selectedCollections != null ? filter.selectedCollections.map(collection => collection.code) : [],
            "labels": filter.selectedLabels != null ? filter.selectedLabels.map(label => label.code) : [],
            "stereotype": filter.stereotype || null,
            "createdBy": filter.selectedAuthors != null ? filter.selectedAuthors.map(state => state.login) : [],
            "validFrom": filter.validFrom || null,
            "validThru": filter.validThru || null,
            "maxPageSize": filter.maxPageSize,
            "pageToken": filter.pageToken,
            "sortingCriteria": filter.sortingCriteria
        }
    }

    getByIdentifier(identifier: string): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Elements.GET_BY_IDENTIFIER_URL.replace(Elements.ELEMENT_ID_PARAM, identifier),
            method: "GET"
        });
    }

    getAttachmentsByIdentifier(identifier: string): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Elements.GET_ATTACHMENTS_BY_IDENTIFIER_URL.replace(Elements.ELEMENT_ID_PARAM, identifier),
            method: "GET"
        });
    }

    getStates(): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Elements.STATES_URL,
            method: "GET",
        });
    }

    getTypes(): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Elements.ELEMENT_TYPES_URL,
            method: "GET",
        });
    }

    downloadAttachment(elementId: string, attachmentId: string, responseType: string, progressSubscriber?: Subscriber<ProgressEvent<EventTarget>>) {
        return Api.createAjax({
            url: Api.replaceUrlParams(Elements.DOWNLOAD_ATTACHMENT_BY_IDENTIFIER_URL, {[Elements.ELEMENT_ID_PARAM]: elementId, [Elements.ATTACHMENT_ID_PARAM]: attachmentId}),
            method: "GET",
            responseType: responseType,
            createXHR: function (){
                const xhr = new XMLHttpRequest();
                if (progressSubscriber != null) {
                    xhr.onprogress = (progress) => progressSubscriber.next(progress);
                }
                return xhr;
            }
        });
    }

    deleteAttachment(elementId: string, attachmentId: string) {
        return Api.createAjax({
            url: Api.replaceUrlParams(Elements.DELETE_ATTACHMENT_BY_IDENTIFIER_URL, {[Elements.ELEMENT_ID_PARAM]: elementId, [Elements.ATTACHMENT_ID_PARAM]: attachmentId}),
            method: "DELETE",
        });
    }

    uploadAttachment(elementId: string, file: File, responseType: string, progressSubscriber?: Subscriber<ProgressEvent<EventTarget>>) {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return Api.createAjax({
            url: Api.replaceUrlParams(Elements.UPLOAD_ATTACHMENT_BY_IDENTIFIER_URL, {[Elements.ELEMENT_ID_PARAM]: elementId}),
            method: "POST",
            body: formData,
            createXHR: function (){
                const xhr = new XMLHttpRequest();
                if (progressSubscriber != null) {
                    xhr.upload.onprogress = (progress) => progressSubscriber.next(progress);
                }
                return xhr;
            }
        }, true);
    }

    createItemAttribute(elementId: string, name: string, type: string, value: string) {
        return Api.createAjax({
                url: Elements.POST_ITEM_CREATE_PROPERTY_URL.replace(":elementId", elementId),
                method: "POST",
                body: {
                    name: name,
                    type: type,
                    value: value,
                },
            });
    }

    updateItemAttribute(elementId: string, propertyDefinitionId: string, value: string) {
        return Api.createAjax({
                url: Elements.PUT_ITEM_UPDATE_PROPERTY_URL.replace(":elementId", elementId)
                    .replace(":propertyDefinitionId", propertyDefinitionId),
                method: "PUT",
                body: {
                    value: value,
                },
            });
    }

    deleteItemAttribute(selectedRowId: string, elementId: string): Observable<AjaxResponse> {
        return Api.createAjax({
                url: Elements.DELETE_ITEM_PROPERTY_URL
                    .replace(Elements.ELEMENT_ID_PARAM, elementId)
                    .replace(Elements.ELEMENT_PROPERTY_DEFINITION_ID_PARAM, selectedRowId),
                method: "DELETE",
            });
    }

    createElement(elementCreate: ElementCreateDto) {
        const request = {
            url: Elements.CREATE_ELEMENT,
            method: "POST",
            body: elementCreate,
        };
        return Api.createAjax(request)
                .pipe(
                    map(response => response.response as ElementDto)
                )
                .toPromise();
    }

    mergeElements(rowToMerge: string, rowIds: Array<string>): Observable<AjaxResponse> {
        return Api.createAjax({
                url: Elements.UPDATE_MERGE_ELEMENTS_URL
                    .replace(Elements.ELEMENT_ID_PARAM, rowToMerge),
                method: "POST",
                body: rowIds,
            });
    }

    //labels bulk assignment - adds labels to selected elements
    bulkAssignLabels(labelCodes: unknown[], selectedRowIds: Array<string>) {
        return Api.createAjax({
                url: Elements.PUT_ADD_BULK_LABELS_TO_ELEMENTS,
                method: "PUT",
                body: {
                    labelsCodes: labelCodes,
                    identifiers: selectedRowIds,
                },
            });
    }

    //collections bulk assignment - adds collections to selected elements
    bulkAssignCollections(collectionCodes: unknown[], selectedRowIds: Array<string>) {
        return Api.createAjax({
                url: Elements.PUT_ADD_BULK_COLLECTIONS_TO_ELEMENTS,
                method: "PUT",
                body: {
                    collectionsCodes: collectionCodes,
                    identifiers: selectedRowIds,
                },
            });
    }

    //labels bulk delete - deletes labels from selected elements
    bulkDeleteLabels(labelCodes: unknown[], selectedRowIds: Array<string>) {
        return Api.createAjax({
                url: Elements.DELETE_BULK_LABELS_TO_ELEMENTS,
                method: "DELETE",
                body: {
                    labelsCodes: labelCodes,
                    identifiers: selectedRowIds,
                },
            });
    }

    //collections bulk delete - deletes collections from selected elements
    bulkDeleteCollections(collectionCodes: unknown[], selectedRowIds: Array<string>) {
        return Api.createAjax({
                url: Elements.DELETE_BULK_COLLECTIONS_TO_ELEMENTS,
                method: "DELETE",
                body: {
                    collectionsCodes: collectionCodes,
                    identifiers: selectedRowIds,
                },
            });
    }
}

