import {Model} from "../model/Model";

export class DiagramBackup {
    constructor(private _diagramId: string,
                private _diagramModel: Model) {
    }

    public getDiagramId() {
        return this._diagramId;
    }

    public getDiagramModel() {
        return this._diagramModel;
    }

    static from(backup: DiagramBackup) {
        return new DiagramBackup(backup._diagramId, backup._diagramModel)
    }
}
