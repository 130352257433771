import {Dashboard} from "./Dashboard";

const initialState = {} as Dashboard

enum DashboardActionType {
    SET_DASHBOARD= "SET_DASHBOARD",
}

interface DashboardAction {
    type: DashboardActionType,
    dashboard: Dashboard,
}

export function getSaveDashboardAction(dashboard: Dashboard): DashboardAction {
    return {
        type: DashboardActionType.SET_DASHBOARD,
        dashboard: dashboard,
    }
}

export default function dashboardReducer(state = initialState, action: DashboardAction) {
    switch (action.type) {
        case DashboardActionType.SET_DASHBOARD:
            return action.dashboard;
        default:
            return state;
    }
}