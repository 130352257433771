import React, {useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {IApplicationState} from "../../../../store/Store";
import {useSelector} from "react-redux";
import {IconButton, Paper, TextField, Tooltip} from '@mui/material';
import {Description, Group} from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import CommonCssStyles from "../../../../css/CommonCssStyles";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {UsersTranslationKey} from "./UsersTranslationKey";
import {UserRoleTranslator} from "./UserRoleTranslator";
import TitleHeader from '../../../../components/TitleHeader';
import LockIcon from "@mui/icons-material/Lock";
import {ChangeUserPasswordDialog} from "./ChangeUserPasswordDialog";

const useStyles = makeStyles((theme: Theme) => createStyles({
    paper: CommonCssStyles.getRootPageStyles(theme),
    wrapper: CommonCssStyles.getBasicInfoGridStyles(theme),
    headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
    controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme, {paddingTop: theme.spacing(1), paddingBottom: theme.spacing(5)}),
    innerDivider: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
    },
    headerText: {
        display: 'flex',
        alignItems: "center",
        "& > *": {
            marginRight: theme.spacing(2),
        }
    },
    remainingSpace: {
        flexGrow: 1
    }
}));

interface ProfilePageProps {
}

export default function ProfilePage(props: ProfilePageProps) {
    const classes = useStyles();
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
    const userData = useSelector((state: IApplicationState) => state.user.userData!);

    function showChangePasswordDialog() {
        setPasswordDialogOpen(true);
    }

    function hideChangePasswordDialog() {
        setPasswordDialogOpen(false);
    }

    return (
        <>
            <ChangeUserPasswordDialog open={passwordDialogOpen}
                                      userLogin={userData.login}
                                      onClose={() => hideChangePasswordDialog()} />

            <Paper className={classes.paper}>
                <TitleHeader title={_transl(UsersTranslationKey.PROFILE)}
                             left={
                                 <Tooltip title={_transl(UsersTranslationKey.CHANGE_PASSWORD)} placement={"top"}>
                                     <IconButton onClick={(e) => showChangePasswordDialog()} size="large">
                                         <LockIcon/>
                                     </IconButton>
                                 </Tooltip>
                             } />
                <div className={classes.wrapper}>
                    <div className={classes.headerPageSegment}>
                        <Typography variant="h5" className={classes.headerText}>
                            <PersonIcon color={"primary"} /> {_transl(UsersTranslationKey.BASIC_DATA)}
                        </Typography>
                    </div>
                    <div className={classes.controlPageSegment} >
                        <TextField
                            id="username"
                            key={userData.login || "login"}
                            label={_transl(UsersTranslationKey.USER_LOGIN_NAME)}
                            style={{ margin: 8 }}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            defaultValue={userData.login}
                            inputProps={{
                                readOnly: true,
                            }}
                            size={"small"}
                        />
                        <TextField
                            id="name"
                            key={userData.firstName || "firstname"}
                            label={_transl(UsersTranslationKey.USER_FIRST_NAME)}
                            style={{ margin: 8 }}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            defaultValue={userData.firstName}
                            size={"small"}
                        />
                        <TextField
                            id="lastname"
                            key={userData.lastName || "lastname"}
                            label={_transl(UsersTranslationKey.USER_LAST_NAME)}
                            style={{ margin: 8 }}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            defaultValue={userData.lastName}
                            size={"small"}
                        />
                        <TextField
                            id="email"
                            key={userData.email || "email"}
                            label={_transl(UsersTranslationKey.USER_EMAIL)}
                            style={{ margin: 8 }}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            defaultValue={userData.email}
                            size={"small"}
                        />
                    </div>
                    <Divider className={classes.innerDivider} />
                    <div className={classes.headerPageSegment}>
                        <Typography variant="h5" className={classes.headerText}>
                            <Description color="primary" />{_transl(UsersTranslationKey.ADDITIONAL_DATA)}
                        </Typography>
                    </div>
                    <div className={classes.controlPageSegment} >
                        <TextField
                            id="note"
                            key={userData.description || "description"}
                            label={_transl(UsersTranslationKey.NOTE)}
                            style={{ margin: 8 }}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            defaultValue={userData.description}
                            fullWidth={true}
                            size={"small"}
                        />
                    </div>
                    <Divider className={classes.innerDivider} />
                    <div className={classes.headerPageSegment}>
                        <Typography variant="h5" className={classes.headerText}>
                            <Group color="primary" />{_transl(UsersTranslationKey.USER_ROLE)}
                        </Typography>
                    </div>
                    <div className={classes.controlPageSegment} >
                        <TextField
                            id="role"
                            key={userData.role || "role"}
                            label={_transl(UsersTranslationKey.USER_ROLE)}
                            style={{ margin: 8 }}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            defaultValue={userData.role ? UserRoleTranslator.get().translateUserRole(userData.role) : ""}
                            size={"small"}
                        />
                    </div>
                </div>
            </Paper>
        </>
    );
}
