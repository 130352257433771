import React from "react";
import AccessBasedRoute from "../../components/AccessBasedRoute";
import {Route} from "react-router-dom";
import {ExtractRouteParams, Redirect, RouteComponentProps} from "react-router";
import RouteDefinition from "./RouteDefinition";
import RouteDefinitionUtils from "./RouteDefinitionUtils";
import {UserDto} from "../apis/user/UserDto";

interface Props {
    definition: RouteDefinition<any>,
    user?: UserDto,
}

export default function RouteDefinitionRenderer({definition, user}: Props) {

    const doRender = (params: RouteComponentProps<ExtractRouteParams<string, string>>) => RouteDefinitionUtils.doRender(definition, params);
    let route: JSX.Element;

    if (definition.accessResolver) {
        route =
            <AccessBasedRoute accessResolver={definition.accessResolver}
                              user={user}
                              path={definition.path}
                              render={doRender}
                              exact={definition.isExact}
                              key={definition.id}
            />
    } else {
        if (definition.isRedirect) {
            route =
                <Route path={definition.path}
                       key={definition.id}>
                    <Redirect to={definition.redirectPath} />
                </Route>
        } else {
            route =
                <Route path={definition.path}
                       render={doRender}
                       exact={definition.isExact}
                       key={definition.id}
                />
        }
    }

    return route;
}
