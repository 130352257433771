import {UserRole} from "../../../../common/access/UserRole";
import {UserRoleType} from "../../../../common/access/UserRoleType";
import {UserDto} from "../../../../common/apis/user/UserDto";

class ImportPageAccessResolver {

    public hasPageAccess(user?: UserDto): boolean {
        return user != null && UserRole.isAtLeast(user, UserRoleType.ROLE_SPRAVCE);
    }

}

const importPageAccessResolver = new ImportPageAccessResolver();
export default importPageAccessResolver;