import React from "react";
import {Menu, MenuItem} from "@mui/material";
import {NavLink} from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import RouteDefinitionUtils from "../../../common/routedefinition/RouteDefinitionUtils";
import {appInfoMenu, logoutMenu, profileMenu} from "../../../common/menudefinition/MenuDefinitionUtils";
import {KeycloakHolder} from "../../../keycloak/KeycloakHolder";
import {_transl} from "../../../store/localization/TranslMessasge";

type Props = {
    opened: boolean;
    anchorRef: any,
    onMenuClose?: () => void;
}

export default function AppBarMenu({opened, anchorRef, onMenuClose}: Props) {

    const onMenuCloseHandler = onMenuClose != null ? onMenuClose : () => {};

    return (
        <Menu
            open={opened}
            id="app-bar-menu"
            anchorEl={anchorRef}
            keepMounted
            onClose={onMenuCloseHandler}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{vertical: -10, horizontal: 0}}
        >
            <MenuItem component={NavLink} to={RouteDefinitionUtils.resolvePath(profileMenu.route, {})} onClick={onMenuCloseHandler}>
                <ListItemIcon>{profileMenu.icon}</ListItemIcon>
                <Typography variant="inherit">{_transl(profileMenu.label)}</Typography>
            </MenuItem>
            <MenuItem component={NavLink} to={RouteDefinitionUtils.resolvePath(appInfoMenu.route, {})} onClick={onMenuCloseHandler}>
                <ListItemIcon>{appInfoMenu.icon}</ListItemIcon>
                <Typography variant="inherit">{_transl(appInfoMenu.label)}</Typography>
            </MenuItem>
            <MenuItem onClick={() => {
                onMenuCloseHandler();
                KeycloakHolder.logout();
            }}>
                <ListItemIcon>{logoutMenu.icon}</ListItemIcon>
                <Typography variant="inherit">{_transl(logoutMenu.label)}</Typography>
            </MenuItem>
        </Menu>
    )

}
