import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Dialog, DialogContent, IconButton, Paper} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import RouteDefinitionUtils, {
    diagramEditorPage,
    ID_PARAM
} from "../../../../common/routedefinition/RouteDefinitionUtils";
import RenderMode from "../../../../common/diagrameditor/context/RenderMode";
import DiagramEditorComponent from "../../../../components/diagrameditor/DiagramEditorComponent";
import IDiagramEditorApi from "../../../../common/diagrameditor/api/IDiagramEditorApi";
import IDiagramApi from "../../../../common/diagrameditor/api/IDiagramApi";
import {IModelDataExportDto} from "../../../../common/apis/Exports";
import Api from "../../../../common/Api";
import {Observable} from "rxjs";
import clsx from "clsx";
import {hideMainPageOverlay, MAIN_PAGE_OVERLAY_ZINDEX} from "../../../MainPage";
import * as d3 from "d3";
import ConfirmationDialog from "../../../../components/dialogs/ConfirmationDialog";
import CloseIcon from "@mui/icons-material/Close";
import {Area, Point} from "../../../../common/diagrameditor/util/GeometryUtils";
import EditableTextField from "../../../../components/fields/EditableTextField";
import {UpdateResponse} from "../../../../components/fields/EditableComponent";
import ConnectionTypeSelectionDialog from "./diagrameditor/ConnectionTypeSelectionDialog";
import {NewConnectionDefinition} from "../../../../common/diagrameditor/manager/ConnectionCreateManager";
import RemoveObjectsConfirmationDialog from "./diagrameditor/RemoveObjectsConfirmationDialog";
import NestingConnectionCreationDialog from "./diagrameditor/NestingConnectionCreationDialog";
import {useHistory} from "react-router-dom";
import NodeContextMenu from "./diagrameditor/NodeContextMenu";
import ElementDetailDialog from "../elementdetail/ElementDetailDialog";
import {IDiagramNodeDto} from "../../../../common/apis/diagram/IDiagramNodeDto";
import AlertDialog, {AlertDialogType} from "../../../../components/dialogs/AlertDialog";
import {ObjectType} from "../../../../common/apis/editor/ObjectType";
import {IDiagramConnectionDto} from "../../../../common/apis/diagram/IDiagramConnectionDto";
import {RelationshipDto} from "../../../../common/apis/relationship/RelationshipDto";
import {IModelDto} from "../../../../common/apis/model/IModelDto";
import {IEditMode} from "../../../../common/diagrameditor/editor/IEditMode";
import {IMode} from "../../../../common/diagrameditor/model/IMode";
import ConnectionContextMenu from "./diagrameditor/ConnectionContextMenu";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "./DiagramTranslationKey";
import {StyleSettings} from "../../../../diagram/editor/style/StyleSettings";
import {
    StyleSettingsDialog,
    StyleSettingsDialogProps
} from "../../../../diagram/editor/style/dialog/StyleSettingsDialog";
import {Styleable} from "../../../../common/apis/diagram/Styleable";
import {DiagramInfoDto} from "../../../../common/apis/diagram/DiagramInfoDto";
import {useDiagramBackupService} from "../../../../common/diagrameditor/backup/provider/DiagramBackupProvider";
import {IPreEditMode} from "../../../../common/diagrameditor/editor/IPreEditMode";
import ElementListDialog from "../elements/ElementListDialog";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import elementsService from "../elements/service/ElementService";
import importsService from "../../../../common/apis/ImportsService";
import {ArchimateElement} from "../../../../common/archimate/ArchimateElement";
import {ArchimateRelationship} from "../../../../common/archimate/ArchimateRelationship";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../../../store/Store";
import {TooltippedIconButton} from "../../../../components/button/TooltippedIconButton";
import {ChatIconButton} from "./diagrameditor/iconbutton/ChatIconButton";
import {ErrorTranslationKey} from "../ErrorTranslationKey";
import chatService, {ChatState} from "../../../../common/apis/chat/ChatService";
import Snackbar from "../snackbar/Snackbar";
import PaperContextMenu from "./diagrameditor/PaperContextMenu";
import EditIcon from "@mui/icons-material/Edit";
import {CommonTranslation} from "../CommonTranslation";
import EventManagerContext from "../../../../common/event/EventManagerContext";
import {ChangeChatLayerVisibilityEvent, ChatEventType, ChatLayerVisibilityChangedEvent} from "../chat/ChatEvents";
import {DiagramsGridAction, DiagramsGridActionType} from "./DiagramsGridAction";
import {ChatCoordinatesManager} from "../chat/ChatCoordinatesManager";
import EventManager from "../../../../common/event/EventManager";
import {
    DiagramEditorEventType,
    ModalWindowVisibilityChangedEvent
} from "../../../../common/event/diagrameditor/DiagramEditorEvents";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: CommonCssStyles.getRootPageStyles(theme, {
            height: "94vh",
            display: "flex",
            flexDirection: "column",
            padding: 0,
            margin: 0
        }),
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        controlPageSegment: {
            position: "relative",
            flexGrow: 1,
            marginBottom: "10px",
        },
        pageMenu: {
            display: "flex",
            alignItems: "center",
            gap: "15px",
        },
        pageTitle: {
            display: "flex",
            userSelect: "none",
        },
        pageMenuButtonsMenu: {
            flexGrow: 1,
            "& > div": {
                display: "flex",
            }
        },
        pageMenuButtonsLeftMenu: {
            "& > *": {
                padding: 5
            }

        },
        pageMenuButtonsCenterMenu: {
            flexGrow: 1,
        },
        pageMenuButtonsRightMenu: {
            "& > *": {
                width: "100",
            }
        },
        nodeLabelUpdateDiv: {
            position: "absolute",
            zIndex: MAIN_PAGE_OVERLAY_ZINDEX + 1000,
            backgroundColor: "white",
        },
        connectionLabelUpdateDiv: {
            position: "absolute",
            zIndex: MAIN_PAGE_OVERLAY_ZINDEX + 1000,
            backgroundColor: "white",
        }
    })
);

export const DIAGRAM_EDITOR_PAGE_ID = "__diagram-editor-page__";
const NODE_LABEL_UPDATE_DIV_ID = "__diagram-editor-node-label-update-div__";
const CONNECTION_LABEL_UPDATE_DIV_ID = "__diagram-editor-connection-label-update-div__";

// Component Props

interface IProps {
    // router props
    [ID_PARAM]: string,
    onClosed: () => void,
    mode?: RenderMode.PRE_EDIT | RenderMode.EDIT,
}

// Component state

export enum SaveButtonStatus {
    SAVE_NOT_NEEDED,
    SAVE_NEEDED,
    SAVE_IN_PROGRESS,
    SAVE_FAILED,
}

export enum SelectionMode {
    UNSELECTED,
    SINGLE,
    MULTIPLE,
}
interface INodeLabelUpdateInfo {
    label: string,
    clientBounds: Area,
    isMultiRow: boolean,
    doUpdate: (label: string) => Observable<UpdateResponse>,
    doCancel: () => void,
}

interface IConnectionLabelUpdateInfo {
    label: string,
    clientBounds: Area,
    doUpdate: (label: string) => Observable<UpdateResponse>,
    doCancel: () => void,
}

interface IConnectionTypeDialogInfo {
    sourceType: ArchimateElement,
    targetType: ArchimateElement,
    allowedRelationshipTypes: ArchimateRelationship[],
    hiddenRelationships: RelationshipDto[],
    eventPoint: Point,
    onCreate: (definition: NewConnectionDefinition, event: any) => void,
    onCancel: (event: any) => void,
}

interface IConfirmationDialog {
    title: string,
    text: string,
    confirmCallback: (event: any) => void,
    cancelCallback: ((event: any) => void),
    isModal: boolean,
}

interface RemoveObjectsConfirmationDialogSettings {
    nodes: Array<IDiagramNodeDto>,
    connections: Array<IDiagramConnectionDto>,
    confirmCallback: (event: any, removeFromModel: boolean) => void,
    cancelCallback: ((event: any) => void),
}

interface INestingConnectionCreationDialog {
    parentCandidate: IDiagramNodeDto,
    nodesToConnect: Array<IDiagramNodeDto>,
    callback: (newRelationships?: Array<RelationshipDto>) => void,
}

interface NodeContextMenuDialog {
    node: IDiagramNodeDto,
    selectedNodes: IDiagramNodeDto[],
    clientCoordinates: [number, number],
    transformedClientCoordinates: [number, number],
}

interface ConnectionContextMenuDialog {
    connection: IDiagramConnectionDto,
    clientCoordinates: [number, number],
}

interface PaperContextMenuDialog {
    clientCoordinates: [number, number],
    transformedClientCoordinates: [number, number],
}

interface ElementDetailDialogInfo {
    elementId: string,
}

interface ElementsGridDialogInfo {
    elements: ElementDto[],
}

interface ErrorDialogInfo {
    text: string,
}

export default function DiagramEditorDialog(props: IProps) {
    const classes = useStyles();
    const diagramId = props.id;
    const history = useHistory();
    const diagramBackupService = useDiagramBackupService();
    const initialMode = props.mode || RenderMode.PRE_EDIT;
    const user = useSelector((application: IApplicationState) => application.user.userData);
    const chatCoordinatesManager = useRef(new ChatCoordinatesManager());

    // refs
    const diagramApi = useRef<IDiagramApi>(createDiagramApi());
    const diagramEditorApi = useRef<IDiagramEditorApi | undefined>();

    // callbacks
    const fetchDiagram = useCallback(() => fetchEditedDiagram(diagramId), [diagramId]);

    const onDiagramModelFetched = useCallback((model: IModelDto) => {
        if (props.mode === RenderMode.EDIT) {
            setHasUserSufficientElementPermissionsAlert(!hasUserAtLeastCtenarPermissionForAllElements(model));
        }
        setDiagramName(model.diagrams[0].diagramInfo.name as string);
    }, [props.mode]);

    function hasUserAtLeastCtenarPermissionForAllElements(model: IModelDto): boolean {
        for (const element of model.graph.elements) {
            if (!element.acl.canRead && !element.acl.canUpdate) {
                return false;
            }
        }
        return true;
    }

    function showDiagramInViewMode(diagramIdentifier: string) {
        const queryParams = [{
            name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.SHOW_EDITOR),
            value: diagramIdentifier
        }];
        window.open(RouteDefinitionUtils.resolvePath(diagramEditorPage, {}, queryParams), '_blank');
    }

    const showPromptBeforeUnloadCallback = useCallback((event: any) => showPromptBeforeUnload(event), []);

    const createPreEditMode = useCallback((): IPreEditMode => {
        return {
            mode: RenderMode.PRE_EDIT,
            diagramApi: diagramApi.current,
            onDiagramEditorApiCreated: setupDiagramEditorApi,
        };
    }, []);

    // state
    const [diagramName, setDiagramName] = useState<String>(props.id);
    const [mode, setMode] = useState<IMode>(initialMode === RenderMode.PRE_EDIT ? createPreEditMode() : createEditMode());
    const [saveButtonStatus, setSaveButtonStatus] = useState<SaveButtonStatus>(SaveButtonStatus.SAVE_NOT_NEEDED);
    const [showSaveChangesDialog, setShowSaveChangesDialog] = useState<boolean>(false);
    const [nodeLabelUpdateInfo, setNodeLabelUpdateInfo] = useState<INodeLabelUpdateInfo | undefined>();
    const [connectionLabelUpdateInfo, setConnectionLabelUpdateInfo] = useState<IConnectionLabelUpdateInfo | undefined>();
    const [connectionTypeDialogInfo, setConnectionTypeDialogInfo] = useState<IConnectionTypeDialogInfo | undefined>();
    const [confirmationDialog, setConfirmationDialog] = useState<IConfirmationDialog | undefined>();
    const [hasUserSufficientElementPermissionsAlert, setHasUserSufficientElementPermissionsAlert] = useState<boolean>(false);
    const [removeItemsDialogSettings, setRemoveItemsDialogSettings] = useState<RemoveObjectsConfirmationDialogSettings | undefined>();
    const [nestingConnectionCreationDialog, setNestingConnectionCreationDialog] = useState<INestingConnectionCreationDialog | undefined>();
    const [nodeContextMenuDialog, setNodeContextMenuDialog] = useState<NodeContextMenuDialog | undefined>();
    const [connectionContextMenuDialog, setConnectionContextMenuDialog] = useState<ConnectionContextMenuDialog | undefined>();
    const [elementDetailDialogInfo, setElementDetailDialogInfo] = useState<ElementDetailDialogInfo | undefined>();
    const [elementsGridDialogInfo, setElementsGridDialogInfo] = useState<ElementsGridDialogInfo | undefined>();
    const [errorDialogInfo, setErrorDialogInfo] = useState<ErrorDialogInfo | undefined>();
    const [styleSettingsDialogState, setStyleSettingsDialogState] = useState<StyleSettingsDialogProps>({open: false});
    const [isUserPermittedToEdit, setIsUserPermittedToEdit] = useState<boolean>(false);
    const [paperContextMenuDialog, setPaperContextMenuDialog] = useState<PaperContextMenuDialog | undefined>();

    const [chatLayerVisible, setChatLayerVisible] = useState<boolean>(false);
    const [numberOfUnresolvedChats, setNumberOfUnresolvedChats] = useState<number>(0);
    const [refreshChatCount, setRefreshChatCount] = useState<Date>(new Date());

    const eventManager = useContext(EventManagerContext);

    const publishDialogVisibilityEvent = (eventManager: EventManager, isDialogVisible: boolean) => {
        const visibilityChangedEvent: ModalWindowVisibilityChangedEvent = {
            type: DiagramEditorEventType.MODAL_WINDOW_VISIBILITY_CHANGED,
            isVisible: isDialogVisible
        };
        eventManager.publishEvent(visibilityChangedEvent);
    };

    // effects
    useEffect(() => {
        return () => hideMainPageOverlay();
    }, []);

    const switchToPreEditMode = useCallback(() => {
        if (mode.mode === RenderMode.EDIT) {
            setMode(createPreEditMode());
        }
    }, [mode.mode, createPreEditMode]);

    useEffect(() => {
        let isUnmounted = false;

        async function fetchDiagramInfo() {
            const response = await Api.diagrams.doSearch({identifiers: diagramId}).toPromise();
            const diagrams = response.response as Array<DiagramInfoDto>;
            return diagrams.length > 0 ? diagrams[0] : null;
        }

        (async () => {
            const diagramInfo = await fetchDiagramInfo();
            if (!isUnmounted) {
                if (diagramInfo?.acl?.canUpdate) {
                    setIsUserPermittedToEdit(true);
                } else {
                    switchToPreEditMode();
                }
            }
        })();
        return () => {
            isUnmounted = true;
        }
    }, [diagramId, switchToPreEditMode]);

    useEffect(() => {
        if (nodeLabelUpdateInfo) {
            const updateDivSelection = d3.select("#" + NODE_LABEL_UPDATE_DIV_ID + " input ");
            if (updateDivSelection.size() === 1) {
                setTimeout(() => (updateDivSelection.node() as HTMLInputElement).click(), 0);
            }
        }
    }, [nodeLabelUpdateInfo]);

    useEffect(() => {
        if (connectionLabelUpdateInfo) {
            const updateDivSelection = d3.select("#" + CONNECTION_LABEL_UPDATE_DIV_ID + " input ");
            if (updateDivSelection.size() === 1) {
                setTimeout(() => (updateDivSelection.node() as HTMLInputElement).click(), 0);
            }
        }
    }, [connectionLabelUpdateInfo]);

    useEffect(() => {
        chatService.countChatsByFilter({diagramId: diagramId, state: ChatState.UNRESOLVED, containsChatNode: true})
            .then(count => setNumberOfUnresolvedChats(count))
            .catch(err => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA)));
    }, [diagramId, refreshChatCount]);

    function onChatsUpdated() {
        setRefreshChatCount(new Date());
    }

    useEffect(() => {
        var unsubscribe = eventManager.subscribeListener(ChatEventType.CHAT_LAYER_VISIBILITY_CHANGED, (event: ChatLayerVisibilityChangedEvent) => {
            setChatLayerVisible(event.chatLayerVisible);
        });
        return () => unsubscribe();
    }, [eventManager]);

    useEffect(() => {
        publishDialogVisibilityEvent(eventManager, elementDetailDialogInfo !== undefined);
    }, [eventManager, elementDetailDialogInfo]);

    useEffect(() => {
        publishDialogVisibilityEvent(eventManager, elementsGridDialogInfo !== undefined);
    }, [eventManager, elementsGridDialogInfo]);

    function switchChatLayerVisibility(visible: boolean) {
        const event: ChangeChatLayerVisibilityEvent = {
            type: ChatEventType.CHANGE_CHAT_LAYER_VISIBILITY,
            chatLayerVisible: visible
        };
        eventManager.publishEvent(event);
    }

    function createEditMode(): IEditMode {
        return {
            mode: RenderMode.EDIT,
            diagramApi: diagramApi.current,
            onDiagramEditorApiCreated: setupDiagramEditorApi,
        }
    }

    function setupDiagramEditorApi(diagramEditor: IDiagramEditorApi) {
        diagramEditorApi.current = diagramEditor;
    }

    function viewDiagramById(diagramId: string) {
        const path = RouteDefinitionUtils.resolveDiagramDetailPath(diagramId);
        history.push(path);
    }

    function createDiagramApi(): IDiagramApi {
        return {
            showElementDetail: (elementId: string) => {
            },
            notifySaveStatusUpdated: (saveNeeded: boolean) =>
                notifySaveStatusUpdated(saveNeeded),
            saveChanges: (event: any) =>
                saveChanges(event),
            getSaveButtonStatus: () => saveButtonStatus,
            updateNodeLabel: (nodeLabel: string, nodeClientBounds: Area, isMultiRow: boolean, doUpdate: (label: string) => Observable<UpdateResponse>, doCancel: () => void) =>
                showNodeLabelUpdateUI(nodeLabel, nodeClientBounds, isMultiRow, doUpdate, doCancel),
            updateConnectionLabel: (connectionLabel: string, connectionClientBounds: Area, doUpdate: (label: string) => Observable<UpdateResponse>, doCancel: () => void) =>
                showConnectionLabelUpdateUI(connectionLabel, connectionClientBounds, doUpdate, doCancel),
            generateIdentifiers: (objectTypes: ObjectType[], successCallback: (ids: string[]) => void, errorCallback: (error: any) => void) =>
                generateIdentifiers(objectTypes, successCallback, errorCallback),
            showConnectionTypeSelectionDialog: (sourceType: ArchimateElement | undefined, targetType: ArchimateElement | undefined,
                                                allowedRelationshipTypes: ArchimateRelationship[], hiddenRelationships: RelationshipDto[],
                                                point: Point, onCreate: (definition: NewConnectionDefinition, event: any) => void, onCancel: (event: any) => void) =>
                showConnectionTypeSelectionDialog(sourceType, targetType, allowedRelationshipTypes, hiddenRelationships, point, onCreate, onCancel),
            showConfirmationDialog: (title: string, text: string, confirmCallback: (event: any) => void, cancelCallback: (event: any) => void, isModal: boolean) =>
                showConfirmationDialog(title, text, confirmCallback, cancelCallback, isModal),
            showRemoveObjectsDialog: (nodes: Array<IDiagramNodeDto>, connections: Array<IDiagramConnectionDto>, confirmCallback: (event: any, removeElementsOrRelationships: boolean) => void, cancelCallback: (event: any) => void) =>
                showRemoveObjectsDialog(nodes, connections, confirmCallback, cancelCallback),
            showNodeNestingConnectionCreationDialog: (parentCandidate: IDiagramNodeDto, nodesToConnect: Array<IDiagramNodeDto>, callback: (newRelationships?: Array<RelationshipDto>) => void) =>
                showNodeNestingConnectionCreationDialog(parentCandidate, nodesToConnect, callback),
            viewDiagramById: (diagramId) => viewDiagramById(diagramId),
            showNodeContextMenu: (node: IDiagramNodeDto, selectedNodes: IDiagramNodeDto[], clientCoordinates: [number, number], transformedClientCoordinates: [number, number]) => showNodeContextMenu(node, selectedNodes, clientCoordinates, transformedClientCoordinates),
            showPaperContextMenu: (clientCoordinates, transformedClientCoordinates: [number, number]) => showPaperContextMenu(clientCoordinates, transformedClientCoordinates),
            showElementDetailDialog: (elementIdentifier: string) => showElementDetailDialog(elementIdentifier),
            showElementsGridDialog: (elementIdentifiers: string[]) => showElementsGridDialog(elementIdentifiers),
            showErrorDialog: (text: string) => setErrorDialogInfo({text: text}),
            showConnectionContextMenu: (connection: IDiagramConnectionDto, clientCoordinates: [number, number]) => showConnectionContextMenu(connection, clientCoordinates),
        }
    }

    function switchToEditMode() {
        setMode(createEditMode());
    }

    function closeDialog() {
        const containsUnsavedChanges = saveButtonStatus !== SaveButtonStatus.SAVE_NOT_NEEDED;

        if (!containsUnsavedChanges) {
            props.onClosed();
        } else {
            setShowSaveChangesDialog(true);
        }
    }

    async function cancelChanges() {
        await chatCoordinatesManager.current.fixAllChatCoordinatesForDiagram(diagramId);
        diagramBackupService.removeBackup(diagramId);
        unregisterBeforeUnloadListener(showPromptBeforeUnloadCallback);
        props.onClosed();
    }

    function saveChanges(event: any, closeDialogAfterSuccessfulSave?: boolean) {
        const model = diagramEditorApi.current!.getModel();
        setSaveButtonStatus(SaveButtonStatus.SAVE_IN_PROGRESS);

        importsService.importModelJSON(model, user!, () => onSuccess(event, model, closeDialogAfterSuccessfulSave),
            () => setSaveButtonStatus(SaveButtonStatus.SAVE_FAILED));
    }

    function onSuccess(event: any, model: IModelDto, closeDialogAfterSuccessfulSave?: boolean) {
        setSaveButtonStatus(SaveButtonStatus.SAVE_NOT_NEEDED);
        diagramEditorApi.current?.diagramSaved(event);
        diagramBackupService.removeBackup(model.diagrams[0].diagramInfo.identifier);
        unregisterBeforeUnloadListener(showPromptBeforeUnloadCallback);
        if (closeDialogAfterSuccessfulSave) {
            setTimeout(() => props.onClosed(), 500);
        }
    }

    function notifySaveStatusUpdated(saveNeeded: boolean) {
        const newState = saveNeeded ? SaveButtonStatus.SAVE_NEEDED : SaveButtonStatus.SAVE_NOT_NEEDED;
        setSaveButtonStatus(newState);
        handleBeforeUnloadListener(newState, showPromptBeforeUnloadCallback);
    }

    function handleBeforeUnloadListener(newState: SaveButtonStatus,
                                        beforeUnloadListener: (event: any) => boolean) {
        unregisterBeforeUnloadListener(beforeUnloadListener);
        if (newState === SaveButtonStatus.SAVE_NEEDED) {
            registrBeforeUnloadListener(beforeUnloadListener);
        }
    }

    function registrBeforeUnloadListener(beforeUnloadListener: (event: any) => boolean) {
        window.addEventListener("beforeunload", beforeUnloadListener);
    }

    function unregisterBeforeUnloadListener(beforeUnloadListener: (event: any) => boolean) {
        window.removeEventListener("beforeunload", beforeUnloadListener);
    }

    function showPromptBeforeUnload(event: any): boolean {
        event.returnValue = true;
        return true;
    }

    function showNodeLabelUpdateUI(nodeLabel: string, nodeClientBounds: Area, isMultiRow: boolean, doUpdate: (label: string) => Observable<UpdateResponse>, doCancel: () => void) {
        setNodeLabelUpdateInfo({
            label: nodeLabel,
            clientBounds: nodeClientBounds,
            isMultiRow: isMultiRow,
            doUpdate: doUpdate,
            doCancel: doCancel,
        });
    }

    function showConnectionLabelUpdateUI(connectionLabel: string, connectionClientBounds: Area, doUpdate: (label: string) => Observable<UpdateResponse>, doCancel: () => void) {
        setConnectionLabelUpdateInfo({
            label: connectionLabel,
            clientBounds: connectionClientBounds,
            doUpdate: doUpdate,
            doCancel: doCancel,
        });
    }

    function generateIdentifiers(objectTypes: ObjectType[], successCallback: (ids: string[]) => void, errorCallback: (error: any) => void) {
        Api.editor.generateIdentifiersUsingCallbacks(objectTypes, successCallback, errorCallback);
    }

    function showConnectionTypeSelectionDialog(sourceType: ArchimateElement | undefined, targetType: ArchimateElement | undefined,
                                               allowedRelationshipTypes: ArchimateRelationship[],
                                               hiddenRelationships: RelationshipDto[], point: Point,
                                               onCreate: (definition: NewConnectionDefinition, event: any) => void,
                                               onCancel: (event: any) => void) {
        if (sourceType && targetType) {
            setConnectionTypeDialogInfo({
                sourceType: sourceType,
                targetType: targetType,
                allowedRelationshipTypes: allowedRelationshipTypes,
                hiddenRelationships: hiddenRelationships,
                eventPoint: point,
                onCreate: onCreate,
                onCancel: onCancel,
            });
        } else {
            Api.editor.generateIdentifier(ObjectType.CONNECTION)
                .subscribe({
                    next: (response) => {
                        onCreate({connectionIdentifier: response.response}, {});
                    },
                    error: () => {
                    },
                })
        }
    }

    function showConfirmationDialog(title: string,
                                    text: string,
                                    confirmCallback: (event: any) => void,
                                    cancelCallback: (event: any) => void,
                                    isModal: boolean) {
        setConfirmationDialog({
            title: title,
            text: text,
            confirmCallback: confirmCallback,
            cancelCallback: cancelCallback,
            isModal: isModal,
        })
    }

    function showRemoveObjectsDialog(nodes: Array<IDiagramNodeDto>, connections: Array<IDiagramConnectionDto>, confirmCallback: (event: any, removeFromModel: boolean) => void, cancelCallback: (event: any) => void) {
        setRemoveItemsDialogSettings({
            nodes: nodes,
            connections: connections,
            confirmCallback: confirmCallback,
            cancelCallback: cancelCallback,
        })
    }

    function showNodeNestingConnectionCreationDialog(parentCandidate: IDiagramNodeDto,
                                                     nodesToConnect: Array<IDiagramNodeDto>,
                                                     callback: (newRelationships?: Array<RelationshipDto>) => void) {
        setNestingConnectionCreationDialog({
            parentCandidate: parentCandidate,
            nodesToConnect: nodesToConnect,
            callback: callback,
        })
    }

    function showNodeContextMenu(node: IDiagramNodeDto, selectedNodes: IDiagramNodeDto[], clientCoordinates: [number, number],
                                 transformedClientCoordinates: [number, number]) {
        setNodeContextMenuDialog({
            node: node,
            selectedNodes: selectedNodes,
            clientCoordinates: clientCoordinates,
            transformedClientCoordinates: transformedClientCoordinates,
        })
    }

    function showConnectionContextMenu(connection: IDiagramConnectionDto, clientCoordinates: [number, number]) {
        setConnectionContextMenuDialog({
            connection: connection,
            clientCoordinates: clientCoordinates,
        })
    }

    function showPaperContextMenu(clientCoordinates: [number, number], transformedClientCoordinates: [number, number]) {
        setPaperContextMenuDialog({
            clientCoordinates: clientCoordinates,
            transformedClientCoordinates: transformedClientCoordinates,
        })
    }

    function showElementDetailDialog(elementId: string | undefined) {
        if (elementId) {
            setElementDetailDialogInfo({
                elementId: elementId,
            })
        } else {
            setElementDetailDialogInfo(undefined);
        }
    }

    function showElementsGridDialog(elementIds: string[]) {
        if (elementIds.length > 0) {
            elementsService.doSearch(elementIds)
                .then(response => {
                    const elementDtos = response.items.map((item) => item)
                    setElementsGridDialogInfo({elements: elementDtos});
                })
                .catch((error) => {
                });
        }
    }

    function showStyleSettingsDialog(styleable: Styleable) {
        if (diagramEditorApi.current) {
            const {
                settings,
                variant,
                mode
            } = diagramEditorApi.current.getStyleManager().extractStyleSettingsDialogConfig(styleable);
            setStyleSettingsDialogState({
                open: true,
                variant: variant,
                settings: settings,
                mode: mode
            });
        }
    }

    function onStyleSettingsSaved(settings: StyleSettings) {
        if (diagramEditorApi.current) {
            diagramEditorApi.current.getStyleManager().applyStyleSettingsToSelection(settings);
            hideStyleSettingsDialog();
        }
    }

    function hideStyleSettingsDialog() {
        setStyleSettingsDialogState({open: false});
    }

    const nodeLabelBounds = nodeLabelUpdateInfo?.clientBounds;
    const connectionLabelBounds = connectionLabelUpdateInfo?.clientBounds;
    const selectionMode = nodeContextMenuDialog ?
        (nodeContextMenuDialog.selectedNodes.length > 1 ? SelectionMode.MULTIPLE : SelectionMode.SINGLE)
        : SelectionMode.UNSELECTED;

    return (
        <React.Fragment>
            {errorDialogInfo &&
                <AlertDialog open={true}
                             type={AlertDialogType.ERROR}
                             onClose={() => setErrorDialogInfo(undefined)}
                             text={errorDialogInfo.text}
                             title={_transl(DiagramTranslationKey.DIAGRAM_EDITOR_PAGE_ERROR)}
                />
            }
            {nodeContextMenuDialog &&
                <NodeContextMenu opened={true}
                                 onClose={() => setNodeContextMenuDialog(undefined)}
                                 onStylesClick={() => showStyleSettingsDialog(nodeContextMenuDialog.node)}
                                 node={nodeContextMenuDialog.node}
                                 selectedNodes={nodeContextMenuDialog.selectedNodes}
                                 selectionMode={selectionMode}
                                 clientCoordinates={nodeContextMenuDialog.clientCoordinates}
                                 transformedClientCoordinates={nodeContextMenuDialog.transformedClientCoordinates}
                                 diagramApi={diagramApi.current}
                                 diagramEditorApi={diagramEditorApi.current}
                                 eventManager={diagramEditorApi.current?.getEventManager()}
                                 mode={mode.mode}
                                 diagramId={diagramId}
                />
            }
            {connectionContextMenuDialog &&
                <ConnectionContextMenu opened={true}
                                       onClose={() => setConnectionContextMenuDialog(undefined)}
                                       onStylesClick={() => showStyleSettingsDialog(connectionContextMenuDialog.connection)}
                                       connection={connectionContextMenuDialog.connection}
                                       clientCoordinates={connectionContextMenuDialog.clientCoordinates}
                                       diagramApi={diagramApi.current}
                                       diagramEditorApi={diagramEditorApi.current}
                />
            }
            {paperContextMenuDialog &&
                <PaperContextMenu opened={true}
                                  onClose={() => setPaperContextMenuDialog(undefined)}
                                  clientCoordinates={paperContextMenuDialog.clientCoordinates}
                                  transformedClientCoordinates={paperContextMenuDialog.transformedClientCoordinates}
                                  eventManager={diagramEditorApi.current?.getEventManager()}
                                  mode={mode.mode}
                                  diagramId={diagramId}
                />
            }
            {elementDetailDialogInfo &&
                <ElementDetailDialog initialElementId={elementDetailDialogInfo.elementId}
                                     opened={true}
                                     onClosed={() => setElementDetailDialogInfo(undefined)}/>
            }

            {elementsGridDialogInfo &&
                <ElementListDialog dialogTitle={_transl(DiagramTranslationKey.DIAGRAM_EDITOR_PAGE_SELECTED_ELEMENTS_GRID)}
                                   isOpened={true}
                                   elements={elementsGridDialogInfo.elements}
                                   onDialogClosed={() => setElementsGridDialogInfo(undefined)}
                />
            }

            {nestingConnectionCreationDialog &&
                <NestingConnectionCreationDialog parentCandidate={nestingConnectionCreationDialog.parentCandidate}
                                                 nodesToConnect={nestingConnectionCreationDialog.nodesToConnect}
                                                 onConfirm={(event: any, createdRelationships: Array<RelationshipDto>) => {
                                                     setNestingConnectionCreationDialog(undefined);
                                                     nestingConnectionCreationDialog.callback(createdRelationships)
                                                 }}
                                                 onCancel={() => {
                                                     setNestingConnectionCreationDialog(undefined);
                                                     nestingConnectionCreationDialog.callback([]);
                                                 }}
                                                 diagramEditorApi={diagramEditorApi.current as IDiagramEditorApi}
                                                 diagramApi={(mode as IEditMode).diagramApi}
                />
            }
            {removeItemsDialogSettings &&
                <RemoveObjectsConfirmationDialog nodes={removeItemsDialogSettings.nodes}
                                                 connections={removeItemsDialogSettings.connections}
                                                 onConfirm={(event, removeFromModel) => {
                                                     setRemoveItemsDialogSettings(undefined);
                                                     removeItemsDialogSettings.confirmCallback(event, removeFromModel);
                                                 }}
                                                 onCancel={(event) => {
                                                     setRemoveItemsDialogSettings(undefined);
                                                     removeItemsDialogSettings.cancelCallback(event);
                                                 }}
                />
            }
            {confirmationDialog &&
                <ConfirmationDialog open={true}
                                    title={confirmationDialog.title}
                                    confirmationText={confirmationDialog.text}
                                    onConfirm={(event) => {
                                        setConfirmationDialog(undefined);
                                        confirmationDialog.confirmCallback(event);
                                    }}
                                    onReject={(event) => {
                                        setConfirmationDialog(undefined);
                                        confirmationDialog.cancelCallback(event);
                                    }}
                                    isModal={confirmationDialog.isModal}
                />
            }
            {hasUserSufficientElementPermissionsAlert && <AlertDialog open={true}
                                                    type={AlertDialogType.INFO}
                                                    title={_transl(DiagramTranslationKey.DIAGRAM_EDITOR_PAGE_ELEMENT_PERMISSIONS_ALERT_TITLE)}
                                                    text={_transl(DiagramTranslationKey.DIAGRAM_EDITOR_PAGE_ELEMENT_PERMISSIONS_ALERT_MESSAGE)}
                                                    onClose={() => {
                                                        setHasUserSufficientElementPermissionsAlert(false);
                                                        closeDialog();
                                                        showDiagramInViewMode(diagramId);
                                                    }}/>
            }
            {connectionTypeDialogInfo &&
                <ConnectionTypeSelectionDialog
                    allowedRelationshipTypes={connectionTypeDialogInfo.allowedRelationshipTypes}
                    hiddenRelationships={connectionTypeDialogInfo.hiddenRelationships}
                    eventPoint={connectionTypeDialogInfo.eventPoint}
                    onSelect={(definition: NewConnectionDefinition, event: any) => {
                        setConnectionTypeDialogInfo(undefined);
                        connectionTypeDialogInfo.onCreate(definition, event);
                    }}
                    onCancel={(event: any) => {
                        setConnectionTypeDialogInfo(undefined);
                        connectionTypeDialogInfo.onCancel(event);
                    }}
                />
            }
            {nodeLabelUpdateInfo && diagramEditorApi &&
                <div id={NODE_LABEL_UPDATE_DIV_ID} className={classes.nodeLabelUpdateDiv}
                     style={{top: nodeLabelBounds?.y, left: nodeLabelBounds?.x, width: nodeLabelBounds?.w}}>
                    <EditableTextField label={""}
                                           initialValue={nodeLabelUpdateInfo.label}
                                           doUpdate={(text: string) => nodeLabelUpdateInfo.doUpdate(text).toPromise()}
                                           onSuccessfulUpdate={() => setNodeLabelUpdateInfo(undefined)}
                                           onCancelChanges={() => {
                                               nodeLabelUpdateInfo.doCancel();
                                               setNodeLabelUpdateInfo(undefined);
                                           }}/>
                </div>
            }
            {connectionLabelUpdateInfo && diagramEditorApi &&
                <div id={CONNECTION_LABEL_UPDATE_DIV_ID} className={classes.connectionLabelUpdateDiv} style={{
                    top: connectionLabelBounds?.y,
                    left: connectionLabelBounds?.x,
                    width: connectionLabelBounds?.w
                }}>
                    <EditableTextField label={""}
                                           initialValue={connectionLabelUpdateInfo.label}
                                           doUpdate={(text: string) => connectionLabelUpdateInfo.doUpdate(text).toPromise()}
                                           onSuccessfulUpdate={() => setConnectionLabelUpdateInfo(undefined)}
                                           onCancelChanges={() => {
                                               connectionLabelUpdateInfo.doCancel();
                                               setConnectionLabelUpdateInfo(undefined);
                                           }}/>
                </div>
            }
            {styleSettingsDialogState.open &&
                <StyleSettingsDialog open={true}
                                     variant={styleSettingsDialogState.variant}
                                     mode={styleSettingsDialogState.mode}
                                     settings={styleSettingsDialogState.settings}
                                     onSave={onStyleSettingsSaved}
                                     onCancel={hideStyleSettingsDialog}/>
            }
            {showSaveChangesDialog &&
                <ConfirmationDialog open={true}
                                    title={_transl(DiagramTranslationKey.DIAGRAM_EDITOR_PAGE_SAVE_CHANGES)}
                                    confirmationText={_transl(DiagramTranslationKey.DIAGRAM_EDITOR_PAGE_SAVE_CHANGES_CONFIRM)}
                                    onConfirm={(e) => {
                                        setShowSaveChangesDialog(false);
                                        saveChanges(e, true);
                                    }}
                                    onReject={() => {
                                        setShowSaveChangesDialog(false);
                                        cancelChanges();
                                    }}
                                    onCancel={() => {
                                        setShowSaveChangesDialog(false);
                                    }}
                                    isModal={true}
                />
            }
            <Dialog open={true}
                    aria-labelledby="diagram-editor-dialog"
                    onClose={(event, reason) => {
                        if (!(mode.mode === RenderMode.EDIT && reason)) {
                            props.onClosed()
                        }
                    }}
                    fullScreen={true}
                    maxWidth={false}
                    disableEnforceFocus={true}
            >
                <DialogContent style={{padding: 0}}>
                    <Paper id={DIAGRAM_EDITOR_PAGE_ID} className={classes.page}>
                        <div className={clsx(classes.headerPageSegment, classes.pageMenu)}>
                            <Typography variant="h6" className={classes.pageTitle}>
                                {diagramName || _transl(DiagramTranslationKey.DIAGRAM_EDITOR_PAGE_LOADING_DIAGRAM)}
                            </Typography>
                            <div className={clsx(classes.pageMenuButtonsMenu)}>
                                <div>
                                    <div className={classes.pageMenuButtonsLeftMenu}>
                                        <TooltippedIconButton
                                            onClick={() => mode.mode === RenderMode.PRE_EDIT && switchToEditMode()}
                                            icon={<EditIcon/>}
                                            variant={mode.mode === RenderMode.EDIT ? "activated" : "standard"}
                                            disabled={!isUserPermittedToEdit}
                                            tooltip={mode.mode === RenderMode.PRE_EDIT ? _transl(CommonTranslation.EDIT) : undefined}
                                            />
                                        <ChatIconButton
                                            numberOfUnresolvedChats={numberOfUnresolvedChats}
                                            chatLayerVisible={chatLayerVisible}
                                            onChange={(visible) => switchChatLayerVisibility(visible)}/>
                                    </div>
                                    <div className={classes.pageMenuButtonsCenterMenu}>
                                    </div>
                                    <div className={classes.pageMenuButtonsRightMenu}>
                                        <IconButton aria-label="save" size={"small"} onClick={() => closeDialog()}>
                                            <CloseIcon/>
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Divider/>
                        <div className={clsx(classes.controlPageSegment)}>
                            <DiagramEditorComponent diagramId={diagramId}
                                                    fetchDiagram={fetchDiagram}
                                                    onDiagramModelFetched={onDiagramModelFetched}
                                                    mode={mode}
                                                    saveButtonStatus={saveButtonStatus}
                                                    onChatsUpdated={onChatsUpdated}
                            />
                        </div>
                    </Paper>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

function fetchEditedDiagram(diagramId: string): Observable<IModelDto> {
    const dto: IModelDataExportDto = {
        diagramIdentifiers: [diagramId],
        elementIdentifiers: [],
        exportOrganizations: true,
        exportUnreferencedElementsAndRelationships: false,
    }
    return Api.exports.exportModelData(dto);
}
