import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import CommonCssStyles from "../../../../../css/CommonCssStyles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme, {minHeight: "35em"}),
        tabPanel: {
            minHeight: "35em"
        },
        fullHeight: CommonCssStyles.getFullHeightStyle(),
        grid: {
            minHeight: "18em",
            "& .MuiDataGrid-columnsContainer": {
                backgroundColor: theme.palette.primary.main,
                color: 'white'
            }
        },
        preview: {
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
        },
        tabs: {
            "&> span:first-child": {
                paddingTop: 9,
            },
            paddingTop: 12,
        },
    })
);
