export enum ElementDetailTranslationKey {
    ATTACHMENTS = "elementsDetail.attachments",
    ATTRIBUTES = "elementsDetail.attributes",
    ATTRIBUTES_ADD_BUTTON = "elementsDetail.attributes.addButton",
    ATTRIBUTES_ADD_FAIL = "elementsDetail.attributes.addFail",
    ATTRIBUTES_ADD_TITLE = "elementsDetail.attributes.addTitle",
    ATTRIBUTES_ATTRIBUTE = "elementsDetail.attributes.attribute",
    ATTRIBUTES_DELETE_BUTTON = "elementsDetail.attributes.deleteButton",
    ATTRIBUTES_DELETE_CONFIRMATION = "elementsDetail.attributes.deleteConfirmation",
    ATTRIBUTES_DELETE_TTILE = "elementsDetail.attributes.deleteTitle",
    ATTRIBUTES_EDIT_BUTTON = "elementsDetail.attributes.editButton",
    ATTRIBUTES_EDIT_FAILED = "elementsDetail.attributes.editFailed",
    ATTRIBUTES_EDIT_TITLE = "elementsDetail.attributes.editTitle",
    ATTRIBUTES_VALUE = "elementsDetail.attributes.value",
    ATTRIBUTE_DELETE_FAILED = "elementsDetail.attributes.deleteFailed",
    ATTRIBUTE_DELETE_SUCCEEDED = "elementsDetail.attributes.deleteSucceeded",
    COMMENTS = "elementsDetail.comments",
    COPY_URL = "elementsDetail.copyUrl",
    DESCRIPTION = "elementsDetail.description",
    DESCRIPTION_GENERATE_USING_AI = "elementsDetail.description.generateUsingAi",
    DESCRIPTION_GENERATION_COMPLETED = "elementsDetail.description.generationCompleted",
    DIAGRAMS = "elementsDetail.diagrams",
    FETCH_DATA_FAILED = "elementsDetail.fetchData.failed",
    FETCH_DATA_STARTED = "elementsDetail.fetchData.started",
    GRAPH = "elementsDetail.graph",
    IDENTIFIER = "elementsDetail.identifier",
    RELATIONSHIPS = "elementsDetail.relationships",
    RELATIONSHIPS_DELETE_BUTTON = "elementsDetail.relationships.deleteButton",
    RELATIONSHIPS_DELETE_CONFIRMATION = "elementsDetail.relationships.deleteConfirmation",
    RELATIONSHIPS_DELETE_FAILED = "elementsDetail.relationships.deleteFailed",
    RELATIONSHIPS_DELETE_SUCCEEDED = "elementsDetail.relationships.deleteSucceeded",
    RELATIONSHIPS_DELETE_TITLE = "elementsDetail.relationships.deleteTitle",
    RELATIONSHIPS_DIRECTION = "elementsDetail.relationships.direction",
    RELATIONSHIPS_NAME = "elementsDetail.relationships.name",
    RELATIONSHIPS_CONNECTED_ELEMENT = "elementsDetail.relationships.connectedElement",
    STATE = "elementsDetail.state",
    STEREOTYPE = "elementsDetail.stereotype",
    TASKS_ADD_TASK = "elementsDetail.tasks.addTask",
    TASKS_DELETE_LINK = "elementsDetail.tasks.deleteLink",
    TASKS_DELETE_LINK_DIALOG_TITLE = "elementsDetail.tasks.deleteLinkDialogTitle",
    TASKS_DELETE_CONFIRMATION_TEXT = "elementsDetail.tasks.deleteLinkConfirmationText",
    TASKS_REFRESH = "elementsDetail.tasks.refresh",
    TASKS_KEY = "elementsDetail.tasks.key",
    TASKS_NAME = "elementsDetail.tasks.name",
    TASKS_TAB_TITLE = "elementsDetail.tasks.tabTitle",
    TASKS_TASK_TYPE = "elementsDetail.tasks.taskType",
    TASKS_STATE = "elementsDetail.tasks.state",
    TITLE = "elementsDetail.title",
    TYPE = "elementsDetail.type",
    URL_COPIED = "elementsDetail.urlCopied",
    VALID_FROM = "elementsDetail.validFrom",
    VALID_THRU = "elementsDetail.validThru",
    COLLECTIONS_TITLE = "elementsDetail.collectionsTitle",
    LABELS_TITLE = "elementsDetail.labelsTitle",
    CLICK_TO_EDIT = "elementsDetail.clickToEdit",
    EDIT_ITEM = "elementsDetail.editItem",
    DISCARD_CHANGES = "elementsDetail.discardChanges",
}
