import React, {useEffect, useRef, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import {TextField} from "@mui/material";
import EditableComponent, {
    EDIT_ROOT_SUBCOMPONENT_ROLE,
    Validator as EditableComponentValidator
} from "./EditableComponent";

const useStyle = makeStyles((theme: Theme) => createStyles({
    root: {
        "& > *": {
            flexGrow: 1,
        },
        display: "flex",
    }
}));

interface EditableTextFieldProps {
    label: string,
    initialValue: string,
    doUpdate: (text: string) => Promise<any>,
    onSuccessfulUpdate?: (text: string) => void,
    onCancelChanges?: () => void,
    id?: string,
    rows?: number,
    validator?: EditableComponentValidator,
    readonly?: boolean,
}

export default function EditableTextField(props: EditableTextFieldProps) {
    const { label, initialValue, doUpdate, onSuccessfulUpdate, onCancelChanges, id, rows, validator, readonly } = props;

    const editInputRef = useRef<HTMLInputElement>();
    const [editRootRole, setEditRootRole] = useState(`${EDIT_ROOT_SUBCOMPONENT_ROLE}_${Math.random()}`);

    const [lastSavedValue, setLastSavedValue] = useState(initialValue);
    const [validationFailed, setValidationFailed] = useState(false);

    const classes = useStyle();

    useEffect(() => {
        setEditRootRole(`${EDIT_ROOT_SUBCOMPONENT_ROLE}_${Math.random()}`);
        setLastSavedValue(initialValue);
    }, [initialValue]);

    function isMultiline(rows?: number) {
        return rows != null ? rows > 1 : false;
    }

    function focusEditTextField() {
        const element = editInputRef.current;
        if (element) {
            element.focus();
            if (isMultiline(rows)) {
                element.setSelectionRange(element.value.length, element.value.length);
            }
        }
    }

    function enhanceValidator(validator?: EditableComponentValidator): EditableComponentValidator | undefined {
        if (validator) {
            return {
                ...validator,
                onValidationFailed: () => {
                    setValidationFailed(true);
                    validator.onValidationFailed && validator.onValidationFailed();
                },
                onValidationCancelled: () => {
                    setValidationFailed(false);
                    validator.onValidationCancelled && validator.onValidationCancelled();
                }
            }
        } else {
            return undefined;
        }
    }

    function renderEditTextField(cancelUpdate: () => void, saveChanges: () => void): JSX.Element {
        return <div className={classes.root}
                    onKeyDown={(event) => {
                        if (!isMultiline(rows)) {
                            if (event.code === "Enter") {
                                saveChanges();
                            }
                        }
                        if (event.code === "Escape") {
                            cancelUpdate();
                        }
                    }}>
            <TextField id={id}
                       key={lastSavedValue}
                       inputRef={editInputRef}
                       label={label}
                       defaultValue={lastSavedValue}
                       multiline={isMultiline(rows)}
                       rows={rows}
                       error={validationFailed}
                       variant="outlined"
                       size={"small"}
                       autoComplete={"off"}
                       InputLabelProps={{shrink: true}} />
        </div>
    }

    return (
        <EditableComponent label={label}
                           initialValue={lastSavedValue}
                           getEditedValue={() => {
                               if (editInputRef.current != null) {
                                   return editInputRef.current.value;
                               } else {
                                   return lastSavedValue;
                               }
                           }}
                           isEditWithEditableButtons={true}
                           valueToViewString={(value) => value as string}
                           valuesEqual={(value, newValue) => value === newValue}
                           getEditComponent={(cancelUpdate, saveChanges) => renderEditTextField(cancelUpdate, saveChanges)}
                           focusEditComponent={() => focusEditTextField()}
                           doUpdate={doUpdate as (value: unknown) => Promise<any>}
                           onSuccessfulUpdate={(value) => {
                               const savedValue = value as string;
                               setLastSavedValue(savedValue);
                               onSuccessfulUpdate && onSuccessfulUpdate(savedValue);
                           }}
                           onFailedUpdate={() => {}}
                           onCancelChanges={() => {
                               if (editInputRef.current) {
                                   editInputRef.current.value = lastSavedValue;
                               }
                               onCancelChanges && onCancelChanges();
                           }}
                           id={id}
                           rows={rows}
                           editRootRole={editRootRole}
                           validator={enhanceValidator(validator)}
                           readonly={readonly}
        />
    );

}
