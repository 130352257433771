export enum DiagramEditorTranslationKey {
    CONTEXT_MENU_ADD_COMMENT = "diagrams.editor.contextMenu.addComment",
    CONTEXT_MENU_DISTRIBUTE_HORIZONTALLY = "diagrams.editor.contextMenu.distributeHorizontally",
    CONTEXT_MENU_DISTRIBUTE_VERTICALLY = "diagrams.editor.contextMenu.distributeVertically",
    CONTEXT_MENU_DEFAULT_SIZE = "diagrams.editor.contextMenu.defaultSize",
    CONTEXT_MENU_MATCH_WIDTH = "diagrams.editor.contextMenu.matchWidth",
    CONTEXT_MENU_MATCH_HEIGHT = "diagrams.editor.contextMenu.matchHeight",
    CONTEXT_MENU_MATCH_SIZE = "diagrams.editor.contextMenu.matchSize",
    CONTEXT_MENU_SHOW_DETAIL = "diagrams.editor.contextMenu.showDetail",
    CONTEXT_MENU_SHOW_IN_GRID = "diagrams.editor.contextMenu.showInGrid",
    CONTEXT_MENU_STYLES = "diagrams.editor.contextMenu.styles",
    COPY_TO_CLIPBOARD = "diagrams.editor.contextMenu.copyToClipboard",
    COPY_TO_CLIPBOARD_SUCCESS_INFO = "diagrams.editor.contextMenu.copyToClipboard.successInfo",
    PASTE_FROM_CLIPBOARD = "diagrams.editor.contextMenu.pasteFromClipboard",
    ELEMENTS_DO_NOT_MATCH = "diagrams.editor.contextMenu.elementsDoNotMatch",
    FAILED_TO_PARSE_ITEMS_FROM_CLIPBOARD_WRONG_VERSION = "diagrams.editor.contextMenu.failedToParseItemsFromClipboard.wrongVersion",
    FAILED_TO_PARSE_ITEMS_FROM_CLIPBOARD_INVALID_INPUT = "diagrams.editor.contextMenu.failedToParseItemsFromClipboard.invalidInput",
}