export enum AppBarTranslationKey {
    PROFILE = "appBarMenu.profile",
    APP_INFO = "appBarMenu.appInfo",
    LOGOUT = "appBarMenu.logout",
    SEARCH_BAR_DIAGRAMS = "appBar.searchBar.diagrams",
    SEARCH_BAR_ELEMENTS = "appBar.searchBar.elements",
    SEARCH_BAR_ENTER_AT_LEAST_3_CHARS = "appBar.searchBar.enterAtLeast3Chars",
    SEARCH_BAR_ERROR_WHILE_FETCHING_DATA = "appBar.searchBar.errorWhileFetchingData",
    SEARCH_BAR_NO_DATA_FOUND = "appBar.searchBar.noDataFound",
    SEARCH_BAR_SEARCH_BY_IDENTIFIER_NAME_AND_DESCRIPTION = "appBar.searchBar.searchByIdentifierNameAndDescription",
    SEARCH_BAR_SEARCH_BY_NAME = "appBar.searchBar.searchByName",
    SEARCH_BAR_SEARCH_IN_DIAGRAMS = "appBar.searchBar.searchInDiagrams",
    SEARCH_BAR_SEARCH_IN_ELEMENTS = "appBar.searchBar.searchInElements",
    SEARCH_BAR_SHOW_ALL = "appBar.searchBar.showAll",
}