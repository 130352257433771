enum RouteDefinitionID {
    // app routes
    LOGIN_PAGE = "LOGIN_PAGE",
    LOGIN_FAILED_PAGE = "LOGIN_FAILED_PAGE",
    DIAGRAM_EXPORT_VIEW_PAGE = "DIAGRAM_EXPORT_VIEW_PAGE",
    MAIN_PAGE = "MAIN_PAGE",
    MAIN_PAGE_REDIRECT = "MAIN_PAGE_REDIRECT",

    // main page routes
    DASHBOARD_PAGE = "DASHBOARD_PAGE",
    STRUCTURES_PAGE = "STRUCTURES_PAGE",
    ELEMENTS_PAGE = "ELEMENTS_PAGE",
    ELEMENT_DETAIL_PAGE = "ELEMENT_DETAIL_PAGE",
    DIAGRAMS_PAGE = "DIAGRAMS_PAGE",
    DIAGRAM_EDITOR_PAGE = "DIAGRAM_EDITOR_PAGE",
    DIAGRAM_DETAIL_PAGE = "DIAGRAM_DETAIL_PAGE",
    TOOLS_PAGE = "TOOLS_PAGE",
    ADMINISTRATION_PAGE = "ADMINISTRATION_PAGE",
    QUERIES_PAGE = "QUERIES_PAGE",
    EXPORT_PAGE = "EXPORT_PAGE",
    EXPORT_PAGE_WITH_PRESELECTED_CONFIGURATION = "EXPORT_PAGE_WITH_PRESELECTED_CONFIGURATION",
    IMPORT_PAGE = "IMPORT_PAGE",
    IMPORT_PAGE_WITH_PRESELECTED_CONFIGURATION = "IMPORT_PAGE_WITH_PRESELECTED_CONFIGURATION",
    GENERATE_METAMODEL = "GENERATE_METAMODEL",
    MODELS_COMPARISON = "MODELS_COMPARISON",
    VALIDATE_MODEL = "VALIDATE_MODEL",
    MODEL_DOCUMENTATION = "MODEL_DOCUMENTATION",
    USERS_PAGE = "USERS_PAGE",
    USER_DETAIL_PAGE = "USER_DETAIL_PAGE",
    LABELS_PAGE = "LABELS_PAGE",
    COLLECTIONS_PAGE = "COLLECTIONS_PAGE",
    STEREOTYPES_PAGE = "STEREOTYPES_PAGE",
    DASHBOARD_ADMINISTRATION_PAGE = "DASHBOARD_ADMINISTRATION_PAGE",
    PROFILE_PAGE = "PROFILE_PAGE",
    MAIN_PAGE_NOT_FOUND_PAGE = "MAIN_PAGE_NOT_FOUND_PAGE",
    LOGOUT_PAGE = "LOGOUT_PAGE",
    APP_INFO_PAGE = "APP_INFO_PAGE"
}

export default RouteDefinitionID;
