import React, {useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import Wizard from "../../../../components/wizard/Wizard";
import UploadFilePanel from "../../../../components/UploadFilePanel";
import {Paper} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {WizardStep} from "../../../../components/wizard/WizardStep";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {BlobUtils} from "../../../../common/BlobUtils";
import RouteDefinitionUtils, {generateMetamodelPage} from "../../../../common/routedefinition/RouteDefinitionUtils";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import clsx from "clsx";
import {useHistory} from "react-router-dom";
import {ModelsComparisonTranslationKey} from "./ModelsComparisonTranslationKey";
import modelsComparisonService from "./ModelsComparisonService";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: CommonCssStyles.getRootPageStyles(theme),
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme, {paddingTop: theme.spacing(7)}),
        uploadContentFileName: {
            fontSize: "1.4em",
        },
    })
);

export default function ModelsComparisonPage() {

    const [firstModelFile, setFirstModelFile] = useState<File>();
    const [secondModelFile, setSecondModelFile] = useState<File>();

    const classes = useStyles();
    const history = useHistory();

    function getSteps(): WizardStep[] {
        return [
            new WizardStep(_transl(ModelsComparisonTranslationKey.SELECT_FIRST_MODEL_FILE_STEP_LABEL), <CloudUploadIcon/>, _transl(ModelsComparisonTranslationKey.SELECT_FIRST_MODEL_FILE_STEP_DESCRIPTION), false,
                () => renderSelectModelFileStep(firstModelFile, setFirstModelFile), () => firstModelFile != null),
            new WizardStep(_transl(ModelsComparisonTranslationKey.SELECT_SECOND_MODEL_FILE_STEP_LABEL), <CloudUploadIcon/>, _transl(ModelsComparisonTranslationKey.SELECT_SECOND_MODEL_FILE_STEP_DESCRIPTION), false,
                () => renderSelectModelFileStep(secondModelFile, setSecondModelFile), () => secondModelFile != null)
        ];
    }

    function renderSelectModelFileStep(modelFile: File | undefined, setModelFile: React.Dispatch<React.SetStateAction<File | undefined>>): JSX.Element {
        const uploadPanelContentGetter = modelFile != null ? () => createFileInfoPanel(modelFile) : undefined;

        return <UploadFilePanel uploadAttachment={file => setModelFile(file)}
                                uploadPanelContent={uploadPanelContentGetter} />
    }

    function createFileInfoPanel(selectedFile: File): JSX.Element {
        return <div className={classes.uploadContentFileName}>{selectedFile?.name}</div>;
    }

    function doExport(): Promise<BlobPart> {
        return modelsComparisonService.compareModels(firstModelFile as File, secondModelFile as File);
    }

    function resetImport() {
        const path = RouteDefinitionUtils.resolvePath(generateMetamodelPage, {});
        history.push(path);
    }


    return <Paper className={classes.page}>
        <div className={classes.headerPageSegment}>
            <Typography variant="h6">
                {_transl(ModelsComparisonTranslationKey.PAGE_TITLE)}
            </Typography>
        </div>
        <Divider />
        <div className={clsx(classes.controlPageSegment)}>
            <Wizard steps={getSteps()}
                    lastStepLabel={_transl(ModelsComparisonTranslationKey.LAST_STEP_LABEL)}
                    lastStepButtonLabel={_transl(ModelsComparisonTranslationKey.LAST_STEP_BUTTON_LABEL)}
                    lastStepAction={() => doExport()}
                    lastStepActionSuccessProcessor={(result) => {
                        const blob = new Blob([result as BlobPart]);
                        BlobUtils.saveBlob(blob, "DifferencesReport.xlsx");
                    }}
                    lastStepActionInProgressText={_transl(ModelsComparisonTranslationKey.LAST_STEP_ACTION_IN_PROGRESS_TEXT)}
                    lastStepActionSuccessfulText={_transl(ModelsComparisonTranslationKey.LAST_STEP_ACTION_SUCCESSFUL_TEXT)}
                    lastStepActionFailedText={_transl(ModelsComparisonTranslationKey.LAST_STEP_ACTION_FAILED_TEXT)}
                    cancelWizard={() => resetImport()}
                    wizardGridMinHeight={"25em"}
            />
        </div>
    </Paper>;

}
