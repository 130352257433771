import React from "react";
import clsx from "clsx";
import DrawerMenu from "./DrawerMenu";
import MUIDrawer from "@mui/material/Drawer";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import archiLogo from "../../ArchiREPO_logo_with_text.svg";

const useStyles = makeStyles((theme: Theme) => createStyles({
    drawer: {
        width: theme.drawer.openedWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        transitionDelay: theme.drawer.transitionDelay,
        width: theme.drawer.openedWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.drawer.minimizedWidth,
        },
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(theme.logoContainer.heightSpacing),
        backgroundColor: theme.palette.primary.main
    },
    logo: {
        backgroundImage: `url(${archiLogo})`,
        backgroundPosition: 'left',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        marginLeft: theme.spacing(3),
        width: "100%",
        height: theme.spacing(5),
    },
}));

interface DrawerProps {
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    drawerOpened: boolean;
}

export default function Drawer(props: DrawerProps) {
    let {drawerOpened, onMouseEnter, onMouseLeave} = props;
    onMouseEnter = onMouseEnter || (() => {});
    onMouseLeave = onMouseLeave || (() => {});

    const classes = useStyles();

    function onItemOpened(item: string, hasChildren: boolean) {
        if (!hasChildren) {
            onMouseLeave && onMouseLeave();
        }
    }

    return (
        <MUIDrawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: drawerOpened,
                [classes.drawerClose]: !drawerOpened,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: drawerOpened,
                    [classes.drawerClose]: !drawerOpened,
                }),
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div className={classes.logoContainer}>
                <div className={classes.logo} />
            </div>

            <DrawerMenu drawerOpened={drawerOpened} onItemOpened={onItemOpened} />
        </MUIDrawer>
    );
}
