import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import AppRoutes from "./common/routes/AppRoutes";
import {BrowserRouter, Route, useHistory, useLocation} from "react-router-dom";
import Constants from "./common/Constants";
import keycloakService, {KeycloakService} from "./keycloak/KeycloakService";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import MomentUtils from "@date-io/moment";
import {Provider} from "react-redux";
import store from "./store/Store";
import {StyledEngineProvider, ThemeProvider} from "@mui/material";
import theme from "./common/Theme";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/cs";
import tokenRefreshService, {TokenRefreshService} from "./common/TokenRefreshService";
import {KeycloakHolder} from "./keycloak/KeycloakHolder";
import LocaleContext, {Locale} from './common/LocaleContext';
import {Language, languageToLocale} from "./common/Language";
import userSettingsService from "./common/apis/user/UserSettingsService";
import EventManagerContext from './common/event/EventManagerContext';
import EventManager from "./common/event/EventManager";
import dayjs from "dayjs";
import en from "dayjs/locale/en"
import cs from "dayjs/locale/cs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import ConfigurationContext, {Configuration} from './common/ConfigurationContext';
import {FeatureStatusesDto} from "./configuration/FeatureStatusesDto";

type Props = {
    keycloakService: KeycloakService,
    tokenRefreshService: TokenRefreshService,
}

function App({keycloakService, tokenRefreshService}: Props) {

    const history = useHistory();
    const location = useLocation();

    const isAuthRequired = location.pathname !== Constants.FE_APP_DIAGRAM_EXPORT_VIEW_ROUTE;

    useEffect(() => {

        function initTokenRefresh(onRefreshFailed: () => void) {
            if (KeycloakHolder.keycloak && KeycloakHolder.getParsedToken() != null) {
                tokenRefreshService.planTokenRefresh(KeycloakHolder.keycloak, KeycloakHolder.getParsedToken()!, onRefreshFailed);
            } else {
                throw new Error();
            }
        }

        if (isAuthRequired) {
            (async () => {
                try {
                    const config = await keycloakService.fetchKeycloakConfig();
                    await keycloakService.doKeycloakLogin(config);
                    initTokenRefresh(() => {
                        history.push(Constants.FE_APP_LOGIN_FAILED_ROUTE);
                    });
                    history.push(Constants.FE_APP_LOGIN_ROUTE);
                } catch (error) {
                    history.push(Constants.FE_APP_LOGIN_FAILED_ROUTE);
                }
            })();
        }
    }, [history, keycloakService, tokenRefreshService, isAuthRequired]);

    return (
        <Route component={AppRoutes}/>
    );
}

export default function AppWrapper() {
    const [language, setLanguage] = useState<Language>(Language.CS);
    const [featureStatuses, setFeatureStatuses] = useState<FeatureStatusesDto>({
        aiEnabled: false,
        graphQueryEnabled: false,
    });
    const eventManager = useRef<EventManager>(new EventManager());

    const locale: Locale = {
        locale: languageToLocale(language) as string,
        language: language,
        setLanguage: updateLanguage
    };

    useEffect(() => {
        dayjs.extend(localizedFormat);
        switch (language) {
            case Language.CS:
                dayjs.locale(cs);
                break;
            case Language.EN:
            default:
                dayjs.locale(en);
                break;
        }
    }, [language]);

    function updateLanguage(language: Language, store: boolean) {
        if (store) {
            userSettingsService.updateGeneralSettings({
                language: language
            });
        }
        setLanguage(language);
    }

    const configuration: Configuration = {
        featureStatuses: featureStatuses,
        setFeatureStatuses: setFeatureStatuses
    };

    return (
        <ConfigurationContext.Provider value={configuration}>
            <LocaleContext.Provider value={locale}>
                <LocalizationProvider dateAdapter={AdapterMoment} utils={MomentUtils} adapterLocale={locale.locale}>
                    <Provider store={store as any}>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={theme}>
                                <EventManagerContext.Provider value={eventManager.current}>
                                    <BrowserRouter>
                                        <App keycloakService={keycloakService}
                                             tokenRefreshService={tokenRefreshService}/>
                                    </BrowserRouter>
                                </EventManagerContext.Provider>
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </Provider>
                </LocalizationProvider>
            </LocaleContext.Provider>
        </ConfigurationContext.Provider>
    );
}
