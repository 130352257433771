import React from "react";
import {Menu, MenuItem} from "@mui/material";
import Typography from "@mui/material/Typography";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import {Language} from "../../../common/Language";
import {buildTranslationKeyForLanguage} from "../../../store/localization/LanguageTranslationKey";
import {_transl} from "../../../store/localization/TranslMessasge";


const appBarLanguageMenuStyles = (theme: Theme) => createStyles({
});

interface IProps extends WithStyles<typeof appBarLanguageMenuStyles> {
    opened: boolean;
    anchorRef: any,
    changeLanguage: (value: Language) => void;
    onMenuClose: () => void;
}

class AppBarLanguageMenu extends React.Component<IProps, {}> {

    render() {
        const { opened, onMenuClose, changeLanguage, anchorRef } = this.props;

        return (
            <Menu
                open={opened}
                id="app-bar-language-menu"
                anchorEl={anchorRef}
                keepMounted
                onClose={onMenuClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{vertical: -10, horizontal: 0}}
            >
                {(Object.entries(Language))
                    .sort()
                    .map((key) => (
                        <span key={key[0]}>
                            <MenuItem onClick={() => changeLanguage(key[0] as Language)}>
                                <Typography variant="inherit">{_transl(buildTranslationKeyForLanguage(key[0] as Language))}</Typography>
                            </MenuItem>
                        </span>
                    ))}
            </Menu>
        )
    }

}

export default withStyles(appBarLanguageMenuStyles, { withTheme: true })(AppBarLanguageMenu);
