import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import React, {useState} from "react";
import {MenuItem} from "@mui/material";
import {SaveButton} from "../../../../components/button/SaveButton";
import {CancelButton} from "../../../../components/button/CancelButton";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "./DiagramTranslationKey";
import {CommonTranslation} from "../CommonTranslation";
import Snackbar from "../snackbar/Snackbar";
import {ErrorTranslationKey} from "../ErrorTranslationKey";
import diagramService from "./service/DiagramService";
import {getLabelForPropertyType, propertyTypes} from "../property/PropertyType";
import DialogActions from "../../../../components/dialogs/DialogActions";
import Grid from "../../../../components/dialogs/Grid";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogContent from "../../../../components/dialogs/DialogContent";
import TextField from "../../../../components/fields/textfield/TextField";


const useStyles = makeStyles((theme: Theme) => createStyles({
    textField: {
        width: "100%",
    },
}));

export interface DiagramPropertyCreateDialogProps {
    open: boolean,
    diagramId: string,
    onPropertyCreated: () => void,
    onClosed: () => void,
}

export function DiagramPropertyCreateDialog(props: DiagramPropertyCreateDialogProps) {
    const {open, diagramId, onClosed, onPropertyCreated} = props;

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);
    const [type, setType] = useState("string");
    const [typeError, setTypeError] = useState(false);
    const [value, setValue] = useState("");

    const classes = useStyles();

    function onDialogClosed() {
        setName("");
        setNameError(false);
        setTypeError(false);
        setValue("")
        onClosed();
    }

    function saveChanges() {
        const isNameInvalid = name.trim().length === 0;
        const isTypeInvalid = type.trim().length === 0;
        if (isNameInvalid || isTypeInvalid) {
            setNameError(isNameInvalid);
            setTypeError(isTypeInvalid);
        } else {
            diagramService.createDiagramProperty(diagramId, name, type, value)
                    .then(() => {
                        onClosed();
                        onPropertyCreated();
                    })
                    .catch(() => {
                        Snackbar.error(_transl(ErrorTranslationKey.UNEXPECTED_ERROR_OCCURRED));
                    });
        }
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            onClose={() => onDialogClosed()}
            fullWidth={true}
            maxWidth={"xs"}
        >
            <DialogTitle id="scroll-dialog-title"
                         title={_transl(DiagramTranslationKey.DETAIL_ATTRIBUTE_ADDITION)}
                         onDialogClosed={onClosed}/>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField className={classes.textField}
                                   id="name-field"
                                   label={_transl(DiagramTranslationKey.DETAIL_ATTRIBUTE_NAME)}
                                   variant="outlined"
                                   size={"small"}
                                   required={true}
                                   errorMessage={nameError ? _transl(CommonTranslation.FILL_OUT_THIS_FIELD) : undefined}
                                   onChange={(value) => setName(value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField className={classes.textField}
                                   id="type-field"
                                   select
                                   label={_transl(DiagramTranslationKey.DETAIL_ATTRIBUTE_TYPE)}
                                   variant="outlined"
                                   size={"small"}
                                   error={typeError}
                                   value={type}
                                   onChange={(value) => setType(value)}
                        >
                            {propertyTypes.map(({value}) => (
                                <MenuItem key={value} value={value}>
                                    {getLabelForPropertyType(value)}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="value-field"
                                   label={_transl(DiagramTranslationKey.DETAIL_ATTRIBUTE_VALUE)}
                                   variant="outlined"
                                   size={"small"}
                                   onChange={(value) => setValue(value)} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SaveButton onClick={(e) => saveChanges()}/>
                <CancelButton onClick={() => onDialogClosed()} variant={"outlined"}/>
            </DialogActions>
        </Dialog>
    );
}
