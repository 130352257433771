import React, {useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import MainPageRoutes from "../common/routes/MainPageRoutes";
import Drawer from "./main/Drawer";
import AppBar from "./main/AppBar";
import PageReloadChecker from "../components/PageReloadChecker";
import * as d3 from "d3";
import SnackbarProvider from "./main/content/snackbar/SnackbarProvider";
import DiagramBackupProvider from "../common/diagrameditor/backup/provider/DiagramBackupProvider";

export const MAIN_PAGE_OVERLAY_ID = "__archirepo-main-page-overlay__";
export const MAIN_PAGE_OVERLAY_ZINDEX = 1202;


export function showMainPageOverlay() {
    d3.select("#" + MAIN_PAGE_OVERLAY_ID)
        .style("display", "block");
}

export function hideMainPageOverlay() {
    d3.select("#" + MAIN_PAGE_OVERLAY_ID)
        .style("display", "none");
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        '@global': {
            '*::-webkit-scrollbar': {
                height: '0.5em',
                width: '0.5em',
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: "1em",
            },
            '[id=__diagram-editor-content-area__]::-webkit-scrollbar': {
                height: '0.7em',
                width: '0.7em',
            },
            '[id=__diagram-editor-content-area__]::-webkit-scrollbar-track-piece': {
                backgroundColor: "rgb(200, 200, 200)",
            },
            '[id=__diagram-editor-content-area__]::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'auto'
            }
        },
        root: {
            display: 'flex',
            backgroundColor: '#f3f5f8'
        },
        content: {
            flexGrow: 1,
            position: "absolute",
            paddingLeft: (Number(theme.spacing(0).replace("px", "")) + theme.drawer.minimizedWidth + "px"),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(9),
            paddingBotton: 0,
            width: "100%",
            minHeight: "100%",
            "& > div": {
                minHeight: "100%",
            },
        },
        menuTopLevel: {
            paddingLeft: theme.spacing(3)
        },
        account: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        userName: {
            fontSize: "small"
        },
        overlay: {
            display: "none",
            position: "absolute",
            inset: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: MAIN_PAGE_OVERLAY_ZINDEX,
        }
    })
);


export default function MainPage() {

    const classes = useStyles();
    const [drawerOpened, setDrawerOpened] = useState(false);

    return (
        <DiagramBackupProvider>
            <SnackbarProvider>
                <div className={classes.root}>
                    <PageReloadChecker/>
                    <CssBaseline/>

                    <AppBar onAppIconClick={() => setDrawerOpened(true)} drawerOpened={drawerOpened}/>

                    <Drawer onMouseEnter={() => setDrawerOpened(true)} onMouseLeave={() => setDrawerOpened(false)}
                            drawerOpened={drawerOpened}/>

                    <main className={classes.content}>
                        <MainPageRoutes/>
                    </main>

                    <div id={MAIN_PAGE_OVERLAY_ID} className={classes.overlay}/>
                </div>
            </SnackbarProvider>
        </DiagramBackupProvider>
    );
}
