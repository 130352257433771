import RouteDefinitionUtils, {
    diagramEditorPage
} from "../../../../../common/routedefinition/RouteDefinitionUtils";
import Constants from "../../../../../common/Constants";
import DetailHeader from "../../elementdetail/DetailHeader";
import CopyUrlIcon from "../../../../../components/CopyUrlIcon";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import React, {useState} from "react";
import {DiagramInfoDto} from "../../../../../common/apis/diagram/DiagramInfoDto";
import {DiagramTranslationKey} from "../DiagramTranslationKey";
import {CircularProgress, IconButton, Tooltip} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ImageIcon from "@mui/icons-material/Image";
import {diagramsMenu} from "../../../../../common/menudefinition/MenuDefinitionUtils";
import {DiagramsGridAction, DiagramsGridActionType} from "../DiagramsGridAction";
import RenderMode from "../../../../../common/diagrameditor/context/RenderMode";
import {FileFormatType} from "../../../../../common/apis/Exports";
import {visualiseDiagram} from "../DiagramVisualization";
import Snackbar from "../../snackbar/Snackbar";
import {ErrorTranslationKey} from "../../ErrorTranslationKey";
import CloseIcon from "@mui/icons-material/Close";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import CommonCssStyles from "../../../../../css/CommonCssStyles";

interface DiagramDetailHeaderProps {
    diagram: DiagramInfoDto
    onClosed?: () => void,
    hideShowAndEditDiagramIcons?: boolean,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        closeButton: CommonCssStyles.getHeaderCloseButtonStyle(theme)
    })
)

export default function DiagramDetailHeader({diagram, onClosed, hideShowAndEditDiagramIcons}: DiagramDetailHeaderProps) {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    function navigateToDiagramEditorDialog(editDiagram: boolean) {
        const queryParams = [{
            name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.SHOW_EDITOR),
            value: diagram.identifier
        }];
        if (editDiagram) {
            queryParams.push({
                name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.EDITOR_MODE),
                value: RenderMode.EDIT
            })
        }
        window.open(RouteDefinitionUtils.resolvePath(diagramEditorPage, {}, queryParams), '_blank');
    }

    function buildDiagramDetailUrl(diagramId: string) {
        const path = RouteDefinitionUtils.resolveDiagramDetailPath(diagramId);
        return Constants.attachAppHost(path);
    }

    function onExportPngClick() {
        setLoading(true);
        visualiseDiagram(diagram.identifier, FileFormatType.PNG, onExportPngFinished, onExportPngFailed);
    }

    function onExportPngFinished() {
        setLoading(false);
    }

    function onExportPngFailed() {
        setLoading(false);
        Snackbar.error(_transl(ErrorTranslationKey.UNEXPECTED_ERROR_OCCURRED));
    }

    return (
        <DetailHeader name={diagram.name || ""}>
            <>
                {!hideShowAndEditDiagramIcons &&
                    <Tooltip title={_transl(DiagramTranslationKey.DETAIL_VIEW_DIAGRAM)}
                             placement={"top"}>
                        <IconButton onClick={() => navigateToDiagramEditorDialog(false)} size="large">
                            {diagramsMenu.icon}
                        </IconButton>
                    </Tooltip>}
                {!hideShowAndEditDiagramIcons && diagram.acl?.canUpdate &&
                    <Tooltip title={_transl(DiagramTranslationKey.DETAIL_EDIT_DIAGRAM)}
                             placement={"top"}>
                        <IconButton onClick={() => navigateToDiagramEditorDialog(true)} size="large">
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>}
                {!hideShowAndEditDiagramIcons &&
                    <Tooltip title={_transl(DiagramTranslationKey.DETAIL_ACTION_EXPORT_PNG_TITLE)}
                             placement={"top"}>
                        <IconButton onClick={() => onExportPngClick()} disabled={loading}>
                            {loading && <CircularProgress variant={"indeterminate"} size={22}/>}
                            {!loading && <ImageIcon/>}
                        </IconButton>
                    </Tooltip>}
                <CopyUrlIcon title={_transl(DiagramTranslationKey.DETAIL_COPY_URL)}
                             successTitle={_transl(DiagramTranslationKey.DETAIL_COPIED_URL)}
                             url={buildDiagramDetailUrl(diagram.identifier)}
                />
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClosed}
                    size="large">
                    <CloseIcon/>
                </IconButton>
            </>
        </DetailHeader>
    )
}
