import React, {useContext, useState} from "react";
import ListItem from "@mui/material/ListItem";
import {withRouter, RouteComponentProps} from "react-router";
import {NavLink} from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {Collapse} from "@mui/material";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import {
    administrationMenu,
    labelsMenu,
    dashboardAdministrationMenu,
    diagramsMenu,
    exportMenu,
    importMenu,
    elementsMenu,
    structuresMenu,
    usersMenu,
    toolsMenu,
    collectionsMenu,
    stereotypesMenu,
    dashboardMenu,
    generateMetamodelMenu,
    validateModelMenu,
    modelDocumentationMenu,
    modelsComparisonMenu,
    queriesMenu,
} from "../../common/menudefinition/MenuDefinitionUtils";
import MenuDefinition from "../../common/menudefinition/MenuDefinition";
import {_transl} from "../../store/localization/TranslMessasge";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../store/Store";
import ConfigurationContext, {Configuration} from "../../common/ConfigurationContext";

const useStyles = makeStyles((theme: Theme) => createStyles({
    menuTopLevel: {
        paddingLeft: theme.spacing(3),
    },
    menuNested_1: {
        paddingLeft: theme.spacing(4),
    },
    nestedDivider_1: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(2),
    },
    expandIcon: {
        marginRight: theme.spacing(4)
    },
}));

interface DrawerMenuItemProps {
    menu: MenuDefinition<any>,
    isTopLevel: boolean,
    hasChildren: boolean,
    isOpened: boolean,
    onOpened: (listItem: string, hasChildren: boolean) => void;
}

function DrawerMenuItem(props: DrawerMenuItemProps) {
    const {menu, hasChildren, isTopLevel, isOpened, onOpened} = props;
    const classes = useStyles();
    const onClick = (e: any) => {
        if (hasChildren) {
            const event = e as Event;
            event.preventDefault();
            event.stopPropagation();
        }
        onOpened(menu.routeId, hasChildren);
    }
    const className = isTopLevel ? classes.menuTopLevel : classes.menuNested_1;

    return <ListItem to={menu.path} component={NavLink} key={menu.routeId} className={className} button disableGutters={true} onClick={onClick}>
        <ListItemIcon>{menu.icon}</ListItemIcon>
        <ListItemText primary={_transl(menu.label)}/>
        {hasChildren && (isOpened ? <ExpandLess className={classes.expandIcon} /> : <ExpandMore className={classes.expandIcon} />)}
    </ListItem>
}

DrawerMenuItem.defaultProps = {
    hasChildren: false
};

interface DrawerMenuProps extends RouteComponentProps {
    drawerOpened: boolean,
    onItemOpened: (item: string, hasChildren: boolean) => void;
}

function DrawerMenu(props: DrawerMenuProps) {
    const {drawerOpened, onItemOpened} = props;

    const { featureStatuses } = useContext<Configuration>(ConfigurationContext);

    // STATE
    const [openedListItem, setOpenedListItem] = useState('');

    // STORE
    const user = useSelector((state: IApplicationState) => state.user.userData);

    function isOpened(menu: MenuDefinition<any>): boolean {
        return openedListItem === menu.routeId;
    }

    function onOpened(item: string, hasChildren: boolean): void {
        setOpenedListItem((item === openedListItem) ? '' : item);
        onItemOpened(item, hasChildren);
    }

    const classes = useStyles();

    const dashboardMainWidget = useSelector((state: IApplicationState) => state.dashboard.mainWidget);
    const dashboardNotEmpty = dashboardMainWidget.url || dashboardMainWidget.diagramIdentifier;

    function hasPageAccess(menuDefinition: MenuDefinition<any>): boolean {
        if (menuDefinition.route.accessResolver) {
            return menuDefinition.route.accessResolver(user);
        } else {
            return true;
        }
    }

    function createDrawerMenuItem(menuDefinition: MenuDefinition<any>,
                                  isTopLevel: boolean,
                                  hasChildren?: boolean) {
        if (hasPageAccess(menuDefinition)) {
            return <DrawerMenuItem menu={menuDefinition} isTopLevel={isTopLevel} hasChildren={hasChildren} isOpened={isOpened(menuDefinition)} onOpened={onOpened} />
        } else {
            return null;
        }
    }

    return (
        <List>
            {dashboardNotEmpty ? createDrawerMenuItem(dashboardMenu, true) : null}
            {createDrawerMenuItem(structuresMenu, true)}
            {createDrawerMenuItem(elementsMenu, true)}
            {createDrawerMenuItem(diagramsMenu, true)}
            <Divider key={"DIVIDER_1"} />
            {createDrawerMenuItem(toolsMenu, true, true)}
            <Collapse key={toolsMenu.routeId + "_COLLAPSE"} in={drawerOpened && isOpened(toolsMenu)} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {featureStatuses.graphQueryEnabled && createDrawerMenuItem(queriesMenu, false)}
                    <Divider key={"DIVIDER_5"} className={classes.nestedDivider_1} />
                    {createDrawerMenuItem(exportMenu, false)}
                    {createDrawerMenuItem(importMenu, false)}
                    <Divider key={"DIVIDER_4"} className={classes.nestedDivider_1} />
                    {createDrawerMenuItem(generateMetamodelMenu, false)}
                    {createDrawerMenuItem(modelsComparisonMenu, false)}
                    {createDrawerMenuItem(validateModelMenu, false)}
                    {createDrawerMenuItem(modelDocumentationMenu, false)}
                </List>
            </Collapse>
            {createDrawerMenuItem(administrationMenu, true, true)}
            <Collapse key={administrationMenu.routeId + "_COLLAPSE"} in={drawerOpened && isOpened(administrationMenu)} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {createDrawerMenuItem(usersMenu, false)}
                    {createDrawerMenuItem(labelsMenu, false)}
                    {createDrawerMenuItem(collectionsMenu, false)}
                    <Divider key={"DIVIDER_2"} className={classes.nestedDivider_1} />
                    {createDrawerMenuItem(stereotypesMenu, false)}
                    <Divider key={"DIVIDER_3"} className={classes.nestedDivider_1} />
                    {createDrawerMenuItem(dashboardAdministrationMenu, false)}
                </List>
            </Collapse>
        </List>
    );
}

export default withRouter(DrawerMenu);
