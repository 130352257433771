import {SearchProvider} from "./SearchProvider";
import {elementsMenu} from "../../../../common/menudefinition/MenuDefinitionUtils";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import Api from "../../../../common/Api";
import {
    getFilterUpdateAction as getElementsFilterUpdateAction,
    IFilter as IElementsFilter
} from "../../../../store/elements/Elements";
import RouteDefinitionUtils, {elementsPage} from "../../../../common/routedefinition/RouteDefinitionUtils";
import {AppBarTranslationKey} from "../AppBarTranslationKey";

const elementSearchProvider : SearchProvider = {
    id: "ELEMENT_SEARCH",
    contextAreaConfig: {
        icon: elementsMenu.icon,
        primaryTextKey: AppBarTranslationKey.SEARCH_BAR_ELEMENTS,
        secondaryTextKey: AppBarTranslationKey.SEARCH_BAR_SEARCH_IN_ELEMENTS,
        secondaryDescriptionKey: AppBarTranslationKey.SEARCH_BAR_SEARCH_BY_IDENTIFIER_NAME_AND_DESCRIPTION,
    },
    resultAreaConfig: {
        toResultItem: (item) => {
            const element = item as ElementDto;
            return {
                id: element.identifier,
                name: element.name || "",
                type: `[${element.type}]  -  ${element.identifier}`,
                description: element.description || "",
            }
        },
        search: (term: string) => Api.elements.doSearch({searchText: term}),
        extractItemsFromResponse: (response: any) => response.items,
        getSearchAction: (term: string) => {
            const newFilter: IElementsFilter = {};
            newFilter.searchText = term;
            newFilter.maxPageSize = 10;
            return getElementsFilterUpdateAction(newFilter);
        },
        getRedirectUrl: () => RouteDefinitionUtils.resolvePath(elementsPage, {}),
        getDetailRedirectUrl: (selectedItem => RouteDefinitionUtils.resolveElementDetailPath(selectedItem.id))
    }
};

export default elementSearchProvider;
