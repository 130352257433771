export class DiagramBackupInfo {
    constructor(private _diagramId: string,
                private _diagramName: string) {
    }

    getDiagramId() {
        return this._diagramId;
    }

    getDiagramName() {
        return this._diagramName;
    }
}
