import { Redirect, Route, RouteProps } from 'react-router';
import {AccessResolverType} from "../common/routedefinition/AccessResolverType";
import Constants from "../common/Constants";
import {UserDto} from "../common/apis/user/UserDto";
import inaccessibleRouteService from "../common/routes/service/InaccessibleRouteService";
import logoutActionProvider from "../common/logout/LogoutActionProvider";

export type AccessBasedRouteProps = { accessResolver: AccessResolverType, user: UserDto | undefined } & RouteProps;

export default function AccessBasedRoute({accessResolver, user,  ...routeProps}: AccessBasedRouteProps) {
    if (user && user.login) {
        if (accessResolver && accessResolver(user)) {
            return <Route {...routeProps} />;
        } else {
            return <Redirect to={{ pathname: Constants.FE_APP_MAIN_ROUTE }} />;
        }
    } else {
        // initiate login
        if (!logoutActionProvider.isLogoutInProgress()) {
            inaccessibleRouteService.saveCurrent();
        } else {
            logoutActionProvider.clear();
        }
        return <Redirect to={{ pathname: Constants.FE_APP_LOGIN_ROUTE }} />;
    }
};
