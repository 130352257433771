import {FormControl, InputLabel} from "@mui/material";
import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import {IApplicationState} from "../../../../store/Store";
import {useSelector} from "react-redux";
import {IFilter} from "../../../../store/elements/Elements";
import MultiselectComboBox, {LabeledComponent} from "../../../../components/fields/MultiselectComboBox";
import MultiselectElementTypesComboBox from "../../../../components/fields/MultiselectElementTypesComboBox";
import MultiselectAuthorComboBox from "../../../../components/fields/MultiselectAuthorComboBox";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ElementTranslationKey} from "./ElementTranslationKey";
import DatePicker from "../../../../components/fields/date/DatePicker";
import stateColorResolver from "../../../../common/StateColorResolver";
import ElementState from "../../../../components/ElementState";
import TextField from "../../../../components/fields/textfield/TextField";
import SearchFab from "../../../../components/button/SearchFab";
import Grid from "../../../../components/dialogs/Grid";
import OutlinedInput from "../../../../components/fields/textfield/OutlinedInput";
import {endOfTheDay, startOfTheDay} from "../../../../common/DateUtils";

const useStyles = makeStyles((theme: Theme) => createStyles({
    searchFieldGridItem: {
        display: "flex",
        alignItems: "center",
    },
    searchField: {
        flexGrow: 1,
    },
    formGridItem: {
        display: "flex",
        flexDirection: "column",
        "&> *": {
            flex: 1,
        }
    },
    searchActionGridItem: {
        display: 'flex',
        justifyContent: "flex-end",
    },
}));

interface ElementsFilterProps {
    filter: IFilter;
    onFilterChanged?: (filter: IFilter) => void;

    onSearchClicked: () => void;
}

export default function ElementsFilter(props: ElementsFilterProps) {
    const classes = useStyles();

    const collectionOptions = useSelector((state: IApplicationState) => state.pages.common.options.collections.resource);
    const labelOptions = useSelector((state: IApplicationState) => state.pages.common.options.labels.resource);
    const stateOptions = useSelector((state: IApplicationState) => state.pages.common.options.states.resource);
    const typeOptions = useSelector((state: IApplicationState) => state.pages.common.options.types.resource);
    const authorOptions = useSelector((state: IApplicationState) => state.pages.common.options.authors.resource);

    const {filter, onSearchClicked} = props;

    function updateFilter<K extends keyof IFilter>(property: K, value: IFilter[K]) {
        const updatedFilter: IFilter = {...filter, [property]: value};
        props.onFilterChanged && props.onFilterChanged(updatedFilter);
    }

    function setValidFor(date: Date | undefined) {
        const updatedFilter: IFilter = {
            ...filter,
            validFrom: date !== undefined ? startOfTheDay(date) : undefined,
            validThru: date !== undefined ? endOfTheDay(date) : undefined
        };
        props.onFilterChanged && props.onFilterChanged(updatedFilter);
    }

    const {
        identifierLike,
        nameLike,
        searchText,
        selectedAuthors,
        selectedCollections,
        selectedLabels,
        selectedStates,
        selectedTypes,
        stereotype,
        validFrom
    } = props.filter;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} className={classes.searchFieldGridItem}>
                    <FormControl variant="outlined" size={"small"} className={classes.searchField}>
                        <InputLabel htmlFor="search-input"
                                    shrink={true}>{_transl(ElementTranslationKey.FILTER_SEARCH)}</InputLabel>
                        <OutlinedInput
                            label={_transl(ElementTranslationKey.FILTER_SEARCH)}
                            placeholder={_transl(ElementTranslationKey.FILTER_SEARCH_PLACEHOLDER)}
                            id="search-input"
                            type={'text'}
                            value={searchText}
                            notched={true}
                            onChange={e => updateFilter('searchText', e.target.value)}
                            onClearButtonClick={() => updateFilter('searchText', "")}
                            autoComplete={"off"}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={4} className={classes.formGridItem}>
                    <MultiselectElementTypesComboBox
                        label={_transl(ElementTranslationKey.FILTER_TYPES)}
                        id="types-multiselectcombobox"
                        options={typeOptions}
                        selectedValues={selectedTypes || []}
                        handleOnChange={(changedValues) => updateFilter('selectedTypes', changedValues)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} className={classes.formGridItem}>
                    <TextField
                        id="name-field"
                        label={_transl(ElementTranslationKey.FILTER_NAME)}
                        variant="outlined"
                        size={"small"}
                        InputLabelProps={{shrink: true}}
                        value={nameLike}
                        onChange={e => updateFilter("nameLike", e)}
                        clearable
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} className={classes.formGridItem}>
                    <TextField
                        id="name-field"
                        label={_transl(ElementTranslationKey.FILTER_IDENTIFIER)}
                        variant="outlined"
                        size={"small"}
                        InputLabelProps={{shrink: true}}
                        value={identifierLike}
                        onChange={e => updateFilter("identifierLike", e)}
                        clearable
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} className={classes.formGridItem}>
                    <TextField
                        id="stereotype-field"
                        label={_transl(ElementTranslationKey.FILTER_STEREOTYPE)}
                        variant="outlined"
                        size={"small"}
                        InputLabelProps={{shrink: true}}
                        value={stereotype}
                        onChange={e => updateFilter("stereotype", e)}
                        clearable
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} className={classes.formGridItem}>
                    <MultiselectComboBox
                        label={_transl(ElementTranslationKey.FILTER_LABELS)}
                        id="labels-multiselectcombobox"
                        options={labelOptions}
                        selectedValues={selectedLabels || []}
                        getRenderLabel={(value) => value.name}
                        handleOnChange={(changedValues) => updateFilter('selectedLabels', changedValues)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} className={classes.formGridItem}>
                    <MultiselectComboBox
                        label={_transl(ElementTranslationKey.FILTER_COLLECTIONS)}
                        id="collections-multiselectcombobox"
                        options={collectionOptions}
                        selectedValues={selectedCollections || []}
                        getRenderLabel={(value) => value.name}
                        handleOnChange={(changedValues) => updateFilter('selectedCollections', changedValues)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} className={classes.formGridItem}>
                    <MultiselectComboBox
                        label={_transl(ElementTranslationKey.FILTER_STATES)}
                        id="states-multiselectcombobox"
                        options={stateOptions}
                        selectedValues={selectedStates || []}
                        getRenderLabel={(value) => {
                            return {
                                label: value.code,
                                component: <ElementState stateCode={value.code}
                                                         stateColorResolver={stateColorResolver}/>
                            } as LabeledComponent;
                        }}
                        handleOnChange={(changedValues) => updateFilter('selectedStates', changedValues)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} className={classes.formGridItem}>
                    <DatePicker
                        value={validFrom || null}
                        onChange={date => setValidFor(date as Date | undefined)}
                        label={_transl(ElementTranslationKey.FILTER_VALID_TO_DATE)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} className={classes.formGridItem}>
                    <MultiselectAuthorComboBox
                        label={_transl(ElementTranslationKey.FILTER_AUTHOR)}
                        id="types-multiselectcombobox"
                        options={authorOptions}
                        selectedValues={selectedAuthors || []}
                        handleOnChange={(changedValues) => updateFilter('selectedAuthors', changedValues)}
                    />
                </Grid>
                <Grid item xs={12} sm md lg className={classes.searchActionGridItem}>
                    <SearchFab search={() => onSearchClicked()}
                               inProgress={false /*gridFetchStatus.status === FetchStatusType.STARTED*/}/>
                </Grid>
            </Grid>
        </>
    );
}
