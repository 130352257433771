import EventManager, {Unsubscriber} from "../../event/EventManager";
import {
    AddElementsToModelEvent,
    AlignNodesEvent,
    ConnectionBendpointType,
    EventType,
    HiddenConnectionVisualizedEvent,
    HiddenConnectionVisualizeEvent,
    IBendpointCreatedEvent,
    IBendpointMovedEvent,
    IBendpointRemoveEvent,
    IChartEvent,
    IConnectionCreateEvent,
    IConnectionLabelUpdateEvent,
    IModelUpdatedEvent,
    IModelUpdatedOnBendpointCreatedEvent,
    IModelUpdatedOnBendpointMovedEvent,
    IModelUpdatedOnBendpointRemovedEvent,
    IModelUpdatedOnConnectionCreatedEvent,
    IModelUpdatedOnConnectionLabelUpdateEvent,
    IModelUpdatedOnConnectionRemovedEvent,
    IModelUpdatedOnItemsCreatedEvent,
    IModelUpdatedOnItemsRemovedEvent,
    IModelUpdatedOnNodeLabelUpdateEvent,
    IModelUpdatedOnNodesMovedEvent,
    IModelUpdatedOnNodesResizedEvent,
    INodeCreateEvent,
    INodeLabelUpdateEvent,
    INodesMovedEvent,
    INodesResizedEvent,
    ModelBackupEvent,
    ModelUpdatedOnNodesAlignedEvent,
    NodeParentPositionType,
    RemoveSelectedItemsEvent,
    UndoRedoType
} from "../../event/Event";
import GeometryUtils, {Area, Point} from "../util/GeometryUtils";
import {DiagramEditorUtils} from "../util/DiagramEditorUtils";
import ModelAccessor from "./model/ModelAccessor";
import {
    ArchiMateAccessType,
    ArchimateRelationship,
    ArchimateRelationshipType
} from "../../archimate/ArchimateRelationship";
import {_transl} from "../../../store/localization/TranslMessasge";
import {PointIncrement} from "../common/PointIncrement";
import ConnectionPredicate from "./model/predicates/ConnectionPredicate";
import Api from "../../Api";
import {IDiagramNodeDto} from "../../apis/diagram/IDiagramNodeDto";
import {IDiagramConnectionDto} from "../../apis/diagram/IDiagramConnectionDto";
import {NodeType} from "../../apis/diagram/NodeType";
import {DiagramConnectionType} from "../../apis/diagram/DiagramConnectionType";
import AddElementsToModelHandler, {
    Manager as AddElementsToModelManager,
    Service as AddElementsToModelService
} from "./model/eventhandlers/AddElementsToModelHandler";
import RenderMode from "../context/RenderMode";
import {map} from "rxjs/operators";
import {forkJoin} from "rxjs";
import {ObjectType} from "../../apis/editor/ObjectType";
import {ParentNodePosition} from "./model/ParentNodePosition";
import RemoveSelectedObjectsHandler, {
    RemoveSelectedObjectsManager
} from "./model/eventhandlers/RemoveSelectedObjectsHandler";
import {RelationshipDto} from "../../apis/relationship/RelationshipDto";
import {ConnectableConceptType} from "../../apis/relationship/ConnectableConceptType";
import {DiagramDto} from "../../apis/diagram/DiagramDto";
import {IDiagramPoint} from "../../apis/diagram/IDiagramPoint";
import {RelationshipSearchType} from "../../apis/relationship/RelationshipSearchType";
import {ElementDto} from "../../apis/element/ElementDto";
import {IGraphDto} from "../../apis/model/IGraphDto";
import {IEditMode} from "../editor/IEditMode";
import {IMode} from "../model/IMode";
import ArrayUtils from "../../ArrayUtils";
import CacheLocator from "./model/CacheLocator";
import {NodeDimensionsExtractor} from "./nodeposition/NodeDimensionsExtractor";
import store from "../../../store/Store";
import {NodeDimensionsType} from "../../event/NodeDimensionsType";
import NodesAligner, {Manager as NodesAlignerManager} from "./model/eventhandlers/NodesAligner";
import {AlignmentType} from "../common/AlignmentType";
import NodesPositionUpdater, {Manager as NodesPositionUpdaterManager} from "./model/eventhandlers/NodesPositionUpdater";
import NodeChildNodes from "./model/NodeChildNodes";
import {PositionUpdateType} from "./model/eventhandlers/positionupdater/PositionUpdate";
import PositionDirection from "./model/eventhandlers/positionupdater/PositionDirection";
import TranslationKey from "../TranslationKey";
import {UpdateNodesPositionEvent} from "../../event/UpdateNodesPositionEvent";
import {ModelUpdatedOnNodesPositionUpdatedEvent} from "../../event/ModelUpdatedOnNodesPositionUpdatedEvent";
import {StyleUpdate} from "../../../diagram/editor/style/StyleUpdate";
import {StyleEventType, StylesUpdatedEvent} from "../../../diagram/editor/style/StyleEvents";
import {ModelManagerTranslationKey} from "./ModelManagerTranslationKey";
import {DiagramNode, Model} from "../model/Model";
import {RelationshipAclDto} from "../../apis/relationship/RelationshipAclDto";
import {DiagramTranslationKey} from "../../../pages/main/content/diagrams/DiagramTranslationKey";
import {ElementAclDto} from "../../apis/element/ElementAclDto";
import {MetamodelDto} from "../../../pages/main/content/metamodel/extraction/MetamodelService";
import {
    AddDiagramRefsRequestEvent,
    DiagramRefsEventType
} from "../../../diagram/editor/editing/diagram-refs/DiagramRefsEvents";
import AddDiagramRefsRequestHandler from "./model/eventhandlers/AddDiagramRefsRequestHandler";
import {SelfLoopBendpointAppender} from "./model/SelfLoopBendpointAppender";
import {
    ClipboardEventType,
    CreateItemsFromClipboardEvent
} from "../../../pages/main/content/diagrams/diagrameditor/ClipboardEvents";
import elementService from "../../../pages/main/content/elements/service/ElementService";
import Snackbar from "../../../pages/main/content/snackbar/Snackbar";
import {ValidationError} from "../../ValidationError";
import { DiagramEditorTranslationKey } from "../../../pages/main/content/diagrams/diagrameditor/DiagramEditorTranslationKey";

import {
    SubmodelExchangeErrorCodes
} from "../../../pages/main/content/diagrams/diagrameditor/submodelexchange/SubmodelExchangeErrorCodes";
import {StyleDtoFactory} from "../../apis/diagram/StyleDtoFactory";
import {StereotypeDtoFactory} from "../../apis/stereotype/StereotypeDtoFactory";
import {StereotypeDto} from "../../apis/stereotype/StereotypeDto";

// let user view actual BE version of the diagram for a while (then replace it using the backup)
const BACKUP_LOAD_DELAY_MS = 150;

export const PARENT_CHILD_RELATIONSHIP_TYPES = [
    ArchimateRelationshipType.AGGREGATION,
    ArchimateRelationshipType.COMPOSITION,
    ArchimateRelationshipType.SPECIALIZATION];

type ConnectionBendpoint = {
    connection: IDiagramConnectionDto,
    bendpointIndex: number,
}

export class ModelManager {

    private eventManager: EventManager;
    private mode?: IMode;
    private modelAccessor?: ModelAccessor;
    private chartStartPoint: Point;
    private nodeDimensionsExtractor: NodeDimensionsExtractor;
    private initialModel?: Model;
    private backupModelToEdit?: Model;
    private metamodel?: MetamodelDto;

    private selfLoopBendpointAppender: SelfLoopBendpointAppender;

    private stereotypeDtoFactory: StereotypeDtoFactory;
    private styleDtoFactory: StyleDtoFactory;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.REMOVE_SELECTED_OBJECTS, this.removeSelectedObjects.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODES_MOVED, this.handleNodesMovedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODES_RESIZED, this.handleNodesResizedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_MOVED, this.handleBendpointMovedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_CREATED, this.handleBendpointCreatedEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_REMOVE_REQUESTED, this.handleBendpointRemoveEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_LABEL_UPDATE, this.handleNodeLabelUpdateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_LABEL_UPDATE, this.handleConnectionLabelUpdateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_CREATE, this.handleNodeCreateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(ClipboardEventType.CREATE_ITEMS_FROM_CLIPBOARD, this.handleCreateItemsFromClipboardEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_CREATE, this.handleConnectionCreateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.HIDDEN_CONNECTION_VISUALIZE, this.handleHiddenConnectionVisualizeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.ADD_ELEMENTS_TO_MODEL, this.handleAddElementsToModelEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(DiagramRefsEventType.ADD_DIAGRAMS_REFS_REQUEST, this.handleAddDiagramRefsRequestEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<AlignNodesEvent>(EventType.ALIGN_NODES, this.handleAlignNodesEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<UpdateNodesPositionEvent>(EventType.UPDATE_NODES_POSITION, this.handleUpdateNodesPositionEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener<IChartEvent>(EventType.CHART_RENDERED, this.handleChartRenderedEvent.bind(this)));

        this.chartStartPoint = new Point(0, 0);
        this.nodeDimensionsExtractor = new NodeDimensionsExtractor(store.getState().diagramDefaults);
        this.selfLoopBendpointAppender = new SelfLoopBendpointAppender();

        this.stereotypeDtoFactory = new StereotypeDtoFactory();
        this.styleDtoFactory = new StyleDtoFactory();
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    init(model: Model, backupModelToEdit: Model | undefined = undefined, metamodel: MetamodelDto | undefined, mode: IMode) {
        this.mode = mode;
        this.initialModel = this.addSelfLoopBendpoints(model);
        this.metamodel = metamodel;
        this.backupModelToEdit = backupModelToEdit;
        this.modelAccessor = new ModelAccessor(model, new CacheLocator());
        this.publishModelEvent(EventType.MODEL_INITIALIZED, () => {});
    }

    private addSelfLoopBendpoints(model: Model): Model {
        model.diagrams = model.diagrams
            .map(diagram => this.selfLoopBendpointAppender.appendSelfLoopBendpoints(diagram));
        return model;
    }

    private handleChartRenderedEvent(event: IChartEvent) {
        if (this.mode?.mode === RenderMode.EDIT && this.backupModelToEdit) {
            setTimeout(() => this.loadModel(this.initialModel!, this.backupModelToEdit!), BACKUP_LOAD_DELAY_MS);
        }
    }

    private loadModel(oldModel: Model,
                      newModel: Model,
                      undoRedoType?: UndoRedoType) {
        this.modelAccessor!.reinitModel(newModel, new CacheLocator());

        const oldModelAccessor = new ModelAccessor(oldModel, new CacheLocator());
        const newModelAccessor = new ModelAccessor(newModel, new CacheLocator());

        const eventType = EventType.MODEL_UPDATED_ON_MODEL_BACKUP_LOADED;
        const eventPublisher = () => this.eventManager.publishEvent<ModelBackupEvent>({
            type: eventType,
            oldModelAccessor: oldModelAccessor,
            newModelAccessor: newModelAccessor,
            nodesToRemove: oldModelAccessor.getAllNodes(),
            connectionsToRemove: oldModelAccessor.getDiagramConnections(),
            nodesToAdd: newModelAccessor.getAllNodes(),
            connectionsToAdd: newModelAccessor.getDiagramConnections(),
            description: _transl(DiagramTranslationKey.BACKUP_ACTION_LOAD),
            redo: () => this.loadModel(oldModel, newModel, UndoRedoType.REDO),
            undo: () => this.loadModel(newModel, oldModel, UndoRedoType.UNDO),
            undoRedoType: undoRedoType,
        });
        this.publishModelEvent(eventType, eventPublisher);
    }

    isInitialised() {
        return this.modelAccessor != null && this.modelAccessor.isInitialised();
    }

    /*
        PUBLIC API
     */

    getModelAccessor() {
        return (this.modelAccessor as ModelAccessor);
    }

    getModel() {
        return (this.modelAccessor as ModelAccessor).getModel();
    }

    getMetamodel(): MetamodelDto | undefined {
        return this.metamodel;
    }

    getDiagram(): DiagramDto {
        return (this.modelAccessor as ModelAccessor).getDiagram();
    }

    getGraph(): IGraphDto {
        return (this.modelAccessor as ModelAccessor).getGraph();
    }

    getElements(): Array<ElementDto> {
        return (this.modelAccessor as ModelAccessor).getElements();
    }

    getRelationships(): Array<RelationshipDto> {
        return (this.modelAccessor as ModelAccessor).getRelationships();
    }

    getDiagramNodes(): Array<DiagramNode> {
        return (this.modelAccessor as ModelAccessor).computeDiagramNodes();
    }

    getChildNodesInclusive(node: IDiagramNodeDto): Array<IDiagramNodeDto> {
        return (this.modelAccessor as ModelAccessor).getChildNodesInclusive(node);
    }

    getChildNodesExclusive(node: IDiagramNodeDto): Array<IDiagramNodeDto> {
        return this.getChildNodesInclusive(node)
            .filter(listNode => listNode.identifier !== node.identifier);
    }

    getParentNode(node: IDiagramNodeDto): IDiagramNodeDto | undefined {
        return (this.modelAccessor as ModelAccessor).getParentNode(node);
    }

    getDiagramConnections(): Array<IDiagramConnectionDto> {
        return (this.modelAccessor as ModelAccessor).getDiagramConnections();
    }

    getDiagramConnectionById(id: string): IDiagramConnectionDto {
        return (this.modelAccessor as ModelAccessor).getDiagramConnectionById(id);
    }

    getElementById(id: string) {
        return (this.modelAccessor as ModelAccessor).getElementById(id);
    }

    getRelationshipById(id: string | undefined) {
        return (this.modelAccessor as ModelAccessor).getRelationshipById(id);
    }

    getRelationshipType(relationshipId: string | undefined) {
        return (this.modelAccessor as ModelAccessor).getRelationshipTypeByReationshipId(relationshipId);
    }

    getDiagramNodeById(id: string) {
        return (this.modelAccessor as ModelAccessor).getDiagramNodeById(id);
    }

    getDiagramNodeConnectionsIncludeNodeChildren(node: IDiagramNodeDto) {
        return (this.modelAccessor as ModelAccessor).getDiagramNodeConnectionsIncludeNodeChildren(node);
    }

    getNodeConnections(node: IDiagramNodeDto) {
        return (this.modelAccessor as ModelAccessor).getNodeConnections(node);
    }

    getElementLayerType(elementId: string | undefined) {
        return (this.modelAccessor as ModelAccessor).getElementLayerType(elementId);
    }

    getElementType(elementId: string | undefined) {
        return (this.modelAccessor as ModelAccessor).getElementType(elementId);
    }

    getConnectionsBetweenNodes(firstNode: IDiagramNodeDto, secondNode: IDiagramNodeDto) {
        return (this.modelAccessor as ModelAccessor).getConnectionsBetweenNodes(firstNode, secondNode);
    }

    getRelationshipsBetweenElements(firstElement: ElementDto, secondElement: ElementDto) {
        return (this.modelAccessor as ModelAccessor).getRelationshipsBetweenElements(firstElement, secondElement);
    }

    getHiddenRelationshipsBetweenNodes(firstNode: IDiagramNodeDto, secondNode: IDiagramNodeDto) {
        return (this.modelAccessor as ModelAccessor).getHiddenRelationshipsBetweenNodes(firstNode, secondNode);
    }

    getHiddenRelationships() {
        return (this.modelAccessor as ModelAccessor).getHiddenRelationships();
    }

    async handleCreateItemsFromClipboardEvent(event: CreateItemsFromClipboardEvent) {
        if (event.type === ClipboardEventType.CREATE_ITEMS_FROM_CLIPBOARD) {
            const nodesToCreate: Array<IDiagramNodeDto> = [];
            const elementsToCreate: Array<ElementDto> = [];
            const connectionsToCreate: Array<IDiagramConnectionDto> = [];
            const relationshipsToCreate: Array<RelationshipDto> = [];

            const coordinatesShift = GeometryUtils.calculateCoordinatesShift(event.nodes.map(node => Point.of(node.x, node.y)), event.startPoint);
            for (const node of event.nodes) {
                try {
                    const elementToCreate = await this.buildElementToCreateIfNeeded(event.sameUrl, event.sameDiagram,
                        node.elementIdentifier, node.elementName, node.elementType, this.stereotypeDtoFactory.createStereotypeDto(
                            node.elementStereotypeId, node.elementStereotypeName, node.elementStereotypeElementType));
                    if (elementToCreate) {
                        elementsToCreate.push(elementToCreate);
                    }

                    const generatedIdentifier = await Api.editor.generateIdentifier(ObjectType.NODE)
                        .pipe(map(response => response.response as string))
                        .toPromise();
                    const elementIdentifier = elementToCreate ? elementToCreate.identifier : node.elementIdentifier;

                    const nodeToCreate: IDiagramNodeDto = {
                        identifier: generatedIdentifier,
                        elementIdentifier: elementIdentifier,
                        type: NodeType.ELEMENT,
                        x: node.x + coordinatesShift.x,
                        y: node.y + coordinatesShift.y,
                        w: node.w,
                        h: node.h,
                        style: this.styleDtoFactory.createStyleDto(node.lineColorR, node.lineColorG, node.lineColorB,
                            node.lineColorA, node.fillColorR, node.fillColorG, node.fillColorB, node.fillColorA, node.fontColorR,
                            node.fontColorG, node.fontColorB, node.fontColorA, node.fontName, node.fontSize, node.fontStyles)
                    };
                    nodesToCreate.push(nodeToCreate);
                } catch (error) {
                    if (error instanceof ValidationError && error.error.code === SubmodelExchangeErrorCodes.ELEMENTS_DO_NOT_MATCH) {
                        Snackbar.error(_transl(DiagramEditorTranslationKey.ELEMENTS_DO_NOT_MATCH));
                        return;
                    }
                }
            }

            const nodeToParentNodePositionMap = this.createNewNodeToParentNodePositionMap(nodesToCreate);
            this.createModelItems(nodesToCreate, nodeToParentNodePositionMap, elementsToCreate, connectionsToCreate,
                relationshipsToCreate, false);
        }
    }

    async buildElementToCreateIfNeeded(sameUrl: boolean, sameDiagram: boolean, elementIdentifier: string, elementName: string,
                                       elementType: string, elementStereotype: StereotypeDto | undefined): Promise<ElementDto | undefined> {
        let elementToCreate: ElementDto | undefined;

        if (sameUrl && sameDiagram) {
            const generatedIdentifier = await Api.editor.generateIdentifier(ObjectType.ELEMENT)
                .pipe(map(response => response.response as string))
                .toPromise();
            const name = "(Copy) " + elementName;
            elementToCreate = this.buildElementToCreate(generatedIdentifier, name, elementType, elementStereotype);
        } else {
            const foundedElement = await elementService.findElementById(elementIdentifier);
            if (foundedElement) {
                if (sameUrl) {
                    if (foundedElement.type !== elementType || foundedElement.stereotype !== elementStereotype) {
                        throw new ValidationError({code: SubmodelExchangeErrorCodes.ELEMENTS_DO_NOT_MATCH});
                    }
                }
                if (this.elementIsNotInModel(elementIdentifier)) {
                    elementToCreate = foundedElement;
                }
            } else {
                elementToCreate = this.buildElementToCreate(elementIdentifier, elementName, elementType, elementStereotype);
            }
        }
        return elementToCreate;
    }

    private buildElementToCreate(elementIdentifier: string, elementName: string, elementType: string,
                                 elementStereotype: StereotypeDto | undefined) {
        return ElementDto.builder(elementIdentifier, elementType)
            .name(elementName)
            .stereotype(elementStereotype)
            .acl(this.createTemporaryAcl())
            .build();
    }

    elementIsNotInModel(elementIdentifier: string): boolean {
      return this.getElements().find(value => value.identifier === elementIdentifier) === undefined;
    }

    async handleNodeCreateEvent(event: INodeCreateEvent) {
        if (event.type === EventType.NODE_CREATE) {
            let elementIdentifier: string | undefined;
            let elementStandardName: string | undefined;
            let isNewElement = false;
            var element: ElementDto | undefined;

            const nodesToCreate: Array<IDiagramNodeDto> = [];
            const elementsToCreate: Array<ElementDto> = [];
            let connectionsToCreate: Array<IDiagramConnectionDto> = [];
            const relationshipsToCreate: Array<RelationshipDto> = [];

            if (event.nodeType === NodeType.ELEMENT) {
                if (event.elementDefiniton.newElement) {
                    isNewElement = true;
                    const newElementDefinition = event.elementDefiniton.newElement;
                    elementIdentifier = newElementDefinition.id;
                    elementStandardName = newElementDefinition.elementStandardName;
                    const element = ElementDto.builder(elementIdentifier, elementStandardName).build();
                    element.acl = this.createTemporaryAcl();
                    element.stereotype = newElementDefinition.stereotype;
                    elementsToCreate.push(element);
                } else {
                    elementIdentifier = event.elementDefiniton.existingElement?.id as string;
                    element = this.getElementById(elementIdentifier);
                    elementStandardName = element.type;
                }
            }
            const nodeDimensions = this.nodeDimensionsExtractor.extractDimensions(elementStandardName);
            const node: IDiagramNodeDto = {
                identifier: event.nodeId,
                elementIdentifier: elementIdentifier,
                type: event.nodeType,
                x: event.diagramGroupPoint.x,
                y: event.diagramGroupPoint.y,
                w: nodeDimensions.width,
                h: nodeDimensions.height,
            };
            nodesToCreate.push(node);

            if (element) {
                const elements: Array<ElementDto> = [];
                elements.push(element);
                const handler = this.buildAddElementsToModelHandler();
                const existingRelationships = await handler.findRelationshipsToBeAdded(elements);
                connectionsToCreate = await handler.createConnectionsToBeAdded(existingRelationships, nodesToCreate);
            }

            const nodeToParentNodePositionMap = this.createNewNodeToParentNodePositionMap([node]);

            this.createModelItems(nodesToCreate, nodeToParentNodePositionMap, elementsToCreate, connectionsToCreate, relationshipsToCreate, isNewElement);
        }
    }

    private buildAddElementsToModelHandler(): AddElementsToModelHandler {
        const manager: AddElementsToModelManager = this.createAddElementsToModelManager();
        const service: AddElementsToModelService = this.createAddElementsToModelService();
        return new AddElementsToModelHandler(manager, service, store.getState().diagramDefaults);
    }

    // This is necessary for adding relationships between elements that have not yet been saved.
    private createTemporaryAcl() {
        const elementAcl = ElementAclDto.builder().build();
        elementAcl.canRead = true;
        elementAcl.canUpdate = true;
        elementAcl.canDelete = true;
        return elementAcl;
    }

    private createNewNodeToParentNodePositionMap(nodes: Array<IDiagramNodeDto>) {
        const nodeToParentNodePositionMap = new Map<string, ParentNodePosition>();
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            const parentNodePosition: ParentNodePosition = {
                parentNodeId: undefined,
                parentNodeChildrenIndex: (this.modelAccessor as ModelAccessor).getDiagram().nodes.length + i,
            }
            nodeToParentNodePositionMap.set(node.identifier, parentNodePosition);
        }
        return nodeToParentNodePositionMap;
    }

    private removeSelectedObjects(event: RemoveSelectedItemsEvent) {
        const manager = this.createRemoveSelectedObjectsManager();
        const handler = new RemoveSelectedObjectsHandler(manager);
        handler.removeSelectedObjects(event.nodes, event.connections, event.removeElementsAndRelationships);
    }

    private createRemoveSelectedObjectsManager(): RemoveSelectedObjectsManager {
        return {
            removeModelItems: this.removeModelItems.bind(this),
            createExistingNodeToParentNodePositionMap: this.createExistingNodeIdToParentNodePositionMap.bind(this),
            filterOutDuplicitItems: ArrayUtils.filterOutDuplicitIdentifiableItems,
            getChildNodesInclusive: this.getChildNodesInclusive.bind(this),
            getElementById: this.getElementById.bind(this),
            getRelationshipById: this.getRelationshipById.bind(this),
            getNodeConnections: this.getNodeConnections.bind(this),
            getHiddenConnections: this.getHiddenConnections.bind(this),
            getDiagramNodes: this.getDiagramNodes.bind(this),
            getDiagramConnections: this.getDiagramConnections.bind(this),
            showError: this.showError.bind(this),
        }
    }

    private createExistingNodeIdToParentNodePositionMap(nodes: Array<IDiagramNodeDto>): Map<string, ParentNodePosition> {
        const nodeToParentNodePositionMap = new Map();
        for (const node of nodes) {
            const parentNodePosition = this.createExistingNodeParentNodePosition(node);
            nodeToParentNodePositionMap.set(node.identifier, parentNodePosition);
        }
        return nodeToParentNodePositionMap;
    }

    private createExistingNodeParentNodePosition(node: IDiagramNodeDto) {
        const parentNode = (this.modelAccessor as ModelAccessor).getParentNode(node);
        let parentNodeChildrenIndex;
        if (parentNode) {
            parentNodeChildrenIndex = parentNode.childNodes?.indexOf(node) as number;
        } else {
            parentNodeChildrenIndex = (this.modelAccessor as ModelAccessor).getTopLevelDiagramNodes().indexOf(node);
        }
        const parentNodePosition: ParentNodePosition = {
            parentNodeId: parentNode?.identifier,
            parentNodeChildrenIndex: parentNodeChildrenIndex,
        }
        return parentNodePosition;
    }

    handleNodesMovedEvent(event: INodesMovedEvent) {
        if (event.type === EventType.NODES_MOVED) {
            const firstNodeAreaOld = this.createNodeDimensions(event.movedNodes[0]).area;
            const firstNodeAreaNew = event.dimensions[event.movedNodes[0].identifier];
            const bendpointsIncrement = this.resolveBendpointIncrement(firstNodeAreaOld, firstNodeAreaNew);

            // update node dimensions
            const nodeDimensionsNew = this.createNodesDimensions(event.movedNodes, event.parentNode, event.dimensions);
            const nodeParentsNew = this.createNodeParents(event.selectedNodes, event.parentNode);
            const connectionBendpointsNew = this.createConnectionBendpoints(event.movedNodes, event.selectedConnections, bendpointsIncrement);
            const connectionsToRemove = this.getConnectionsToRemoveForNodesAddedToParent(nodeParentsNew);

            const moveNodes = (connectionsToAdd: Array<IDiagramConnectionDto>) =>
                this.moveNodes(nodeDimensionsNew, nodeParentsNew, connectionBendpointsNew, connectionsToAdd, connectionsToRemove, event.parentNodeNewRelationships || [], []);

            this.createConnectionsForNodesRemovedFromItsParent(nodeParentsNew, moveNodes);
        }
    }

    handleNodesResizedEvent(event: INodesResizedEvent) {
        if (event.type === EventType.NODES_RESIZED) {
            // update nodes
            const nodeDimensionsNew = this.createNodesDimensions(event.nodes, undefined, event.dimensions);

            this.resizeNodes(nodeDimensionsNew, undefined, event.nodes)
        }
    }

    handleBendpointCreatedEvent(event: IBendpointCreatedEvent) {
        if (event.type === EventType.CONNECTION_BENDPOINT_CREATED) {
            const newBendpoint = new Point(event.bendpoint[0], event.bendpoint[1]);

            this.createBendpoint(event.connection, event.bendpointIndex, newBendpoint);
        }
    }

    handleBendpointRemoveEvent(event: IBendpointRemoveEvent) {
        if (event.type === EventType.CONNECTION_BENDPOINT_REMOVE_REQUESTED) {
            this.removeBendpoint(event.connection, event.bendpointIndex);
        }
    }

    handleBendpointMovedEvent(event: IBendpointMovedEvent) {
        if (event.type === EventType.CONNECTION_BENDPOINT_MOVED) {
            const bendpointOld = (this.modelAccessor as ModelAccessor).getDiagramConnectionById(event.connection.identifier).bendpoints[event.bendpointIndex];
            const bendpointNew = this.createUpdatedBendpoint(event.connection.identifier, event.bendpointIndex, event.increment);

            this.moveBendpoint(event.connection.identifier, event.bendpointIndex, bendpointOld, bendpointNew);
        }
    }

    handleNodeLabelUpdateEvent(event: INodeLabelUpdateEvent) {
        if (event.type === EventType.NODE_LABEL_UPDATE) {
            const node = (this.modelAccessor as ModelAccessor).getDiagramNodeById(event.node.identifier);
            const oldLabel = DiagramEditorUtils.getNodeLabel(node, this);

            this.updateNodeLabel(node, oldLabel, event.label, event.isNodeCreateResult);
        }
    }

    handleConnectionLabelUpdateEvent(event: IConnectionLabelUpdateEvent) {
        if (event.type === EventType.CONNECTION_LABEL_UPDATE) {
            const connection = (this.modelAccessor as ModelAccessor).getDiagramConnectionById(event.connection.identifier);
            const oldLabel = DiagramEditorUtils.getConnectionLabel(connection, this);

            this.updateConnectionLabel(connection, oldLabel, event.label, event.isConnectionCreateResult);
        }
    }

    handleConnectionCreateEvent(event: IConnectionCreateEvent) {
        if (event.type === EventType.CONNECTION_CREATE) {
            const modelAccessor = this.modelAccessor as ModelAccessor;
            let isRelationshipNew: boolean;
            let relationship: RelationshipDto | undefined;
            const isNestedConnection = modelAccessor.getParentNode(event.firstNode) === event.secondNode ||
                modelAccessor.getParentNode(event.secondNode) === event.firstNode;
            if (event.connectionDefinition.existingRelationship != null) {
                isRelationshipNew = false;
                relationship = modelAccessor.getRelationshipById(event.connectionDefinition.existingRelationship.identifier) as RelationshipDto;
            } else {
                if (event.connectionDefinition.newRelationship) {
                    isRelationshipNew = true;
                    const newRelationship = event.connectionDefinition.newRelationship;
                    const sourceElement = modelAccessor.getElementById(event.firstNode.elementIdentifier as string);
                    const targetElement = modelAccessor.getElementById(event.secondNode.elementIdentifier as string);
                    relationship = this.createRelationship(
                        newRelationship.identifier, newRelationship.type, sourceElement, targetElement, newRelationship.accessType, newRelationship.directed)
                } else {
                    // connection without relationhip
                    isRelationshipNew = false;
                    relationship = undefined;
                }
            }

            const connection: IDiagramConnectionDto = {
                identifier: event.connectionDefinition.connectionIdentifier,
                type: relationship == null ? DiagramConnectionType.LINE : DiagramConnectionType.RELATIONSHIP,
                relationshipIdentifier: relationship?.identifier,
                sourceIdentifier: event.firstNode.identifier,
                targetIdentifier: event.secondNode.identifier,
                bendpoints: [],
            }

            this.createConnection(relationship, isRelationshipNew, connection, isNestedConnection);
        }
    }

    private createRelationship(identifier: string,
                               type: ArchimateRelationshipType,
                               sourceElement: ElementDto,
                               targetElement: ElementDto,
                               accessType?: ArchiMateAccessType,
                               directed?: boolean): RelationshipDto {
        return {
            identifier: identifier,
            type: ArchimateRelationship[type].standardNames[0],
            associationDirected: directed,
            accessType: accessType,
            source: {
                identifier: sourceElement.identifier,
                name: sourceElement.name as string,
                conceptType: ConnectableConceptType.ELEMENT,
            },
            target: {
                identifier: targetElement.identifier,
                name: targetElement.name as string,
                conceptType: ConnectableConceptType.ELEMENT,
            },
            acl: RelationshipAclDto.builder().build()
        };
    }

    private handleHiddenConnectionVisualizeEvent(event: HiddenConnectionVisualizeEvent) {
        if (this.modelAccessor) {
            Api.editor.generateIdentifier(ObjectType.CONNECTION)
                .subscribe({
                    next: response => {
                        const modelAccessor = this.modelAccessor as ModelAccessor;
                        const connectionIdentifier = response.response;
                        this.visualizeHiddenConnection(modelAccessor, event, connectionIdentifier);
                    }
                });
        }
    }

    private visualizeHiddenConnection(modelAccessor: ModelAccessor, event: HiddenConnectionVisualizeEvent, connectionIdentifier: any) {
        const hiddenConnectionId = event.connection.identifier;
        const sourceNode = modelAccessor.getDiagramNodeById(event.connection.sourceIdentifier);
        const targetNode = modelAccessor.getDiagramNodeById(event.connection.targetIdentifier);
        const relationship = modelAccessor.getRelationshipById(event.connection.relationshipIdentifier) as RelationshipDto;
        const newConnection = ModelManager.createConnection(connectionIdentifier, relationship, sourceNode, targetNode);
        this.createConnection(relationship, false, newConnection, false);

        this.eventManager.publishEvent<HiddenConnectionVisualizedEvent>({
            type: EventType.HIDDEN_CONNECTION_VISUALIZED,
            connectionId: newConnection.identifier,
            hiddenConnectionId: hiddenConnectionId,
        })
    }

    private handleAddElementsToModelEvent(event: AddElementsToModelEvent) {
        const handler = this.buildAddElementsToModelHandler();
        handler.addElementsToModel(event.elements);
    }

    private createAddElementsToModelManager(): AddElementsToModelManager {
        return {
            getElementById: this.getElementById.bind(this),
            createModelItems: this.createModelItems.bind(this),
            getDiagramNodes: this.getDiagramNodes.bind(this),
            getDiagramElements: this.getElements.bind(this),
            getDiagramConnections: this.getDiagramConnections.bind(this),
            getRelationshipById: this.getRelationshipById.bind(this),
            createNewNodeToParentNodePositionMap: this.createNewNodeToParentNodePositionMap.bind(this),
            showError: this.showError.bind(this),
        }
    }

    private createAddElementsToModelService(): AddElementsToModelService {
        return {
            generateIdentifiers: objectTypes => Api.editor.generateIdentifiers(objectTypes)
                .pipe(
                    map(response => response.response as Array<string>)
                ),
            findAllRelationshipsForElements: elementIds => forkJoin([
                Api.relationships.findRelationships(elementIds, RelationshipSearchType.SOURCE),
                Api.relationships.findRelationships(elementIds, RelationshipSearchType.TARGET),
            ]).pipe(
                map(responses => [...responses[0].response, ...responses[1].response])
            ),
        }
    }

    private async handleAddDiagramRefsRequestEvent(event: AddDiagramRefsRequestEvent) {
        try {
            const handler = new AddDiagramRefsRequestHandler(this.createAddElementsToModelService());
            const nodes = await handler.createDiagramRefNodes(event.diagrams, this.getDiagramNodes());
            const nodeToParentNodePositionMap = this.createNewNodeToParentNodePositionMap(nodes);
            this.createModelItems(nodes, nodeToParentNodePositionMap, [], [], [], false);
        } catch (error) {
            this.showError(_transl(TranslationKey.DIAGRAMS_DIAGRAMEDITOR_EDITOR_ADD_ELEMENTS_TO_MODEL_FAILED));
        }
    }

    private handleAlignNodesEvent(event: AlignNodesEvent) {
        const manager = this.createNodesAlignerManager();
        const aligner = new NodesAligner(manager);
        aligner.align(event.nodes, event.alignmentType, event.alignmentTarget);
    }

    private createNodesAlignerManager(): NodesAlignerManager {
        return {
            alignNodes: this.alignNodes.bind(this),
            createNodeDimensions: this.createNodeDimensions.bind(this),
            getChildNodesExclusive: this.getChildNodesExclusive.bind(this),
            getNodeIdToParentNodeIdMap: this.getNodeIdToParentNodeIdMap.bind(this),
            showError: this.showError.bind(this),
        }
    }

    public getNodeIdToParentNodeIdMap(nodes: Array<IDiagramNodeDto>) {
        const map = new Map<string, string | undefined>();
        for (const node of nodes) {
            map.set(node.identifier, this.modelAccessor?.getParentNode(node)?.identifier);
        }
        return map;
    }

    private alignNodes(oldNodeDimensions: Array<NodeDimensionsType>,
                       newNodeDimensions: Array<NodeDimensionsType>,
                       alignmentType: AlignmentType,
                       alignmentTarget: IDiagramNodeDto | undefined,
                       undoRedoType?: UndoRedoType) {
        newNodeDimensions.forEach(dim => this.updateNodeDimensions(dim.node.identifier, dim.area));
        const alignedNodes = newNodeDimensions.map(dim => this.getDiagramNodeById(dim.node.identifier));

        const eventType = EventType.MODEL_UPDATED_ON_NODES_ALIGNED;
        const eventPublisher = () => this.eventManager.publishEvent<ModelUpdatedOnNodesAlignedEvent>({
            type: eventType,
            alignmentType: alignmentType,
            alignmentTarget: alignmentTarget,
            nodes: alignedNodes,
            description: _transl(ModelManagerTranslationKey.ALIGNMENT_ELEMENTS),
            undo: () => this.alignNodes(newNodeDimensions, oldNodeDimensions, alignmentType, alignmentTarget, UndoRedoType.UNDO),
            redo: () => this.alignNodes(oldNodeDimensions, newNodeDimensions, alignmentType, alignmentTarget, UndoRedoType.REDO),
            undoRedoType: undoRedoType,
        });
        this.publishModelEvent(eventType, eventPublisher);
    }

    private handleUpdateNodesPositionEvent(event: UpdateNodesPositionEvent) {
        const manager = this.createNodesPositionUpdaterManager();
        const positionUpdater = new NodesPositionUpdater(manager);
        positionUpdater.updatePosition(event.nodes, event.direction);
    }

    private createNodesPositionUpdaterManager(): NodesPositionUpdaterManager {
        return {
            getNodeIdToParentNodeChildNodesMap: this.getNodeIdToParentNodeDirectChildNodesMap.bind(this),
            createExistingNodeIdToParentNodePositionMap: this.createExistingNodeIdToParentNodePositionMap.bind(this),
            updateNodePositions: this.updateNodePositions.bind(this),
        }
    }

    private updateNodePositions(nodes: Array<IDiagramNodeDto>,
                                oldNodePositions: Array<PositionUpdateType>,
                                newNodePositions: Array<PositionUpdateType>,
                                direction: PositionDirection,
                                undoRedoType?: UndoRedoType) {
        for (const newNodePosition of newNodePositions) {
            const node = this.getDiagramNodeById(newNodePosition.nodeId);
            this.modelAccessor?.updateNodePosition(node, newNodePosition.parentNodePosition);
        }
        this.publishModelUpdatedOnNodesPositionUpdatedEvent(direction, nodes, oldNodePositions, newNodePositions, undoRedoType);
    }

    private publishModelUpdatedOnNodesPositionUpdatedEvent(direction: PositionDirection, nodes: Array<IDiagramNodeDto>, oldNodePositions: Array<PositionUpdateType>, newNodePositions: Array<PositionUpdateType>, undoRedoType: UndoRedoType | undefined) {
        const undoRedoEventDescription = direction === PositionDirection.TO_BACK ?
            _transl(TranslationKey.DIAGRAMS_DIAGRAMEDITOR_EDITOR_MOVE_TO_FRONT_UNDO_REDO) :
            _transl(TranslationKey.DIAGRAMS_DIAGRAMEDITOR_EDITOR_MOVE_TO_BACK_UNDO_REDO);

        const eventType = EventType.MODEL_UPDATED_ON_NODES_POSITION_UPDATED;
        const eventPublisher = () => this.eventManager.publishEvent<ModelUpdatedOnNodesPositionUpdatedEvent>({
            type: eventType,
            nodes: nodes,
            oldNodePositions: oldNodePositions,
            newNodePositions: newNodePositions,
            direction: direction,
            description: undoRedoEventDescription,
            undo: () => this.updateNodePositions(nodes, newNodePositions, oldNodePositions, direction, UndoRedoType.UNDO),
            redo: () => this.updateNodePositions(nodes, oldNodePositions, newNodePositions, direction, UndoRedoType.REDO),
            undoRedoType: undoRedoType,
        });
        this.publishModelEvent(eventType, eventPublisher);
    }

    publishModelEvent(eventType: string, eventPublisher: () => void, undoRedoType?: UndoRedoType) {
        eventPublisher();

        this.eventManager.publishEvent<IModelUpdatedEvent>({
            type: EventType.MODEL_UPDATED,
            reasonType: eventType,
            model: this.modelAccessor!.getModel(),
            undoRedoType: undoRedoType,
        });
    }

    // EVENT LISTENER PRIVATE HANDLERS

    private createModelItems(nodesToAdd: Array<IDiagramNodeDto>,
                             nodeToParentNodePositionMap: Map<string, ParentNodePosition>,
                             elementsToAdd: Array<ElementDto>,
                             connectionsToAdd: Array<IDiagramConnectionDto>,
                             relationshipsToAdd: Array<RelationshipDto>,
                             isNewElement: boolean,
                             undoRedoType?: UndoRedoType) {
        this.addToModel(relationshipsToAdd, connectionsToAdd, elementsToAdd, nodesToAdd, nodeToParentNodePositionMap);

        const eventType = EventType.MODEL_UPDATED_ON_ITEMS_CREATED;
        const eventPublisher = () => this.eventManager.publishEvent<IModelUpdatedOnItemsCreatedEvent>({
            type: eventType,
            description: _transl(ModelManagerTranslationKey.CREATE_ITEMS_IN_MODEL),
            createdNodes: nodesToAdd,
            createdElements: elementsToAdd,
            createdConnections: connectionsToAdd,
            createdRelationships: relationshipsToAdd,
            isNewElement: isNewElement,
            undo: () => this.removeModelItems(nodesToAdd, nodeToParentNodePositionMap, elementsToAdd, connectionsToAdd, relationshipsToAdd, isNewElement, UndoRedoType.UNDO),
            redo: () => this.createModelItems(nodesToAdd, nodeToParentNodePositionMap, elementsToAdd, connectionsToAdd, relationshipsToAdd, isNewElement, UndoRedoType.REDO),
            undoRedoType: undoRedoType,
        });
        this.publishModelEvent(eventType, eventPublisher);
    }

    private addToModel(relationshipsToAdd: Array<RelationshipDto>, connectionsToAdd: Array<IDiagramConnectionDto>, elementsToAdd: Array<ElementDto>, nodesToAdd: Array<IDiagramNodeDto>, nodeToParentNodePositionMap: Map<string, ParentNodePosition>) {
        const modelAccessor = this.modelAccessor as ModelAccessor;
        for (const relationship of relationshipsToAdd) {
            modelAccessor.addRelationshipToModel(relationship);
        }
        for (const connection of connectionsToAdd) {
            modelAccessor.addConnectionToModel(connection);
        }
        for (const element of elementsToAdd) {
            modelAccessor.addElementToModel(element);
        }
        for (const node of nodesToAdd) {
            const parentNodePosition = nodeToParentNodePositionMap.get(node.identifier) as ParentNodePosition;
            modelAccessor.addNodeToModel(node, parentNodePosition.parentNodeId, parentNodePosition.parentNodeChildrenIndex)
        }
    }

    private removeModelItems(nodesToRemove: Array<IDiagramNodeDto>,
                             nodeToParentNodePositionMap: Map<string, ParentNodePosition>,
                             elementsToRemove: Array<ElementDto>,
                             connectionsToRemove: Array<IDiagramConnectionDto>,
                             relationshipsToRemove: Array<RelationshipDto>,
                             isNewElement: boolean,
                             undoRedoType?: UndoRedoType) {

        const modelAccessor = this.modelAccessor as ModelAccessor;

        relationshipsToRemove.forEach(relationship => modelAccessor.removeRelationshipFromModel(relationship));
        connectionsToRemove.forEach(connection => modelAccessor.removeConnectionFromModel(connection));
        elementsToRemove.forEach(element => modelAccessor.removeElementFromModel(element));
        nodesToRemove.forEach(node => modelAccessor.removeNodeFromModel(node));

        const eventType = EventType.MODEL_UPDATED_ON_ITEMS_REMOVED;
        const eventPublisher = () => this.eventManager.publishEvent<IModelUpdatedOnItemsRemovedEvent>(
            {
                type: eventType,
                removedNodes: nodesToRemove,
                removedElements: elementsToRemove,
                removedConnections: connectionsToRemove,
                removedRelationships: relationshipsToRemove,
                description: _transl(ModelManagerTranslationKey.REMOVE_ITEMS_IN_MODEL),
                undo: () => this.createModelItems(nodesToRemove, nodeToParentNodePositionMap, elementsToRemove, connectionsToRemove, relationshipsToRemove, isNewElement, UndoRedoType.UNDO),
                redo: () => this.removeModelItems(nodesToRemove, nodeToParentNodePositionMap, elementsToRemove, connectionsToRemove, relationshipsToRemove, isNewElement, UndoRedoType.REDO),
                undoRedoType: undoRedoType,
            });
        this.publishModelEvent(eventType, eventPublisher);
    }

    private moveNodes(nodeDimensionsNew: Array<NodeDimensionsType>,
                      nodeParentsNew: Array<NodeParentPositionType>,
                      connectionBendpointsNew: Array<ConnectionBendpointType>,
                      connectionsToAdd: Array<IDiagramConnectionDto>,
                      connectionsToRemove: Array<IDiagramConnectionDto>,
                      relationshipsToAdd: Array<RelationshipDto>,
                      relationshipsToRemove: Array<RelationshipDto>,
                      undoRedoType?: UndoRedoType) {

        const modelAccessor = this.modelAccessor as ModelAccessor;
        const nodeDimensionsOld: Array<NodeDimensionsType> = [];
        const nodeParentsOld: Array<NodeParentPositionType> = [];
        const connectionBendpointsOld: Array<ConnectionBendpointType> = [];

        for (const nodeDimension of nodeDimensionsNew) {
            const dimensionsOld = this.updateNodeDimensions(nodeDimension.node.identifier, nodeDimension.area);
            nodeDimensionsOld.push(dimensionsOld);
        }

        for (const nodeParent of nodeParentsNew) {
            const node = nodeParent.node;
            const parentOld = modelAccessor.getParentNode(node);
            const nodeParentOld: NodeParentPositionType = {
                node: node,
                parent: parentOld,
                index: this.getNodeIndexInParentChildren(node, parentOld),
            }
            nodeParentsOld.push(nodeParentOld);

            modelAccessor.updateNodeParentInModel(node, nodeParent.parent, nodeParent.index);
        }

        for (const connectionBendpoint of connectionBendpointsNew) {
            const connectionId = connectionBendpoint.connection.identifier;
            connectionBendpointsOld.push(this.createConnectionBendpoint(connectionId, connectionBendpoint.bendpointIndex));
            const connection = modelAccessor.getDiagramConnectionById(connectionId);

            connection.bendpoints[connectionBendpoint.bendpointIndex] = connectionBendpoint.bendpoint;
        }

        connectionsToAdd.forEach(connection => modelAccessor.addConnectionToModel(connection));
        connectionsToRemove.forEach(connection => modelAccessor.removeConnectionFromModel(connection));
        relationshipsToAdd.forEach(relationship => modelAccessor.addRelationshipToModel(relationship));
        relationshipsToRemove.forEach(relationship => modelAccessor.removeRelationshipFromModel(relationship));

        const eventType = EventType.MODEL_UPDATED_ON_NODES_MOVED;
        const eventPublisher = () => this.eventManager.publishEvent<IModelUpdatedOnNodesMovedEvent>({
            type: eventType,
            parentNode: nodeParentsNew[0].parent,
            nodeDimensionsOld: nodeDimensionsOld,
            nodeDimensionsNew: nodeDimensionsNew,
            connectionBendpointsOld: connectionBendpointsOld,
            connectionBendpointsNew: connectionBendpointsNew,
            connectionsToAdd: connectionsToAdd,
            connectionsToRemove: connectionsToRemove,
            description: _transl(ModelManagerTranslationKey.TRANSFER_OF_ELEMENTS),
            undo: () => this.moveNodes(nodeDimensionsOld, nodeParentsOld, connectionBendpointsOld, connectionsToRemove, connectionsToAdd, relationshipsToRemove, relationshipsToAdd, UndoRedoType.UNDO),
            redo: () => this.moveNodes(nodeDimensionsNew, nodeParentsNew, connectionBendpointsNew, connectionsToAdd, connectionsToRemove, relationshipsToAdd, relationshipsToRemove, UndoRedoType.REDO),
            undoRedoType: undoRedoType,
        });
        this.publishModelEvent(eventType, eventPublisher, undoRedoType);
    }

    private getNodeIndexInParentChildren(node: IDiagramNodeDto, parent: IDiagramNodeDto | undefined) {
        let index;
        if (parent) {
            if (parent.childNodes) {
                const nodeIndex = parent.childNodes.indexOf(node);
                if (nodeIndex !== -1) {
                    index = nodeIndex;
                } else {
                    index = parent.childNodes.length;
                }
            } else {
                index = 0;
            }
        } else {
            // no parent specified -> parent = diagram
            const diagramRootNodes = (this.modelAccessor as ModelAccessor).getDiagram().nodes;
            const nodeIndex = diagramRootNodes.indexOf(node);
            index = nodeIndex !== -1 ? nodeIndex : diagramRootNodes.length - 1;
        }
        return index;
    }

    private resizeNodes(nodeDimensionsNew: Array<NodeDimensionsType>, undoRedoType?: UndoRedoType, nodes?: IDiagramNodeDto[]) {
        const nodeDimensionsOld: Array<NodeDimensionsType> = [];

        nodeDimensionsNew.forEach(nodeDimension => {
            const dimensionsOld = this.updateNodeDimensions(nodeDimension.node.identifier, nodeDimension.area);
            nodeDimensionsOld.push(dimensionsOld);
        });

        const eventType = EventType.MODEL_UPDATED_ON_NODES_RESIZED;
        const eventPublisher = () => this.eventManager.publishEvent<IModelUpdatedOnNodesResizedEvent>({
            type: eventType,
            nodeDimensionsOld: nodeDimensionsOld,
            nodeDimensionsNew: nodeDimensionsNew,
            description: _transl(ModelManagerTranslationKey.CHANGE_SIZE_OF_ELEMENTS),
            nodes: nodes,
            undo: () => this.resizeNodes(nodeDimensionsOld, UndoRedoType.UNDO, nodes),
            redo: () => this.resizeNodes(nodeDimensionsNew, UndoRedoType.REDO, nodes),
            undoRedoType: undoRedoType,
        });
        this.publishModelEvent(eventType, eventPublisher);
    }

    private createBendpoint(connection: IDiagramConnectionDto, bendpointIndex: number, newBendpoint: Point, undoRedoType?: UndoRedoType) {
        const modelConnection = (this.modelAccessor as ModelAccessor).getDiagramConnectionById(connection.identifier);
        modelConnection.bendpoints.splice(bendpointIndex, 0, newBendpoint);

        const eventType = EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_CREATED;
        const eventPublisher = () => this.eventManager.publishEvent<IModelUpdatedOnBendpointCreatedEvent>({
            type: eventType,
            connection: modelConnection,
            bendpointIndex: bendpointIndex,
            bendpoint: [newBendpoint.x, newBendpoint.y],
            description: _transl(ModelManagerTranslationKey.CREATE_BENDPOINT),
            undo: () => this.removeBendpoint(connection, bendpointIndex, UndoRedoType.UNDO),
            redo: () => this.createBendpoint(connection, bendpointIndex, newBendpoint, UndoRedoType.REDO),
            undoRedoType: undoRedoType,
        });
        this.publishModelEvent(eventType, eventPublisher);
    }

    private removeBendpoint(connection: IDiagramConnectionDto, bendpointIndex: number, undoRedoType?: UndoRedoType) {
        const modelConnection = (this.modelAccessor as ModelAccessor).getDiagramConnectionById(connection.identifier);
        const removedBendpoint = modelConnection.bendpoints[bendpointIndex];
        modelConnection.bendpoints.splice(bendpointIndex, 1);

        const eventType = EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_REMOVED;
        const eventPublisher = () => this.eventManager.publishEvent<IModelUpdatedOnBendpointRemovedEvent>({
            type: eventType,
            connection: modelConnection,
            bendpointIndex: bendpointIndex,
            description: _transl(ModelManagerTranslationKey.REMOVE_BENDPOINT),
            undo: () => this.createBendpoint(connection, bendpointIndex, new Point(removedBendpoint.x, removedBendpoint.y), UndoRedoType.UNDO),
            redo: () => this.removeBendpoint(connection, bendpointIndex, UndoRedoType.REDO),
            undoRedoType: undoRedoType,
        });

        this.publishModelEvent(eventType, eventPublisher);
    }

    private moveBendpoint(connectionIdentifier: string, bendpointIndex: number, bendpointOld: IDiagramPoint, bendpointNew: IDiagramPoint, undoRedoType?: UndoRedoType) {
        const connection = (this.modelAccessor as ModelAccessor).getDiagramConnectionById(connectionIdentifier);
        connection.bendpoints[bendpointIndex] = bendpointNew;

        const eventType = EventType.MODEL_UPDATED_ON_CONNECTION_BENDPOINT_MOVED;
        const eventPublisher = () => this.eventManager.publishEvent<IModelUpdatedOnBendpointMovedEvent>({
            type: eventType,
            connection: connection,
            bendpointIndex: bendpointIndex,
            bendpointOld: bendpointOld,
            bendpointNew: bendpointNew,
            description: _transl(ModelManagerTranslationKey.TRANSFER_BENDPOINT),
            undo: () => this.moveBendpoint(connectionIdentifier, bendpointIndex, bendpointNew, bendpointOld, UndoRedoType.UNDO),
            redo: () => this.moveBendpoint(connectionIdentifier, bendpointIndex, bendpointOld, bendpointNew, UndoRedoType.REDO),
            undoRedoType: undoRedoType,
        });
        this.publishModelEvent(eventType, eventPublisher);
    }

    private updateNodeLabel(node: IDiagramNodeDto, labelOld: string, labelNew: string, isNodeCreateResult?: boolean, undoRedoType?: UndoRedoType) {
        DiagramEditorUtils.setNodeLabel(node, labelNew, this);

        const eventType = EventType.MODEL_UPDATED_ON_NODE_LABEL_UPDATE;
        const eventPublisher = () => this.eventManager.publishEvent<IModelUpdatedOnNodeLabelUpdateEvent>({
            type: EventType.MODEL_UPDATED_ON_NODE_LABEL_UPDATE,
            node: node,
            nodeLabelOld: labelOld,
            nodeLabelNew: labelNew,
            description: _transl(ModelManagerTranslationKey.CHANGE_TITLE),
            undo: () => this.updateNodeLabel(node, labelNew, labelOld, isNodeCreateResult, UndoRedoType.UNDO),
            redo: () => this.updateNodeLabel(node, labelOld, labelNew, isNodeCreateResult, UndoRedoType.REDO),
            isNodeCreateResult: isNodeCreateResult,
            undoRedoType: undoRedoType,
        });

        this.publishModelEvent(eventType, eventPublisher);
    }

    private updateConnectionLabel(connection: IDiagramConnectionDto, labelOld: string, labelNew: string, isConnectionCreateResult?: boolean, undoRedoType?: UndoRedoType) {
        DiagramEditorUtils.setConnectionLabel(connection, labelNew, this);

        const eventType = EventType.MODEL_UPDATED_ON_CONNECTION_LABEL_UPDATE;
        const eventPublisher = () => this.eventManager.publishEvent<IModelUpdatedOnConnectionLabelUpdateEvent>({
            type: EventType.MODEL_UPDATED_ON_CONNECTION_LABEL_UPDATE,
            connection: connection,
            connectionLabelOld: labelOld,
            connectionLabelNew: labelNew,
            description: _transl(ModelManagerTranslationKey.CHANGE_TITLE),
            undo: () => this.updateConnectionLabel(connection, labelNew, labelOld, isConnectionCreateResult, UndoRedoType.UNDO),
            redo: () => this.updateConnectionLabel(connection, labelOld, labelNew, isConnectionCreateResult, UndoRedoType.REDO),
            isConnectionCreateResult: isConnectionCreateResult,
            undoRedoType: undoRedoType,
        });

        this.publishModelEvent(eventType, eventPublisher);
    }

    private createConnection(relationship: RelationshipDto | undefined, isRelationshipNew: boolean, connection: IDiagramConnectionDto,
                             isNestedConnection: boolean, undoRedoType?: UndoRedoType) {
        if (isRelationshipNew && relationship) {
            (this.modelAccessor as ModelAccessor).addRelationshipToModel(relationship);
        }
        if (!isNestedConnection) {
            if (this.selfLoopBendpointAppender.isSelfLoop(connection)) {
                const sourceNode = this.modelAccessor!.getDiagramNodeById(connection.sourceIdentifier);
                connection = this.selfLoopBendpointAppender.addSelfLoopBendpoints(connection, sourceNode);
            }
            (this.modelAccessor as ModelAccessor).addConnectionToModel(connection);
        }
        const eventType = EventType.MODEL_UPDATED_ON_CONNECTION_CREATED;
        const eventPublisher = () => this.eventManager.publishEvent<IModelUpdatedOnConnectionCreatedEvent>({
            type: eventType,
            relationship: relationship,
            isRelationshipNew: isRelationshipNew,
            connection: connection,
            description: _transl(ModelManagerTranslationKey.CREATE_RELATIONSHIP),
            undo: () => this.removeConnection(relationship, isRelationshipNew, connection, isNestedConnection, UndoRedoType.UNDO),
            redo: () => this.createConnection(relationship, isRelationshipNew, connection, isNestedConnection, UndoRedoType.REDO),
            undoRedoType: undoRedoType,
            isNestedConnection: isNestedConnection,
        });
        this.publishModelEvent(eventType, eventPublisher);
    }

    private removeConnection(relationship: RelationshipDto | undefined, isRelationshipNew: boolean, connection: IDiagramConnectionDto,
                             isNestedConnection: boolean, undoRedoType?: UndoRedoType) {
        if (isRelationshipNew && relationship) {
            (this.modelAccessor as ModelAccessor).removeRelationshipFromModel(relationship);
        }
        if (!isNestedConnection) {
            (this.modelAccessor as ModelAccessor).removeConnectionFromModel(connection);
        }

        const eventType = EventType.MODEL_UPDATED_ON_CONNECTION_REMOVED;
        const eventPublisher = () => this.eventManager.publishEvent<IModelUpdatedOnConnectionRemovedEvent>({
            type: eventType,
            relationship: relationship,
            isRelationshipNew: isRelationshipNew,
            connection: connection,
            description: _transl(ModelManagerTranslationKey.REMOVE_RELATIONSHIP),
            undo: () => this.removeConnection(relationship, isRelationshipNew, connection, isNestedConnection, UndoRedoType.UNDO),
            redo: () => this.createConnection(relationship, isRelationshipNew, connection, isNestedConnection, UndoRedoType.REDO),
            undoRedoType: undoRedoType,
        });
        this.publishModelEvent(eventType, eventPublisher);
    }

    // PRIVATE METHODS

    private createNodeParents(selectedNodes: Array<IDiagramNodeDto>, parentNodeNew: IDiagramNodeDto | undefined): Array<NodeParentPositionType> {
        const topLevelSelectedNodes = this.filterTopLevelNodes(selectedNodes);
        return topLevelSelectedNodes.map(node => {
            return {
                node: node,
                parent: parentNodeNew,
                index: this.getNodeIndexInParentChildren(node, parentNodeNew),
            }
        });
    }

    private createNodesDimensions(nodes: Array<IDiagramNodeDto>, parentNode: IDiagramNodeDto | undefined, newDimensionByNodeId?: {[id: string]: Area}): Array<NodeDimensionsType> {
        const dimensions = nodes.map(node => this.createNodeDimensions(node, newDimensionByNodeId ? newDimensionByNodeId[node.identifier] : undefined));
        if (parentNode) {
            const parentAreaNew = new Area(parentNode.x, parentNode.y, parentNode.w, parentNode.h);
            dimensions.forEach(nodeDimensions => {
                const childArea = nodeDimensions.area;
                if (childArea.x < parentAreaNew.x) {
                    parentAreaNew.x = childArea.x;
                }
                if (childArea.y < parentAreaNew.y) {
                    parentAreaNew.y = childArea.y;
                }
                if (childArea.x + childArea.w > parentAreaNew.x + parentAreaNew.w) {
                    parentAreaNew.w += (childArea.x + childArea.w) - (parentAreaNew.x + parentAreaNew.w);
                }
                if (childArea.y + childArea.h > parentAreaNew.y + parentAreaNew.h) {
                    parentAreaNew.h += (childArea.y + childArea.h) - (parentAreaNew.y + parentAreaNew.h);
                }
            });
            const parentAreaOld = new Area(parentNode.x, parentNode.y, parentNode.w, parentNode.h);
            if (parentAreaOld.x !== parentAreaNew.x || parentAreaOld.y !== parentAreaNew.y || parentAreaOld.w !== parentAreaNew.w || parentAreaOld.h !== parentAreaNew.h) {
                dimensions.push({
                    node: parentNode,
                    area: parentAreaNew,
                })
            }
        }
        return dimensions;
    }

    private createNodeDimensions(node: IDiagramNodeDto, newDimensions?: Area): NodeDimensionsType {
        return {
            node: node,
            area: newDimensions ? newDimensions : new Area(node.x, node.y, node.w, node.h),
        }
    }

    private createConnectionBendpoints(nodes: Array<IDiagramNodeDto>, selectedConnections: Array<IDiagramConnectionDto>, increment: PointIncrement): Array<ConnectionBendpointType> {
        return this.resolveBendpointsToBeMoved(nodes, selectedConnections)
            .map(bendpointToBeMoved =>
                this.createConnectionBendpoint(
                    bendpointToBeMoved.connection.identifier,
                    bendpointToBeMoved.bendpointIndex,
                    increment)
            );
    }

    private createConnectionBendpoint(connectionId: string, bendpointIndex: number, increment?: PointIncrement): ConnectionBendpointType {
        const modelConnection = (this.modelAccessor as ModelAccessor).getDiagramConnectionById(connectionId);
        const bendPoint = modelConnection.bendpoints[bendpointIndex];
        return {
            connection: modelConnection,
            bendpointIndex: bendpointIndex,
            bendpoint: {
                x: bendPoint.x + (increment ? increment.incrementX : 0),
                y: bendPoint.y + (increment ? increment.incrementY : 0),
            }
        }
    }

    private createUpdatedBendpoint(connectionId: string, bendpointIndex: number, increment: PointIncrement) {
        const modelConnection = (this.modelAccessor as ModelAccessor).getDiagramConnectionById(connectionId);
        const bendPointOld = modelConnection.bendpoints[bendpointIndex];
        const bendPointNew: IDiagramPoint = {
            x: bendPointOld.x + increment.incrementX,
            y: bendPointOld.y + increment.incrementY,
        }
        return bendPointNew;
    }

    private updateNodeDimensions(nodeIdentifier: string, newDimensions: Area) {
        const node = (this.modelAccessor as ModelAccessor).getDiagramNodeById(nodeIdentifier);
        const oldDimensions: NodeDimensionsType = this.createNodeDimensions(node);

        node.x = newDimensions.x;
        node.y = newDimensions.y;
        node.w = newDimensions.w;
        node.h = newDimensions.h;

        return oldDimensions;
    }

    private resolveBendpointIncrement(nodeDimensionsOld: Area, nodeDimensionsNew: Area): PointIncrement {
        const x = nodeDimensionsNew.x - nodeDimensionsOld.x;
        const y = nodeDimensionsNew.y - nodeDimensionsOld.y;
        return new PointIncrement(x, y);
    }

    private resolveBendpointsToBeMoved(nodes: Array<IDiagramNodeDto>, selectedConnections: Array<IDiagramConnectionDto>): Array<ConnectionBendpoint> {
        const bendpoints = new Array<ConnectionBendpoint>();

        // store all moved node ids into a map
        const movedNodeIds: any = {};
        nodes.forEach(node => movedNodeIds[node.identifier] = true);

        const selectedConnectionsIds: any = {};
        selectedConnections.forEach(connection => selectedConnectionsIds[connection.identifier] = true);

        const allConnections = nodes.flatMap((node) => (this.modelAccessor as ModelAccessor).getNodeConnections(node));
        const processedConnectionIds = new Array<string>();
        allConnections.forEach(connection => {
            if (processedConnectionIds.indexOf(connection.identifier) === -1) {
                // process every connection exactly once
                processedConnectionIds.push(connection.identifier);

                const isSourceMoved = movedNodeIds[connection.sourceIdentifier] === true;
                const isTargetMoved = movedNodeIds[connection.targetIdentifier] === true;
                const isConnectionSelected = selectedConnectionsIds[connection.identifier] === true;

                if ((isSourceMoved && isTargetMoved) || isConnectionSelected) {
                    // move all bendpoints
                    if (connection.bendpoints) {
                        connection.bendpoints.forEach((bendpoint, index) => bendpoints.push({
                            connection: connection,
                            bendpointIndex: index
                        }));
                    }
                } else if (isSourceMoved) {
                    // move first bendpoint in bendpoints array
                    if (connection.bendpoints && connection.bendpoints.length > 0) {
                        bendpoints.push({connection: connection, bendpointIndex: 0})
                    }
                } else if (isTargetMoved) {
                    // move last bendpoint in bendpoints array
                    if (connection.bendpoints && connection.bendpoints.length > 0) {
                        bendpoints.push({connection: connection, bendpointIndex: connection.bendpoints.length - 1});
                    }
                }
            }
        });

        return bendpoints;
    }

    private filterTopLevelNodes(selectedNodes: Array<IDiagramNodeDto>) {
        const childNodes = new Array<IDiagramNodeDto>();
        selectedNodes.forEach(selectedNode => {
            const nodeChildNodes = (this.modelAccessor as ModelAccessor).getChildNodesInclusive(selectedNode)
                .filter(node => node !== selectedNode)
                .filter(node => selectedNodes.indexOf(node) !== -1);

            childNodes.push(...nodeChildNodes);
        });
        return selectedNodes.filter(selectedNode => childNodes.indexOf(selectedNode) === -1);
    }

    private createConnectionsForNodesRemovedFromItsParent(nodeParentsNew: Array<NodeParentPositionType>, successCallback: (connections: Array<IDiagramConnectionDto>) => void) {
        const connectionsNew: Array<IDiagramConnectionDto> = [];
        nodeParentsNew.forEach(nodeParentNew => {
            const node = nodeParentNew.node;
            const parentNodeNew = nodeParentNew.parent;
            const parentNodeOld = (this.modelAccessor as ModelAccessor).getParentNode(node);
            if (parentNodeNew !== parentNodeOld) {
                const relationships: Array<RelationshipDto> = this.getRelationshipsOfTypeWithoutConnection(node, parentNodeOld, PARENT_CHILD_RELATIONSHIP_TYPES);
                const connections = relationships.map(relationship => {
                    const con: IDiagramConnectionDto = {
                        identifier: "",
                        type: DiagramConnectionType.RELATIONSHIP,
                        bendpoints: [],
                        relationshipIdentifier: relationship.identifier,
                        sourceIdentifier: relationship.source.identifier === node.elementIdentifier ? node.identifier : parentNodeOld?.identifier as string,
                        targetIdentifier: relationship.target.identifier === node.elementIdentifier ? node.identifier : parentNodeOld?.identifier as string,
                    }
                    return con;
                });
                connectionsNew.push(...connections);
            }
        });
        if (connectionsNew.length > 0) {
            (this.mode as IEditMode).diagramApi.generateIdentifiers(
                connectionsNew.map(node => ObjectType.CONNECTION),
                (ids) => {
                    successCallback(connectionsNew.map((connection, index) => {
                        connection.identifier = ids[index];
                        return connection;
                    }));
                },
                () => {}
            );
        } else {
            successCallback([]);
        }
    }

    private getRelationshipsOfTypeWithoutConnection(node: IDiagramNodeDto, parentNodeOld: IDiagramNodeDto | undefined, relationhipTypes: ArchimateRelationshipType[]) {
        const relationships: Array<RelationshipDto> = [];
        if (node.elementIdentifier && parentNodeOld?.elementIdentifier) {
            const nodeElement = (this.modelAccessor as ModelAccessor).getElementById(node.elementIdentifier);
            const parentOldElement = (this.modelAccessor as ModelAccessor).getElementById(parentNodeOld.elementIdentifier);

            const allRelationships = (this.modelAccessor as ModelAccessor).getRelationshipsBetweenElements(nodeElement, parentOldElement);
            const allConnectionRelationshipIds = (this.modelAccessor as ModelAccessor).getConnectionsBetweenNodes(node, parentNodeOld)
                .filter(connection => connection.relationshipIdentifier != null)
                .map(connection => connection.relationshipIdentifier as string);

            const relationshipsToVisualize = allRelationships
                .filter(relationship => allConnectionRelationshipIds.indexOf(relationship.identifier) === -1);

            relationships.push(...relationshipsToVisualize);
        }
        return relationships;
    }

    private getConnectionsToRemoveForNodesAddedToParent(nodeParentsNew: Array<NodeParentPositionType>) {
        return nodeParentsNew
            .flatMap(nodeParentNew => {
                const connections = nodeParentNew.parent != null ? (this.modelAccessor as ModelAccessor).getConnectionsBetweenNodes(nodeParentNew.node, nodeParentNew.parent) : [];
                return connections
                    .filter(connection => {
                        const relationship = connection.relationshipIdentifier != null ? (this.modelAccessor as ModelAccessor).getRelationshipById(connection.relationshipIdentifier) : null;
                        return relationship != null;
                    })
            })
    }

    getDiagramNodesByElementIds(elementIds: Array<string>) {
        if (this.modelAccessor) {
            return this.getDiagramNodes()
                .filter(node => node.elementIdentifier && elementIds.indexOf(node.elementIdentifier) !== -1);
        }
        return [];
    }

    getDiagramConnectionsByRelationshipIds(relationshipIds: Array<string>) {
        if (this.modelAccessor) {
            return this.getDiagramConnections()
                .filter(connection => connection.relationshipIdentifier && relationshipIds.indexOf(connection.relationshipIdentifier) !== -1);
        }
        return [];
    }

    getHiddenConnections() {
        if (this.modelAccessor) {
            return this.getHiddenRelationships().flatMap((relationship) => this.createHiddenConnectionsForRelationship(relationship))
        } else {
            return [];
        }
    }

    getFilteredHiddenConnections(predicate: ConnectionPredicate) {
        if (this.modelAccessor) {
            return this.getHiddenConnections()
                .filter(connection => {
                    const modelAccessor = this.modelAccessor as ModelAccessor;
                    const sourceNode = modelAccessor.getDiagramNodeById(connection.sourceIdentifier);
                    const targetNode = modelAccessor.getDiagramNodeById(connection.targetIdentifier);
                    return predicate.test(connection, sourceNode, targetNode);
                });
        } else {
            return [];
        }
    }

    private createHiddenConnectionsForRelationship(relationship: RelationshipDto) {
        const connections: Array<IDiagramConnectionDto> = [];
        const sourceNodes = this.getDiagramNodesByElementIds([relationship.source.identifier]);
        const targetNodes = this.getDiagramNodesByElementIds([relationship.target.identifier]);
        for (const sourceNode of sourceNodes) {
            for (const targetNode of targetNodes) {
                connections.push(ModelManager.createHiddenConnection(relationship, sourceNode, targetNode));
            }
        }
        return connections;
    }

    private static createHiddenConnection(relationship: RelationshipDto,
                                          sourceNode: IDiagramNodeDto,
                                          targetNode: IDiagramNodeDto): IDiagramConnectionDto {
        const tempIdentifier = `${sourceNode.identifier}-${targetNode.identifier}`;
        return ModelManager.createConnection(tempIdentifier, relationship, sourceNode, targetNode);
    }

    private static createConnection(identifier: string,
                                    relationship: RelationshipDto,
                                    sourceNode: IDiagramNodeDto,
                                    targetNode: IDiagramNodeDto): IDiagramConnectionDto {
        return {
            identifier: identifier,
            relationshipIdentifier: relationship.identifier,
            type: DiagramConnectionType.RELATIONSHIP,
            bendpoints: [],
            sourceIdentifier: sourceNode.identifier,
            targetIdentifier: targetNode.identifier,
        }
    }

    private showError(text: string) {
        if (this.mode?.mode === RenderMode.EDIT) {
            (this.mode as IEditMode).diagramApi.showErrorDialog(text);
        }
    }

    public getNodeIdToParentNodeDirectChildNodesMap(nodes: Array<IDiagramNodeDto>) {
        const map = new Map<string, NodeChildNodes>();
        for (const node of nodes) {
            let childNodes = this.getParentNodeDirectChildNodes(node);
            map.set(node.identifier, childNodes);
        }
        return map;
    }

    private getParentNodeDirectChildNodes(node: IDiagramNodeDto): NodeChildNodes {
        const parentNode = this.getParentNode(node);
        if (parentNode !== undefined) {
            return {
                nodeId: parentNode.identifier,
                childNodes: parentNode.childNodes as Array<IDiagramNodeDto>,
            }
        } else {
            return {
                nodeId: undefined,
                childNodes: (this.modelAccessor as ModelAccessor).getTopLevelDiagramNodes(),
            }
        }
    }

    public createNodeIdToChildNodesMap(nodes: Array<IDiagramNodeDto>): Map<string, Array<IDiagramNodeDto>> {
        const map = new Map<string, Array<IDiagramNodeDto>>();
        for (const node of nodes) {
            map.set(node.identifier, this.getChildNodesExclusive(node));
        }
        return map;
    }

    public updateStyles(nodeUpdates: StyleUpdate[],
                        connectionUpdates: StyleUpdate[],
                        undoRedoType?: UndoRedoType) {
        nodeUpdates.forEach(update => this.updateNodeStyle(update));
        connectionUpdates.forEach(update => this.updateConnectionStyle(update));
        this.publishStylesUpdatedEvent(nodeUpdates, connectionUpdates, undoRedoType);
    }

    private updateNodeStyle(styleUpdate: StyleUpdate) {
        const node = this.getDiagramNodeById(styleUpdate.id);
        if (node) {
            node.style = styleUpdate.newStyle;
        }
    }

    private updateConnectionStyle(styleUpdate: StyleUpdate) {
        const connection = this.getDiagramConnectionById(styleUpdate.id);
        if (connection) {
            connection.style = styleUpdate.newStyle;
        }
    }

    private publishStylesUpdatedEvent(nodeUpdates: StyleUpdate[],
                                      connectionUpdates: StyleUpdate[],
                                      undoRedoType?: UndoRedoType) {
        const nodes = nodeUpdates.map(update => this.getDiagramNodeById(update.id));
        const nodeUpdatesForUndo = nodeUpdates.map(this.swapUpdateForUndo);
        const connections = connectionUpdates.map(update => this.getDiagramConnectionById(update.id));
        const connectionUpdatesForUndo = connectionUpdates.map(this.swapUpdateForUndo);

        const event = {
            type: StyleEventType.STYLES_UPDATED,
            nodes: nodes,
            connections: connections,
            description: _transl(TranslationKey.DIAGRAMS_EDITOR_UPDATE_STYLES),
            undo: () => this.updateStyles(nodeUpdatesForUndo, connectionUpdatesForUndo, UndoRedoType.UNDO),
            redo: () => this.updateStyles(nodeUpdates, connectionUpdates, UndoRedoType.REDO),
            undoRedoType: undoRedoType,
        };
        this.eventManager.publishEvent<StylesUpdatedEvent>(event);
        this.publishModelEvent(event.type, () => {});
    }

    private swapUpdateForUndo(update: StyleUpdate): StyleUpdate {
        return {
            id: update.id,
            oldStyle: update.newStyle,
            newStyle: update.oldStyle
        };
    }

    public highlightNodes(nodeIds: string[]): DiagramNode[] {
        const nodes = nodeIds.map(id => this.getDiagramNodeById(id))
                .filter(node => node != null);
        nodes.forEach(node => this.highlightNode(node));
        return nodes;
    }

    private highlightNode(node: DiagramNode) {
        node.highlighted = true;
    }

    public unhighlightNodes(): DiagramNode[] {
        const highlightedNodes = this.getDiagramNodes()
            .filter(node => node.highlighted);
        highlightedNodes.forEach(node => this.unhighlightNode(node));
        return highlightedNodes;
    }

    private unhighlightNode(node: DiagramNode) {
        node.highlighted = false;
    }

}
