import {StereotypeDto} from "./StereotypeDto";

export class StereotypeDtoFactory {

    createStereotypeDto(elementStereotypeId: string | undefined, elementStereotypeName: string | undefined,
                        elementStereotypeElementType: string | undefined): StereotypeDto | undefined {
        if (elementStereotypeId && elementStereotypeName && elementStereotypeElementType) {
            return {id: elementStereotypeId, name: elementStereotypeName, elementType: elementStereotypeElementType};
        } else {
            return undefined;
        }
    }

}

