import {ExtractRouteParams, RouteComponentProps} from "react-router";
import RouteDefinition from "./RouteDefinition";

export default class ParamsExtractor {

    public static extract<T>(params: RouteComponentProps<ExtractRouteParams<string, string>>, definition: RouteDefinition<T>): T {
        let extracted = {};
        if (definition != null) {
            const variableNames = Object.keys(definition.paramsTemplate);
            if (variableNames.length > 0) {
                const obj = {};
                variableNames.forEach(name => {
                    // @ts-ignore
                    obj[name] = params.match.params[name];
                });
                extracted = obj;
            }
        }
        return extracted as T;
    }

}