import * as React from "react";
import {RouteComponentProps, Switch} from "react-router-dom";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../store/Store";
import {StringNullable} from "../Types";
import RouteDefinitionUtils from "../routedefinition/RouteDefinitionUtils";
import RouteDefinitionType from "../routedefinition/RouteDefinitionType";

interface IProps {
    token: StringNullable,
}

type RoutesProps = RouteComponentProps & IProps;

export default function AppRoutes(props: RoutesProps) {
    const user = useSelector((application: IApplicationState) => application.user.userData);

    return (
        <div>
            <Switch>
                {
                    RouteDefinitionUtils.getRoutesByType(RouteDefinitionType.APPLICATION, user)
                }
            </Switch>
        </div>
    );
}