import React, {useState} from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {MenuItem, Paper, Select, Step, StepContent, StepLabel, Stepper} from '@mui/material';
import CommonCssStyles from "../../../../css/CommonCssStyles";
import {Configuration, ConfigurationId} from "./Configuration";
import Button from "@mui/material/Button";
import clsx from "clsx";
import RouteDefinitionUtils, {
    exportPage,
    INITIAL_CONFIGURATION_ID_PARAM
} from "../../../../common/routedefinition/RouteDefinitionUtils";
import {ExportConfigurationNullable} from "../../../../common/Types";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ExportTranslationKey} from "./ExportTranslationKey";
import {useHistory} from "react-router-dom";

const INITIAL_STEP = 0;
const CONFIGURATION_STEP = 1;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: CommonCssStyles.getRootPageStyles(theme),
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme, {paddingTop: theme.spacing(7)}),
        mainWizardSegment: CommonCssStyles.getControlPageSegmentStyles(theme, {paddingTop: theme.spacing(5), paddingBottom: theme.spacing(2)}),
        exportTypeSelect: {
            minWidth: "12em",
        },
        exportTypeBlock: {
            "& .MuiButton-label": {
                fontSize: "0.7em",
            },
            display: "inline-block",
            fontSize: "0.9em",
            marginLeft: theme.spacing(2),
        },
        exportTypeDiv: {
            paddingTop: theme.spacing(2),
        },
        stepperPage: {
            display: "flex",
        },
        stepperDiv: {
            "& .MuiStepper-root": {
                padding: 0,
                borderRight: "1px solid lightgray",
            },
            display: "inline-block",
            minWidth: "23em",
        },
        stepContentDiv: {
            display: "inline-block",
            paddingLeft: theme.spacing(CommonCssStyles.PAGE_SEGMENT_PADDING),
            flexGrow: 1,
        }
    })
);

interface Props {
    [INITIAL_CONFIGURATION_ID_PARAM]?: ConfigurationId,
}

export default function ExportPage(props: Props) {

    const configurations = Configuration.getConfigurations();
    const classes = useStyles();

    // STATE
    const [selectedConfiguration, setSelectedConfiguration] = useState<ExportConfigurationNullable>(getInitialConfiguration());
    const [activeStep, setActiveStep] = useState<number>(selectedConfiguration ? CONFIGURATION_STEP : INITIAL_STEP);

    // HISTORY
    const history = useHistory();

    function getInitialConfiguration(): ExportConfigurationNullable {
        const initialConfigurationId = props[INITIAL_CONFIGURATION_ID_PARAM];
        return initialConfigurationId ? Configuration.findById(configurations, initialConfigurationId) : null;
    }

    function updateSelectedConfiguration(configurationId: number) {
        const configuration: ExportConfigurationNullable = configurationId === INITIAL_STEP ? null : configurations[configurationId - 1];
        const activeStep = configuration !== null ? CONFIGURATION_STEP : INITIAL_STEP;

        setSelectedConfiguration(configuration);
        setActiveStep(activeStep);
    }

    function createConfigurationId(index: number): number {
        return index + 1;
    }

    function resetExport() {
        const path = RouteDefinitionUtils.resolvePath(exportPage, {});
        history.push(path);
    }

    const pageTitle = selectedConfiguration != null ? _transl(selectedConfiguration.pageLabelKey) : _transl(ExportTranslationKey.TITLE);

    return (
        <Paper className={classes.paper}>
            <div className={classes.headerPageSegment}>
                <Typography variant="h6">
                    {pageTitle}
                </Typography>
            </div>
            <Divider />
            <div className={clsx(classes.controlPageSegment, classes.stepperPage)}>
                <div className={classes.stepperDiv}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        <Step key={_transl(ExportTranslationKey.TYPE_LABEL)}>
                            <StepLabel>
                                {_transl(ExportTranslationKey.TYPE_LABEL)}
                            </StepLabel>
                            <StepContent>
                                {activeStep === INITIAL_STEP && <div>
                                    <Typography style={{fontSize: "0.9em"}}>{_transl(ExportTranslationKey.TYPE_DESCRIPTION)}</Typography>
                                    <div className={classes.exportTypeDiv}>
                                        <Select
                                            id="export-type-select"
                                            value={INITIAL_STEP}
                                            className={classes.exportTypeSelect}
                                            onChange={(event) => updateSelectedConfiguration(event.target.value as number)}
                                        >
                                            <MenuItem value={INITIAL_STEP} key={"initial_step"}>
                                                <span>&nbsp;</span>
                                            </MenuItem>
                                            {
                                                Configuration.getConfigurations().map((config, index) => <MenuItem value={createConfigurationId(index)} key={config.label}>{config.label}</MenuItem>)
                                            }
                                        </Select>
                                    </div>
                                </div>}
                            </StepContent>
                        </Step>
                        <Step key={_transl(ExportTranslationKey.EXPORT_CONFIGURATION_LABEL)}>
                            <StepLabel>
                                {_transl(ExportTranslationKey.EXPORT_CONFIGURATION_LABEL)}
                            </StepLabel>
                            <StepContent>
                                    <span>
                                        <Typography style={{fontSize: "0.9em"}} display={'inline'}>{pageTitle}</Typography>
                                        <span className={classes.exportTypeBlock}>
                                            <Button variant={"contained"}
                                                    size={"small"}
                                                    style={{fontSize: "inherit"}}
                                                    color={"primary"}
                                                    onClick={() => updateSelectedConfiguration(INITIAL_STEP)}>{_transl(ExportTranslationKey.CHANGE_EXPORT_TYPE)}</Button>
                                        </span>
                                    </span>
                            </StepContent>
                        </Step>
                    </Stepper>
                </div>
                <div className={classes.stepContentDiv}>
                    {activeStep !== INITIAL_STEP && <div>
                        {selectedConfiguration?.getWizard(() => resetExport())}
                    </div>}
                </div>
            </div>
        </Paper>
    );
}
