import React, {useState} from "react";
import TextField from "../textfield/TextField";
import {MobileDatePicker} from "@mui/x-date-pickers";
import {MobileDatePickerProps} from "@mui/x-date-pickers/MobileDatePicker/MobileDatePicker";

export type DatePickerProps = Omit<MobileDatePickerProps<Date, Date>, "renderInput"> & {
    id?: string,
    value: Date | null,
    onChange: (date: Date) => void,
    readOnly?: boolean,
}

export default function DatePicker(props: DatePickerProps) {
    const {id, value, onChange, label, readOnly, ...rest} = props;
    const [date, setDate] = useState<Date | null>(value);

    const onDateSelected = (newDate: any) => {
        setDate(newDate);
        onChange(newDate);
    }

    return <MobileDatePicker
        label={label}
        disabled={readOnly}
        closeOnSelect={true}
        componentsProps={{
            actionBar: {
                actions: [],
            }
        }}
        inputFormat={"DD.MM.yyyy"}
        value={date || null}
        onChange={(newDate) => onDateSelected(newDate)}
        {...rest}

        renderInput={(textFieldProps: any) =>
            <TextField
                {...textFieldProps}
                id={id}
                value={date}
                InputProps={{
                    readOnly: readOnly,
                }}
                onChange={(newDate) => onDateSelected(newDate)}
                clearable
            />
        }
    />
}
