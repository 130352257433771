import {JSXElementNullable} from "../Types";
import RouteDefinition from "../routedefinition/RouteDefinition";

export default class MenuDefinition<T> {

    constructor(private _label: string,
                private _icon: JSXElementNullable,
                private _routeDefinition: () => RouteDefinition<T>) {
    }

    public get label() {
        return this._label;
    }

    public get icon() {
        return this._icon;
    }

    public get routeId() {
        return this._routeDefinition().id;
    }

    public get route() {
        return this._routeDefinition();
    }

    public get path() {
        return this._routeDefinition().path;
    }

}