import configurationService from "../configuration/ConfigurationService";
import {map} from "rxjs/operators";
import Keycloak from "keycloak-js";
import {KeycloakHolder} from "./KeycloakHolder";
import {fromPromise} from "rxjs/internal-compatibility";

interface KeycloakConfig {
    realm: string,
    url: string,
    clientId: string,
    enableLogging: boolean,
    "enable-pkce": boolean,
}

export interface KeycloakService {
    fetchKeycloakConfig(): Promise<KeycloakConfig>,
    doKeycloakLogin(config: KeycloakConfig): Promise<boolean>,
    getTokenExpiration(): number | undefined
}

class KeycloakServiceImpl implements KeycloakService {

    async fetchKeycloakConfig(): Promise<KeycloakConfig> {
        return configurationService.getKeycloakConfig()
            .then((responseConfig => {
                    const config: KeycloakConfig = {
                        realm: responseConfig.realm,
                        url: responseConfig.url.trim(),
                        clientId: responseConfig.clientId,
                        enableLogging: true,
                        "enable-pkce": true,
                    };
                    return config;
                })
            );
    }

    async doKeycloakLogin(config: KeycloakConfig): Promise<boolean> {
        const keycloak = Keycloak(config);
        KeycloakHolder.keycloak = keycloak;
        return fromPromise(keycloak.init( {onLoad: "login-required"}))
            .pipe(
                map(response => true)
            )
            .toPromise();
    }

    getTokenExpiration(): number | undefined {
        return KeycloakHolder.keycloak?.tokenParsed?.exp;
    }
}

export default new KeycloakServiceImpl();
