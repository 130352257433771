import {
    composeFetchableResourceEpic,
    createFetchableResourceReducer,
    FetchableResourceType,
} from "../common/FetchableResource";
import {Action, Reducer} from "redux";
import Api from "../../common/Api";
import {CollectionDto} from "../../common/apis/collection/CollectionDto";
import {ActionsObservable, StateObservable} from "redux-observable";
import {IApplicationState} from "../Store";
import {switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {ViewpointDto} from "../../common/apis/ViewpointService";
import {ILabelDto} from "../../common/apis/label/ILabelDto";
import {DiagramInfoDto} from "../../common/apis/diagram/DiagramInfoDto";
import {PagingFilterDto} from "../../common/apis/PagingFilterDto";

// ### PAGE SPECIFIC DTOs ###

export interface IFilter extends PagingFilterDto{
    identifiers?: string;
    nameLike?: string,
    viewpoints?: ViewpointDto[],
    labels?: ILabelDto[];
    collections?: CollectionDto[];
}

// ### DIAGRAMS FILTER ###

// Actions

export enum FilterActionType {
    UPDATE = "ACTION/DIAGRAMS/FILTER/UPDATE",
    REFETCH_FILTER = "ACTION/DIAGRAMS/FILTER/REFETCH_FILTER",
}

interface IFilterAction extends Action<FilterActionType> {
    type: FilterActionType;
    filter?: IFilter,
    searchString?: string,
    refetchOnly?: boolean,
}

export function getFilterUpdateAction(filter: IFilter): IFilterAction {
    return {
        type: FilterActionType.UPDATE,
        filter: filter,
    }
}

// epic private refetch only action
function getFilterUpdateRefetchOnlyAction(filter: IFilter): IFilterAction {
    return {
        type: FilterActionType.UPDATE,
        filter: filter,
        refetchOnly: true,
    }
}

export function getFilterRefetchAction(): IFilterAction {
    return {
        type: FilterActionType.REFETCH_FILTER,
    }
}


// State

export interface IFilterState {
    lastFilter: IFilter;
    lastFilterUpdate?: Date,
}

const initialFilterState: IFilterState = {
    lastFilter: {},
}

// Reducer

export const filterReducer: Reducer<IFilterState, IFilterAction> = (
    state = initialFilterState,
    action
) => {
    switch(action.type) {
        case FilterActionType.UPDATE :
            return {
                ...state,
                lastFilter: action.filter as IFilter,
                lastFilterUpdate: action.refetchOnly === true ? state.lastFilterUpdate : new Date(),
            }
    }
    return state;
};

export const refetchFilterEpic = (action$: ActionsObservable<IFilterAction>, $state: StateObservable<IApplicationState>) =>
    action$.ofType(FilterActionType.REFETCH_FILTER)
        .pipe(
            switchMap((action) => {
                return of(getFilterUpdateRefetchOnlyAction($state.value.pages.diagrams.filter.submittedFilter.lastFilter));
            }),
        );


// ### DIAGRAMS GRID ###

// Reducer & epic

const type = FetchableResourceType.DIAGRAMS_PAGE_GRID;

export const gridReducer = createFetchableResourceReducer<DiagramInfoDto[]>(type, []);

export const gridRefreshEpic = composeFetchableResourceEpic<IFilterAction>(
    FilterActionType.UPDATE,
    type,
    (action) =>  Api.diagrams.doSearch(action.filter as IFilter),
    (action) => type,
);
