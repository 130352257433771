import {IModelDto} from "./IModelDto";
import {ModelInputDto} from "./ModelInputDto";

export class ModelConverter {
    static convertToModelInput(model: IModelDto, collectionCodes: Array<string>): ModelInputDto {
        const diagram = model.diagrams[0];
        return {
            identifier: Math.random() + "",
            inputName: Math.random() + "",
            elements: model.graph.elements,
            relationships: model.graph.relationships,
            diagrams: [{
                identifier: diagram.diagramInfo.identifier,
                nodes: diagram.nodes,
                connections: diagram.connections,
            }],
            collectionCodes: collectionCodes,
        }
    }
}