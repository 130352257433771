import * as React from "react";
import * as ReactDOM from "react-dom";
import "@fontsource/roboto";
import "./index.css";
import App from "./App";
import {LicenseInfo} from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey("0541c4dac468715e17ab4ad5a4aef148Tz00OTcxMixFPTE2OTMwNDY5MjQ4MzQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

ReactDOM.render(
    <App />,
    document.getElementById("root") as HTMLElement
);

