enum TranslationKey {
    DIAGRAMS_DIAGRAMEDITOR_EDITOR_CREATE_CONNECTION_HANDLE = "diagrams.diagramEditor.editor.connectionCreateHandle",
    DIAGRAMS_DIAGRAMEDITOR_EDITOR_SHOW_HIDDEN_CONNECTION_HANDLE = "diagrams.diagramEditor.editor.showHiddenConnectionHandle",
    DIAGRAMS_DIAGRAMEDITOR_EDITOR_ADD_ELEMENTS_TO_MODEL_FAILED = "diagrams.diagramEditor.editor.addElementsToModelFailed",
    DIAGRAMS_DIAGRAMEDITOR_EDITOR_REMOVE_ITEMS_FROM_MODEL_FAILED = "diagrams.diagramEditor.editor.removeItemsFromModelFailed",
    DIAGRAMS_DIAGRAMEDITOR_EDITOR_ALIGN_NODES_FAILED = "diagrams.diagramEditor.editor.alignNodesFailed",
    DIAGRAMS_DIAGRAMEDITOR_EDITOR_MOVE_TO_FRONT_UNDO_REDO = "diagrams.diagramEditor.editor.moveToFrontUndoRedo",
    DIAGRAMS_DIAGRAMEDITOR_EDITOR_MOVE_TO_BACK_UNDO_REDO = "diagrams.diagramEditor.editor.moveToBackUndoRedo",
    DIAGRAMS_EDITOR_UPDATE_STYLES = "diagrams.editor.updateStyles",
    DIAGRAMS_BACKUP_DIALOG_ACTION_EDIT = "diagrams.backup.dialog.action.edit",
    DIAGRAMS_BACKUP_DIALOG_ACTION_DISCARD = "diagrams.backup.dialog.action.discard",
    DIAGRAMS_BACKUP_DIALOG_TITLE = "diagrams.backup.dialog.title",
    DIAGRAMS_BACKUP_DIALOG_TEXT_BACKUP_FOUND = "diagrams.backup.dialog.text.backup.found",
    DIAGRAMS_BACKUP_DIALOG_TEXT_BACKUP_ACTION = "diagrams.backup.dialog.text.backup.action",
}

export default TranslationKey;
