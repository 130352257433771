import React, {useEffect, useRef, useState} from "react";
import {GridRowParams, GridValueFormatterParams} from "@mui/x-data-grid";
import {useSelector} from "react-redux";
import {ActionButtonType, EnabledPolicy, GridAction} from "../../../../components/grid/GridAction";
import {PersistentStateId} from "../../../../store/common/Grid";
import {IApplicationState} from "../../../../store/Store";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BlockIcon from '@mui/icons-material/Block';
import moment from "moment/moment";
import ActivateUserDialog from "./ActivateUserDialog";
import NotSucceededAlert from "../../../../components/NotSucceededAlert";
import Constants from "../../../../common/Constants";
import LockIcon from '@mui/icons-material/Lock';
import AddIcon from "@mui/icons-material/Add";
import CreateUserDialog from "./CreateUserDialog";
import AlertDialog, {AlertDialogType} from "../../../../components/dialogs/AlertDialog";
import StackedIcon, {Position} from "../../../../components/StackedIcon";
import {ConfigurationId} from "../import/Configuration";
import SecurityIcon from '@mui/icons-material/Security';
import ReplyIcon from '@mui/icons-material/Reply';
import {_transl} from "../../../../store/localization/TranslMessasge";
import {UsersTranslationKey} from "./UsersTranslationKey";
import {CommonTranslation} from "../CommonTranslation";
import {UserRoleTranslator} from "./UserRoleTranslator";
import UserFormatter from "./UserFormatter";
import {UserDto} from "../../../../common/apis/user/UserDto";
import {ButtonId} from "./ButtonId";
import constructUsersController, {PermissionsData, UsersPageController} from "./controller/UsersPageController";
import ImportDialog from "../import/ImportDialog";
import {GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {ChangeUserPasswordDialog} from "./ChangeUserPasswordDialog";
import DescriptionIcon from "@mui/icons-material/Description";
import UserDetailDialog from "./UserDetailDialog";
import userService from "../../../../common/apis/UserService";
import ExtGridWrapper from "../../../../components/grid/ExtGridWrapper";

const dateFormatter = (params: GridValueFormatterParams) => params.value != null ? moment(params.value as Date).format("DD.MM.YYYY") : "";
const userActiveFormatter = (params: GridValueFormatterParams) => params.value === true ?
    _transl(CommonTranslation.YES) : _transl(CommonTranslation.NO);
const roleFormatter = (params: GridValueFormatterParams) => UserRoleTranslator.get().translateUserRole(params.value as string) ?? "";

const fullNameGetter = (params: GridValueGetterParams) => {
    return UserFormatter.formatFullName(params.row.firstName, params.row.lastName);
}

type MountStatus = {
    mounted: boolean,
}

type AlertDialogInfo = {
    type: AlertDialogType,
    title: string,
    text: string,
}

type ImportDialogInfo = {
    configurationId: ConfigurationId,
}

export default function UsersGrid() {

    const [refreshUsersRequest, setRefreshUsersRequest] = useState<number>(1);
    const [users, setUsers] = useState<UserDto[]>([]);
    const [selectedLogin, setSelectedLogin] = useState<string | undefined>();
    const [userActiveStatus, setUserActiveStatus] = useState<string>(_transl(UsersTranslationKey.USERS_GRID_ACTIVE_STATUS_UNDEFINED));
    const [activateUserDialogIsOpened, setActivateUserDialogIsOpened] = useState<boolean>(false);
    const [changeUserPasswordDialogIsOpen, setChangeUserPasswordDialogIsOpen] = useState<boolean>(false);
    const [notSucceededAlertIsOpened, setNotSucceededAlertIsOpened] = useState<boolean>(false);
    const [userCreateDialogIsOpen, setUserCreateDialogIsOpen] = useState<boolean>(false);
    const [userDetailDialogIsOpen, setUserDetailDialogIsOpen] = useState<boolean>(false);
    const [alertDialog, setAlertDialog] = useState<AlertDialogInfo>();
    const [importDialog, setImportDialog] = useState<ImportDialogInfo>();

    const loggedUser = useSelector((state: IApplicationState) => state.user.userData as UserDto);

    const controller = useRef<UsersPageController>(constructUsersController(userService))

    useEffect(() => {
        const mountStatus: MountStatus = {mounted: true};

        (async () => {
            const users = await controller.current.getAllUsers();
            if (mountStatus.mounted) {
                setUsers(users);
            }
        })();
        return () => {
            mountStatus.mounted = false;
        }
    }, [refreshUsersRequest]);


    function refreshUsers() {
        setRefreshUsersRequest(refreshUsersRequest + 1);
    }

    function onGridRowClicked(param: GridRowParams) {
        setSelectedLogin(param.row.login);
    }

    function changeActiveUserStatus() {
        const userActiveStatus = getSelectedUser()?.userActive ?
            _transl(UsersTranslationKey.USERS_GRID_ACTIVE_STATUS_INACTIVE) :
            _transl(UsersTranslationKey.USERS_GRID_USER_ACTIVE_STATUS_ACTIVE);

        setUserActiveStatus(userActiveStatus);
        setActivateUserDialogIsOpened(true);
    }

    async function toggleIsUserActive() {
        const selectedUser = getSelectedUser();
        if (selectedUser?.userActive !== undefined) {
            const toggledIsUserActive = !selectedUser.userActive;
            try {
                await controller.current.activateUserByLogin(selectedUser.login, toggledIsUserActive);
                onIsUserActiveToggleSuccess();
            } catch (error) {
                onIsUserActiveToggleFailed();
            }
        }
    }

    function getSelectedUser(): UserDto | undefined {
        if (selectedLogin) {
            return users.filter((user) => user.login === selectedLogin)[0];
        } else {
            return undefined;
        }
    }

    function onIsUserActiveToggleSuccess() {
        setActivateUserDialogIsOpened(false);
        refreshUsers();
    }

    function onIsUserActiveToggleFailed() {
        setActivateUserDialogIsOpened(false);
        setNotSucceededAlertIsOpened(true);
        setTimeout(() => setNotSucceededAlertIsOpened(false), Constants.FE_APP_ALERT_DELAY);
    }

    function showAlertDialog(type: AlertDialogType, title: string, text: string) {
        setAlertDialog({
            type: type,
            title: title,
            text: text,
        });
    }

    function closeUserDetailDialog(userUpdated: boolean) {
        setUserDetailDialogIsOpen(false);
        if (userUpdated) {
            refreshUsers();
        }
    }

    function closeCreateUserDialog(userCreated: boolean) {
        setUserCreateDialogIsOpen(false);
        if (userCreated) {
            refreshUsers();
        }
    }

    async function exportPermissions() {
        try {
            const data = await controller.current.exportPermissions();
            onPermissionsFetched(data);
        } catch (error) {
            onPermissionsFetchFailed();
        }
    }

    function onPermissionsFetched(data: PermissionsData) {
        controller.current.saveBlobData({blobParts: [data.data], fileName: data.fileName});
    }

    function onPermissionsFetchFailed() {
        showAlertDialog(AlertDialogType.ERROR, _transl(UsersTranslationKey.EXPORT_PERMISSIONS), _transl(UsersTranslationKey.EXPORT_PERMISSIONS_FAILED));
    }

    function hideImportDialog() {
        setImportDialog(undefined);
        refreshUsers();
    }

    function createColumns() {
        return [
            {field: 'login', headerName: _transl(UsersTranslationKey.USER_LOGIN_NAME), width: 200},
            {
                field: 'fullName',
                headerName: _transl(UsersTranslationKey.USER_FULL_NAME),
                width: 200,
                valueGetter: fullNameGetter
            },
            {
                field: 'role',
                headerName: _transl(UsersTranslationKey.USER_ROLE),
                width: 200,
                valueFormatter: roleFormatter
            },
            {
                field: 'userActive',
                headerName: _transl(UsersTranslationKey.USER_IS_ACTIVE),
                width: 150,
                valueFormatter: userActiveFormatter
            },
            {field: 'email', headerName: _transl(UsersTranslationKey.USER_EMAIL), width: 200},
            {field: 'phone', headerName: _transl(UsersTranslationKey.USER_PHONE), width: 200},
            {
                field: 'created',
                headerName: _transl(UsersTranslationKey.USER_CREATED),
                width: 200,
                valueFormatter: dateFormatter
            },
            {
                field: 'lastLogin',
                headerName: _transl(UsersTranslationKey.USER_LAST_LOGIN),
                width: 200,
                valueFormatter: dateFormatter
            },
        ]
    }

    const selectedUser = getSelectedUser();
    const notSucceededAlertText = userActiveStatus === UsersTranslationKey.USERS_GRID_USER_ACTIVE_STATUS_ACTIVE ?
        _transl(UsersTranslationKey.ACTIVATE_USER_FAILED) : _transl(UsersTranslationKey.DEACTIVATE_USER_FAILED);
    const dialogContentText = userActiveStatus === UsersTranslationKey.USERS_GRID_USER_ACTIVE_STATUS_ACTIVE ?
        _transl(UsersTranslationKey.ACTIVATE_USER_CONFIRMATION) : _transl(UsersTranslationKey.DEACTIVATE_USER_CONFIRMATION);

    return (
        <React.Fragment>
            {alertDialog && <AlertDialog open={true}
                                         type={alertDialog.type}
                                         title={alertDialog.title}
                                         text={alertDialog.text}
                                         onClose={() => setAlertDialog(undefined)}/>
            }
            {importDialog && <ImportDialog isOpened={true}
                                           initialConfigurationId={importDialog.configurationId}
                                           onDialogClosed={hideImportDialog}/>
            }
            <NotSucceededAlert opened={notSucceededAlertIsOpened}
                               text={notSucceededAlertText}
                               onClose={() => setNotSucceededAlertIsOpened(false)}/>
            {selectedUser &&
                <UserDetailDialog opened={userDetailDialogIsOpen}
                                  onClose={(updated) => closeUserDetailDialog(updated)}
                                  user={selectedUser}
                />
            }
            <CreateUserDialog opened={userCreateDialogIsOpen}
                              onClose={(created) => closeCreateUserDialog(created)}
                              loggedUser={loggedUser}
                              controller={controller.current}
            />
            <ActivateUserDialog opened={activateUserDialogIsOpened}
                                text={dialogContentText}
                                onYesClicked={() => toggleIsUserActive()}
                                onNoClicked={() => setActivateUserDialogIsOpened(false)}
            />
            {selectedUser &&
                <ChangeUserPasswordDialog open={changeUserPasswordDialogIsOpen}
                                          onClose={() => setChangeUserPasswordDialogIsOpen(false)}
                                          userLogin={selectedUser.login}
                />
            }
            <ExtGridWrapper
                columns={createColumns()}
                rows={users}
                rowCount={users.length}
                getRowId={row => row.login}
                actions={[
                    GridAction.buttonBuilder(ButtonId.USER_DETAIL, ActionButtonType.IMMEDIATE, _transl(UsersTranslationKey.USER_DETAIL),
                        <DescriptionIcon/>)
                        .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
                        .onClick(() => setUserDetailDialogIsOpen(true))
                        .build(),
                    GridAction.buttonBuilder(ButtonId.ADD_USER, ActionButtonType.IMMEDIATE, _transl(UsersTranslationKey.CREATE_USER),
                        <AddIcon/>)
                        .enabledPolicy(EnabledPolicy.ALWAYS)
                        .onClick(() => setUserCreateDialogIsOpen(true))
                        .isEnabled(() => controller.current.canCreateUsers(loggedUser.userAcl))
                        .build(),
                    GridAction.buttonBuilder(ButtonId.ACTIVATE_USER, ActionButtonType.IMMEDIATE, _transl(UsersTranslationKey.ACTIVATE_USER),
                        <CheckCircleOutlineIcon/>)
                        .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
                        .onClick(changeActiveUserStatus)
                        .isEnabled(() => selectedUser ? controller.current.canActivateUser(selectedUser.userAcl) : false)
                        .build(),
                    GridAction.buttonBuilder(ButtonId.DEACTIVATE_USER, ActionButtonType.IMMEDIATE, _transl(UsersTranslationKey.DEACTIVATE_USER),
                        <BlockIcon/>)
                        .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
                        .onClick(changeActiveUserStatus)
                        .isEnabled(() => selectedUser ? controller.current.canDeactivateUser(selectedUser.userAcl) : false)
                        .build(),
                    GridAction.buttonBuilder(ButtonId.CHANGE_PASSWORD, ActionButtonType.IMMEDIATE, _transl(UsersTranslationKey.CHANGE_PASSWORD),
                        <LockIcon/>)
                        .enabledPolicy(EnabledPolicy.WHEN_EXACTLY_ONE_SELECTED)
                        .onClick(() => setChangeUserPasswordDialogIsOpen(true))
                        .isEnabled(() => selectedUser ? controller.current.canChangePassword(selectedUser.userAcl) : false)
                        .build(),
                    GridAction.buttonBuilder(ButtonId.EXPORT_PERMISSIONS, ActionButtonType.SPEED, _transl(UsersTranslationKey.EXPORT_PERMISSIONS),
                        <StackedIcon icon={<SecurityIcon/>}
                                     secondaryIcon={<ReplyIcon style={{transform: "scaleX(-1)"}}/>}
                                     secondaryIconPosition={Position.TOP_RIGHT_MINIFIED}/>)
                        .enabledPolicy(EnabledPolicy.ALWAYS)
                        .onClick(exportPermissions)
                        .isEnabled(() => controller.current.canExportPermissions(loggedUser.userAcl))
                        .build(),
                    GridAction.buttonBuilder(ButtonId.IMPORT_PERMISSIONS, ActionButtonType.SPEED, _transl(UsersTranslationKey.IMPORT_PERMISSIONS),
                        <StackedIcon icon={<SecurityIcon/>}
                                     secondaryIcon={<ReplyIcon
                                         style={{transform: "rotate(-90deg)"}}/>}
                                     secondaryIconPosition={Position.TOP_RIGHT_MINIFIED}/>)
                        .enabledPolicy(EnabledPolicy.ALWAYS)
                        .onClick(() => setImportDialog({configurationId: ConfigurationId.PERMISSIONS}))
                        .isEnabled(() => controller.current.canImportPermissions(loggedUser.userAcl))
                        .build(),
                ]}
                onRowClick={(param, event) => onGridRowClicked(param)}
                onRowDoubleClick={() => {
                    setUserDetailDialogIsOpen(true);
                }}
                peristentStateId={PersistentStateId.USERS_PAGE_GRID}
                resourceId={""}
            />
        </React.Fragment>
    );
}
