import React from "react";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import {Chip, CircularProgress, Grow, IconButton, Paper, Tooltip} from "@mui/material";
import clsx from "clsx";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import Constants from "../../common/Constants";
import TextField from "./textfield/TextField";
import {_transl} from "../../store/localization/TranslMessasge";
import {ElementDetailTranslationKey} from "../../pages/main/content/elementdetail/ElementDetailTranslationKey";
import Snackbar from "../../pages/main/content/snackbar/Snackbar";
import {ErrorTranslationKey} from "../../pages/main/content/ErrorTranslationKey";

export const EDIT_ROOT_SUBCOMPONENT_ROLE = "$$EDIT_ROOT_SUBCOMPONENT_ROLE";

export interface Validator {
    isValid: (editedValue: any) => boolean,
    getValidationErrorMessage?: () => JSX.Element,
    onValidationFailed?: () => void,
    onValidationCancelled?: () => void,
}

enum Mode {
    VIEW = "VIEW",
    EDIT = "EDIT",
}

enum EditModeStatus {
    NOT_STARTED = "NOT_STARTED",
    STARTED = "STARTED",
    SERVICE_CALL_IN_PROGRESS = "SERVICE_CALL_IN_PROGRESS",
    SERVICE_CALL_OK = "SERVICE_CALL_OK",
    SERVICE_CALL_FAILED = "SERVICE_CALL_FAILED",
    VALIDATION_FAILED = "VALIDATION_FAILED",
}

const styles = (theme: Theme) => createStyles({
    root: {
        position: "relative",
        display: "flex",
        "&> *": {
            flexGrow: 1,
        }
    },
    editButtons: {
        position: "absolute",
        bottom: "-2.35em",
        right: 0,
        display: "flex",
        zIndex: 1,
    },
    editButton: {
        padding: theme.spacing(0.2),
        margin: theme.spacing(0.2),
        "& .MuiIconButton-root": {
            color: "inherit",
        }
    },
    saveButton: {
        color: "white",
        backgroundColor: theme.palette.primary.main,
    },
    validationFailedButton: {
        color: "white",
        backgroundColor: theme.palette.error.main,
    },
    cancelButton: {
        backgroundColor: theme.palette.background.paper,
    },
    spinner: {
        minWidth: "1em",
        color: "white",
    },
    errorBox: {
        backgroundColor: theme.palette.error.light,
        color: "white",
        "& .MuiSvgIcon-root": {
            color: "white",
        }
    },
    validationFailedMessage: {
        "& .MuiTooltip-tooltip": {
            fontSize: ".85em",
            color: "black",
            backgroundColor: "white",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.palette.error.main,
        },
        "& .MuiTooltip-arrow": {
            color: "white",
        },
        "& .MuiTooltip-arrow::before": {
            borderTopColor: theme.palette.error.main,
            borderTopWidth: "1px",
            borderTopStyle: "solid",
            borderLeft: `1px solid ${Constants.DEFAULT_ERROR_COLOR}`,
        }
    },
    displayNone: {
        display: "none",
    },

});

export type UpdateResponse = {
    status: number;
}

export const UPDATE_RESPONSE_STATUS_OK = 200;

interface IProps<T> extends WithStyles<typeof styles> {
    label: string,
    initialValue: T,
    getEditedValue: () => T,
    valueToViewString: (value: T) => string | null,
    isViewDatePicker?: boolean,
    deleteDateErrorMsg?: string,
    valuesEqual: (value: T, newValue: T) => boolean,
    getEditComponent: (cancelUpdate: () => void, saveChanges: () => void) => JSX.Element,
    onClearDate?: (saveChanges: () => void) => void,
    focusEditComponent: () => void,
    doUpdate: (value: T) => Promise<any>,
    onEditModeEntered?: () => void,
    onUpdateInProgress?: (value: T) => void,
    onSuccessfulUpdate: (value: T) => void,
    onFailedUpdate: (value: T) => void,
    onCancelChanges: () => void,
    id?: string,
    rows?: number,
    editRootRole: string,
    validator?: Validator,
    readonly?: boolean,
    isEditWithEditableButtons?: boolean,
}

interface IState {
    showConfirmationDialog: boolean,
    mode: Mode,
    editModeStatus: EditModeStatus,
}

class EditableComponent<T> extends React.Component<IProps<T>, IState> {

    editRootRef = React.createRef<HTMLDivElement>();

    constructor(props: IProps<T>) {
        super(props);
        this.state = this.createInitialState();
    }

    createInitialState() {
        return {
            showConfirmationDialog: false,
            mode: Mode.VIEW,
            editModeStatus: EditModeStatus.NOT_STARTED,
        }
    }

    updateState<V extends keyof IState>(newPropertyValues: Array<{ propertyName: V, value: IState[V] }>, callback?: () => void) {
        const {onEditModeEntered} = this.props;
        const stateCopy = {...this.state};
        newPropertyValues.forEach(propertyValue => {
            stateCopy[propertyValue.propertyName] = propertyValue.value;
            if (propertyValue.propertyName === "mode" && (this.state.mode === Mode.VIEW && propertyValue.value === Mode.EDIT) && onEditModeEntered) {
                setTimeout(() => onEditModeEntered(), 0)
            }
        });
        this.setState(stateCopy, callback);
    }

    render() {
        const {classes, editRootRole} = this.props;
        const {mode} = this.state;

        return (
            <div className={clsx('EditableComponent-root', classes.root)}
                 role={editRootRole}
                 ref={this.editRootRef}
                 onMouseEnter={() => {
                     if (mode === Mode.VIEW) {
                     }
                 }}
                 onMouseLeave={() => {
                     if (mode === Mode.VIEW) {
                     }
                 }}
                 onBlur={(event) => {
                     if (mode === Mode.EDIT &&
                         !this.isEditRootChild(event.relatedTarget as HTMLElement)
                     ) {
                         if (!this.initialValueChanged()) {
                             this.setToViewMode();
                         } else {
                             this.updateState([{propertyName: "showConfirmationDialog", value: true}]);
                         }
                     }
                 }}
            >
                {
                    mode === Mode.VIEW && this.renderViewTextField()
                }
                {
                    mode === Mode.EDIT && this.renderEditComponent()
                }
            </div>
        );
    }

    private renderViewTextField(): JSX.Element {
        const {label, id, rows, valueToViewString, getEditedValue, readonly, isViewDatePicker, onClearDate} = this.props;
        const viewValue = valueToViewString(getEditedValue());

        const openEditView = () => {
            if (!readonly) {
                 this.updateEditStatus(Mode.EDIT, EditModeStatus.STARTED);
            }
        }

        if (isViewDatePicker) {
            return <TextField id={id}
                              key={viewValue}
                              label={label}
                              value={viewValue}
                              multiline={this.isMultiline()}
                              rows={rows}
                              onClick={openEditView}
                              onClearButtonClick={() =>
                                  onClearDate !== undefined
                                      ? onClearDate(() => this.saveDate())
                                      : undefined
                              }
                              InputProps={{
                                  readOnly: readonly,
                              }}
                              clearable
            />
        } else {
            return <TextField id={id}
                              key={viewValue}
                              label={label}
                              defaultValue={viewValue}
                              multiline={this.isMultiline()}
                              rows={rows}
                              onClick={openEditView}
                              InputProps={{
                                  readOnly: readonly,
                              }}
            />
        }
    }

    private renderEditComponent(): JSX.Element {
        const {getEditComponent, isEditWithEditableButtons, isViewDatePicker} = this.props;
        return (
            <React.Fragment>
                {!isViewDatePicker && this.renderConfirmationDialog()}
                {getEditComponent(() => this.setToViewMode(), () => this.saveChanges())}
                {isEditWithEditableButtons && this.renderEditButtons()}
            </React.Fragment>
        )
    }

    private renderConfirmationDialog() {
        const {onCancelChanges, editRootRole} = this.props;
        const {showConfirmationDialog} = this.state;

        return <ConfirmationDialog open={showConfirmationDialog}
                                   title={_transl(ElementDetailTranslationKey.EDIT_ITEM)}
                                   confirmationText={_transl(ElementDetailTranslationKey.DISCARD_CHANGES)}
                                   role={editRootRole}
                                   onConfirm={() => {
                                       this.reset();
                                       onCancelChanges();
                                   }}
                                   onReject={() => {
                                       this.updateState([{propertyName: "showConfirmationDialog", value: false}]);
                                       setTimeout(() => this.focusEditComponent(), 0);
                                   }}/>
    }

    private renderEditButtons(): JSX.Element {
        const {classes, validator, editRootRole, focusEditComponent} = this.props;
        const {mode, editModeStatus} = this.state;
        const onValidationCancelled = () => {
            this.updateEditStatus(Mode.EDIT, EditModeStatus.NOT_STARTED);
            validator && validator.onValidationCancelled && validator.onValidationCancelled();
            focusEditComponent();
        }

        return (
            <Grow in={mode === Mode.EDIT} onEntered={() => this.focusEditComponent()}>
                <div className={classes.editButtons}>
                    {
                        editModeStatus === EditModeStatus.SERVICE_CALL_FAILED &&
                        <Chip
                            icon={<ErrorIcon/>}
                            label={_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA)}
                            className={classes.errorBox}
                        />
                    }
                    {editModeStatus === EditModeStatus.VALIDATION_FAILED &&
                        <Paper elevation={1}
                               className={clsx(classes.editButton, classes.validationFailedButton)}>
                            {validator?.getValidationErrorMessage != null &&
                                <Tooltip open={true}
                                         arrow={true}
                                         classes={{
                                             popper: classes.validationFailedMessage,
                                         }}
                                         title={this.createValidationErrorMessageElement(validator.getValidationErrorMessage())}
                                         placement={"bottom"}
                                         PopperProps={{
                                             role: editRootRole,
                                         }}
                                >
                                    <IconButton aria-label="validationerror"
                                                size={"small"}
                                                onClick={() => onValidationCancelled()}
                                                data-testid={"editable-component-validation-button-tooltip"}>
                                        <WarningIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            }
                            {validator?.getValidationErrorMessage == null &&
                                <IconButton aria-label="validationerror"
                                            size={"small"}
                                            onClick={() => onValidationCancelled()}
                                            data-testid={"editable-component-validation-button-no-tooltip"}>
                                    <WarningIcon fontSize="small"/>
                                </IconButton>
                            }
                        </Paper>
                    }
                    <Paper elevation={1}
                           className={clsx(classes.editButton, classes.saveButton)}>
                        {editModeStatus !== EditModeStatus.SERVICE_CALL_IN_PROGRESS &&
                            <IconButton aria-label="save"
                                        size={"small"}
                                        onMouseDown={(event) => event.preventDefault()}
                                        onClick={() => this.saveChanges()}
                                        data-testid={"editable-component-save-button"}>
                                <CheckIcon fontSize="small"/>
                            </IconButton>
                        }
                        {editModeStatus === EditModeStatus.SERVICE_CALL_IN_PROGRESS &&
                            <IconButton aria-label="in progress"
                                        size={"small"}
                                        data-testid={"editable-component-in-progress-button"}>
                                <CircularProgress size={"small"} className={classes.spinner}/>
                            </IconButton>
                        }
                    </Paper>
                    <Paper elevation={1}
                           className={clsx(classes.editButton, classes.cancelButton)}>
                        <IconButton aria-label="storno"
                                    size={"small"}
                                    onMouseDown={(event) => event.preventDefault()}
                                    onClick={() => this.setToViewMode()}
                                    data-testid={"editable-component-storno-button"}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </Paper>
                </div>
            </Grow>
        );
    }

    private updateEditStatus(mode: Mode, editModeStatus: EditModeStatus, onUpdateCallback?: () => void) {
        this.updateState([
                 {propertyName: "mode", value: mode},
                {propertyName: "editModeStatus", value: editModeStatus}],
            () => {
                onUpdateCallback && onUpdateCallback();
            })
    }

    private saveChanges() {
        const {
            doUpdate,
            onUpdateInProgress,
            onSuccessfulUpdate,
            onFailedUpdate,
            getEditedValue,
            validator,
            focusEditComponent
        } = this.props;
        const editedValue = getEditedValue();

        let isValid = validator ? validator.isValid(editedValue) : true;

        if (isValid) {
            const editInProgress = () => {
                this.updateEditStatus(Mode.EDIT, EditModeStatus.SERVICE_CALL_IN_PROGRESS, () => {
                    onUpdateInProgress && onUpdateInProgress(editedValue);
                    validator && validator.onValidationCancelled && validator.onValidationCancelled();
                });
            }
            const editOk = () => {
                this.updateEditStatus(Mode.VIEW, EditModeStatus.NOT_STARTED, () => onSuccessfulUpdate(editedValue));
            }
            const editFailed = () => {
                this.updateEditStatus(Mode.EDIT, EditModeStatus.SERVICE_CALL_FAILED, () => onFailedUpdate(editedValue));
            }

            editInProgress();
            (async () => {
                try {
                    await doUpdate(editedValue);
                    editOk()
                } catch (error) {
                    editFailed()
                }
            })();
        } else {
            this.updateEditStatus(Mode.EDIT, EditModeStatus.VALIDATION_FAILED, () => {
                validator && validator.onValidationFailed && validator.onValidationFailed();
                focusEditComponent();
            });
        }
    }

    private saveDate() {
        const {doUpdate, getEditedValue, validator, deleteDateErrorMsg} = this.props;
        const editedValue = getEditedValue();

        let isValid = validator ? validator.isValid(editedValue) : true;

        if (isValid) {
            (async () => {
                try {
                    await doUpdate(editedValue);
                } catch (error) {
                    if (deleteDateErrorMsg) {
                        Snackbar.error(deleteDateErrorMsg);
                    }
                }
            })();
        }
    }

    private initialValueChanged() {
        const {initialValue, getEditedValue, valuesEqual} = this.props;
        const {mode} = this.state;
        return mode === Mode.EDIT && !valuesEqual(initialValue, getEditedValue());
    }

    private setToViewMode() {
        const {onCancelChanges} = this.props;
        if (!this.initialValueChanged()) {
            this.reset();
            onCancelChanges();
        } else {
            this.updateState([{propertyName: "showConfirmationDialog", value: true}]);
        }
    }

    private reset() {
        const {validator} = this.props;
        this.setState(this.createInitialState(), () => validator && validator.onValidationCancelled && validator.onValidationCancelled());
    }

    private isEditRootChild(element: HTMLElement) {
        const editRoot = this.editRootRef.current;
        const editRootRole = this.props.editRootRole;
        if (element != null && editRoot != null) {
            let htmlElement: HTMLElement | null = element;
            do {
                if (EditableComponent.hasEditRootSubcomponentRole(htmlElement, editRootRole)) {
                    return true;
                } else {
                    htmlElement = htmlElement.parentElement;
                }
            } while (htmlElement != null);
        }
        return false;
    }


    private static hasEditRootSubcomponentRole(element: HTMLElement, editRootRole: string): boolean {
        const roleAttribute = element.getAttribute("role");
        return roleAttribute != null && roleAttribute.indexOf(editRootRole) !== -1;
    }

    private focusEditComponent() {
        const {focusEditComponent} = this.props;
        const {mode} = this.state;
        if (mode === Mode.EDIT) {
            focusEditComponent();
        }
    }

    private isMultiline() {
        const {rows} = this.props;
        return rows != null ? rows > 1 : false;
    }

    private createValidationErrorMessageElement(validationErrorMessage: JSX.Element) {
        return <React.Fragment>
            {validationErrorMessage}
        </React.Fragment>
    }
}

export default withStyles(styles, {withTheme: true})(EditableComponent);
