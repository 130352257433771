import "../../css/mainPageRoutes.css"
import React from "react";
import {Switch} from "react-router";
import RouteDefinitionType from "../routedefinition/RouteDefinitionType";
import RouteDefinitionUtils from "../routedefinition/RouteDefinitionUtils";
import {useHistory} from "react-router-dom";
import {
    TransitionGroup,
    CSSTransition
} from "react-transition-group";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../store/Store";

export default function MainPageRoutes() {

    const { location } = useHistory();
    const user = useSelector((state: IApplicationState) => state.user.userData);

    return (
        <TransitionGroup>
            <CSSTransition
                key={location.key}
                classNames="fade"
                timeout={{
                    appear: 250,
                    enter: 250,
                    exit: 0,
                }}
            >
                <Switch location={location}>
                    {
                        RouteDefinitionUtils.getRoutesByType(RouteDefinitionType.MAIN_PAGE, user)
                    }
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}