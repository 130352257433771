import clsx from "clsx";
import {default as MaterialAppBar} from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";
import {Badge, Fade, IconButton} from "@mui/material";
import archiIcon from "../../ArchiREPO_logo.svg";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ListItemText from "@mui/material/ListItemText";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, {Dispatch} from "react";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import AppBarMenu from "./appbar/AppBarMenu";
import Search from "./appbar/search/Search";
import LanguageIcon from '@mui/icons-material/Language';
import AppBarLanguageMenu from "./appbar/AppBarLanguageMenu";
import {ITranslation} from "../../store/localization/ITranslation";
import {IApplicationState} from "../../store/Store";
import {connect} from "react-redux";
import {StringNullable} from "../../common/Types";
import {getSaveTranslationsAction} from "../../store/localization/TranslationReducer";
import UserFormatter from "./content/users/UserFormatter";
import translationsService from "../../common/apis/TranslationsService";
import elementSearchProvider from "./appbar/search/ElementSearchProvider";
import diagramSearchProvider from "./appbar/search/DiagramSearchProvider";
import {Language} from "../../common/Language";
import LocaleContext from "../../common/LocaleContext";

const appBarStyles = (theme: Theme) => createStyles({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        transitionDelay: theme.drawer.transitionDelay,
        marginLeft: theme.drawer.openedWidth,
        width: `calc(100% - ${theme.drawer.openedWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 24,
    },
    hideOpenDrawerIcon: {
        transitionDelay: theme.drawer.transitionDelay,
        display: 'none',
    },
    fade: {
        transitionDelay: theme.drawer.transitionDelay,
    },
    icon: {
        height: theme.spacing(5)
    },
    grow: {
        flexGrow: 1,
    },
    accountIcon: {
        marginRight: theme.spacing(1),
        color: 'white',
        minWidth: theme.spacing(0)
    },
    languageIcon: {
        color: 'white',
        minWidth: theme.spacing(0)
    },
    accountArea: {
        paddingLeft: theme.spacing(0),
        "& .MuiListItem-root": {
            paddingLeft: theme.spacing(1)
        }
    },
    menuTopLevel: {
        paddingLeft: theme.spacing(3)
    },
    accountDivider: {
        marginRight: theme.spacing(10)
    },
});

interface IAppBarProps extends WithStyles<typeof appBarStyles, true> {
    onAppIconClick?: () => void;
    drawerOpened: boolean;
    firstname: StringNullable,
    lastname: StringNullable,

    //redux props
    setTranslations: (translations: ITranslation) => void,
}

interface IAppBarMenuState {
    appBarMenuOpened: boolean,
    appBarLanguageMenuOpened: boolean,
}

class AppBar extends React.Component<IAppBarProps, IAppBarMenuState> {
    static contextType = LocaleContext;

    private accountIconRef: React.RefObject<HTMLDivElement>;
    private languageIconRef: React.RefObject<HTMLDivElement>;

    constructor(props: IAppBarProps) {
        super(props);
        this.state = {
            appBarMenuOpened: false,
            appBarLanguageMenuOpened: false,
        }
        this.accountIconRef = React.createRef<HTMLDivElement>();
        this.languageIconRef = React.createRef<HTMLDivElement>();
    }

    handleProfileMenuChange(opened: boolean) {
        this.setState((state) => {
            return {
                appBarMenuOpened: opened,
            };
        });
    }

    handleLanguageMenuIsOpen(opened: boolean) {
        this.setState((state) => {
            return {
                appBarLanguageMenuOpened: opened,
            };
        });
    }

    handleLanguageMenuChange(opened: boolean) {
        this.setState((state) => {
            return {
                appBarLanguageMenuOpened: opened,
            };
        });
    }

    private async changeLanguage(language: Language, opened: boolean) {
        this.setState((state) => {
            return {
                appBarLanguageMenuOpened: opened
            };
        });
        this.context.setLanguage(language, true);
        const translations = await translationsService.getTranslations(language);
        this.props.setTranslations(translations);
    }

    render() {
        const { drawerOpened, onAppIconClick, classes, theme } = this.props;
        const { firstname, lastname } = this.props;
        const { appBarMenuOpened, appBarLanguageMenuOpened } = this.state;
        const onAppIconClickHandler = onAppIconClick || (() => {});

        return (
            <MaterialAppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: drawerOpened,
                })}
            >
                <Toolbar>
                    <Fade in={!drawerOpened} style={{transitionDelay: theme.drawer.transitionDelay}}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={onAppIconClickHandler}
                            edge="start"
                            size="large">
                            <img className={classes.icon} src={archiIcon} alt={"ArchiREPO"} />
                        </IconButton>
                    </Fade>

                    <div className={classes.grow} />

                    <Search searchProviders={[elementSearchProvider, diagramSearchProvider]} />

                    <IconButton
                        edge="end"
                        aria-label="account"
                        aria-haspopup="true"
                        onClick={e => this.handleProfileMenuChange(!appBarMenuOpened)}
                        color="inherit"
                        className={classes.accountArea}
                        size="large">
                        <ListItem aria-controls="simple-menu" aria-haspopup="true" className={classes.menuTopLevel} button key={"Account"} disableGutters={true}>
                            <ListItemIcon ref={this.accountIconRef} className={classes.accountIcon}>{<AccountCircle/>}</ListItemIcon>
                            <ListItemText primary={UserFormatter.formatFullName(firstname, lastname)}/>
                            <ArrowDropDownIcon />
                        </ListItem>
                    </IconButton>

                    <AppBarMenu opened={appBarMenuOpened} anchorRef={this.accountIconRef.current} onMenuClose={() => this.handleProfileMenuChange(false)}/>

                    <IconButton
                        edge="end"
                        aria-label="language"
                        aria-haspopup="true"
                        onClick={e => this.handleLanguageMenuIsOpen(!appBarLanguageMenuOpened)}
                        color="inherit"
                        className={classes.accountArea}
                        size="large">
                        <ListItem aria-controls="simple-language-menu" aria-haspopup="true" className={classes.menuTopLevel} button key={"Language"} disableGutters={true}>
                            <ListItemIcon ref={this.languageIconRef} className={classes.languageIcon}>{
                                <Badge badgeContent={this.context.language} color={"secondary"} >
                                <LanguageIcon/>
                                </Badge>
                            }</ListItemIcon>
                            <ArrowDropDownIcon />
                        </ListItem>
                    </IconButton>

                    <AppBarLanguageMenu
                        opened={appBarLanguageMenuOpened}
                        anchorRef={this.languageIconRef.current}
                        onMenuClose={() => this.handleLanguageMenuChange(false)}
                        changeLanguage={(language) => this.changeLanguage(language, false)}/>
                </Toolbar>
            </MaterialAppBar>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    firstname: state.user.userData?.firstName || "",
    lastname: state.user.userData?.lastName || "",
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
    setTranslations: (translations: ITranslation) => dispatch(getSaveTranslationsAction(translations)),
});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(appBarStyles, { withTheme: true })(AppBar));
