import {DefaultDiagramBackupService, DiagramBackupService} from "../DiagramBackupService";
import {createContext, useContext, useEffect, useRef, useState} from "react";
import {DiagramBackupInfo} from "../DiagramBackupInfo";
import EditDiagramBackupDialog from "./EditDiagramBackupDialog";
import {DefaultCheckIntervalFactory, IntervalBasedDiagramBackupPersister} from "../DiagramBackupPersister";
import {LocalStorageBasedDiagramBackupStorage} from "../DiagramBackupStorage";
import {useHistory} from "react-router-dom";
import RouteDefinitionUtils, {diagramEditorPage} from "../../../routedefinition/RouteDefinitionUtils";
import {DiagramsGridAction, DiagramsGridActionType} from "../../../../pages/main/content/diagrams/DiagramsGridAction";
import RenderMode from "../../context/RenderMode";
import {ChatCoordinatesManager} from "../../../../pages/main/content/chat/ChatCoordinatesManager";

const checkIntervalFactory = new DefaultCheckIntervalFactory();

const diagramBackupService = new DefaultDiagramBackupService(
    new IntervalBasedDiagramBackupPersister(
        new LocalStorageBasedDiagramBackupStorage(localStorage), checkIntervalFactory.nextCheckInterval()));

const DiagramBackupServiceContext = createContext<DiagramBackupService>(diagramBackupService);

export function useDiagramBackupService() {
    return useContext(DiagramBackupServiceContext);
}

type Props = {}

export default function DiagramBackupProvider(props: React.PropsWithChildren<Props>) {
    const [diagramBackupsToBeChecked, setDiagramBackupsToBeChecked] = useState<Array<DiagramBackupInfo>>([]);
    const editDiagramBackupCheckDone = useRef<boolean>(false);
    const chatCoordinatesManager = useRef(new ChatCoordinatesManager());

    const history = useHistory();

    useEffect(() => {
        const backups = diagramBackupService.findAllBackups();
        if (backups.length > 0 && !editDiagramBackupCheckDone.current) {
            editDiagramBackupCheckDone.current = true;
            setDiagramBackupsToBeChecked(backups);
        }
    }, [setDiagramBackupsToBeChecked])

    function onEditBackup() {
        setDiagramBackupsToBeChecked([]);
        const queryParams = [
            {name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.SHOW_EDITOR), value: getBackupToBeChecked().getDiagramId()},
            {name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.EDITOR_MODE), value: RenderMode.EDIT},
            {name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.EDIT_BACKUP), value: "true"},
        ];
        history.push(RouteDefinitionUtils.resolvePath(diagramEditorPage, {}, queryParams));
    }

    async function onDiscardBackup() {
        const diagramId = getBackupToBeChecked().getDiagramId();
        await chatCoordinatesManager.current.fixAllChatCoordinatesForDiagram(diagramId);
        diagramBackupService.removeBackup(diagramId);
        setDiagramBackupsToBeChecked([]);
    }

    function getBackupToBeChecked() {
        return diagramBackupsToBeChecked[0];
    }

    return <DiagramBackupServiceContext.Provider value={diagramBackupService}>
        {diagramBackupsToBeChecked.length > 0 && <EditDiagramBackupDialog diagramBackupInfo={getBackupToBeChecked()}
                                                                          onEditBackup={onEditBackup}
                                                                          onDiscardBackup={onDiscardBackup} />}
        {props.children}
    </DiagramBackupServiceContext.Provider>
}
