import React, {useEffect, useRef, useState} from "react";
import RouteDefinitionUtils from "../../../../common/routedefinition/RouteDefinitionUtils";
import {PersistentStateId} from "../../../../store/common/Grid";
import {ActionButtonType, EnabledPolicy, GridAction} from "../../../../components/grid/GridAction";
import DeleteIcon from "@mui/icons-material/Delete";
import Api from "../../../../common/Api";
import Constants from "../../../../common/Constants";
import AddIcon from "@mui/icons-material/Add";
import CreateElementDialog from "./CreateElementDialog";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {FileFormat, IElementsExportDto} from "../../../../common/apis/Exports";
import AlertDialog, {AlertDialogType} from "../../../../components/dialogs/AlertDialog";
import {BlobUtils} from "../../../../common/BlobUtils";
import {ElementsGridDef} from "./RenderMode";
import {ButtonId} from "../../../General";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import MergeElementsDialog from "./MergeElementsDialog";
import TransformIcon from '@mui/icons-material/Transform';
import {IFilter} from "../../../../store/elements/Elements";
import {CreateRelationshipDialog} from "./CreateRelationshipDialog";
import CreateDiagramDialog from "../diagrams/create/CreateDiagramDialog";
import GraphDataChartDialog from "../../../../components/dialogs/graphdata/GraphDataChartDialog";
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import {from, Observable} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";
import {ContextMenuItem} from "../../../../components/ContextMenu";
import DescriptionIcon from '@mui/icons-material/Description';
import LinkIcon from "@mui/icons-material/Link";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ElementTranslationKey} from "./ElementTranslationKey";
import {CommonTranslation} from "../CommonTranslation";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import elementService from "./service/ElementService";
import DeleteConfirmationInfoDialog from "./DeleteConfirmationInfoDialog";
import {ILabelDto} from "../../../../common/apis/label/ILabelDto";
import {ElementsController} from "./controller/ElementsController";
import {
    AccountTreeOutlined,
    DashboardTwoTone,
    FormatPaint,
    InsertLink,
    IosShare,
    LocalOffer,
    MoreHoriz,
    Save,
    SettingsBackupRestore,
    Share
} from "@mui/icons-material";
import ConfirmationDialog from "../../../../components/dialogs/ConfirmationDialog";
import Snackbar from "../snackbar/Snackbar";
import ElementDetailDialog from "../elementdetail/ElementDetailDialog";
import {ColumnPresets, GridPresets} from "../../../../components/grid/presets/GridPresets";
import {GridColDef} from "@mui/x-data-grid";
import CustomPropertyColumnAddDialog from "./CustomColumnAddDialog";
import UpdateElementTypeDialog from "./UpdateElementTypeDialog";
import {useHistory, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../../../store/Store";
import {ExtGridWithDataProvider} from "../../../../components/grid/ExtGridWithDataProvider";
import DataProvider from "../../../../components/grid/dataprovider/DataProvider";
import {PagePresetsTranslationKey} from "../presets/PagePresetsTranslationKey";
import {ELEMENTS_PAGE_SHOW_DETAIL_QUERY_PARAM} from "./ElementsPage";
import AddBulkLabelsToElementsDialog from "./bulk/AddBulkLabelsToElementsDialog";
import AddBulkCollectionsToElementsDialog from "./bulk/AddBulkCollectionsToElementsDialog";
import RemoveBulkLabelsFromElementsDialog from "./bulk/RemoveBulkLabelsFromElementsDialog";
import RemoveBulkCollectionsFromElementsDialog
    from "./bulk/RemoveBulkCollectionsFromElementsDialog";

export enum ElementsGridType {
    OVERVIEW = "OVERVIEW",
    LISTING = "LISTING",
    PICKER = "PICKER",
}

interface ElementsGridProps {
    filter: IFilter;
    refetchData: () => void;

    gridPresets?: GridPresets;
    onGridPresetsChanged?: (gridPresets: GridPresets) => void;
    persistentStateId: PersistentStateId;

    isDefaultPresetsAvailable?: boolean;

    onLoadDefaultPresets?: () => void;
    onSavePresetsAsDefault?: () => void;
    onResetDefaultPresets?: () => void;

    initialPageReset?: boolean;

    elementsGridType?: ElementsGridType;

    bulkActionsOpenedPermanently?: boolean;
    onSelectionChanged?: (selectedRowIds: string[], selectedRows: unknown[]) => void,

    isAtLeastOneIdentifierMandatory? : boolean,
}

export const ELEMENTS_GRID_ID = "__ELEMENTS_GRID__";

export default function ElementsGrid(props: ElementsGridProps) {

    const history = useHistory();
    const location = useLocation();

    const labelOptions = useSelector((state: IApplicationState) => state.pages.common.options.labels.resource);
    const collectionOptions = useSelector((state: IApplicationState) => state.pages.common.options.collections.resource);
    const user = useSelector((state: IApplicationState) => state.user.userData);

    const dataProvider = useRef<DataProvider<IFilter, ElementDto>>({fetch: elementService.searchByFilter});

    const [showConfirmDialogType, setShowConfirmDialogType] = useState<ButtonId>();
    const [selectedRowId] = useState<string>();
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [objectDialogIsOpen, setObjectDialogIsOpen] = useState<boolean>(false);
    const [createDiagramDialogOpened, setCreateDiagramDialogOpened] = useState<boolean>(false);
    const [elementDetailDialog, setElementDetailDialog] = useState<{
        open: boolean,
        elementId?: string
    }>({open: false});
    const [alertDialog, setAlertDialog] = useState<{
        open: boolean,
        type: AlertDialogType,
        title: string,
        text: string,
    }>();
    const [changeElementsTypeDialog, setChangeElementsTypeDialog] = useState<{
        objectDialogIsOpen: boolean,
        onClosed: () => void,
        message: string,
    }>();
    const [mergeElementsDialog, setMergeElementsDialog] = useState<{
        dialogIsOpen: boolean,
        onClosed: () => void,
        selectedRows: ElementDto[],
        selectedRowIds: Array<string>,
        mergeIsDone: () => void,
    }>();
    const [addBindingElementsDialog, setAddBindingElementsDialog] = useState<{
        objectDialogIsOpen: boolean,
        onClosed: () => void,
        message: string,
    }>();
    const [customPropertyColumnAddDialogOpen, setCustomPropertyColumnAddDialogOpen] = useState<boolean>(false);
    const [graphDataChartDialog, setGraphDataChartDialog] = useState<{
        title: string,
        refreshGraphData: (elementIds: Array<string>) => Observable<AjaxResponse>,
    }>();
    const [selectedRows, setSelectedRows] = useState<ElementDto[]>([]);
    const [, setHandleShowDetailQueryAction] = useState<boolean>(true);
    const [elementsController] = useState<ElementsController>(new ElementsController());
    const [isAddBulkLabelsToElementsDialogVisible, setIsAddBulkLabelsToElementsDialogVisible] = useState<boolean>(false);
    const [isRemoveBulkLabelsFromElementsDialogVisible, setIsRemoveBulkLabelsFromElementsDialogVisible] = useState<boolean>(false);
    const [isAddBulkCollectionsToElementsDialogVisible, setIsAddBulkCollectionsToElementsDialogVisible] = useState<boolean>(false);
    const [isRemoveBulkCollectionsFromElementsDialogVisible, setIsRemoveBulkCollectionsFromElementsDialogVisible] = useState<boolean>(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const elementId = params.get(ELEMENTS_PAGE_SHOW_DETAIL_QUERY_PARAM);
        if (elementId) {
            setElementDetailDialog({
                open: true,
                elementId: elementId
            })
        }
    }, [location]);

    function setObjectDialogIsClosed() {
        setObjectDialogIsOpen(false);
    }

    function setChangeElementsTypeDialogToClose() {
        setChangeElementsTypeDialog(undefined);
    }

    function setElementsBindingDialogToClose() {
        setAddBindingElementsDialog(undefined);
    }

    function addBulkLabels(selectedRowIds: Array<string>, selectedRows: ElementDto[]) {
        setSelectedRowIds(selectedRowIds);
        setSelectedRows(selectedRows);
        setIsAddBulkLabelsToElementsDialogVisible(true);
    }

    function removeBulkLabels(selectedRowIds: Array<string>, selectedRows: ElementDto[]) {
        setSelectedRowIds(selectedRowIds);
        setSelectedRows(selectedRows);
        setIsRemoveBulkLabelsFromElementsDialogVisible(true);
    }

    function removeBulkCollections(selectedRowIds: Array<string>, selectedRows: ElementDto[]) {
        if (getSelectedCollectionOptions(selectedRows).length === 0) {
            setSelectedRowIds(selectedRowIds);
            setSelectedRows(selectedRows);
            setAlertDialog({
                open: true,
                type: AlertDialogType.INFO,
                title: _transl(ElementTranslationKey.ALERT_DIALOG_REMOVE_BULK_COLLECTIONS_TITLE),
                text: _transl(ElementTranslationKey.ALERT_DIALOG_REMOVE_BULK_COLLECTIONS_TEXT)
            })
        } else {
            setSelectedRowIds(selectedRowIds);
            setSelectedRows(selectedRows);
            setIsRemoveBulkCollectionsFromElementsDialogVisible(true);
        }
    }

    function addBulkCollections(selectedRowIds: Array<string>, selectedRows: ElementDto[]) {
        setSelectedRowIds(selectedRowIds);
        setSelectedRows(selectedRows);
        setIsAddBulkCollectionsToElementsDialogVisible(true);
    }

    function showMergeElementsDialog(selectedRows: ElementDto[], selectedRowIds: Array<string>) {
        setMergeElementsDialog({
            dialogIsOpen: true,
            onClosed: () => setMergeElementsDialogToClose(),
            selectedRows: selectedRows,
            selectedRowIds: selectedRowIds,
            mergeIsDone: props.refetchData,
        });
    }

    function setMergeElementsDialogToClose() {
        setMergeElementsDialog(undefined);
    }

    function getSelectedCollectionOptions(selectedRows: ElementDto[]) {
        return collectionOptions.filter(collectionDto =>
                selectedRows.flatMap(elementSearchDto =>
                        elementSearchDto.collections.map(collection => collection.code)).indexOf(collectionDto.code) !== -1);
    }

    function getAvailableLabelOptions(selectedRows: ElementDto[]): ILabelDto[] {
        let availableLabelOptions = labelOptions;
        availableLabelOptions = filterOnlyAssignedLabels(availableLabelOptions, selectedRows);
        if (canUpdatePublicLabelsForAllElements(selectedRows)) {
            return availableLabelOptions;
        } else {
            return availableLabelOptions.filter(value => !value.publicLabel);
        }
    }

    function filterOnlyAssignedLabels(labelOptions: ILabelDto[], selectedRows: ElementDto[]): ILabelDto[] {
        let assignedLabelsCodesSet = getAssignedLabelsSet(selectedRows);
        return labelOptions.filter(labelDto => assignedLabelsCodesSet.has(labelDto.code));
    }

    function getAssignedLabelsSet(selectedRows: ElementDto[]) {
        let assignedLabelsCodes = selectedRows.flatMap(element => element.labels).map(label => label.code);
        return new Set(assignedLabelsCodes);
    }

    function canUpdatePublicLabelsForAllElements(selectedRows: ElementDto[]): boolean {
        let elementDto = selectedRows.find(value => !value.acl.canUpdatePublicLabels);
        return elementDto === undefined;
    }

    function showChangeTypeConfirmationDialog(selectedRowIds: Array<string>) {
        setChangeElementsTypeDialog({
            objectDialogIsOpen: true,
            onClosed: () => setChangeElementsTypeDialogToClose(),
            message: _transl(ElementTranslationKey.DIALOG_CHANGE_ELEMENT_TYPE_MESSAGE),
        });
        setSelectedRowIds(selectedRowIds);
    }

    function showRelationshipsCreateDialog(selectedRowIds: string[], selectedRows: ElementDto[]) {
        setSelectedRows(selectedRows);
        setSelectedRowIds(selectedRowIds);
        setAddBindingElementsDialog({
            objectDialogIsOpen: true,
            onClosed: () => setElementsBindingDialogToClose(),
            message: _transl(ElementTranslationKey.DIALOG_ELEMENT_BINDING_MESSAGE),
        });
    }

    function elementsAreTheSameType(selectedRows: ElementDto[]) {
        const firstItem = selectedRows[0].type;

        return selectedRows.filter(elements => elements.type === firstItem).length === selectedRows.length;
    }

    function showMergeElementsAlertDialog() {
        setAlertDialog({
            open: true,
            type: AlertDialogType.INFO,
            title: _transl(ElementTranslationKey.ALERT_DIALOG_MERGE_TITLE),
            text: _transl(ElementTranslationKey.ALERT_DIALOG_MERGE_TEXT)
        });
    }

    const {filter, refetchData, gridPresets, onGridPresetsChanged, persistentStateId,
            isDefaultPresetsAvailable, onSavePresetsAsDefault, onLoadDefaultPresets, onResetDefaultPresets,
            onSelectionChanged, bulkActionsOpenedPermanently} = props;

    const itemAddedMessage = _transl(ElementTranslationKey.ELEMENT_ADDED_MESSAGE);
    const userAcl = user?.userAcl;

    const customColumnDefs = buildCustomAttributeColumns(gridPresets);
    const customAttributeNames = customColumnDefs.map(column => extractPropertyName(column.field));
    const columns = [...ElementsGridDef.getGridColDef(), ...customColumnDefs];

    const elementsGridType = props.elementsGridType ? props.elementsGridType : ElementsGridType.OVERVIEW;

    let menuItems: ContextMenuItem<string>[];
    let actions: GridAction[];

    function isAtLeastOneIdentifier(): boolean {
        if (!props.isAtLeastOneIdentifierMandatory || (filter && filter.identifiers && filter.identifiers.length > 0)) {
            return true;
        } else {
            return false;
        }
    }

    if (elementsGridType === ElementsGridType.PICKER) {
        menuItems = [];
        actions = [];
    } else {
        menuItems = [
            {
                icon: <DescriptionIcon/>,
                label: _transl(CommonTranslation.SHOW_DETAIL),
                onClick: (identifier) => showElementDetail(identifier)
            },
            {
                icon: <DeleteIcon/>,
                label: _transl(CommonTranslation.REMOVE),
                onClick: (identifier) => identifier != null && showDeleteConfirmationDialog([identifier]),
                isEnabled: isDeleteContextMenuItemEnabled
            },
            {
                icon: <LinkIcon/>,
                label: _transl(CommonTranslation.COPY_URL),
                onClick: (identifier) => copyElementUrl(identifier)
            },
        ];

        actions = [
            GridAction.detailButtonBuilder()
                .onClick((selectedRowIds, selectedRows) => {
                    showElementDetail(selectedRowIds[0] as string);
                }).build(),
            GridAction.buttonBuilder(ButtonId.ADD_ELEMENT, ActionButtonType.IMMEDIATE, _transl(ElementTranslationKey.ACTION_BUTTON_ADD_ELEMENT),
                <AddIcon/>)
                .enabledPolicy(EnabledPolicy.ALWAYS)
                .isEnabled(() => userAcl ? userAcl.canCreateElements : false)
                .isVisible(() => elementsGridType === ElementsGridType.OVERVIEW)
                .onClick(() => showAddConfirmationDialog(Constants.ITEM_OBJECT))
                .build(),
            GridAction.buttonBuilder(ButtonId.DELETE_ELEMENT, ActionButtonType.IMMEDIATE, _transl(ElementTranslationKey.ACTION_BUTTON_REMOVE_ELEMENT),
                <DeleteIcon/>)
                .enabledPolicy(EnabledPolicy.WHEN_AT_LEAST_ONE_SELECTED)
                .isEnabled(selectedElements => elementsController.canDeleteAllElements(selectedElements))
                .onClick(selectedRowIds => showDeleteConfirmationDialog(selectedRowIds))
                .build(),
            GridAction.bulkMenuBuilder("BULK_LABELS", _transl(ElementTranslationKey.ACTION_BUTTON_BULK_LABEL_ACTIONS),
                <LocalOffer/>, [
                    GridAction.bulkMenuItemBuilder(ButtonId.ADD_BULK_LABELS, _transl(ElementTranslationKey.ACTION_BUTTON_BULK_LABEL_ACTIONS_ASSIGNMENT) + "...",
                        <AddCircleOutlineIcon/>)
                        .onClick((selectedRowIds, selectedRows) => addBulkLabels(selectedRowIds as Array<string>, selectedRows as ElementDto[]))
                        .build(),
                    GridAction.bulkMenuItemBuilder(ButtonId.REMOVE_BULK_LABELS, _transl(ElementTranslationKey.ACTION_BUTTON_BULK_LABEL_ACTIONS_REMOVAL) + "...",
                        <RemoveCircleOutlineIcon/>)
                        .isEnabled((selectedRows) => getAvailableLabelOptions(selectedRows).length !== 0)
                        .onClick((selectedRowIds, selectedRows) => removeBulkLabels(selectedRowIds as Array<string>, selectedRows as ElementDto[]))
                        .build(),
                ]).build(),
            GridAction.bulkMenuBuilder("BULK_COLLECTIONS", _transl(ElementTranslationKey.ACTION_BUTTON_BULK_COLLECTION_ACTIONS),
                <DashboardTwoTone/>, [
                    GridAction.bulkMenuItemBuilder(ButtonId.ADD_BULK_COLLECTIONS, _transl(ElementTranslationKey.ACTION_BUTTON_BULK_COLLECTION_ACTIONS_ASSIGNMENT) + "...",
                        <AddCircleOutlineIcon/>)
                        .isEnabled(selectedElements => elementsController.canUpdateCollectionsOfAllElements(selectedElements))
                        .onClick((selectedRowIds, selectedRows) =>
                            addBulkCollections(selectedRowIds as Array<string>, selectedRows as ElementDto[]))
                        .build(),
                    GridAction.bulkMenuItemBuilder(ButtonId.REMOVE_BULK_COLLECTIONS, _transl(ElementTranslationKey.ACTION_BUTTON_BULK_COLLECTION_ACTIONS_REMOVAL) + "...",
                        <RemoveCircleOutlineIcon/>)
                        .isEnabled(selectedElements => elementsController.canUpdateCollectionsOfAllElements(selectedElements))
                        .onClick((selectedRowIds, selectedRows) => removeBulkCollections(selectedRowIds as Array<string>, selectedRows as ElementDto[]))
                        .build(),
                ]).build(),
            GridAction.bulkMenuBuilder("BULK_OTHERS", "", <MoreHoriz/>, [
                GridAction.bulkMenuItemBuilder(ButtonId.ADD_DIAGRAM, _transl(ElementTranslationKey.ACTION_BUTTON_CREATE_DIAGRAM),
                    <AccountTreeOutlined/>)
                    .isEnabled(selectedElements => elementsController.canCreateDiagramWithElements(selectedElements, userAcl))
                    .onClick((selectedRowIds, selectedRows) => {
                        setCreateDiagramDialogOpened(true);
                        setSelectedRows(selectedRows as ElementDto[])
                    }).build(),
                GridAction.bulkMenuItemBuilder(ButtonId.MERGE_ELEMENTS, _transl(ElementTranslationKey.ACTION_BUTTON_MERGE_ELEMENTS),
                    <MergeTypeIcon/>)
                    .enabledPolicy(EnabledPolicy.WHEN_MORE_SELECTED)
                    .isEnabled(selectedElements => elementsController.canMergeAllElements(selectedElements))
                    .onClick((selectedRowIds, selectedRows) => {
                        if (elementsAreTheSameType(selectedRows as ElementDto[])) {
                            showMergeElementsDialog(selectedRows as ElementDto[], selectedRowIds);
                        } else {
                            showMergeElementsAlertDialog()
                        }
                    }).build(),
                GridAction.bulkMenuItemBuilder(ButtonId.SHOW_NEIGHBOURS_CHART, _transl(ElementTranslationKey.ACTION_BUTTON_SHOW_NEIGHBOURHOOD_GRAPH),
                    <BubbleChartIcon/>)
                    .enabledPolicy(EnabledPolicy.WHEN_MORE_SELECTED)
                    .onClick((selectedRowIds, selectedRows) => {
                        showGraphDataChartDialog(selectedRowIds as Array<string>, _transl(ElementTranslationKey.GRAPH_OF_NEIGHBOURHOOD), (elementsIds) => Api.graphData.getNeighbours(elementsIds));
                    }).build(),
                GridAction.bulkMenuItemBuilder(ButtonId.SHOW_INTERRELATIONSHIPS_CHART, _transl(ElementTranslationKey.ACTION_BUTTON_SHOW_RELATIONSHIPS),
                    <Share/>)
                    .enabledPolicy(EnabledPolicy.WHEN_MORE_SELECTED)
                    .onClick((selectedRowIds, selectedRows) => {
                        showGraphDataChartDialog(selectedRowIds as Array<string>, _transl(ElementTranslationKey.GRAPH_OF_RELATIONSHIPS), (elementsIds) => Api.graphData.getInterrelationships(elementsIds));
                    }).build(),
                GridAction.bulkMenuItemBuilder(ButtonId.CHANGE_ELEMENTS_TYPE, _transl(ElementTranslationKey.ACTION_BUTTON_CHANGE_ELEMENT_TYPE),
                    <TransformIcon/>)
                    .isEnabled(selectedElements => elementsController.canAlterTypeOfAllElements(selectedElements))
                    .onClick((selectedRowIds, selectedRows) => {
                        showChangeTypeConfirmationDialog(selectedRowIds as Array<string>)
                    }).build(),
                GridAction.bulkMenuItemBuilder(ButtonId.ADD_BINDING_ELEMENTS, _transl(ElementTranslationKey.ACTION_BUTTON_ADD_RELATIONSHIP),
                    <InsertLink/>)
                    .onClick((selectedRowIds, selectedRows) => {
                        showRelationshipsCreateDialog(selectedRowIds as string[], selectedRows as ElementDto[]);
                    }).build(),
            ]).build(),
            GridAction.buttonBuilder(ButtonId.EXPORT_DATA, ActionButtonType.SPEED, _transl(ElementTranslationKey.ACTION_BUTTON_EXPORT_FILTERED_ELEMENTS),
                <IosShare/>)
                .enabledPolicy(EnabledPolicy.ALWAYS)
                .isEnabled(() => isAtLeastOneIdentifier())
                .onClick(() => exportData())
                .build(),
            GridAction.buttonBuilder("SAVE_PRESETS_AS_DEFAULT", ActionButtonType.SPEED, _transl(PagePresetsTranslationKey.SAVE_GRID_PRESETS_AS_DEFAULT),
                <Save/>)
                .enabledPolicy(EnabledPolicy.ALWAYS)
                .isEnabled(() => gridPresets !== undefined)
                .isVisible(() => onSavePresetsAsDefault !== undefined)
                .onClick(() => onSavePresetsAsDefault && onSavePresetsAsDefault())
                .build(),
            GridAction.buttonBuilder("LOAD_DEFAULT_PRESETS", ActionButtonType.SPEED, _transl(PagePresetsTranslationKey.RESTORE_TO_DEFAULT_GRID_PRESETS),
                <FormatPaint/>)
                .enabledPolicy(EnabledPolicy.ALWAYS)
                .isEnabled(() => isDefaultPresetsAvailable === true)
                .isVisible(() => onLoadDefaultPresets !== undefined)
                .onClick(() => onLoadDefaultPresets && onLoadDefaultPresets())
                .build(),
            GridAction.buttonBuilder("RESET_DEFAULT_PRESETS", ActionButtonType.SPEED, _transl(PagePresetsTranslationKey.RESET_GRID_PRESETS),
                <SettingsBackupRestore/>)
                .enabledPolicy(EnabledPolicy.ALWAYS)
                .isEnabled(() => gridPresets !== undefined)
                .isVisible(() => onSavePresetsAsDefault !== undefined)
                .onClick(() => onResetDefaultPresets && onResetDefaultPresets())
                .build()
        ];
    }

    return (
        <React.Fragment>
            {mergeElementsDialog && <MergeElementsDialog dialogIsOpen={mergeElementsDialog.dialogIsOpen}
                                                         onClosed={mergeElementsDialog.onClosed}
                                                         selectedRows={mergeElementsDialog.selectedRows}
                                                         selectedRowIds={mergeElementsDialog.selectedRowIds}
                                                         mergeIsDone={mergeElementsDialog.mergeIsDone}
            />}
            <CreateElementDialog
                open={objectDialogIsOpen}
                onCreated={(element) => showElementDetail(element.identifier)}
                onClosed={() => setObjectDialogIsClosed()}
                message={itemAddedMessage}
            />
            {changeElementsTypeDialog && <UpdateElementTypeDialog
                open={changeElementsTypeDialog.objectDialogIsOpen}
                onClosed={changeElementsTypeDialog.onClosed}
                elementIdentifiers={selectedRowIds}
                refetchFilter={refetchData}
            />}
            {addBindingElementsDialog && <CreateRelationshipDialog title={addBindingElementsDialog.message}
                                                                   onClosed={addBindingElementsDialog.onClosed}
                                                                   selectedRows={selectedRows}
                                                                   refetchFilter={refetchData}/>
            }
            {graphDataChartDialog && <GraphDataChartDialog isOpened={true}
                                                           entityId={selectedRowIds.join(",")}
                                                           elementIds={selectedRowIds}
                                                           onShowElementDetail={(id) => showElementDetail(id)}
                                                           onDialogClosed={() => setGraphDataChartDialog(undefined)}
                                                           refreshGraphData={graphDataChartDialog.refreshGraphData}
                                                           title={graphDataChartDialog.title}/>
            }
            {createDiagramDialogOpened && <CreateDiagramDialog isOpened={true}
                                                               initiallyPickedElements={selectedRows}
                                                               onDialogClosed={() => setCreateDiagramDialogOpened(false)}
                                                               onDiagramCreated={(identifier) => navigateToDiagramDetail(identifier)}/>
            }
            {showConfirmDialogType === ButtonId.DELETE_ELEMENT && <DeleteConfirmationInfoDialog open={true}
                                                                                                onConfirm={() => deleteElementsById(selectedRowIds)}
                                                                                                onCancel={() => hideConfirmationDialog()}
                                                                                                elementId={selectedRowId!}/>
            }
            {showConfirmDialogType === ButtonId.BULK_DELETE_ELEMENT && <ConfirmationDialog open={true}
                                                                                           title={_transl(ElementTranslationKey.CONFIRMATION_DIALOG_BULK_DELETE_TITLE)}
                                                                                           confirmationText={_transl(ElementTranslationKey.CONFIRMATION_DIALOG_BULK_DELETE_CONFIRMATION_TEXT)}
                                                                                           onConfirm={() => deleteElementsById(selectedRowIds)}
                                                                                           onReject={() => hideConfirmationDialog()}/>
            }
            <AddBulkLabelsToElementsDialog isOpened={isAddBulkLabelsToElementsDialogVisible}
                                           onClosed={() => setIsAddBulkLabelsToElementsDialogVisible(false)}
                                           elements={selectedRows}
                                           refetchFilter={refetchData}/>
            <RemoveBulkLabelsFromElementsDialog isOpened={isRemoveBulkLabelsFromElementsDialogVisible}
                                                onClosed={() => setIsRemoveBulkLabelsFromElementsDialogVisible(false)}
                                                elements={selectedRows}
                                                refetchFilter={refetchData}/>
            <AddBulkCollectionsToElementsDialog isOpened={isAddBulkCollectionsToElementsDialogVisible}
                                                onClosed={() => setIsAddBulkCollectionsToElementsDialogVisible(false)}
                                                elements={selectedRows}
                                                refetchFilter={refetchData}/>
            <RemoveBulkCollectionsFromElementsDialog isOpened={isRemoveBulkCollectionsFromElementsDialogVisible}
                                                     onClosed={() => setIsRemoveBulkCollectionsFromElementsDialogVisible(false)}
                                                     elements={selectedRows}
                                                     refetchFilter={refetchData}/>
            {alertDialog && alertDialog.open && <AlertDialog open={alertDialog.open}
                                                             type={alertDialog.type}
                                                             title={alertDialog.title}
                                                             text={alertDialog.text}
                                                             onClose={() => updateAlertDialog(false)}/>
            }
            <CustomPropertyColumnAddDialog open={customPropertyColumnAddDialogOpen}
                                           existingCustomPropertyNames={customAttributeNames}
                                           onClosed={() => closeCustomPropertyAddDialog()}
                                           onAddCustomColumn={(attributeName) => addCustomPropertyColumn(attributeName)}/>

            <ElementDetailDialog opened={elementDetailDialog.open}
                                 initialElementId={elementDetailDialog.elementId!}
                                 onClosed={closeElementDetail}/>

            <ExtGridWithDataProvider
                id={ELEMENTS_GRID_ID}
                dataProvider={dataProvider.current}
                filter={filter}
                columns={columns}
                getRowId={row => row.identifier}
                menuItems={menuItems}
                actions={actions}
                presets={gridPresets}
                onPresetsChanged={onGridPresetsChanged}

                onRowDoubleClick={(params, e) => {
                    showElementDetail(params.row.identifier as string);
                }}
                peristentStateId={persistentStateId}
                resourceId={getFilterStringWithoutPaging(filter)}

                onCustomColumnAddClick={showCustomPropertyAddDialog}
                onCustomColumnRemoveClick={removeCustomAttributeColumn}
                initialPageReset={props.initialPageReset}

                bulkActionsOpenedPermanently={bulkActionsOpenedPermanently}
                onSelectionChanged={(selectedRowIds, selectedRows) => onGridSelectionChanged(selectedRowIds as string[], selectedRows as ElementDto[])}

                isAtLeastOneIdentifierMandatory={props.isAtLeastOneIdentifierMandatory}
            />
        </React.Fragment>
    );

    function onGridSelectionChanged(selectedRowIds: string[], selectedRows: ElementDto[]) {
        setSelectedRows(selectedRows);
        if (onSelectionChanged) {
            onSelectionChanged(selectedRowIds, selectedRows);
        }
    }

    function showGraphDataChartDialog(selectedRowIds: Array<string>, title: string, apiCall: (elementIds: Array<string>) => Observable<AjaxResponse>) {
        setGraphDataChartDialog({
            title: title,
            refreshGraphData: apiCall,
        });
        setSelectedRowIds(selectedRowIds as Array<string>);
    }

    function showDeleteConfirmationDialog(selectedRowIds: string[]) {
        if (selectedRowIds.length === 1) {
            setShowConfirmDialogType(ButtonId.DELETE_ELEMENT);
            setSelectedRowIds(selectedRowIds);
        } else {
            setShowConfirmDialogType(ButtonId.BULK_DELETE_ELEMENT);
            setSelectedRowIds(selectedRowIds);
        }
    }

    function deleteElementsById(selectedRowIds: string[]) {
        setShowConfirmDialogType(undefined);
        (async () => {
            try {
                await elementService.deleteElementsById(selectedRowIds);
                onElementDeleteSuccess(selectedRowIds);
            } catch (error) {
                onElementDeleteFail(selectedRowIds);
            }
        })();
    }

    function showAddConfirmationDialog(constant: Constants) {
        setObjectDialogIsOpen(true)
    }

    function hideConfirmationDialog(callback ?: () => void) {
        setShowConfirmDialogType(undefined);
        if (callback) {
            callback();
        }
    }

    function onElementDeleteSuccess(selectedRowIds: string[]) {
        const text = selectedRowIds.length === 1 ? _transl(ElementTranslationKey.REMOVAL_SUCCEED_TEXT) :
            _transl(ElementTranslationKey.BULK_REMOVAL_SUCCEED_TEXT);
        Snackbar.success(text);

        setShowConfirmDialogType(undefined);
        props.refetchData();
    }

    function onElementDeleteFail(selectedRowIds: string[]) {
        const text = selectedRowIds.length === 1 ? _transl(ElementTranslationKey.REMOVAL_FAILED_TEXT) :
            _transl(ElementTranslationKey.BULK_REMOVAL_FAILED_TEXT);
        Snackbar.error(text);

        setShowConfirmDialogType(undefined);
    }

    function updateAlertDialog(open: boolean, type ?: AlertDialogType, title ?: string, text ?: string) {
        setAlertDialog({
            open: open,
            type: type || AlertDialogType.INFO,
            title: title || "",
            text: text || "",
        });
    }

    function exportData() {
        const {filter} = props;
        const dto: IElementsExportDto = {
            filter: filter,
            format: FileFormat.EXCEL.getFileFormatType(),
        };
        Api.exports.exportElements(dto).subscribe(
            (resp) => {
                const disposition = resp.xhr.getResponseHeader('Content-Disposition') as string;
                const fileName = disposition.substring(disposition?.indexOf("filename=") + 9);
                const blob = new Blob([resp.response]);

                BlobUtils.saveBlob(blob, fileName);
            },
            () => {
                updateAlertDialog(true, AlertDialogType.ERROR, _transl(ElementTranslationKey.EXPORT),
                    _transl(ElementTranslationKey.EXPORT_FAILED_TEXT));
            }
        );
    }

    function navigateToDiagramDetail(identifier: string) {
        const path = RouteDefinitionUtils.resolveDiagramDetailPath(identifier);
        history.push(path);
    }

    function showElementDetail(elementIdentifier?: string) {
        if (elementIdentifier) {
            setElementDetailDialog({
                open: true,
                elementId: elementIdentifier,
            });
        }
    }

    function closeElementDetail(reload: boolean) {
        setHandleShowDetailQueryAction(false);
        setElementDetailDialog({
            open: false,
            elementId: undefined,
        })
        if (reload) {
            handleOnElementUpdated();
        }
    }

    function handleOnElementUpdated() {
        dataProvider.current = {fetch: elementService.searchByFilter};
    }

    function copyElementUrl(rowId: string | undefined) {
        if (rowId !== undefined) {
            const path = RouteDefinitionUtils.resolveElementDetailPath(rowId);
            const url = Constants.attachAppHost(path);
            from(navigator.clipboard.writeText(url)).subscribe(
                () => {
                    Snackbar.success(_transl(ElementTranslationKey.URL_COPIED));
                },
                () => {
                }
            )
        }
    }

    function isDeleteContextMenuItemEnabled(rowId ?: string): boolean {
        if (rowId) {
            const elements = selectedRows.filter(element => element.identifier === rowId);
            if (elements.length === 1) {
                return elements[0].acl.canDelete;
            }
        }
        return false;
    }

    function getFilterStringWithoutPaging(filter: IFilter) {
        const filterCopy = JSON.parse(JSON.stringify(filter || {})) as IFilter;
        filterCopy.pageToken = undefined;
        filterCopy.maxPageSize = undefined;
        return JSON.stringify(filterCopy);
    }

    function showCustomPropertyAddDialog() {
        setCustomPropertyColumnAddDialogOpen(true);
    }

    function closeCustomPropertyAddDialog() {
        setCustomPropertyColumnAddDialogOpen(false);
    }

    function addCustomPropertyColumn(propertyName: string) {
        if (gridPresets?.columns) {
            const columnPresets: ColumnPresets = {
                field: `properties#${propertyName}`
            };
            const columns = [...gridPresets.columns];
            columns.push(columnPresets);
            if (onGridPresetsChanged) {
                const presets = {
                    columns: columns
                };
                onGridPresetsChanged(presets);
            }
        }
    }

    function removeCustomAttributeColumn(colDef: GridColDef) {
        if (gridPresets?.columns) {
            const columns = gridPresets.columns.filter((columnPresets) => columnPresets.field !== colDef.field);
            if (onGridPresetsChanged) {
                const presets = {
                    columns: columns
                };
                onGridPresetsChanged(presets);
            }
        }
    }

    function buildCustomAttributeColumns(gridPresets ?: GridPresets): GridColDef[] {
        if (gridPresets) {
            return gridPresets.columns
                .filter(columnPresets => columnPresets.field.startsWith("properties#"))
                .map(columnPresets => createPropertyColumn(columnPresets.field));
        } else {
            return [];
        }
    }

    function createPropertyColumn(propertyField: string): GridColDef {
        const propertyName = extractPropertyName(propertyField);
        return {
            field: propertyField,
            headerName: propertyName,
            headerClassName: 'datagrid-column',
            valueGetter: params => {
                const element = params.row as ElementDto;
                return element.properties?.filter(prop => prop.definition.name === propertyName)
                    .map(prop => prop.value);
            },
            sortable: false
        };
    }

    function extractPropertyName(propertyField: string) {
        return propertyField.substring("properties#".length);
    }
}
