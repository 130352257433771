import Constants from "../Constants";
import {Observable, Subscriber} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";
import Api from "../Api";
import {IFilter} from "../../store/diagrams/Diagrams";
import {CollectionDto} from "./collection/CollectionDto";
import {ColorDto} from "./Color";
import {ILabelDto} from "./label/ILabelDto";
import {FileFormatType} from "./Exports";
import {IModelDto} from "./model/IModelDto";
import {AjaxRequest} from "rxjs/internal/observable/dom/AjaxObservable";
import {DiagramDefaultsDto} from "./diagram/DiagramDefaultsDto";
import {map} from "rxjs/operators";

export interface FontDto {
    color: ColorDto | null,
    name: string | null
    size: number | null,
    styles: Array<string> | null,
}

export interface IOrganizationItemDto {
    identifier: string,
    label: string,
    documentation: string,
    childItems: Array<IOrganizationItemDto>,
}

export interface IOrganizationDto {
    items: Array<IOrganizationItemDto>,
}

export interface DiagramVisualizationInputDto {
    identifier: string,
    format : FileFormatType;
    imageSizeInPixels : number,
}

export interface DiagramVisualizationInputWithModelDto {
    format : FileFormatType,
    model : IModelDto
}

export class DiagramsApi {

    public static DIAGRAM_ID_PARAM = ":diagramId";
    public static ATTACHMENT_ID_PARAM = ":attachmentId";
    public static ENDPOINT_URL: string = Constants.API_HOST + "/rest-api/repository/diagrams";
    public static SEARCH_URL: string = DiagramsApi.ENDPOINT_URL + ":search";
    public static CONCRETE_DIAGRAM_URL: string = `${DiagramsApi.ENDPOINT_URL}/${DiagramsApi.DIAGRAM_ID_PARAM}`;
    public static GET_DIAGRAM_BY_ID_URL: string = DiagramsApi.ENDPOINT_URL + "/:id";
    public static UPDATE_COLLECTIONS_URL: string = `${DiagramsApi.ENDPOINT_URL}/${DiagramsApi.DIAGRAM_ID_PARAM}/collections`;
    public static UPDATE_LABELS_URL: string = `${DiagramsApi.ENDPOINT_URL}/${DiagramsApi.DIAGRAM_ID_PARAM}/labels`;
    public static GET_ATTACHMENTS_BY_IDENTIFIER_URL: string = `${DiagramsApi.ENDPOINT_URL}/${DiagramsApi.DIAGRAM_ID_PARAM}/attachments`;
    public static DOWNLOAD_ATTACHMENT_BY_IDENTIFIER_URL: string = `${DiagramsApi.ENDPOINT_URL}/${DiagramsApi.DIAGRAM_ID_PARAM}/attachments/${DiagramsApi.ATTACHMENT_ID_PARAM}`;
    public static UPLOAD_ATTACHMENT_BY_IDENTIFIER_URL: string = `${DiagramsApi.ENDPOINT_URL}/${DiagramsApi.DIAGRAM_ID_PARAM}/attachments`;
    public static DELETE_ATTACHMENT_BY_IDENTIFIER_URL: string = `${DiagramsApi.ENDPOINT_URL}/${DiagramsApi.DIAGRAM_ID_PARAM}/attachments/${DiagramsApi.ATTACHMENT_ID_PARAM}`;
    public static PUT_ADD_BULK_LABELS_TO_DIAGRAMS: string = `${DiagramsApi.ENDPOINT_URL}/bulk/labels:add`;
    public static DELETE_BULK_LABELS_TO_DIAGRAMS: string = `${DiagramsApi.ENDPOINT_URL}/bulk/labels:delete`;
    public static PUT_ADD_BULK_COLLECTIONS_TO_DIAGRAMS: string = `${DiagramsApi.ENDPOINT_URL}/bulk/collections:add`;
    public static DELETE_BULK_COLLECTIONS_TO_DIAGRAMS: string = `${DiagramsApi.ENDPOINT_URL}/bulk/collections:delete`;
    public static DELETE_DIAGRAM_URL: string = `${DiagramsApi.ENDPOINT_URL}/${DiagramsApi.DIAGRAM_ID_PARAM}`;
    public static DELETE_BULK_DIAGRAMS: string = `${DiagramsApi.ENDPOINT_URL}/bulk:delete`;
    public static GET_ELEMENT_LIST_FOR_DIAGRAM_IDS: string = `${DiagramsApi.ENDPOINT_URL}/bulk:list-elements`;


    doSearch(filter: IFilter): Observable<AjaxResponse> {
        return Api.createAjax({
            url: DiagramsApi.SEARCH_URL,
            method: "POST",
            body: {
                "collections": filter.collections != null ? filter.collections.map(coll => coll.code) : [],
                "identifiers": filter.identifiers ? [filter.identifiers] : [],
                "labels": filter.labels != null ? filter.labels.map(label => label.code) : [],
                "nameLike": filter.nameLike,
                "viewpoints": filter.viewpoints != null ? filter.viewpoints.map(coll => coll.identifier) : [],
            }
        });
    }

    getDiagramById(identifier: string): Observable<AjaxResponse> {
        return Api.createAjax(
            {
                url: DiagramsApi.GET_DIAGRAM_BY_ID_URL.replace(":id", identifier),
                method: "GET",
            });
    }

    updateCollections(diagramId: string, collections: Array<CollectionDto>) {
        return Api.createAjax({
            url: Api.replaceUrlParams(DiagramsApi.UPDATE_COLLECTIONS_URL, {[DiagramsApi.DIAGRAM_ID_PARAM]: diagramId}),
            method: "PUT",
            body: {
                "collectionsCodes": collections.map(item => item.code)
            }
        });
    }

    updateLabels(diagramId: string, labels: Array<ILabelDto>) {
        return Api.createAjax({
            url: Api.replaceUrlParams(DiagramsApi.UPDATE_LABELS_URL, {[DiagramsApi.DIAGRAM_ID_PARAM]: diagramId}),
            method: "PUT",
            body: {
                "labelsCodes": labels.map(item => item.code)
            }
        });
    }


    updateNameById(diagramId: any, text: string): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Api.replaceUrlParams(DiagramsApi.CONCRETE_DIAGRAM_URL, {[DiagramsApi.DIAGRAM_ID_PARAM]: diagramId}),
            method: "PATCH",
            body: Object.fromEntries(new Map([
                ["name", text]
            ])),
        });
    }

    updateDescriptionById(diagramId: any, text: string): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Api.replaceUrlParams(DiagramsApi.CONCRETE_DIAGRAM_URL, {[DiagramsApi.DIAGRAM_ID_PARAM]: diagramId}),
            method: "PATCH",
            body: Object.fromEntries(new Map([
                ["description", text]
            ])),
        });
    }

    updateViewpointById(diagramId: any, text: string): Observable<AjaxResponse> {
        return Api.createAjax({
            url: Api.replaceUrlParams(DiagramsApi.CONCRETE_DIAGRAM_URL, {[DiagramsApi.DIAGRAM_ID_PARAM]: diagramId}),
            method: "PATCH",
            body: Object.fromEntries(new Map([
                ["viewpoint", text]
            ])),
        });
    }

    getAttachmentsByIdentifier(identifier: string): Observable<AjaxResponse> {
        return Api.createAjax({
            url: DiagramsApi.GET_ATTACHMENTS_BY_IDENTIFIER_URL.replace(DiagramsApi.DIAGRAM_ID_PARAM, identifier),
            method: "GET"
        });
    }

    downloadAttachment(elementId: string, attachmentId: string, responseType: string, progressSubscriber?: Subscriber<ProgressEvent<EventTarget>>) {
        return Api.createAjax({
            url: Api.replaceUrlParams(DiagramsApi.DOWNLOAD_ATTACHMENT_BY_IDENTIFIER_URL, {[DiagramsApi.DIAGRAM_ID_PARAM]: elementId, [DiagramsApi.ATTACHMENT_ID_PARAM]: attachmentId}),
            method: "GET",
            responseType: responseType,
            createXHR: function (){
                const xhr = new XMLHttpRequest();
                if (progressSubscriber != null) {
                    xhr.onprogress = (progress) => progressSubscriber.next(progress);
                }
                return xhr;
            }
        });
    }

    deleteAttachment(elementId: string, attachmentId: string) {
        return Api.createAjax({
            url: Api.replaceUrlParams(DiagramsApi.DELETE_ATTACHMENT_BY_IDENTIFIER_URL, {[DiagramsApi.DIAGRAM_ID_PARAM]: elementId, [DiagramsApi.ATTACHMENT_ID_PARAM]: attachmentId}),
            method: "DELETE",
        });
    }

    uploadAttachment(elementId: string, file: File, responseType: string, progressSubscriber?: Subscriber<ProgressEvent<EventTarget>>) {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return Api.createAjax({
            url: Api.replaceUrlParams(DiagramsApi.UPLOAD_ATTACHMENT_BY_IDENTIFIER_URL, {[DiagramsApi.DIAGRAM_ID_PARAM]: elementId}),
            method: "POST",
            body: formData,
            createXHR: function (){
                const xhr = new XMLHttpRequest();
                if (progressSubscriber != null) {
                    xhr.upload.onprogress = (progress) => progressSubscriber.next(progress);
                }
                return xhr;
            }
        }, true);
    }

    deleteDiagram(selectedRowId: string) {
        return Api.createAjax({
                url: DiagramsApi.DELETE_DIAGRAM_URL.replace(DiagramsApi.DIAGRAM_ID_PARAM, selectedRowId),
                method: "DELETE",
            });
    }

    deleteDiagrams(selectedRowIds: Array<string>) {
        return Api.createAjax({
                url: DiagramsApi.DELETE_BULK_DIAGRAMS,
                method: "DELETE",
                body: selectedRowIds,
            });
    }

    //labels bulk assignment - adds labels to selected diagrams
    bulkAssignLabels(labelCodes: unknown[], selectedRowIds: Array<string>) {
        return Api.createAjax({
                url: DiagramsApi.PUT_ADD_BULK_LABELS_TO_DIAGRAMS,
                method: "PUT",
                body: {
                    labelsCodes: labelCodes,
                    identifiers: selectedRowIds,
                },
            });
    }

    //labels bulk delete - deletes labels from selected diagrams
    bulkDeleteLabels(labelCodes: unknown[], selectedRowIds: Array<string>) {
        return Api.createAjax({
                url: DiagramsApi.DELETE_BULK_LABELS_TO_DIAGRAMS,
                method: "DELETE",
                body: {
                    labelsCodes: labelCodes,
                    identifiers: selectedRowIds,
                },
            });
    }

    //collections bulk assignment - adds collections to selected diagrams
    bulkAssignCollections(collectionCodes: unknown[], selectedRowIds: Array<string>) {
        return Api.createAjax({
                url: DiagramsApi.PUT_ADD_BULK_COLLECTIONS_TO_DIAGRAMS,
                method: "PUT",
                body: {
                    collectionsCodes: collectionCodes,
                    identifiers: selectedRowIds,
                },
            });
    }

    //collections bulk delete - deletes collections from selected diagrams
    bulkDeleteCollections(collectionCodes: unknown[], selectedRowIds: Array<string>) {
        return Api.createAjax({
                url: DiagramsApi.DELETE_BULK_COLLECTIONS_TO_DIAGRAMS,
                method: "DELETE",
                body: {
                    collectionsCodes: collectionCodes,
                    identifiers: selectedRowIds,
                },
            });
    }

    getElementListForDiagramIds(diagramIds: Array<string>) {
        return Api.createAjax({
                url: DiagramsApi.GET_ELEMENT_LIST_FOR_DIAGRAM_IDS,
                method: "POST",
                body: diagramIds,
            });
    }

    getDiagramDefaults() {
        var request: AjaxRequest = {
            url: `${DiagramsApi.ENDPOINT_URL}/defaults`,
            method: "GET",
        };
        return Api.createAjaxWithoutToken(request)
            .pipe(
                map(response => response.response as DiagramDefaultsDto)
            )
            .toPromise();
    }

    visualizeDiagram(dto: DiagramVisualizationInputDto) {
        return Api.createAjax({
            url: `${DiagramsApi.ENDPOINT_URL}/${dto.identifier}:visualize?format=${dto.format}`,
            method: "GET",
            responseType: "blob",
        });
    }

    visualizeDiagramSendingModel(dto: DiagramVisualizationInputWithModelDto) {
        return Api.createAjax({
            url: `${DiagramsApi.ENDPOINT_URL}:visualize?format=${dto.format}`,
            method: "POST",
            responseType: "blob",
            body: dto.model
        });
    }
}

const diagramsApi = new DiagramsApi();
export default diagramsApi;


