import EventManager, {Unsubscriber} from "../../event/EventManager";
import {
    EventType,
    IChartEvent, IModelUpdatedOnNodeLabelUpdateEvent, INodeEvent, INodeLabelUpdateCancelledEvent,
    ISelectionChangedEvent, OnDeleteSelectedItemsMenuActivatedEvent,
    RemoveSelectedItemsEvent
} from "../../event/Event";
import {IDiagramConnectionDto} from "../../apis/diagram/IDiagramConnectionDto";
import {IDiagramNodeDto} from "../../apis/diagram/IDiagramNodeDto";
import RenderMode from "../context/RenderMode";
import {ModelManager} from "./ModelManager";
import {IEditMode} from "../editor/IEditMode";
import {IMode} from "../model/IMode";
import {DiagramEditorEventType, ModalWindowVisibilityChangedEvent} from "../../event/diagrameditor/DiagramEditorEvents";

export default class SelectedItemsRemoveManager {

    private eventManager: EventManager;
    private modelManager?: ModelManager;
    private mode: IMode;
    private selectedNodes: Array<IDiagramNodeDto>;
    private selectedConnections: Array<IDiagramConnectionDto>;
    private openedModalWindowsCounter: number;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager,
                mode: IMode) {
        this.eventManager = eventManager;
        this.mode = mode;
        this.unsubscribers.push(eventManager.subscribeListener<ISelectionChangedEvent>(EventType.SELECTION_CHANGED, this.handleSelectionChangedEvent.bind(this)));
        this.unsubscribers.push(eventManager.subscribeListener<IChartEvent>(EventType.CHART_KEYUP, this.handleChartKeyupEvent.bind(this)));
        this.unsubscribers.push(eventManager.subscribeListener<OnDeleteSelectedItemsMenuActivatedEvent>(EventType.ON_DELETE_SELECTED_ITEMS_MENU_ACTIVATED, this.handleOnDeleteItemsMenuActivatedEvent.bind(this)));
        this.unsubscribers.push(eventManager.subscribeListener(EventType.NODE_DBLCLICK, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(eventManager.subscribeListener(EventType.MODEL_UPDATED_ON_NODE_LABEL_UPDATE, this.handleModelUpdatedOnNodeLabelUpdateEvent.bind(this)));
        this.unsubscribers.push(eventManager.subscribeListener(EventType.NODE_LABEL_UPDATE_CANCELLED, this.handleNodeLabelUpdateCancelledEvent.bind(this)));
        this.unsubscribers.push(eventManager.subscribeListener(DiagramEditorEventType.MODAL_WINDOW_VISIBILITY_CHANGED, this.handleModalWindowVisibilityChangedEvent.bind(this)));

        this.selectedNodes = [];
        this.selectedConnections = [];
        this.openedModalWindowsCounter = 0;
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    init(modelManager: ModelManager) {
        this.modelManager = modelManager;
    }

    private handleSelectionChangedEvent(event: ISelectionChangedEvent) {
        this.selectedNodes = event.selectedNodes;
        this.selectedConnections = event.selectedConnections;
    }

    private handleChartKeyupEvent(event: IChartEvent) {
        if (this.shouldPublishDeleteSelectedItemsEvent(event)) {
            this.showDeleteSelectedItemsDialog();
        }
    }

    private shouldPublishDeleteSelectedItemsEvent(event: IChartEvent) {
        return event.type === EventType.CHART_KEYUP &&
            event.event.key === "Delete" &&
            this.isAnyItemSelected()
    }

    private isAnyItemSelected() {
        return this.selectedNodes.length > 0 || this.selectedConnections.length > 0;
    }

    private handleOnDeleteItemsMenuActivatedEvent(event: OnDeleteSelectedItemsMenuActivatedEvent) {
        this.showDeleteSelectedItemsDialog();
    }

    private handleNodeEvent(event: INodeEvent) {
        if (event.type === EventType.NODE_DBLCLICK) {
            this.openedModalWindowsCounter += 1;
        }
    }

    private handleModelUpdatedOnNodeLabelUpdateEvent(event: IModelUpdatedOnNodeLabelUpdateEvent) {
        if (event.type === EventType.MODEL_UPDATED_ON_NODE_LABEL_UPDATE) {
            if (this.openedModalWindowsCounter > 0) {
                this.openedModalWindowsCounter -= 1;
            }
        }
    }

    private handleNodeLabelUpdateCancelledEvent(event: INodeLabelUpdateCancelledEvent) {
        if (event.type === EventType.NODE_LABEL_UPDATE_CANCELLED) {
            if (this.openedModalWindowsCounter > 0) {
                this.openedModalWindowsCounter -= 1;
            }
        }
    }

    private handleModalWindowVisibilityChangedEvent(event: ModalWindowVisibilityChangedEvent) {
        if (event.isVisible) {
            this.openedModalWindowsCounter += 1;
        } else if (this.openedModalWindowsCounter > 0) {
            this.openedModalWindowsCounter -= 1;
        }
    }

    private publishDeleteSelectedItemsEvent(removeElementsAndRelationships: boolean) {
        this.eventManager.publishEvent<RemoveSelectedItemsEvent>({
            type: EventType.REMOVE_SELECTED_OBJECTS,
            nodes: this.selectedNodes,
            connections: this.selectedConnections,
            removeElementsAndRelationships: removeElementsAndRelationships,
        })
    }

    private showDeleteSelectedItemsDialog() {
        if (this.mode.mode === RenderMode.EDIT && this.openedModalWindowsCounter === 0) {
            (this.mode as IEditMode).diagramApi.showRemoveObjectsDialog(
                this.selectedNodes, this.selectedConnections, (event: any, removeElementsAndRelationships: boolean) => this.publishDeleteSelectedItemsEvent(removeElementsAndRelationships), () => {});
        }
    }

}

