import {ColorDto} from "../../../common/apis/Color";


export enum UpdateMode {
    KEEP_UNCHANGED = "keepUnchanged",
    RESET_TO_DEFAULTS = "resetToDefaults",
    USE_CUSTOM = "useCustom",
}

export enum FontStyle {
    BOLD = "bold",
    ITALIC = "italic",
    UNDERLINE = "underline",
}

export interface StyleSettings {
    fillColorUpdateMode: UpdateMode,
    fillColor: ColorDto,
    lineColorUpdateMode: UpdateMode,
    lineColor: ColorDto,
    textColorUpdateMode: UpdateMode,
    textColor: ColorDto,
    fontStylesUpdateMode: UpdateMode,
    fontStyles: FontStyle[],
    fontSizeUpdateMode: UpdateMode,
    fontSize: number,
}
