import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import React, {useCallback, useEffect, useReducer, useState} from "react";
import {Badge, Tab, Tabs} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import ElementBasicInfoPanel from "./main/ElementBasicInfoPanel";
import AttachmentsPanel from "../../../../components/attachment/AttachmentsPanel";
import {AttachmentType} from "../../../../store/common/Attachments";
import {IApplicationState} from "../../../../store/Store";
import {useDispatch, useSelector} from "react-redux";
import {getUiComponentsStateUpdateAction, UiComponentsStateType} from "../../../../store/common/UiComponentsState";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import {ElementDetailTranslationKey} from "./ElementDetailTranslationKey";
import {_transl} from "../../../../store/localization/TranslMessasge";
import chatService, {ChatDto} from "../../../../common/apis/chat/ChatService";
import Snackbar from "../snackbar/Snackbar";
import {ErrorTranslationKey} from "../ErrorTranslationKey";
import ChatBox from "../chat/detail/ChatBox";

// state
enum TabId {
    BASIC = "BASIC",
    COMMENTS = "COMMENTS",
    ATTACHMENTS = "ATTACHMENTS",
}

interface IState {
    attachmentsCount: number,
}

function createInitialState(attachmentsCount: number): IState {
    return {
        attachmentsCount: attachmentsCount,
    }
}

enum ActionType {
    UPDATE_COMMENTS_COUNT = "UPDATE_COMMENTS_COUNT",
    UPDATE_ATTACHMENTS_COUNT = "UPDATE_ATTACHMENTS_COUNT",
}

type Action =
    {type: ActionType.UPDATE_ATTACHMENTS_COUNT, payload: number} |
    {type: ActionType.UPDATE_COMMENTS_COUNT, payload: number};


function reducer(state: IState, action: Action): IState {
    switch(action.type) {
        case ActionType.UPDATE_ATTACHMENTS_COUNT:
            if (state.attachmentsCount !== action.payload) {
                return {
                    ...state,
                    attachmentsCount: action.payload,
                }
            }
            return state;
        default: return state;
    }
}


// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tabs: {
            "&> span:first-child": {
                paddingTop: 9,
            },
            paddingTop: 12,
        },
    })
);

// props
interface Props {
    element: ElementDto,
    onPropertyUpdate: () => void,
}

export const TABS_HEIGHT = "48px";

export default function MainPanel({element, onPropertyUpdate}: Props) {
    const classes = useStyles();

    // STORE
    const selectedTab = useSelector((state: IApplicationState) => state.uiComponentsState[UiComponentsStateType.ITEM_DETAIL_DETAIL_SELECTED_TAB] as TabId || TabId.BASIC);
    const reduxDispatch = useDispatch();

    // STATE
    const [{attachmentsCount}] = useReducer(reducer, createInitialState(element.attachmentsCount || 0));

    const [chats, setChats] = useState<ChatDto[]>([]);

    const fetchChats = useCallback(() => {
        chatService.searchChatsByFilter({elementId: element.identifier})
            .then(chats => {
                setChats(chats);
            })
            .catch(() => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA)));
    }, [element.identifier]);

    useEffect(() => {
        fetchChats();
    }, [fetchChats]);

    function onChatCommentEdited(chatId: number, commentId: number, message: string) {
        const copiedChats = [...chats];
        const chat = copiedChats.find((chat) => chat.id === chatId);
        if (chat) {
            const comment = chat.chatComments.find(comment => comment.id === commentId);
            if (comment) {
                comment.message = message;
            }
            setChats(copiedChats);
        }
        fetchChats();
    }

    return (
        <TabContext value={selectedTab}>
            <Tabs
                value={selectedTab}
                onChange={(e, val) => reduxDispatch(getUiComponentsStateUpdateAction(UiComponentsStateType.ITEM_DETAIL_DETAIL_SELECTED_TAB, val))}
                indicatorColor="primary"
                textColor="primary"
                style={{height: TABS_HEIGHT}}
            >
                <Tab className={classes.tabs}
                     value={TabId.BASIC}
                     label={<span>{_transl(ElementDetailTranslationKey.TITLE)}</span>} />
                <Tab className={classes.tabs}
                     value={TabId.COMMENTS}
                     label={
                         <Badge badgeContent={`${chats.length}`} color={"secondary"}>
                             {_transl(ElementDetailTranslationKey.COMMENTS)}
                         </Badge>
                     } />
                <Tab className={classes.tabs}
                     value={TabId.ATTACHMENTS}
                     label={
                         <Badge badgeContent={`${attachmentsCount}`} color={"secondary"}>
                             {_transl(ElementDetailTranslationKey.ATTACHMENTS)}
                         </Badge>
                     } />
            </Tabs>
            <TabPanel value={TabId.BASIC}>
                <ElementBasicInfoPanel element={element}
                                       onPropertyUpdate={onPropertyUpdate}
                />
            </TabPanel>
            <TabPanel value={TabId.COMMENTS}
                      style={{height: `calc(100% - ${TABS_HEIGHT})`}}>
                <ChatBox chats={chats}
                         canCreateChats={element.acl.canCreateChats}
                         onChatsUpdated={fetchChats}
                         onChatCommentEdited={onChatCommentEdited}
                         elementId={element.identifier}/>
            </TabPanel>
            <TabPanel value={TabId.ATTACHMENTS}>
                <AttachmentsPanel resourceType={AttachmentType.ELEMENTS}
                                  resourceId={element.identifier}
                                  onAttachmentsUpdated={() => {}}
                                  canUploadAttachment={element.acl.canCreateAttachment}
                />
            </TabPanel>
        </TabContext>
    );
}
