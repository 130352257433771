import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import React from "react";
import {MenuItem, Select} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IDiagramEditorApi from "../../../../../common/diagrameditor/api/IDiagramEditorApi";
import ElementTypeIcon from "../../../../../components/fields/ElementTypeIcon";
import {DiagramEditorUtils} from "../../../../../common/diagrameditor/util/DiagramEditorUtils";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import IDiagramApi from "../../../../../common/diagrameditor/api/IDiagramApi";
import {PARENT_CHILD_RELATIONSHIP_TYPES} from "../../../../../common/diagrameditor/manager/ModelManager";
import {ArchimateElement, ArchimateElementType} from "../../../../../common/archimate/ArchimateElement";
import {ArchimateRelationship, ArchimateRelationshipType} from "../../../../../common/archimate/ArchimateRelationship";
import {IDiagramNodeDto} from "../../../../../common/apis/diagram/IDiagramNodeDto";
import {NodeType} from "../../../../../common/apis/diagram/NodeType";
import {ObjectType} from "../../../../../common/apis/editor/ObjectType";
import {RelationshipDto} from "../../../../../common/apis/relationship/RelationshipDto";
import {ConnectableConceptType} from "../../../../../common/apis/relationship/ConnectableConceptType";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "../DiagramTranslationKey";
import {RelationshipAclDto} from "../../../../../common/apis/relationship/RelationshipAclDto";
import Dialog from "../../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../../components/dialogs/DialogActions";

const styles = (theme: Theme) => createStyles({
    iconWrapper: {
        display: "flex",
        minWidth: "1em",
    },
    icon: {
        width: "1em",
        height: "1em",
        display: "inline-block",
        fontSize: "1.5em",
    },
    relationshipSelect: {
        minWidth: "20em",
        "& .MuiSelect-root": {
            paddingTop: "4px",
            paddingBottom: "4px",
        }
    },
    nodeLabel: {
        display: "flex",
        alignItems: "center",
    },
});

interface IProps extends WithStyles<typeof styles> {
    parentCandidate: IDiagramNodeDto,
    nodesToConnect: Array<IDiagramNodeDto>,
    onConfirm: (event: any, createdRelationships: Array<RelationshipDto>) => void,
    onCancel: (event: any) => void,
    diagramEditorApi: IDiagramEditorApi,
    diagramApi: IDiagramApi,
}

const EMPTY_SELECT_VALUE = "-";

interface IState {
    nodeIdToRelEnumMap: {[elementId: string]: ArchimateRelationshipType | typeof EMPTY_SELECT_VALUE},
}

class NestingConnectionCreationDialog extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const map: {[nodeId: string]: ArchimateRelationshipType | typeof EMPTY_SELECT_VALUE} = {};
        props.nodesToConnect.forEach(node => map[node.elementIdentifier as string] = PARENT_CHILD_RELATIONSHIP_TYPES[0]);
        this.state = {
            nodeIdToRelEnumMap: map,
        }
    }

    render() {

        const {classes, onCancel, onConfirm, parentCandidate, nodesToConnect} = this.props;
        const {nodeIdToRelEnumMap} = this.state;

        const createRelationshipChoiceText = nodesToConnect.length > 1 ?
            _transl(DiagramTranslationKey.NESTING_RELATIONSHIP_CREATE_RELATIONSHIPS) : _transl(DiagramTranslationKey.NESTING_RELATIONSHIP_CREATE_RELATIONSHIP);
        const doNotCreateRelationshipChoiceText = nodesToConnect.length > 1
            ? _transl(DiagramTranslationKey.NESTING_RELATIONSHIP_DO_NOT_CREATE_RELATIONSHIPS) : _transl(DiagramTranslationKey.NESTING_RELATIONSHIP_DO_NOT_CREATE_RELATIONSHIP);

        return (
            <Dialog
                open={true}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <DialogTitle title={_transl(DiagramTranslationKey.NESTING_RELATIONSHIP_CREATE_RELATIONSHIP_TITLE)}
                             onDialogClosed={onCancel} />
                <DialogContent>
                    <Grid container marginTop={2}>
                        <Grid item xs={12}>
                            <span>{nodesToConnect.length > 1 ?
                                _transl(DiagramTranslationKey.NESTING_RELATIONSHIP_NEW_RELATIONSHIP_ELEMENTS)
                                : _transl(DiagramTranslationKey.NESTING_RELATIONSHIP_NEW_RELATIONSHIP_ELEMENT)}
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{maxHeight: "20em", overflow: "auto"}}>
                            <table style={{borderSpacing: ".5em .5em", width: "100%"}}>
                                <tr>
                                    <th>{_transl(DiagramTranslationKey.NESTING_RELATIONSHIP_NESTED_ELEMENT)}</th>
                                    <th>{_transl(DiagramTranslationKey.NESTING_RELATIONSHIP_RELATIONSHIP)}</th>
                                </tr>
                            {nodesToConnect.map(node =>
                                {
                                    return <tr>
                                        <td>
                                                <span className={classes.nodeLabel}>
                                                    <span className={classes.iconWrapper}>
                                                        {this.getNodeIcon(node)}
                                                    </span>
                                                    {this.getNodeLabel(node)}
                                                </span>
                                        </td>
                                        <td>
                                            <Select
                                                value={nodeIdToRelEnumMap[node.elementIdentifier as string]}
                                                onChange={(event) => {
                                                    nodeIdToRelEnumMap[node.elementIdentifier as string] = event.target.value as ArchimateRelationshipType;
                                                    this.setState({nodeIdToRelEnumMap: nodeIdToRelEnumMap});
                                                }}
                                                variant={"outlined"}
                                                className={classes.relationshipSelect}
                                                fullWidth={true}
                                            >
                                                <MenuItem value={EMPTY_SELECT_VALUE}>{_transl(DiagramTranslationKey.NESTING_RELATIONSHIP_NONE)}</MenuItem>
                                                {NestingConnectionCreationDialog.getAvailableRelationships(parentCandidate, node).map(rel => {
                                                    const relationship = ArchimateRelationship[rel];
                                                    return <MenuItem value={rel}>{relationship.standardNames[0]}</MenuItem>
                                                })
                                                }
                                            </Select>
                                        </td>
                                    </tr>
                                }
                            )}
                            </table>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        autoFocus variant={"contained"}
                        onClick={(e) => {
                            this.createRelationships((relationships: Array<RelationshipDto>) => onConfirm(e, relationships))
                        }}
                    >
                        {createRelationshipChoiceText}
                    </Button>
                    <Button
                        onClick={(event) => onCancel(event)}
                        variant={"outlined"}
                    >
                        {doNotCreateRelationshipChoiceText}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    private static getAvailableRelationships(parentCandidate: IDiagramNodeDto, childNode: IDiagramNodeDto) {
        return PARENT_CHILD_RELATIONSHIP_TYPES;
    }

    private createRelationships(callback: (relationships: Array<RelationshipDto>) => void) {
        const {diagramApi, parentCandidate} = this.props;
        const {nodeIdToRelEnumMap} = this.state;
        const parentElementId = parentCandidate.elementIdentifier as string;

        const idsArray: ObjectType[] = [];
        Object.keys(nodeIdToRelEnumMap).forEach((id, index) => {
            if (nodeIdToRelEnumMap[id] !== EMPTY_SELECT_VALUE) {
                idsArray.push(ObjectType.RELATIONSHIP);
            }
        });
        diagramApi.generateIdentifiers(
            idsArray,
            (ids) => {
                const relationships: RelationshipDto[] = [];
                Object.keys(nodeIdToRelEnumMap).forEach((elementId, index) => {
                    if (nodeIdToRelEnumMap[elementId] !== EMPTY_SELECT_VALUE) {
                        const relationshipEnum = nodeIdToRelEnumMap[elementId] as ArchimateRelationshipType;
                        const relationship: RelationshipDto = {
                            identifier: ids.pop() as string,
                            type: ArchimateRelationship[relationshipEnum].standardNames[0],
                            source: {
                                conceptType: ConnectableConceptType.ELEMENT,
                                name: "",
                                identifier: relationshipEnum === ArchimateRelationshipType.SPECIALIZATION ? elementId : parentElementId,
                            },
                            target: {
                                conceptType: ConnectableConceptType.ELEMENT,
                                name: "",
                                identifier: relationshipEnum === ArchimateRelationshipType.SPECIALIZATION ? parentElementId : elementId,
                            },
                            acl: RelationshipAclDto.builder().build(),
                        }
                        relationships.push(relationship);
                    }
                });
                callback(relationships);
            },
            () => {})
    }

    private getNodeLabel(node: IDiagramNodeDto) {
        let label = this.props.diagramEditorApi.getNodeLabel(node) || `[${this.getNodeTypeName(node)}]`;
        if (label.length > 150) {
            label = label.substring(0, 150) + " ...";
        }
        return label;
    }

    private getNodeTypeName(node: IDiagramNodeDto) {
        return this.props.diagramEditorApi.getNodeTypeName(node);
    }

    private getNodeIcon(node: IDiagramNodeDto) {
        const {classes} = this.props;
        if (node.type === NodeType.ELEMENT) {
            const standardName = this.props.diagramEditorApi.getNodeElementTypeStandardName(node);
            return standardName ? <ElementTypeIcon name={standardName} isMenuIcon={false} clazz={classes.icon} /> : <span />
        } else if (node.type === NodeType.CONTAINER) {
            return <ElementTypeIcon name={ArchimateElement[ArchimateElementType.GROUPING].standardName} isMenuIcon={false} clazz={classes.icon} />
        } else {
            // LABEL
            if (DiagramEditorUtils.isDiagramReferenceNode(node)) {
                return <AccountTreeIcon />
            }
        }
    }
}

export default withStyles(styles, {withTheme: true})(NestingConnectionCreationDialog);
