import React, {useEffect, useState} from "react";
import {createStyles, makeStyles} from "@mui/styles";
import DiagramDetailPanel from "./DiagramDetailPanel";
import diagramService from "../service/DiagramService";
import constructDiagramController from "../controller/DiagramController";
import {DiagramDto} from "../../../../../common/apis/diagram/DiagramDto";
import DiagramDetailHeader from "./DiagramDetailHeader";
import Dialog from "../../../../../components/dialogs/Dialog";
import DialogContent from "../../../../../components/dialogs/DialogContent";

const useStyles = makeStyles(() =>
    createStyles({
        dialogPaper: {
            minWidth: "98%",
            height: "100%",
        }
    })
)

interface DiagramDetailDialogProps {
    diagramId: string,
    opened: boolean,
    onClosed: () => void,
    hideDiagramPreviewTab?: boolean,
    hideShowAndEditDiagramIcons?: boolean,
}

export default function DiagramDetailDialog(props: DiagramDetailDialogProps) {
    const {opened, onClosed, diagramId, hideDiagramPreviewTab, hideShowAndEditDiagramIcons} = props;
    const classes = useStyles();

    const controller = constructDiagramController();

    const [diagramDto, setDiagramDto] = useState<DiagramDto>()

    useEffect(() => {
        fetchDiagramDto(diagramId);
    }, [diagramId]);

    function fetchDiagramDto(id: string) {
        diagramService.findDiagramById(id)
            .then((response) => {
                setDiagramDto(response as DiagramDto)
            })
            .catch(() => {
            });
    }

    return (
        <React.Fragment>
            {diagramDto?.diagramInfo &&
                <Dialog open={opened}
                        aria-labelledby="diagram-detail-dialog"
                        onClose={onClosed}
                        PaperProps={{
                            className: classes.dialogPaper,
                        }}
                >
                    <DiagramDetailHeader
                        diagram={diagramDto.diagramInfo}
                        hideShowAndEditDiagramIcons={hideShowAndEditDiagramIcons}
                        onClosed={onClosed}
                    />
                    <DialogContent>
                        <DiagramDetailPanel id={diagramId}
                                            key={diagramId}
                                            diagramInfoDto={diagramDto?.diagramInfo}
                                            controller={controller}
                                            hideDiagramPreviewTab={hideDiagramPreviewTab}
                                            hideShowAndEditIcons={hideShowAndEditDiagramIcons}
                        />
                    </DialogContent>
                </Dialog>}
        </React.Fragment>
    );
}
