import React, {useState} from "react";
import ElementsPickDialog from "./ElementsPickDialog";
import {ArchimateRelationship} from "../../../../common/archimate/ArchimateRelationship";
import {SaveButton} from "../../../../components/button/SaveButton";
import {CancelButton} from "../../../../components/button/CancelButton";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import {_transl} from "../../../../store/localization/TranslMessasge";
import SingleselectComboBox from "../../../../components/fields/SingleselectComboBox";
import Snackbar from "../snackbar/Snackbar";
import AlertDialog, {AlertDialogType} from "../../../../components/dialogs/AlertDialog";
import {CreateRelationshipTranslationKey} from "./CreateRelationshipTranslationKey";
import {TextFieldWithMoreIcon} from "../../../../components/TextFieldWithAdornmentIcon";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogActions from "../../../../components/dialogs/DialogActions";
import DialogContent from "../../../../components/dialogs/DialogContent";
import Grid from "../../../../components/dialogs/Grid";
import {CommonTranslation} from "../CommonTranslation";
import elementsService from "./service/ElementService";

enum Direction {
    OUTGOING = "outgoing",
    INCOMING = "incoming"
}

interface RelationshipsCreateDialogProps {
    title: string,
    onClosed: () => void,
    selectedRows: Array<ElementDto>,
    refetchFilter: () => void,
}

export function CreateRelationshipDialog(props: RelationshipsCreateDialogProps) {
    const {title, onClosed, selectedRows, refetchFilter} = props;

    const [isUnsuccessfullyValidated, setUnsuccessfullyValidated] = useState<boolean>(false);
    const [errorDialogOpened, setErrorDialogOpened]  = useState(false);
    const [pickDialogOpened, setPickDialogOpened] = useState(false);
    const [pickedElement, setPickedElement] = useState<ElementDto | undefined>(undefined);
    const [direction, setDirection] = useState<string | undefined>(undefined);
    const [type, setType] = useState<string | undefined>(undefined);
    const [directionError, setDirectionError] = useState<string | undefined>(undefined);
    const [typeError, setTypeError] = useState<string | undefined>(undefined);
    const [pickedElementError, setPickedElementError] = useState<string | undefined>(undefined);

    function onElementsPicked(elements: ElementDto[]) {
        if (elements.length === 1) {
            const element = elements[0];
            if (!canCreateRelationships(element, selectedRows)) {
                showErrorDialog();
            } else {
                isUnsuccessfullyValidated && validatePickedElement(element);
                setPickedElement(element)
                closePickDialog();
            }
        }
    }

    function canCreateRelationships(commonElement: ElementDto, otherElements: ElementDto[]): boolean {
        return commonElement.acl.canUpdate || otherElements.some(element => element.acl.canUpdate);
    }

    function showPickDialog() {
        setPickDialogOpened(true)
    }

    function closePickDialog() {
        setPickDialogOpened(false);
    }

    function showErrorDialog() {
        setErrorDialogOpened(true);
    }

    function closeErrorDialog() {
        setErrorDialogOpened(false);
    }

    const validateDirection = (direction: string | undefined): boolean => {
        if (direction === undefined) {
            setDirectionError(_transl(CommonTranslation.FILL_OUT_ITEM_FROM_LIST));
            return false;
        } else {
            setDirectionError(undefined);
            return true;
        }
    };

    const validateType = (type: string | undefined): boolean => {
        if (type === undefined) {
            setTypeError(_transl(CommonTranslation.FILL_OUT_ITEM_FROM_LIST));
            return false;
        } else {
            setTypeError(undefined);
            return true;
        }
    };

    const validatePickedElement = (pickedElement: ElementDto | undefined): boolean => {
        if (pickedElement === undefined) {
            setPickedElementError(_transl(CommonTranslation.FILL_OUT_ITEM_FROM_LIST));
            return false;
        } else {
            setPickedElementError(undefined);
            return true;
        }

    };

    const isFormValid = (): boolean => {
        const isDirectionValid = validateDirection(direction);
        const isTypeValid = validateType(type);
        const isPickedElementValid = validatePickedElement(pickedElement);

        return isDirectionValid && isTypeValid && isPickedElementValid;
    };

    function saveChanges() {
        if (isFormValid()) {
            const identifier = pickedElement!.identifier;
            const relationshipDirection = direction === Direction.OUTGOING;

            if (identifier !== undefined) {
                createRelationship(identifier, relationshipDirection);
            }
        } else {
            setUnsuccessfullyValidated(true)
        }
    }

    function createRelationship(identifier: string, relationshipDirection: boolean) {
        elementsService.createRelationship(identifier, selectedRows.map(el => el.identifier), type!, relationshipDirection)
            .then(() => {
                refetchFilter();
                onClosed();
                Snackbar.success(_transl(CreateRelationshipTranslationKey.DIALOG_CREATE_RELATIONSHIP_DIRECTION_SUCCEEDED));
            })
            .catch(() => {
                Snackbar.error(_transl(CreateRelationshipTranslationKey.DIALOG_CREATE_RELATIONSHIP_DIRECTION_NOTSUCCEEDED));
            });
    }

    function getLabelForDirection(direction: Direction) {
        return (direction === Direction.OUTGOING)
            ? _transl(CreateRelationshipTranslationKey.COMMON_ELEMENT_IS_SOURCE)
            : _transl(CreateRelationshipTranslationKey.COMMON_ELEMENT_IS_TARGET);
    }

    return (
        <>
            <Dialog open onClose={onClosed}>

                <DialogTitle id="scroll-dialog-title"
                             title={title}
                             onDialogClosed={onClosed}/>

                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <SingleselectComboBox id={"direction-field"}
                                                  label={_transl(CreateRelationshipTranslationKey.RELATIONSHIP_DIRECTION)}
                                                  required={true}
                                                  options={[Direction.OUTGOING, Direction.INCOMING]}
                                                  getRenderLabel={(value) => getLabelForDirection(value)}
                                                  handleOnChange={(value) => {
                                                      isUnsuccessfullyValidated && validateDirection(value)
                                                      setDirection(value)
                                                  }}
                                                  errorMessage={directionError}
                                                  size={"small"}/>
                        </Grid>
                        <Grid item xs={12}>
                            <SingleselectComboBox id={"type-field"}
                                                  label={_transl(CreateRelationshipTranslationKey.RELATIONSHIP_TYPE)}
                                                  required={true}
                                                  options={ArchimateRelationship.values()}
                                                  getRenderLabel={(value) => value?.visibleName || ""}
                                                  handleOnChange={(value) => {
                                                      isUnsuccessfullyValidated && validateType(value?.standardNames[0])
                                                      setType(value?.standardNames[0])
                                                  }}
                                                  errorMessage={typeError}
                                                  size={"small"}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldWithMoreIcon key={pickedElement?.name || ""}
                                                   id="assignedItem-field"
                                                   required={true}
                                                   defaultValue={pickedElement?.name || ""}
                                                   label={_transl(CreateRelationshipTranslationKey.COMMON_ELEMENT)}
                                                   errorMessage={pickedElementError}
                                                   tooltipTitle={_transl(CreateRelationshipTranslationKey.FIND_COMMON_ELEMENT)}
                                                   onClick={() => showPickDialog()} />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <SaveButton onClick={saveChanges}/>
                    <CancelButton onClick={onClosed}/>
                </DialogActions>
            </Dialog>

            <ElementsPickDialog isOpened={pickDialogOpened}
                                isMultiSelection={false}
                                onElementsPicked={onElementsPicked}
                                onDialogClosed={closePickDialog} />

            <AlertDialog open={errorDialogOpened}
                         title={_transl(CreateRelationshipTranslationKey.NOT_ALLOWED_WARNING_TITLE)}
                         text={_transl(CreateRelationshipTranslationKey.NOT_ALLOWED_WARNING_MESSAGE)}
                         type={AlertDialogType.WARNING}
                         onClose={() => closeErrorDialog()} />
        </>
    );
}
