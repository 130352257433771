import RouteDefinitionID from "./RouteDefinitionID";
import RouteDefinitionType from "./RouteDefinitionType";
import {AccessResolverType} from "./AccessResolverType";

export default class RouteDefinition<T> {
    constructor(private _id: RouteDefinitionID,
                private _type: RouteDefinitionType,
                private _path: string,
                private _paramsTemplate: T,
                private _doRender?: (params: T) => JSX.Element,
                private _isExact?: boolean,
                private _isRedirect?: boolean,
                private _redirectPath?: string,
                private _accessResolver?: AccessResolverType,) {
    }

    public get id() {
        return this._id;
    }

    public get type() {
        return this._type;
    }

    public get path(): string {
        return this._path;
    }

    public get doRender() {
        return this._doRender;
    }

    public get paramsTemplate(): T {
        return this._paramsTemplate;
    }

    public get isExact(): boolean {
        return this._isExact != null ? this._isExact : true;
    }

    public get isRedirect(): boolean {
        return this._isRedirect != null ? this._isRedirect : false;
    }

    public get redirectPath(): string {
        return this._redirectPath || "";
    }

    public get accessResolver(): AccessResolverType | undefined {
        return this._accessResolver;
    }

}