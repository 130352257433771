import React from 'react';
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {Paper} from '@mui/material';
import CommonCssStyles from "../../../../css/CommonCssStyles";
import {Configuration} from "./Configuration";
import RouteDefinitionUtils, {
    importPage,
    INITIAL_CONFIGURATION_ID_PARAM
} from "../../../../common/routedefinition/RouteDefinitionUtils";
import {ConfigurationId} from "./Configuration";
import {ImportConfigurationNullable} from "../../../../common/Types";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ImportTranslationKey} from "./ImportTranslationKey";
import ImportPanel from "./ImportPanel";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: CommonCssStyles.getRootPageStyles(theme),
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        controlPageSegment: CommonCssStyles.getControlPageSegmentStyles(theme, {paddingTop: theme.spacing(7)}),
    })
);

interface Props {
    [INITIAL_CONFIGURATION_ID_PARAM]?: ConfigurationId,
}

function getInitialConfiguration(props: Props): ImportConfigurationNullable {
    const initialConfigurationId = props[INITIAL_CONFIGURATION_ID_PARAM];
    let initialConfiguration = null;
    if (initialConfigurationId != null) {
        initialConfiguration = Configuration.findById(Configuration.getConfigurations(), initialConfigurationId);
    }
    return initialConfiguration;
}

export default function ImportPage(props: Props) {

    const history = useHistory();
    const classes = useStyles();

    const initialConfiguration = getInitialConfiguration(props);
    const pageTitle = initialConfiguration != null ? initialConfiguration.pageLabel : _transl(ImportTranslationKey.TITLE);

    function resetImport() {
        const path = RouteDefinitionUtils.resolvePath(importPage, {});
        history.push(path);
    }

    return (
        <Paper className={classes.paper}>
            <div className={classes.headerPageSegment}>
                <Typography variant="h6">
                    {pageTitle}
                </Typography>
            </div>
            <Divider />
            <div className={classes.controlPageSegment}>
                <ImportPanel initialConfigurationId={props[INITIAL_CONFIGURATION_ID_PARAM]}
                             resetImport={resetImport} />
            </div>
        </Paper>
    );
}
