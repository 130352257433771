import {createStyles, WithStyles, withStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import React from "react";
import {TextField} from "@mui/material";
import {IPropertyDto} from "../../../../common/apis/CommonTypes";
import {SaveButton} from "../../../../components/button/SaveButton";
import {CancelButton} from "../../../../components/button/CancelButton";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "./DiagramTranslationKey";
import diagramService from "./service/DiagramService";
import {getLabelForPropertyType} from "../property/PropertyType";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../components/dialogs/DialogActions";
import Grid from "../../../../components/dialogs/Grid";
import Snackbar from "../snackbar/Snackbar";


const styles = (theme: Theme) => createStyles({
    textField: {
        width: "100%",
    },
});

interface IProps extends WithStyles<typeof styles> {
    //router props
    changePropertyDialogIsOpen: boolean,
    onClosed: () => void,
    diagramId: string,
    onPropertyChanged: () => void,
    selectedProperty?: IPropertyDto,
}

interface IState {
    name: string,
    type: string,
    value: string,
    nameError: boolean,
    typeError: boolean,
}

class AddItemPropertyDialog extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = this.createInitialState();
    }

    createInitialState() {
        return {
            name: "",
            type: "string",
            value: "",
            nameError: false,
            typeError: false,
            showBackendCallFailed: false,
        }
    }

    handleChange<P extends keyof IState>(property: P, value: IState[P]) {
        this.setState(state => {
            const copy = {...state};
            copy[property] = value;
            return copy;
        })
    }

    saveChanges() {
        const {selectedProperty} = this.props;
        const name = selectedProperty?.definition.name ? selectedProperty?.definition.name : "";
        const type = selectedProperty?.definition.type ? selectedProperty?.definition.type : "";
        const isNameError = name.trim().length === 0;
        const isTypeError = type.trim().length === 0;
        if (isNameError || isTypeError) {
            this.setState(state => {
                return {
                    ...state,
                    nameError: isNameError,
                    typeError: isTypeError,
                    showBackendCallFailed: false,
                }
            })
        } else {
            const {diagramId, onPropertyChanged, onClosed, selectedProperty} = this.props;
            const {value} = this.state;
            const propertyDefinitionId = selectedProperty?.definition.identifier;

            if (propertyDefinitionId !== undefined) {
                diagramService.updateDiagramProperty(diagramId, propertyDefinitionId, value)
                        .then(() => {
                            onClosed();
                            onPropertyChanged();
                        })
                        .catch((err) => {
                            Snackbar.error(_transl(DiagramTranslationKey.CHANGE_DIAGRAM_PROPERTY_FAILED_TO_EDIT), err);
                        });
            }
        }
    }

    private onDialogClosed() {
        this.setState(this.createInitialState())
        this.props.onClosed();
    }

    render() {

        const {classes, changePropertyDialogIsOpen, onClosed, selectedProperty} = this.props;

        return (
            <Dialog
                open={changePropertyDialogIsOpen}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                onClose={() => this.onDialogClosed()}
                fullWidth={true}
                maxWidth={"xs"}
            >
                <DialogTitle id="scroll-dialog-title"
                             title={_transl(DiagramTranslationKey.CHANGE_DIAGRAM_PROPERTY_DIALOG_TITLE)}
                             onDialogClosed={onClosed}/>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField className={classes.textField}
                                       id="name-field"
                                       label={_transl(DiagramTranslationKey.CHANGE_DIAGRAM_PROPERTY_TITLE)}
                                       variant="outlined"
                                       size={"small"}
                                       value={selectedProperty?.definition.name}
                                       InputProps={{
                                           readOnly: true,
                                       }}
                                       disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField className={classes.textField}
                                       id="type-field"
                                       label={_transl(DiagramTranslationKey.CHANGE_DIAGRAM_PROPERTY_TYPE)}
                                       variant="outlined"
                                       size={"small"}
                                       value={getLabelForPropertyType(selectedProperty?.definition.type)}
                                       InputProps={{
                                           readOnly: true,
                                       }}
                                       disabled={true}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField className={classes.textField}
                                       id="value-field"
                                       label={_transl(DiagramTranslationKey.CHANGE_DIAGRAM_PROPERTY_VALUE)}
                                       variant="outlined"
                                       size={"small"}
                                       onChange={(e) => this.handleChange("value", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SaveButton onClick={(e) => this.saveChanges()}/>
                    <CancelButton onClick={() => this.onDialogClosed()} variant={"outlined"}/>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles, {withTheme: true})(AddItemPropertyDialog);
