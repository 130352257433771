import IConnectionRenderer from "./IConnectionRenderer";
import {ModelManager} from "../../manager/ModelManager";
import * as d3 from "d3";
import ConnectionRendererUtils from "./ConnectionRendererUtils";
import {DiagramEditorUtils} from "../../util/DiagramEditorUtils";
import MarkerUtils from "../../util/MarkerUtils";
import RenderMode from "../../context/RenderMode";
import EventManager from "../../../event/EventManager";
import {EventType, IConnectionEvent} from "../../../event/Event";
import RenderContext from "../../context/RenderContext";
import MarkersRenderer from "../MarkersRenderer";
import {MarkerType} from "../marker/MarkerDefinitionFactory";
import {DEFAULT_FONT_COLOR, HIDDEN_CONNECTION_LINE_COLOR} from "../../common/UIConstants";
import {IDiagramConnectionDto} from "../../../apis/diagram/IDiagramConnectionDto";
import {FontStyleBuilder} from "../text/FontStyleBuilder";
import {ColorRenderer} from "../color/ColorRenderer";
import {ClickHandlerBuilder} from "../../util/ClickHandler";

export const TEXT_CLASS_NAME = "text";

export enum LineType {
    SOLID = "SOLID",
    DASHED_1_2 = "DASHED_1_2",
    DASHED_4_3 = "DASHED_4_3",
}

const defaultStrokeWidth = 1;
const bolderStrokeWidth = 2;
export const publisherStrokeWidth = defaultStrokeWidth + 9;

export type LineDef = {
    lineType: LineType,
    lineGroup: d3.Selection<SVGGElement, IDiagramConnectionDto, null, undefined>,
}

export type MarkerDef = {
    startMarker: MarkerType | undefined,
    endMarker: MarkerType | undefined,
}

export type ContextDef = {
    connection: IDiagramConnectionDto,
    isHidden: boolean,
    renderContext: RenderContext,
    eventManager: EventManager
}

export default abstract class AbstractConnectionRenderer implements IConnectionRenderer {

    public static readonly CLICK_THRESHOLD = 2;

    abstract init(diagramGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
                  defsGroup: d3.Selection<SVGDefsElement, unknown, null, undefined>,
                  modelAccessor: ModelManager): void;

    abstract render(connection: IDiagramConnectionDto,
                    connectionGroup: d3.Selection<SVGGElement, IDiagramConnectionDto, null, undefined>,
                    isHidden: boolean,
                    renderContext: RenderContext,
                    eventManager: EventManager): void;

    protected renderLine(lineDef: LineDef,
                         markerDef: MarkerDef,
                         contextDef: ContextDef) {
        const modelManager = contextDef.renderContext.modelManager;
        const mode = contextDef.renderContext.renderMode.mode;
        const isHidden = contextDef.isHidden;
        const connection = contextDef.connection;

        // create line path
        const linePath = ConnectionRendererUtils.createLinePath(connection, modelManager);

        const d = linePath?.path || "";

        const lineColor = isHidden ? HIDDEN_CONNECTION_LINE_COLOR : ConnectionRendererUtils.createLineColor(connection);

        // create line with given path
        const line = lineDef.lineGroup
            .append("path")
            .attr("d", d)
            .attr("id", ConnectionRendererUtils.createLineId(connection))
            .attr("stroke", lineColor)
            .attr("stroke-width", defaultStrokeWidth)
            .attr("pointer-events", "none");   // let all events fall through

        ConnectionRendererUtils.appendLinePathProperty(line, linePath);

        // apply linetype and markers
        AbstractConnectionRenderer.applyLineType(lineDef.lineType, line);
        this.applyMarkers(markerDef.startMarker, markerDef.endMarker, line, lineColor, contextDef.renderContext);

        if (mode !== RenderMode.PREVIEW) {
            // append label
            const label = DiagramEditorUtils.getConnectionLabel(connection, modelManager);
            let labelGroup = null;
            if (label) {
                labelGroup = this.applyLabel(label, lineDef.lineGroup, line);
            }

            line.append("title").text(label);

            if (mode === RenderMode.EDIT || mode === RenderMode.PRE_EDIT) {
                // append pointer events publisher
                this.appendPointerEventsPublisher(lineDef.lineGroup, connection, d, labelGroup, label, isHidden, contextDef.eventManager);
            }
        }

    }

    private appendPointerEventsPublisher(lineGroup: d3.Selection<SVGGElement, IDiagramConnectionDto, null, undefined>,
                                         connection: IDiagramConnectionDto,
                                         connectionPath: string | null,
                                         labelGroup: d3.Selection<SVGTextElement, IDiagramConnectionDto, null, undefined> | null,
                                         label: string,
                                         isHidden: boolean,
                                         eventManager: EventManager) {
        const eventsPublisher = lineGroup.append("path")
            .lower()
            .attr("id", ConnectionRendererUtils.createLinePointerEventsPublisherId(connection))
            .attr("d", connectionPath)
            .attr("fill", "none")
            .attr("stroke", "none")
            .attr("stroke-width", publisherStrokeWidth)
            .attr("pointer-events", "all");
        eventsPublisher.append("title").text(label);

        eventsPublisher.attr("pointer-events", "stroke"); // publish events when on stroke (not BBox)
        this.applyPointerEvents(eventsPublisher, eventManager, connection, isHidden);
        if (labelGroup) {
            this.applyPointerEvents(labelGroup, eventManager, connection, isHidden);
        }
    }

    private applyPointerEvents(selection: d3.Selection<any, IDiagramConnectionDto, null, undefined>,
                               eventManager: EventManager,
                               connection: IDiagramConnectionDto,
                               isHidden: boolean) {
        const clickHandler = ClickHandlerBuilder.builder()
            .setOnClick((event: any) => this.onConnectionClicked(isHidden, eventManager, connection, event))
            .setOnDblClick((event: any) => this.onConnectionDblClicked(connection, eventManager, event))
            .build();
        selection
            .on("mouseenter", (event: any, connection) => {
                AbstractConnectionRenderer.applyBolderStyle(true, connection);
                const eventType = isHidden ? EventType.HIDDEN_CONNECTION_MOUSE_ENTER : EventType.CONNECTION_MOUSE_ENTER
                eventManager.publishEvent({type: eventType, connection: connection, event: event});
            })
            .on("mouseleave", (event, connection) => {
                AbstractConnectionRenderer.applyBolderStyle(false, connection);
                const eventType = isHidden ? EventType.HIDDEN_CONNECTION_MOUSE_LEAVE : EventType.CONNECTION_MOUSE_LEAVE;
                eventManager.publishEvent({type: eventType, connection: connection, event: event});
            })
            .on("mousedown", (event: any) => {
                const eventType = isHidden ? EventType.HIDDEN_CONNECTION_MOUSE_DOWN : EventType.CONNECTION_MOUSE_DOWN;
                eventManager.publishEvent({type: eventType, connection: connection, event: event});
            })
            .on("click", (event, connection) => {
                // seems that svg path doesn't fire dblclick events -> emulate it
                clickHandler.onClick(event);
            });
    }

    private onConnectionClicked(isHidden: boolean, eventManager: EventManager, connection: IDiagramConnectionDto, event: any) {
        const eventType = isHidden ? EventType.HIDDEN_CONNECTION_MOUSE_CLICKED : EventType.CONNECTION_MOUSE_CLICKED;
        eventManager.publishEvent({type: eventType, connection: connection, event: event});
        if (this.isRightButtonClick(event)) {
            // seems that svg path doesn't fire contextmenu events -> emulate it
            const eventType = isHidden ? EventType.HIDDEN_CONNECTION_SHOW_CONTEXT_MENU : EventType.CONNECTION_SHOW_CONTEXT_MENU;
            eventManager.publishEvent<IConnectionEvent>({type: eventType, connection: connection, event: event});
        }
    }

    private onConnectionDblClicked(connection: IDiagramConnectionDto, eventManager: EventManager, event: any) {
        AbstractConnectionRenderer.applyBolderStyle(false, connection);
        eventManager.publishEvent({type: EventType.CONNECTION_DBLCLICK, connection: connection, event: event});
    }

    private static applyBolderStyle(apply: boolean, connection: IDiagramConnectionDto) {
        const strokeWidth = apply ? bolderStrokeWidth : defaultStrokeWidth;
        const fontWeight = apply ? "bolder" : "normal";

        d3.select(ConnectionRendererUtils.createLineId(connection, true)).attr("stroke-width", strokeWidth);
        d3.select(ConnectionRendererUtils.createTextId(connection, true)).attr("font-weight", fontWeight);
        d3.select(ConnectionRendererUtils.createTextShadowId(connection, true)).attr("font-weight", fontWeight);
    }

    private static applyLineType(lineType: LineType,
                                 linePath: d3.Selection<SVGPathElement, IDiagramConnectionDto, null, undefined>) {
        if (lineType === LineType.DASHED_4_3) {
            linePath.attr("stroke-dasharray", "4 3")
        } else if (lineType === LineType.DASHED_1_2) {
            linePath.attr("stroke-dasharray", "1 2")
        }
    }

    private applyMarkers(startMarker: MarkerType | undefined,
                         endMarker: MarkerType | undefined,
                         linePath: d3.Selection<SVGPathElement, IDiagramConnectionDto, null, undefined>,
                         lineColor: string,
                         context: RenderContext) {
        if (startMarker) {
            MarkersRenderer.renderMarker(startMarker, lineColor, context);
            linePath.attr("marker-start", () => `url(${new URL(`#${MarkerUtils.createMarkerId(startMarker, lineColor)}`, window.location.href)})`);
        }
        if (endMarker) {
            MarkersRenderer.renderMarker(endMarker, lineColor, context);
            linePath.attr("marker-end", () => `url(${new URL(`#${MarkerUtils.createMarkerId(endMarker, lineColor)}`, window.location.href)})`);
        }
    }

    private applyLabel(label: string,
                       lineGroup: d3.Selection<SVGGElement, IDiagramConnectionDto, null, undefined>,
                       linePath: d3.Selection<SVGPathElement, IDiagramConnectionDto, null, undefined>) {

        const pathNode = linePath.node() as SVGPathElement;

        const centerPoint = pathNode.getPointAtLength(pathNode.getTotalLength() / 2);

        return lineGroup.append("text")
            .classed(TEXT_CLASS_NAME, true)
            .attr("id", connection => ConnectionRendererUtils.createTextShadowId(connection))
            .attr("font-family", connection => ConnectionRendererUtils.createFontFamily(connection))
            .attr("font-size", connection => ConnectionRendererUtils.createFontSize(connection))
            .attr("font-style", connection => this.buildFontStyle(connection))
            .attr("font-weight", connection => this.buildFontWeight(connection))
            .attr("text-decoration", connection => this.buildTextDecoration(connection))
            .attr("fill", "none")
            .attr("stroke", "white")
            .attr("stroke-width", 2)
            .style("user-select", "none")
            .text(label)
            .attr("x", function (d) {
                return centerPoint.x - this.getComputedTextLength() / 2;
            })
            .attr("y", centerPoint.y - 2)
            .clone(true)
            .attr("id", connection => ConnectionRendererUtils.createTextId(connection))
            .attr("fill", connection => this.buildFontColor(connection))
            .attr("stroke", "none")
            .attr("stroke-width", 0)
            .attr("font-family", connection => ConnectionRendererUtils.createFontFamily(connection))
            .attr("font-size", connection => ConnectionRendererUtils.createFontSize(connection))
            .attr("font-style", connection => this.buildFontStyle(connection))
            .attr("font-weight", connection => this.buildFontWeight(connection))
            .attr("text-decoration", connection => this.buildTextDecoration(connection))
    }

    private buildFontColor(connection: IDiagramConnectionDto) {
        return ColorRenderer.renderColor(connection.style?.font?.color, DEFAULT_FONT_COLOR);
    }

    private buildFontStyle(connection: IDiagramConnectionDto) {
        return FontStyleBuilder.buildFontStyle(this.getFont(connection));
    }

    private buildFontWeight(connection: IDiagramConnectionDto) {
        return FontStyleBuilder.buildFontWeight(this.getFont(connection));
    }

    private buildTextDecoration(connection: IDiagramConnectionDto) {
        return FontStyleBuilder.buildTextDecoration(this.getFont(connection));
    }

    private getFont(connection: IDiagramConnectionDto) {
        return connection.style?.font || null;
    }

    private isRightButtonClick(event: any) {
        return event?.which === 3;
    }
}
