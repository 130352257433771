import React, {useState} from "react";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../../../store/Store";
import Constants from "../../../../common/Constants";
import SingleselectElementTypesComboBox from "../../../../components/fields/SingleselectElementTypesComboBox";
import {IElementTypeDto} from "../../../../common/apis/Elements";
import { _transl } from "../../../../store/localization/TranslMessasge";
import { StereotypesTranslationKey } from "./StereotypesTranslationKey";
import {SaveButton} from "../../../../components/button/SaveButton";
import {CancelButton} from "../../../../components/button/CancelButton";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import TextField from "../../../../components/fields/textfield/TextField";
import DialogActions from "../../../../components/dialogs/DialogActions";
import Grid from "../../../../components/dialogs/Grid";
import {CommonTranslation} from "../CommonTranslation";
import Snackbar from "../snackbar/Snackbar";
import stereotypeService from "../../../../common/apis/StereotypeService";

export interface IStereotypeCreateDto {
    name: string;
    elementType: string;
}

interface CreateStereotypeDialogProps {
    stereotypesAddDialogIsOpen: boolean,
    onClosed: () => void,
    onLabelAdded: () => void,
}

export default function CreateStereotypeDialog(props: CreateStereotypeDialogProps) {
    const {onClosed, stereotypesAddDialogIsOpen, onLabelAdded} = props;

    const [name, setName] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [selectedValue] = useState<IElementTypeDto>();
    const [nameError, setNameError] = useState<string | undefined>(undefined);
    const [typeError, setTypeError] = useState<string | undefined>(undefined);
    const typeOptions = useSelector((state: IApplicationState) => state.pages.common.options.types.resource);

    const validateName = (name: string): boolean => {
        let isNameValid;

        if (name.trim().length === 0) {
            setNameError(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
            isNameValid = false;
        } else {
            setNameError(undefined);
            isNameValid = true;
        }

        return isNameValid;
    }

    const validateType = (type: string): boolean  => {
        let isTypeValid;

        if (type.trim().length === 0) {
            setTypeError(_transl(CommonTranslation.FILL_OUT_ITEM_FROM_LIST));
            isTypeValid = false;
        } else {
            setTypeError(undefined);
            isTypeValid = true;
        }

        return isTypeValid;
    }

    const isFormValid = () => {
        const isNameValid = validateName(name)
        const isTypeValid = validateType(type)

        return isNameValid && isTypeValid;
    }

    const createStereotypDto = (name: string, type: string): IStereotypeCreateDto => {
        return {name: name, elementType: type};
    }

    function saveChanges() {
        if (isFormValid()) {
            stereotypeService.createStereotype(createStereotypDto(name, type))
                .then(() => {
                    onClosed();
                    onLabelAdded();
                    setName("");
                    setType("");
                    Snackbar.success(_transl(StereotypesTranslationKey.CREATE_STEREOTYPE_SUCCEEDED));
                })
                .catch(() => {
                    Snackbar.error(_transl(StereotypesTranslationKey.CREATE_STEREOTYPE_NOT_SUCCEEDED));
                    setTimeout(() => Constants.FE_APP_ALERT_DELAY);
                });
        }
    }

    return (
         <Dialog open={stereotypesAddDialogIsOpen} onClose={onClosed} >

            <DialogTitle id="scroll-dialog-title"
                         title={_transl(StereotypesTranslationKey.ADD_STEREOTYPE_TITLE)}
                         onDialogClosed={onClosed}/>

            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField id="name-field"
                                   label={_transl(StereotypesTranslationKey.ADD_STEREOTYPE_NAME)}
                                   value={name}
                                   errorMessage={nameError}
                                   onChange={(name) => setName(name)}
                                   required={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <SingleselectElementTypesComboBox id="name-field"
                                                          label={_transl(StereotypesTranslationKey.ADD_STEREOTYPE_DERIVED_FROM)}
                                                          errorMessage={typeError}
                                                          options={typeOptions}
                                                          selectedValue={selectedValue}
                                                          handleOnChange={(type) => {
                                                              const newType = type?.name
                                                              setType(newType)
                                                          }}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SaveButton onClick={saveChanges}/>
                <CancelButton onClick={onClosed}/>
            </DialogActions>
        </Dialog>
    );
}
