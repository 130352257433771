import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles"
import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import {SaveButton} from "../../../../components/button/SaveButton";
import {CancelButton} from "../../../../components/button/CancelButton";
import {_transl} from "../../../../store/localization/TranslMessasge";
import Dialog from "../../../../components/dialogs/Dialog";
import DialogTitle from "../../../../components/dialogs/DialogTitle";
import DialogContent from "../../../../components/dialogs/DialogContent";
import DialogActions from "../../../../components/dialogs/DialogActions";
import TextField from "../../../../components/fields/textfield/TextField";
import {CommonTranslation} from "../CommonTranslation";
import {ElementTranslationKey} from "./ElementTranslationKey";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContainer: {
            display: "flex",
            width: "100%",
            paddingTop: "1em",
            paddingBottom: "1em",
        },
        gridItem: {
            width: "100%",
            padding: theme.spacing(2, 0, 1, 0),
        },
    }));

interface CustomPropertyColumnAddDialogProps {
    open: boolean;
    existingCustomPropertyNames: string[];
    onAddCustomColumn: (attributeName: string) => void;
    onClosed: () => void;
}

export default function CustomPropertyColumnAddDialog(props: CustomPropertyColumnAddDialogProps) {

    const classes = useStyles();
    const {open, onAddCustomColumn, onClosed} = props;
    const existingCustomAttributeNames = new Set(...props.existingCustomPropertyNames);

    const [name, setName] = useState<string>("");
    const [nameErrorMessage, setNameErrorMessage] = useState<string | undefined>();

    function changeName(name: string) {
        setName(name);
    }

    function save() {
        if (validate()) {
            onAddCustomColumn(name);
            close();
        }
    }

    function validate() {
        if (!name) {
            setNameErrorMessage(_transl(CommonTranslation.FILL_OUT_THIS_FIELD));
            return false;
        }
        if (existingCustomAttributeNames.has(name)) {
            setNameErrorMessage(_transl(ElementTranslationKey.CUSTOM_PROPERTY_COLUMN_ADD_DIALOG_COLUMN_ALREADY_EXISTS));
            return false;
        }
        return true;
    }

    function close() {
        onClosed();
        resetState();
    }

    function resetState() {
        setName("")
        setNameErrorMessage(undefined);
    }

    return (
        <Dialog open={open}>
            <DialogTitle title={_transl(ElementTranslationKey.CUSTOM_PROPERTY_COLUMN_ADD_DIALOG_TITLE)} onDialogClosed={onClosed} />
            <DialogContent>
                <Grid container className={classes.dialogContainer}>
                    <Grid item width={"100%"}>
                        <TextField id="name-field"
                                   label={_transl(ElementTranslationKey.CUSTOM_PROPERTY_COLUMN_ADD_DIALOG_NAME)}
                                   value={name}
                                   onChange={(e) => changeName(e)}
                                   required={true}
                                   errorMessage={nameErrorMessage}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SaveButton onClick={(e) => save()}/>
                <CancelButton
                    onClick={() => {
                        close();
                    }}
                />
            </DialogActions>
        </Dialog>
    );
}
