import React, {useState} from 'react';
import Wizard from "../../../../components/wizard/Wizard";
import {IconButton} from "@mui/material";
import {AjaxResponse} from "rxjs/ajax";
import Api from "../../../../common/Api";
import {WizardStep} from "../../../../components/wizard/WizardStep";
import {PersistentStateId} from "../../../../store/common/Grid";
import {ActionButtonType, EnabledPolicy, GridAction} from "../../../../components/grid/GridAction";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ElementsPickDialog from "../elements/ElementsPickDialog";
import {BlobUtils} from "../../../../common/BlobUtils";
import ActionButtonUtils from "../../../../components/grid/ActionButtonUtils";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {FileFormat, IElementsExportDto} from "../../../../common/apis/Exports";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {ExportTranslationKey} from "./ExportTranslationKey";
import {createElementsExportWizardGridColDef} from "./ElementExportWizardGrid";
import {useStyles} from "./ElementExportWizardStyle";
import ExtGridWrapper from "../../../../components/grid/ExtGridWrapper";

const ELEMENTS_GRID_ID = PersistentStateId.EXPORT_MODEL_ELEMENTS_GRID;

enum ButtonId {
    ADD_ITEMS = "ADD_ITEMS",
    REMOVE_ITEM = "REMOVE_ITEM",
}

interface ElementExportWizardProps {
    resetExport: () => void,
}

export default function ElementExportWizard(props: ElementExportWizardProps) {

    const classes = useStyles();

    const [elements, setElements] = useState<Array<ElementDto>>([]);
    const [selectElementsDialogOpened, setSelectElementsDialogOpened] = useState<boolean>(false);

    function getSteps(): WizardStep[] {
        return [
            new WizardStep(
                _transl(ExportTranslationKey.MODEL_STEP_ELEMENTSSELECTION_LABEL),
                <AccountTreeIcon/>,
                <div className={classes.wizardElementSelectionDescription}>
                    <div>{_transl(ExportTranslationKey.MODEL_STEP_ELEMENTSSELECTION_LABEL)}</div>
                    <div>
                        <IconButton
                            aria-label="close"
                            onClick={() => ActionButtonUtils.clickOnImmediateButton(ButtonId.ADD_ITEMS, ELEMENTS_GRID_ID)}
                            size="large">
                            <AddIcon/>
                        </IconButton>
                    </div>
                </div>,
                false,
                () => renderSelectElementsStep(),
                () => canProceedFromSelectElementsStep())
        ];
    }

    function removeItems<T extends { identifier: string }>(items: Array<T>, identifiersToRemove: Array<string>) {
        return items.filter(item => identifiersToRemove.indexOf(item.identifier) === -1);
    }

    function addItems<T extends { identifier: string }>(items: Array<T>, itemsToBeAdded: Array<T>): Array<T> {
        const newItems = removeItems(itemsToBeAdded, items.map(item => item.identifier));
        return [...items, ...newItems];
    }

    function renderSelectElementsStep(): JSX.Element {
        return <div className={classes.itemsGridDiv}>
            {selectElementsDialogOpened &&
                <ElementsPickDialog isOpened={selectElementsDialogOpened}
                                    isMultiSelection={true}
                                    onElementsPicked={(pickedItems) => {
                                        setElements(addItems(elements, pickedItems));
                                        setSelectElementsDialogOpened(false);
                                    }}
                                    onDialogClosed={() => setSelectElementsDialogOpened(false)}/>}
            {
                <ExtGridWrapper
                    columns={createElementsExportWizardGridColDef(_transl)}
                    rows={elements}
                    rowCount={elements.length}
                    getRowId={row => row.identifier}
                    actions={[
                        GridAction.buttonBuilder(ButtonId.ADD_ITEMS, ActionButtonType.IMMEDIATE, _transl(ExportTranslationKey.MODEL_ELEMENTSGRID_BUTTON_ADD),
                            <AddIcon/>)
                            .enabledPolicy(EnabledPolicy.ALWAYS)
                            .onClick(() => setSelectElementsDialogOpened(true)).build(),
                        GridAction.buttonBuilder(ButtonId.REMOVE_ITEM, ActionButtonType.IMMEDIATE, _transl(ExportTranslationKey.MODEL_ELEMENTSGRID_BUTTON_REMOVE),
                            <DeleteIcon/>)
                            .onClick(selectedRowIds => setElements(removeItems(elements, selectedRowIds as Array<string>)))
                            .build(),
                    ]}
                    peristentStateId={ELEMENTS_GRID_ID}
                    resourceId={"elements"}
                />
            }
        </div>
    }

    function canProceedFromSelectElementsStep(): boolean {
        return elements.length > 0
    }

    function doExport(): Promise<any> {
        const dto: IElementsExportDto = {
            filter: {identifiers: elements.map(dto => dto.identifier)},
            format: FileFormat.EXCEL.getFileFormatType(),
        }
        return Api.exports.exportElements(dto).toPromise();
    }


    return (
        <Wizard steps={getSteps()}
                lastStepLabel={_transl(ExportTranslationKey.MODEL_LAST_STEP_LABEL)}
                lastStepButtonLabel={_transl(ExportTranslationKey.MODEL_LAST_STEP_BUTTON_LABEL)}
                lastStepAction={() => doExport()}
                lastStepActionSuccessProcessor={(result) => {
                    const response = result as AjaxResponse;
                    const disposition = response.xhr.getResponseHeader('Content-Disposition') as string;
                    const fileName = disposition.substring(disposition?.indexOf("filename=") + 9);
                    const blob = new Blob([response.response]);

                    BlobUtils.saveBlob(blob, fileName);
                }}
                lastStepActionInProgressText={_transl(ExportTranslationKey.ELEMENTS_LAST_STEP_IN_PROGRESS_TEXT)}
                lastStepActionSuccessfulText={_transl(ExportTranslationKey.ELEMENTS_LAST_STEP_SUCCESSFUL_TEXT)}
                lastStepActionFailedText={_transl(ExportTranslationKey.ELEMENTS_LAST_STEP_FAILED_TEXT)}
                cancelWizard={props.resetExport}
                wizardGridMinHeight={"25em"}
        />
    );

}
