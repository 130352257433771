import React, {useEffect, useState} from "react";
import {ILabelDto} from "../../../../../common/apis/label/ILabelDto";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {
    BulkOperationsLabelsTranslation
} from "../../diagrams/bulk/BulkOperationsLabelsTranslation";
import labelsService from "../../labels/service/LabelsService";
import Snackbar from "../../snackbar/Snackbar";
import {LabelsTranslationKey} from "../../labels/LabelsTranslationKey";
import Api from "../../../../../common/Api";
import LabelsDialog from "../../labels/LabelsDialog";
import DialogTitle from "../../../../../components/dialogs/DialogTitle";
import {IconButton, Tooltip} from "@mui/material";
import {LocalOffer} from "@mui/icons-material";
import DialogContent from "../../../../../components/dialogs/DialogContent";
import PickList from "../../../../../components/picklist/PickList";
import DialogActions from "../../../../../components/dialogs/DialogActions";
import {CancelButton} from "../../../../../components/button/CancelButton";
import Dialog from "../../../../../components/dialogs/Dialog";
import {SaveButton} from "../../../../../components/button/SaveButton";
import {ElementDto} from "../../../../../common/apis/element/ElementDto";

interface RemoveBulkLabelsFromElementsDialogProps {
    isOpened: boolean,
    onClosed: () => void,
    elements: ElementDto[]
    refetchFilter: () => void,
}

export default function RemoveBulkLabelsFromElementsDialog(props: RemoveBulkLabelsFromElementsDialogProps) {
    const {isOpened, onClosed, elements, refetchFilter} = props;

    const [labels, setLabels] = useState<ILabelDto[]>([]);
    const [reload, setReload] = useState<number>(0);
    const [labelsAdministration, setLabelsAdministration] = useState<boolean>(false);

    const [pickedOptions, setPickedOptions] = useState<ILabelDto[]>([]);

    useEffect(() => {
        let isUnmounted = false;
        labelsService.getAll()
            .then((allLabels) => {
                if (!isUnmounted) {
                    const assignedLabelsCodes = new Set(elements.flatMap(diagramInfoDto => diagramInfoDto.labels).map(label => label.code));
                    let availableLabelOptions = allLabels.filter(labelDto => assignedLabelsCodes!.has(labelDto.code));
                    if (elements.find(value => !value.acl.canUpdatePublicLabels)) {
                        availableLabelOptions.filter(value => !value.publicLabel)
                    }
                    setLabels(availableLabelOptions);
                }
            })
            .catch((error) => Snackbar.error(_transl(LabelsTranslationKey.NOT_FETCHED)));
        return () => {
            isUnmounted = true;
        }
    }, [reload, elements]);

    function saveChanges() {
        const labelCodes = (pickedOptions as ILabelDto[]).map(options => options.code);
        Api.elements.bulkDeleteLabels(labelCodes, elements.map(value => value.identifier))
            .subscribe(
                () => {
                    onClosed();
                    refetchFilter();
                },
                (err) => {
                    Snackbar.error(_transl(BulkOperationsLabelsTranslation.LABELS_REMOVE_FAILED_TEXT), err);
                }
            );
    }

    return (
        <React.Fragment>
            <LabelsDialog open={labelsAdministration}
                          onClosed={() => {
                              setLabelsAdministration(false);
                              setReload(reload + 1);
                          }}/>
            <Dialog open={isOpened}
                    aria-labelledby="picklist-dialog-title"
                    onClose={onClosed}
                    fullWidth={true}
                    maxWidth={'md'}>
                <DialogTitle id="picklist-dialog-title"
                             title={_transl(BulkOperationsLabelsTranslation.LABELS_REMOVE_TITLE)}
                             onDialogClosed={onClosed}
                             right={
                                 <Tooltip title={_transl(LabelsTranslationKey.ADMINISTRATION_TOOLTIP)}>
                                     <IconButton onClick={() => setLabelsAdministration(true)}>
                                         <LocalOffer/>
                                     </IconButton>
                                 </Tooltip>}/>
                <DialogContent>
                    <PickList
                        initialAvailableOptions={labels}
                        initialPickedOptions={[]}
                        getOptionText={(item: unknown) => item ? (item as ILabelDto).name : ""}
                        availableOptionsTitle={_transl(BulkOperationsLabelsTranslation.LABELS_AVAILABLE_OPTIONS)}
                        pickedOptionsTitle={_transl(BulkOperationsLabelsTranslation.LABELS_REMOVE_TITLE_PICKED_OPTIONS)}
                        onPickedOptionsUpdated={(pickedOptions) => setPickedOptions(pickedOptions as Array<ILabelDto>)}/>
                </DialogContent>
                <DialogActions>
                    <SaveButton onClick={saveChanges} disabled={pickedOptions.length === 0}/>
                    <CancelButton onClick={onClosed}/>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}